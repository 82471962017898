import React from 'react';

export default ({ fill }) => (
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='M16.8125 15.4688H16.0312V10C16.0312 6.82892 13.921 4.14169 11.0309 3.26828C11.1809 2.9657 11.2656 2.62543 11.2656 2.26562C11.2656 1.01639 10.2492 0 9 0C7.75076 0 6.73438 1.01639 6.73438 2.26562C6.73438 2.62543 6.81906 2.9657 6.96906 3.26828C4.07904 4.14169 1.96875 6.82892 1.96875 10V15.4688H1.1875C0.755981 15.4688 0.40625 15.8185 0.40625 16.25C0.40625 16.6815 0.755981 17.0312 1.1875 17.0312H5.17584C5.53625 18.7253 7.04382 20 8.84375 20H9.15625C10.9562 20 12.4637 18.7253 12.8242 17.0312H16.8125C17.244 17.0312 17.5938 16.6815 17.5938 16.25C17.5938 15.8185 17.244 15.4688 16.8125 15.4688ZM8.29688 2.26562C8.29688 1.8779 8.61227 1.5625 9 1.5625C9.38773 1.5625 9.70312 1.8779 9.70312 2.26562C9.70312 2.65335 9.38773 2.96875 9 2.96875C8.61227 2.96875 8.29688 2.65335 8.29688 2.26562ZM9.15625 18.4375H8.84375C7.91266 18.4375 7.11554 17.8528 6.80045 17.0312H11.1996C10.8845 17.8528 10.0873 18.4375 9.15625 18.4375ZM3.53125 15.4688V10C3.53125 6.98456 5.98456 4.53125 9 4.53125C12.0154 4.53125 14.4688 6.98456 14.4688 10V15.4688C13.4072 15.4688 3.94415 15.4688 3.53125 15.4688Z'
    />
  </svg>
);
