import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext, Form } from 'formik';
import { object, string } from 'yup';

import OutsideClickHandler from 'react-outside-click-handler';
import { FormInput } from 'components';

const styles = (theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    width: '60%',
  },
  container: {
    background: theme.palette.background.paper,
    boxShadow: '0 8px 16px 2px rgba(15, 18, 26, 0.08)',
    borderRadius: theme.radius,
    height: '120px',
    padding: '10px',
    margin: '20px',
  },

  submitLink: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
    margin: '0',
    padding: '0',
  },
  cardLabel: {
    fontSize: '16px',
    color: theme.palette.secondary.grayText,
    textTransform: 'uppercase',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  input: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  textXL: {
    fontSize: 18,
    marginTop: 8,
    textTransform: 'capitalize',
  },
});

const EditTag = ({ classes, error, onSubmit, tag, onOutsideClick }) => {
  const FormBody = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form>
        <div className={classes.container}>
          <div className={classes.controls}>
            <div className={classes.cardLabel}>{'Edit Tag'}</div>
            <div>
              <button className={classes.submitLink} href={'#'} type={'submit'}>
                {'Save'}
              </button>
            </div>
          </div>
          <div className={classes.textXL}>
            <FormInput error={error} name='title' placeholder='Tag Name' />
          </div>
        </div>
      </Form>
    );
  };

  const validationSchema = object().shape({
    title: string().min(3).required('Plan name is required'),
  });

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Formik
        initialValues={{ title: tag.label }}
        onSubmit={(values) => onSubmit({ id: tag.value, tag: values })}
        validationSchema={validationSchema}
      >
        <FormBody />
      </Formik>
    </OutsideClickHandler>
  );
};

export default withStyles(styles)(EditTag);
