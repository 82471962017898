import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';

import { Types as ExerciseTypes } from 'modules/dashboard/exercises';
import { MixpanelEvents, track } from 'core/utils/mixpanel';
import {
  getWorkoutFormat,
  getWorkoutTags,
  getWorkoutType,
} from 'core/utils/workouts';

const INITIAL_STATE = {
  currentWorkout: null,
  dailyClass: [],
  error: null,
  painAndInjury: [],
  preWorkout: [],
  processing: false,
  processedItemId: -1,
  recovery: [],
  results: null,
  workRoutine: [],
  workouts: [],
};

const WORKOUTS = [
  'dailyClass',
  'painAndInjury',
  'preWorkout',
  'recovery',
  'workRoutine',
];

// Action creators
const { Types, Creators } = createActions(
  {
    getWorkoutsSuccess: ['response'],
    getWorkoutsFail: ['error'],
    getWorkouts: () => ({
      type: 'workouts/GET_WORKOUTS',
      payload: {
        request: {
          url: '/workouts',
          method: 'get',
        },
      },
    }),
    getWorkoutSuccess: ['response'],
    getWorkoutFail: ['error'],
    getWorkout: (id) => ({
      type: 'workouts/GET_WORKOUT',
      payload: {
        request: {
          url: `/workouts/${id}`,
          method: 'get',
        },
      },
    }),
    sendStatsSuccess: ['response'],
    sendStatsFail: ['error'],
    sendStats: (data) => ({
      type: 'workouts/SEND_STATS',
      payload: {
        request: {
          url: '/user/routine-statistics',
          method: 'post',
          data,
        },
      },
    }),
    addFavoriteSuccess: ['response'],
    addFavoriteFail: ['error'],
    addFavorite: ({ id }) => {
      console.log(id , "HELLO WOTLD")
      return {
      type: 'workouts/ADD_FAVORITE',
      payload: {
        request: {
          url: `/workouts/favorites/${id}`,
          method: 'post',
        },
        id,
      },
    }},
    deleteFavoriteSuccess: ['response'],
    deleteFavoriteFail: ['error'],
    deleteFavorite: ({ id }) => ({
      type: 'workouts/DELETE_FAVORITE',
      payload: {
        request: {
          url: `/workouts/favorites/${id}`,
          method: 'delete',
        },
        id,
      },
    }),
    updateResults: ['results'],
  },
  { prefix: 'workouts/' }
);

// Reducers
const getWorkouts = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getWorkoutsSuccess = (
  state = INITIAL_STATE,
  {
    payload: {
      data: { data },
    },
  }
) => {
  const enrichedWorkouts = data.map((workout) => ({
    ...workout,
    chips: [workout.difficulty],
    duration: workout.duration,
  }));

  const workoutsByType = enrichedWorkouts.reduce((acc, currentWorkout) => {
    if (!currentWorkout.workoutTypes) {
      return acc;
    }

    currentWorkout.workoutTypes.map(({ key }) => {
      if (acc[key]) {
        acc[key] = acc[key].concat(currentWorkout);
      } else {
        acc[key] = [currentWorkout];
      }
      return true;
    });
    return acc;
  }, {});

  return {
    ...state,
    processing: false,
    dailyClass: workoutsByType['daily-class'] || [],
    painAndInjury: workoutsByType['pain-and-injury'] || [],
    preWorkout: workoutsByType['pre-workout'] || [],
    recovery: workoutsByType['recovery'] || [],
    workRoutine: workoutsByType['work-routine'] || [],
    workouts: data,
  };
};

const getWorkoutsFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const addFavorite = (state = INITIAL_STATE, { payload: { id } }) => ({
  ...state,
  processedItemId: id,
});

const getWorkout = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getWorkoutSuccess = (state = INITIAL_STATE, { payload: { data } }) => ({
  ...state,
  currentWorkout: data,
  processing: false,
});

const getWorkoutFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const addFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  const _workout = getCurrentWorkout(state, id);
  track(MixpanelEvents.addedWorkoutToFavorites, {
    workoutName: _workout.title,
    workoutType: getWorkoutType(_workout),
    workoutTags: getWorkoutTags(_workout),
    workoutDuration: Number(_workout.duration),
    workoutFormat: getWorkoutFormat(_workout),
    workoutExerciseCount: _workout.exercises ? _workout.exercises.length : 0,
    workoutTrainer: _workout.trainer.title,
  });

  return {
    ...state,
    processedItemId: -1,
    processing: false,
    ...WORKOUTS.reduce((acc, currentWorkout) => {
      acc[currentWorkout] = state[currentWorkout].map((workout) => {
        if (workout.id === id) {
          return { ...workout, isFavorite: true };
        }
        return workout;
      });
      return acc;
    }, {}),
  };
};

const addFavoriteFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processedItemId: -1,
  processing: false,
});

const sendStats = (state = INITIAL_STATE) => ({
  ...state,
});

const sendStatsSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    processing: false,
  };
};

const sendStatsFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const deleteFavorite = (state = INITIAL_STATE, { payload: { id } }) => ({
  ...state,
  processedItemId: id,
});

const deleteFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  const _workout = getCurrentWorkout(state, id);

  track(MixpanelEvents.removedWorkoutFromFavorites, {
    workoutName: _workout.title,
    workoutType: getWorkoutType(_workout),
    workoutTags: getWorkoutTags(_workout),
    workoutDuration: Number(_workout.duration),
    workoutFormat: getWorkoutFormat(_workout),
    workoutExerciseCount: _workout.exercises ? _workout.exercises.length : 0,
    workoutTrainer: _workout.trainer.title,
  });

  return {
    ...state,
    processedItemId: -1,
    processing: false,
    ...WORKOUTS.reduce((acc, currentWorkout) => {
      acc[currentWorkout] = state[currentWorkout].map((workout) => {
        if (workout.id === id) {
          return { ...workout, isFavorite: false };
        }
        return workout;
      });
      return acc;
    }, {}),
  };
};

const deleteFavoriteFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processedItemId: -1,
  processing: false,
});

const changeFavoriteStatusInWorkout = ({ exerciseId, newStatus, workout }) =>
  workout.map((dc) => ({
    ...dc,
    playlist: dc?.playlist?.map((playlistItem) => ({
      ...playlistItem,
      exercise:
        playlistItem.exercise.id === exerciseId
          ? { ...playlistItem.exercise, isFavorite: newStatus }
          : playlistItem.exercise,
    })),
    addedExercises: dc?.addedExercises?.map((addedExercise) => ({
      ...addedExercise,
      isFavorite:
        addedExercise.id === exerciseId ? newStatus : addedExercise.isFavorite,
    })),
  }));

const addExerciseFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    ...WORKOUTS.reduce((acc, currentWorkout) => {
      acc[currentWorkout] = changeFavoriteStatusInWorkout({
        exerciseId: id,
        newStatus: true,
        workout: state[currentWorkout],
      });
      return acc;
    }, {}),
  };
};

const deleteExerciseFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    ...[
      'dailyClass',
      'painAndInjury',
      'preWorkout',
      'recovery',
      'workRoutine',
    ].reduce((acc, currentWorkout) => {
      acc[currentWorkout] = changeFavoriteStatusInWorkout({
        exerciseId: id,
        newStatus: false,
        workout: state[currentWorkout],
      });
      return acc;
    }, {}),
    processing: false,
  };
};

const updateResults = (state = INITIAL_STATE, { results }) => ({
  ...state,
  results,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_WORKOUT]: getWorkout,
  [Types.GET_WORKOUT_SUCCESS]: getWorkoutSuccess,
  [Types.GET_WORKOUT_FAIL]: getWorkoutFail,
  [Types.GET_WORKOUTS]: getWorkouts,
  [Types.GET_WORKOUTS_SUCCESS]: getWorkoutsSuccess,
  [Types.GET_WORKOUTS_FAIL]: getWorkoutsFail,
  [Types.SEND_STATS]: sendStats,
  [Types.SEND_STATS_SUCCESS]: sendStatsSuccess,
  [Types.SEND_STATS_FAIL]: sendStatsFail,
  [Types.ADD_FAVORITE]: addFavorite,
  [Types.ADD_FAVORITE_SUCCESS]: addFavoriteSuccess,
  [Types.ADD_FAVORITE_FAIL]: addFavoriteFail,
  [Types.DELETE_FAVORITE]: deleteFavorite,
  [Types.DELETE_FAVORITE_SUCCESS]: deleteFavoriteSuccess,
  [Types.DELETE_FAVORITE_FAIL]: deleteFavoriteFail,
  [Types.UPDATE_RESULTS]: updateResults,
  [ExerciseTypes.ADD_FAVORITE_SUCCESS]: addExerciseFavoriteSuccess,
  [ExerciseTypes.DELETE_FAVORITE_SUCCESS]: deleteExerciseFavoriteSuccess,
});

export { Creators, Types, Reducer };

const getCurrentWorkout = (state, id) => {
  return WORKOUTS.reduce((acc, currentWorkout) => {
    const workout = state[currentWorkout].find((workout) => workout.id === id);
    if (workout) {
      return workout;
    }
    return acc;
  }, {});
};
