import React from 'react';

export default ({ fill, height = 14, width = 14 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z'></path>
    <path d='M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z'></path>
  </svg>
);
