import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import FormError from './FormError';

const styles = (theme) => ({
  container: {
    border: `1px solid ${theme.palette.primary.gray}`,
    borderRadius: 2,
    padding: 8,
    margin: '2px 0px',
  },
  label: {
    color: theme.palette.primary.generalText,
    fontSize: 14,
    height: 15,
    marginBottom: theme.spacing(0.5),
  },
  labelError: {
    color: theme.palette.messages.error,
  },
});

export const FormDateTimePicker = ({
  classes,
  label,
  name,
  value,
  ...rest
}) => {
  const [field, meta] = useField(name || 'test');
  const hasError = meta.error && meta.touched;
  const errorMessage = meta.error;
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <div className={cx(classes.label, hasError ? classes.labelError : '')}>
        {label}
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          classes={{ root: classes.container }}
          id='date-picker-inline'
          InputProps={{ disableUnderline: true }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          margin='normal'
          onChange={(date) => {
            setFieldValue(field.name, date);
          }}
          value={field.value}
          {...rest}
        />
      </MuiPickersUtilsProvider>
      {hasError && <FormError>{errorMessage}</FormError>}
    </>
  );
};

FormDateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(FormDateTimePicker);
