import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { goBack } from 'connected-react-router';

import ParentPlanForm from './ParentPlanForm';
import { Creators } from '../reducer';
import TopBar from './TopBar2';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
});

const EditParentPlan = ({
  classes,
  id,
  navigateBack,
  parentPlan,
  updatePlan,
}) => {
  const onSubmit = (values) => updatePlan({ id: id, planGroup: values });
  return (
    <div className={classes.container}>
      <TopBar onBack={navigateBack} title='edit parent plan' />
      <ParentPlanForm
        onCancel={navigateBack}
        onSubmit={onSubmit}
        buttonText='Save'
        parentPlan={parentPlan}
      />
    </div>
  );
};

const mapStateToProps = (
  { plan: { error, plans, processing } },
  {
    match: {
      params: { id },
    },
  }
) => ({
  error,
  id,
  processing,
  parentPlan: plans.find((plan) => plan.id === id),
});

const mapDispatchToProps = {
  navigateBack: goBack,
  updatePlan: Creators.updatePlanGroup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditParentPlan));
