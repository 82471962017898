import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import cx from 'classnames';

import FormError from './FormError';
import Select from './Select';
import { sortByLabel } from 'core/utils/sort';

const styles = (theme) => ({
  label: {
    color: theme.palette.primary.generalText,
    fontSize: 14,
    height: 15,
    marginBottom: theme.spacing(0.5),
  },
  labelError: {
    color: theme.palette.messages.error,
  },
});

export const FormSelect = ({
  classes,
  height = 45,
  isMulti = false,
  label,
  name,
  options,
  width = 200,
  avoidSorting = false,
  ...rest
}) => {
  const [field, meta] = useField(name || 'test');
  const hasError = meta.error && meta.touched;
  const errorMessage = meta.error;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const sortedOptions = avoidSorting ? options : options.sort(sortByLabel);

  // console.log(field.name , "FIELD NAME")


  // console.log(field.value  , "FIELD")

  // const result = field?.value?.find(({ label }) => label === "Programs");

  // console.log(result?.label , "RES")

  return (
    <>
      <div className={cx(classes.label, hasError ? classes.labelError : '')}>
        {label} 
        {/* {result?.label === "Programs" ? "hi" : null} */}

      </div>
      <Select
        height={height}
        isMulti={isMulti}
        name={field.name}
        onBlur={() => setFieldTouched(field.name, true)}
        onChange={(props) => {

          setFieldValue(field.name, isMulti ? props : props.value);
        }}
        options={sortedOptions}
        // value="HI"
        value={field.value}
        width={width}
        {...rest}
      />
      {hasError && <FormError>{errorMessage}</FormError>}
    </>
  );
};

FormSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withStyles(styles)(FormSelect);
