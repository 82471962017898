import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#D3F2FF',
      main: '#28aae2',
      mainDark: '#0D90C8',
      dark: '#020401',
      gray: '#DFE2E6',
      red: '#FF7B7B',
      chipGray: '#e8e8ec',
      contrastText: '#fff',
      generalText: '#585963',
      black: '#181817',
      hunterGreen: '#020401',
      darkGray: '#a4a4a4',
    },
    secondary: {
      light: '#ff4081',
      main: '#CEFF00',
      inactive: '#f8fedb',
      green: '#78bb0e',
      dark: '#79BB0D',
      red: '#FF2121',
      grayText: '#4a4b6b66',
      gray: '#DFE2E6',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#f7f8fa',
    },
    messages: {
      error: '#FF2121',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 769,
      lg: 900,
      xl: 1400,
      new : 896,

    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#edf2f7',
      },
    },
  },
  letterSpacing: {
    veryNarrow: '0.01em',
    narrow: '0.04em',
  },
  typography: {
    fontFamily: ['Lato', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },
  radius: 6,
  spacing: 8,
});
