import React from 'react';
import { withStyles } from '@material-ui/styles';

import playlistImg from 'assets/svg/playlist.svg';

const styles = ({ palette: { primary } }) => ({
  container: {
    position: 'relative',
    width: '100%',
    '& > img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& label': {
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      bottom: 0,
      color: primary.main,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      height: 40,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      width: '100%',
    },
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(2, 4, 1, 0.8)',
    color: primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 24,
    height: 200,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 110,
    '& img': {
      height: 24,
      marginTop: 4,
      width: 24,
    },
  },
});

const PlayistPreview = ({
  classes,
  label,
  length,
  onClick = () => {},
  thumbnailUrl,
}) => {
  return (
    <div className={classes.container} onClick={onClick}>
      <img src={thumbnailUrl} alt='playlist thumbnail' />
      <div className={classes.overlay}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <div>{length}</div>
          <img src={playlistImg} alt='playlist' />
        </div>
      </div>
      {label && <label>{label}</label>}
    </div>
  );
};

export default withStyles(styles)(PlayistPreview);
