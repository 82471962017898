import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog } from 'components';
import { Line, Circle } from 'rc-progress';
import Assessment from './Assessment';
import icon_info_blue from '../../../../assets/img/icon_info blue.png';
// import { Dialog } from '@material-ui/core';

const styles = ({ theme, breakpoints }) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
      // paddingTop: '40px',
      paddingBottom: '40px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '41px',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: '10px',
    },
  },
  assesmentText: {
    fontSize: '18px',
    fontWeight: 900,
    textTransform: 'uppercase',
    color: '#020401',
    [breakpoints.down('xs')]: {
      fontSize: '20px',
      paddingLeft: '15px',
    },
  },
  totalMobilityScoreContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  totalMobilityScoreText: {
    fontSize: '19px',
    color: '#333333',
    fontWeight: 700,
    paddingLeft: '30px',
    [breakpoints.down('xs')]: {
      paddingLeft: '0px',
      fontSize: '18px',
      paddingLeft: '15px',
      display : "none"
    },
  },
  line: {
    width: '337px',
    border: '1px solid #DFE2E6',
    marginTop: '22px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lineSecond: {
    width: '696px',
    border: '1px solid #DFE2E6',
    marginTop: '22px',
    [breakpoints.down('xs')]: {
      width: '100%',
      display:"none"
    },
  },
  CirclePercentageContainer: {
    paddingTop: '80px',
    [breakpoints.down('xs')]: {
      // paddingLeft : "15px",
      // paddingRight : "15px",
    },
  },
  userResultPercentageMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingTop: '0px',
      // paddingLeft : "15px",
      // paddingRight : "15px",
    },
  },
  userResultPercentageContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    // paddingTop: '22px',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      marginTop : "-7px"
    },
  },
  bodyPartText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#44444B',
  },

  percentLineDesktop: {
    // background: "linear-gradient(#FF7878, #FF78A7)",
    // stroke : "blue"
    display :"flex",
    marginTop: '10px',
    [breakpoints.down('xs')]: {
      display:"none",

    },
  },
  percentLineMobile: {
    // background: "linear-gradient(#FF7878, #FF78A7)",
    // stroke : "blue"
    display:"none",
    marginTop: '10px',
    [breakpoints.down('xs')]: {
      display:"flex",
      marginTop: '10px',

    },
  },
  bodyPartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > div:first-child': {
      marginTop : "0px",
    },
  },
  bodyPartMainContainer: {
    marginTop: '22px',
    [breakpoints.down('xs')]: {
      marginTop: '10px',

    },
  },
  button: {
    width: '225px',
    marginTop: '41px',
    height: '60px',
    fontSize: '18px',
    textTransform: 'capitalize',
    [breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '16px',
      textTransform: 'uppercase',
    },
  },
  circlePercentNumber: {
    position: 'absolute',
    marginTop: '80px',
    marginLeft: '10px',
    color: '#44444B',
    fontSize: '82px',
    fontWeight: 700,
    [breakpoints.down('xs')]: {
      position: 'absolute',
    marginTop: '50px',
    marginLeft: '5px',
    color: '#44444B',
    fontSize: '40px',
    fontWeight: 700,
    },
  },
  circlePercent: {
    position: 'absolute',
    marginTop: '180px',
    marginLeft: '10px',
    color: '#B8BFD1',
    fontSize: '30px',
    fontWeight: 700,
    [breakpoints.down('xs')]: {
      position: 'absolute',
      marginTop: '100px',
      marginLeft: '6px',
      color: '#B8BFD1',
      fontSize: '20px',
      fontWeight: 700,
    },
  },
  circleContainer: {
    marginTop: '75px',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      marginTop: '20px',
    },
  },
  circle: {

    height :"261px",
    [breakpoints.down('xs')]: {
      height: '150px',
    },


  },
  desktopLine: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileLine: {
    display: 'none',
    [breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoIcon: {
    width: 22,
    height: 22,
    cursor : "pointer",

    [breakpoints.down('xs')]: {
      // display: 'none',
      marginRight: '15px',
    },
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    // padding : "15px",
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '30px',
    paddingBottom: '15px',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '55px',
      // paddingBottom: '40px',
    },
  },
  singleParagraph1: {
    paddingTop: '25px',
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25px',
  },
  singleParagraph: {
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25px',
  },
  paper: {
    minWidth: '1128px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: '100%'
    },
  },quitButton: {
    marginTop : "20px",
    width: '208px',
    color: '#28AAE2',
    borderStyle: 'solid',
    borderColor: '#28AAE2',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  totalMobilityScore:{
    display : "none",
    [breakpoints.down('xs')]: {
      display:"flex",
      alignItems: "center",
      justifyContent : "center",
      marginTop : "10px",
      color: "#8C8E9E",
      fontSize: "18px",
      fontWeight: 700,
    },
  },
  tst:{
        transform: 'scaleX(-1)',

  }
});

const Result = ({ classes, user, results }) => {
  const [openQuestionsAndAnswers, setOpenQuestionsAndAnswers] = useState(false);
  // console.log(user, 'USERIIII');

  const closeQuestionsAndAnswersPopUp = () => {
    setOpenQuestionsAndAnswers(false);
  };

  const openQuestionsAndAnswersPopUp = () => {
    setOpenQuestionsAndAnswers(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const colors = [
    // {'0%' : '#FF7878' , '100%' :'#51D4F3'} ,
    // {
    //   fromColor : "red",
    //   toColor : "blue"
    // },
    '#FF7878',
    '#51D4F3',
    '#1F97E4',
    '#C858FF',
    '#58FFBC',
  ];

  // strokeColor={{ '0%': '#28AAE2', '100%': '#2676D7' }}

  const assessmentResult = results || user.assessmentScore;

  console.log(user, 'USER111111');

  console.log(assessmentResult,"SIMOOON")

  return (
    <div className={classes.mainContainer}>
     
      <div className={classes.headerContainer}>
        <div className={classes.assesmentText}> Assessment</div>
       
        <img className={classes.infoIcon}  onClick={handleClickOpen} src={icon_info_blue} />
      </div>
      <div className={classes.container}>
        <div className={classes.totalMobilityScoreContainer}>
          <div className={classes.totalMobilityScoreText}>
            Total Mobility Score
          </div>
          <div className={classes.line} />
          <div className={classes.circleContainer}>
            <div className={classes.circlePercentNumber}>
              {assessmentResult?.global}
            </div>
            <div className={classes.circlePercent}> % </div>
            <div className={classes.tst} >
            <Circle
              percent={assessmentResult?.global}
              trailWidth={3}
              className={classes.circle}
              strokeWidth={3}
              strokeColor={{ '0%': '#2676D7', '100%': '#28AAE2' }}
            />
            </div>

          </div>
          <div className={classes.totalMobilityScore} >Total mobility score</div>
        </div>
        <div className={classes.userResultPercentageMainContainer}>
          <div className={classes.totalMobilityScoreText}>Body Parts</div>
          <div className={classes.lineSecond} />
          <div className={classes.userResultPercentageContainer}>
            {assessmentResult?.bodyAreas?.map((t, index) => {
              console.log(t.percentage , "SIM")
              return (
                <div key={index}>
                  <div className={classes.bodyPartMainContainer}>
                    <div className={classes.bodyPartContainer}>
                      <div className={classes.bodyPartText}>{t.bodyArea}</div>
                      <div className={classes.bodyPartText}>
                        {t.percentage}%
                      </div>
                    </div>

                    {/* <div className={classes.desktopLine}> */}
                      <Line
                        percent={t.percentage}
                        strokeWidth={1.3}
                        trailWidth={1.3}
                        className={classes.percentLineDesktop}
                        // style={{ stroke: 'linearGradient(yellow,black)' }}
                        // strokeColor={'linear-gradient(red, blue)'}
                        strokeColor={colors[index]}
                      />
                    {/* </div> */}
                    {/* <div className={classes.mobileLine}> */}
                      <Line
                        percent={t.percentage}
                        strokeWidth={3}
                        trailWidth={3}
                        className={classes.percentLineMobile}
                        // style={{ stroke: 'green' }}
                        strokeColor={colors[index]}
                        // strokeColor="red"
                      />
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
            <div className={classes.start}>
              <Button
                onClick={openQuestionsAndAnswersPopUp}
                className={classes.button}
                title='Start Assessment'
              />
            </div>
          </div>
        </div>
      </div>

      <Assessment
        close={closeQuestionsAndAnswersPopUp}
        onClose={closeQuestionsAndAnswersPopUp}
        open={openQuestionsAndAnswers}
      />

      <Dialog
        classes={{ paper: classes.paper }}
        // classes={paper : classes.paper}
        // open={open}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.emptyContainer}>
          <div className={classes.singleParagraph}>
            The assessment is a 14-step assessment that will test how each of
            the major joints in your body moves.
          </div>
          <div className={classes.singleParagraph1}>
            The assessment will test how your muscles, fascia, ligaments, and
            other connective tissues are moving.
          </div>
          <div className={classes.singleParagraph1}>
            The assessment will help you determine what areas of your body are
            moving well, and which areas you should dedicate extra mobility time
            to. The assessment also serves as a benchmark so that you can check
            back and track your progress. We recommend repeating the assessment
            once per month.
          </div>
          <div className={classes.singleParagraph1}>
            Keep in mind that the range of motion that you are able to achieve
            is important, but also how you feel while moving is equally
            important. So take a mental note on the differences you feel when
            moving and while you are performing the assessment. Do you have less
            of a pinch, less stiffness, better ability to activate specific
            muscles, and smoother movement?
          </div>
          <div className={classes.singleParagraph1}>
            Also, remember that making lasting mobility changes takes time. It
            has taken years and even decades for you to accumulate the tightness
            in your muscles and joints. You will see results quickly, but also
            know that it will take time. The key to making lasting changes is
            consistency over time.
          </div>
          <div className={classes.singleParagraph1}>
            Directions: The entire test will take you about 10 minutes to
            complete. Watch each video. Perform the test on yourself.
          </div>
          <div className={classes.singleParagraph1}>
            We recommend videotaping yourself if you want to be extra accurate.
            You will then enter your results for each side of your body for each
            test. You must complete all 14 tests in order to generate a mobility
            score. Take the assessment as often as you would like.
          </div>

          <div
                className={classes.quitButton}
                title='Close'
                type='button'
                //   onClick={onClose}
                //   onClick={Open}
                onClick={handleClose}
              >
                Close
              </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  // deleteTask: Creators.deleteTask,
  // showModal: ModalCreators.showModal,
  // getTasks: Creators.getTasks,
  // setActiveTask: Creators.setActiveTask,
};

const mapStateToProps = ({ auth: { user }, userAssessment }) => {
  return {
    user,
    data: userAssessment.tasks,
    results: userAssessment.results,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Result));


