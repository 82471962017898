import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, ref, string } from 'yup';
import { useParams } from 'react-router-dom';
import { Button, Form, FormInput, FormRow, Text } from 'components';
import { Creators } from '../reducer';

const styles = (theme) => ({
  button: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

const ResetPasswordForm = ({ classes, error, resetPassword }) => {
  const FormBody = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form
        error={error}
        title='Reset Password'
        renderHeader={() => (
          <>
            <FormRow>
              <Text>{'Enter your new password below'}</Text>
            </FormRow>
          </>
        )}
      >
        <FormRow>
          <FormInput
            label='New Password'
            name='newPassword'
            type='password'
            placeholder='New Password'
          />
        </FormRow>
        <FormRow>
          <FormInput
            label='Confirm Password'
            name='confirmPassword'
            type='password'
            placeholder='Confirm Password'
          />
        </FormRow>
        <FormRow>
          <FormInput name='token' type='hidden' />
        </FormRow>
        <FormRow>
          <div className={classes.button}>
            <Button title='Done' type='submit' />
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    newPassword: string()
      .min(6, 'Password needs to be at least 6 characters long')
      .required('Password is required'),
    confirmPassword: string()
      .min(6, 'Password needs to be at least 6 characters long')
      .required('Password is required')
      .oneOf([ref('newPassword'), null], "Passwords don't match"),
  });
  const { token } = useParams();

  return (
    <Formik
      initialValues={{ newPassword: '', confirmPassword: '', token: token }}
      validationSchema={validationSchema}
      onSubmit={(values) => resetPassword(values)}
    >
      <FormBody />
    </Formik>
  );
};

const mapStateToProps = ({ auth: { error } }) => ({
  error,
});

const mapDispatchToProps = { resetPassword: Creators.resetPassword };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetPasswordForm));
