import React from 'react';
import { Helmet } from 'react-helmet';
import WorkOutProgramList from "./WorkOutProgramList"
import List from './List';

export default () => (
  <>  
    <Helmet>
      <title>Programs</title>
    </Helmet>
    <WorkOutProgramList
      title='Programs'
      workoutKey='pain-and-injury'
      workoutType='painAndInjury'
      
    />
  </>
);
