import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  activeType: 'bodyArea',
  activeTag: '',
  bodyArea: [],
  difficulty: [
    { value: 'easy', label: 'Easy' },
    { value: 'medium', label: 'Medium' },
    { value: 'hard', label: 'Hard' },
  ],
  equipment: [],
  frontEndVisible: [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ],
  isDirty: false,
  modality: [],
  movementPrep: [],
  muscles: [],
  status: [
    { value: 'draft', label: 'Draft' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'published', label: 'Published' },
  ],
  usageArea: [
    { value: 'free', label: 'Free' },
    { value: 'premium', label: 'Premium' },
  ],
  workoutType: [],
};

// Action creators
const { Types, Creators } = createActions(
  {
    setActiveType: ['activeType'],
    setActiveTag: ['activeTag'],

    createWorkoutTypeSuccess: ['response'],
    createWorkoutTypeFail: ['error'],
    createWorkoutType: (workoutType) => {
      const data = {
        ...workoutType,
        key: workoutType.title.trim().toLowerCase().replaceAll(' ', '-'),
      };

      return {
        type: 'tags/CREATE_WORKOUT_TYPE',
        payload: {
          request: {
            url: '/workout-type-management',
            method: 'post',
            data,
          },
        },
      };
    },
    updateWorkoutTypeSuccess: ['response'],
    updateWorkoutTypeFail: ['error'],
    updateWorkoutType: ({ id, tag }) => ({
      type: 'tags/UPDATE_WORKOUT_TYPE',
      payload: {
        id: id,
        request: {
          url: `/workout-type-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    getWorkoutTypesSuccess: ['response'],
    getWorkoutTypes: () => ({
      type: 'tags/GET_WORKOUT_TYPES',
      payload: {
        request: {
          url: '/workout-type-management',
          method: 'get',
        },
      },
    }),

    createBodyAreaSuccess: ['response'],
    createBodyAreaFail: ['error'],
    createBodyArea: (bodyArea) => ({
      type: 'tags/CREATE_BODY_AREA',
      payload: {
        request: {
          url: '/body-area-management',
          method: 'post',
          data: bodyArea,
        },
      },
    }),

    updateBodyAreaSuccess: ['response'],
    updateBodyAreaFail: ['error'],
    updateBodyArea: ({ id, tag }) => ({
      type: 'tags/UPDATE_BODY_AREA',
      payload: {
        id: id,
        request: {
          url: `/body-area-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    deleteBodyAreaSuccess: ['response'],
    deleteBodyAreaFail: ['error'],
    deleteBodyArea: (id) => ({
      type: 'tags/DELETE_BODY_AREA',
      payload: {
        id: id,
        request: {
          url: `/body-area-management/${id}`,
          method: 'delete',
        },
      },
    }),
    getBodyAreaSuccess: ['response'],
    getBodyArea: () => ({
      type: 'tags/GET_BODY_AREA',
      payload: {
        request: {
          url: '/site/body-areas',
          method: 'get',
        },
      },
    }),
    createEquipmentSuccess: ['response'],
    createEquipmentFail: ['error'],
    createEquipment: (equipment) => ({
      type: 'tags/CREATE_EQUIPMENT',
      payload: {
        request: {
          url: '/equipment-management',
          method: 'post',
          data: equipment,
        },
      },
    }),

    updateEquipmentSuccess: ['response'],
    updateEquipmentFail: ['error'],
    updateEquipment: ({ id, tag }) => ({
      type: 'tags/UPDATE_EQUIPMENT',
      payload: {
        id: id,
        request: {
          url: `/equipment-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    deleteEquipmentSuccess: ['response'],
    deleteEquipmentFail: ['error'],
    deleteEquipment: (id) => ({
      type: 'tags/DELETE_EQUIPMENT',
      payload: {
        id: id,
        request: {
          url: `/equipment-management/${id}`,
          method: 'delete',
        },
      },
    }),
    getEquipmentSuccess: ['response'],
    getEquipment: () => ({
      type: 'tags/GET_EQUIPMENT',
      payload: {
        request: {
          url: '/site/equipments',
          method: 'get',
        },
      },
    }),

    createModalitySuccess: ['response'],
    createModalityFail: ['error'],
    createModality: (bodyArea) => ({
      type: 'tags/CREATE_MODALITY',
      payload: {
        request: {
          url: '/modality-management',
          method: 'post',
          data: bodyArea,
        },
      },
    }),

    updateModalitySuccess: ['response'],
    updateModalityFail: ['error'],
    updateModality: ({ id, tag }) => ({
      type: 'tags/UPDATE_MODALITY',
      payload: {
        id: id,
        request: {
          url: `/modality-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    deleteModalitySuccess: ['response'],
    deleteModalityFail: ['error'],
    deleteModality: (id) => ({
      type: 'tags/DELETE_MODALITY',
      payload: {
        id: id,
        request: {
          url: `/modality-management/${id}`,
          method: 'delete',
        },
      },
    }),
    getModalitySuccess: ['response'],
    getModality: () => ({
      type: 'tags/GET_MODALITY',
      payload: {
        request: {
          url: '/site/modalities',
          method: 'get',
        },
      },
    }),

    createMovementPrepSuccess: ['response'],
    createMovementPrepFail: ['error'],
    createMovementPrep: (bodyArea) => ({
      type: 'tags/CREATE_MOVEMENT_PREP',
      payload: {
        request: {
          url: '/movement-prep-management',
          method: 'post',
          data: bodyArea,
        },
      },
    }),
    updateMovementPrepSuccess: ['response'],
    updateMovementPrepFail: ['error'],
    updateMovementPrep: ({ id, tag }) => ({
      type: 'tags/UPDATE_MOVEMENT_PREP',
      payload: {
        id: id,
        request: {
          url: `/movement-prep-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    deleteMovementPrepSuccess: ['response'],
    deleteMovementPrepFail: ['error'],
    deleteMovementPrep: (id) => ({
      type: 'tags/DELETE_MOVEMENT_PREP',
      payload: {
        id: id,
        request: {
          url: `/movement-prep-management/${id}`,
          method: 'delete',
        },
      },
    }),
    getMovementPrepSuccess: ['response'],
    getMovementPrep: () => ({
      type: 'tags/GET_MOVEMENT_PREP',
      payload: {
        request: {
          url: '/site/movement-preps',
          method: 'get',
        },
      },
    }),
    createMusclesSuccess: ['response'],
    createMusclesFail: ['error'],
    createMuscles: (muscles) => ({
      type: 'tags/CREATE_MUSCLES',
      payload: {
        request: {
          url: '/muscle-management',
          method: 'post',
          data: muscles,
        },
      },
    }),
    updateMusclesSuccess: ['response'],
    updateMusclesFail: ['error'],
    updateMuscles: ({ id, tag }) => ({
      type: 'tags/UPDATE_MUSCLES',
      payload: {
        id: id,
        request: {
          url: `/muscle-management/${id}`,
          method: 'patch',
          data: tag,
        },
      },
    }),
    deleteMusclesSuccess: ['response'],
    deleteMusclesFail: ['error'],
    deleteMuscles: (id) => ({
      type: 'tags/DELETE_MUSCLES',
      payload: {
        id: id,
        request: {
          url: `/muscle-management/${id}`,
          method: 'delete',
        },
      },
    }),
    getMusclesSuccess: ['response'],
    getMuscles: () => ({
      type: 'tags/GET_MUSCLES',
      payload: {
        request: {
          url: '/site/muscles',
          method: 'get',
        },
      },
    }),
    updateState: ['payload'],
  },
  { prefix: 'tags/' }
);
// Reducers
const setActiveType = (state = INITIAL_STATE, { activeType }) => ({
  ...state,
  activeType,
});

const setActiveTag = (state = INITIAL_STATE, { activeTag }) => ({
  ...state,
  activeTag,
});

const createWorkoutType = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createWorkoutTypeSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    workoutType: [
      ...state.workoutType,
      { label: data.title, value: data.id, key: data.key },
    ],
  };
};

const createWorkoutTypeFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const updateWorkoutType = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateWorkoutTypeSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    workoutType: state.workoutType.map((tag) => {
      if (tag.value === id) {
        tag.label = title;
      }
      return tag;
    }),
  };
};

const updateWorkoutTypeFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const getWorkoutTypesSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    workoutType: data[0]
      .map(({ id, title, key }) => ({
        label: title,
        value: id,
        key,
      }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const createBodyArea = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createBodyAreaSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    bodyArea: [...state.bodyArea, { label: data.title, value: data.id }].sort(
      function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }
    ),
  };
};

const createBodyAreaFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteBodyAreaSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    bodyArea: state.bodyArea
      .filter((tag) => tag.value !== id)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const deleteBodyAreaFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteBodyArea = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getBodyAreaSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    bodyArea: data[0]
      .map(({ id, title }) => ({ label: title, value: id }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateBodyArea = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateBodyAreaSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    bodyArea: state.bodyArea
      .map((tag) => {
        if (tag.value === id) {
          tag.label = title;
        }
        return tag;
      })
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateBodyAreaFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  processing: false,
});

const createEquipment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createEquipmentSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    equipment: [...state.equipment, { label: data.title, value: data.id }].sort(
      function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }
    ),
  };
};

const createEquipmentFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteEquipmentSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    equipment: state.equipment
      .filter((tag) => tag.value !== id)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const deleteEquipmentFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteEquipment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getEquipmentSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    equipment: data[0]
      .map(({ id, title }) => ({ label: title, value: id }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateEquipment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateEquipmentSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    equipment: state.equipment
      .map((tag) => {
        if (tag.value === id) {
          tag.label = title;
        }
        return tag;
      })
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateEquipmentFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  processing: false,
});

const createModality = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createModalitySuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    modality: [...state.modality, { label: data.title, value: data.id }].sort(
      function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }
    ),
  };
};

const createModalityFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteModalitySuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    modality: state.modality
      .filter((tag) => tag.value !== id)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const deleteModalityFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteModality = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getModalitySuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    modality: data[0]
      .map(({ id, title }) => ({ label: title, value: id }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateModality = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateModalitySuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    modality: state.modality
      .map((tag) => {
        if (tag.value === id) {
          tag.label = title;
        }
        return tag;
      })
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateModalityFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  processing: false,
});

const createMovementPrep = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createMovementPrepSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    movementPrep: [
      ...state.movementPrep,
      { label: data.title, value: data.id },
    ].sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }),
  };
};

const createMovementPrepFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteMovementPrepSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    movementPrep: state.movementPrep
      .filter((tag) => tag.value !== id)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const deleteMovementPrepFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deleteMovementPrep = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMovementPrep = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMovementPrepSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    movementPrep: state.movementPrep
      .map((tag) => {
        if (tag.value === id) {
          tag.label = title;
        }
        return tag;
      })
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateMovementPrepFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
});

const getMovementPrepSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    movementPrep: data[0]
      .map(({ id, title }) => ({ label: title, value: id }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const createMuscles = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createMusclesSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    muscles: [...state.muscles, { label: data.title, value: data.id }].sort(
      function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }
    ),
  };
};

const createMusclesFail = (state = INITIAL_STATE) => {
  return {
    ...state,
  };
};

const deleteMusclesSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    muscles: state.muscles
      .filter((tag) => tag.value !== id)
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const deleteMusclesFail = (state = INITIAL_STATE) => ({
  ...state,
});

const deleteMuscles = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMuscles = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMusclesSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { title },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    muscles: state.muscles
      .map((tag) => {
        if (tag.value === id) {
          tag.label = title;
        }
        return tag;
      })
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateMusclesFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
});

const getMusclesSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    muscles: data[0]
      .map(({ id, title }) => ({ label: title, value: id }))
      .sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      }),
  };
};

const updateState = (state = INITIAL_STATE, { payload: { key, value } }) => {
  return {
    ...state,
    [key]: value,
  };
};

const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_TYPE]: setActiveType,
  [Types.SET_ACTIVE_TAG]: setActiveTag,
  [Types.CREATE_WORKOUT_TYPE]: createWorkoutType,
  [Types.CREATE_WORKOUT_TYPE_SUCCESS]: createWorkoutTypeSuccess,
  [Types.CREATE_WORKOUT_TYPE_FAIL]: createWorkoutTypeFail,
  [Types.UPDATE_WORKOUT_TYPE]: updateWorkoutType,
  [Types.UPDATE_WORKOUT_TYPE_SUCCESS]: updateWorkoutTypeSuccess,
  [Types.UPDATE_WORKOUT_TYPE_FAIL]: updateWorkoutTypeFail,
  [Types.GET_WORKOUT_TYPES_SUCCESS]: getWorkoutTypesSuccess,
  [Types.CREATE_BODY_AREA]: createBodyArea,
  [Types.CREATE_BODY_AREA_SUCCESS]: createBodyAreaSuccess,
  [Types.CREATE_BODY_AREA_FAIL]: createBodyAreaFail,
  [Types.DELETE_BODY_AREA]: deleteBodyArea,
  [Types.DELETE_BODY_AREA_SUCCESS]: deleteBodyAreaSuccess,
  [Types.DELETE_BODY_AREA_FAIL]: deleteBodyAreaFail,
  [Types.UPDATE_BODY_AREA]: updateBodyArea,
  [Types.UPDATE_BODY_AREA_SUCCESS]: updateBodyAreaSuccess,
  [Types.UPDATE_BODY_AREA_FAIL]: updateBodyAreaFail,
  [Types.GET_BODY_AREA_SUCCESS]: getBodyAreaSuccess,
  [Types.CREATE_EQUIPMENT]: createEquipment,
  [Types.CREATE_EQUIPMENT_SUCCESS]: createEquipmentSuccess,
  [Types.CREATE_EQUIPMENT_FAIL]: createEquipmentFail,
  [Types.DELETE_EQUIPMENT]: deleteEquipment,
  [Types.DELETE_EQUIPMENT_SUCCESS]: deleteEquipmentSuccess,
  [Types.DELETE_EQUIPMENT_FAIL]: deleteEquipmentFail,
  [Types.UPDATE_EQUIPMENT]: updateEquipment,
  [Types.UPDATE_EQUIPMENT_SUCCESS]: updateEquipmentSuccess,
  [Types.UPDATE_EQUIPMENT_FAIL]: updateEquipmentFail,
  [Types.GET_EQUIPMENT_SUCCESS]: getEquipmentSuccess,
  [Types.CREATE_MODALITY]: createModality,
  [Types.CREATE_MODALITY_SUCCESS]: createModalitySuccess,
  [Types.CREATE_MODALITY_FAIL]: createModalityFail,
  [Types.DELETE_MODALITY]: deleteModality,
  [Types.DELETE_MODALITY_SUCCESS]: deleteModalitySuccess,
  [Types.DELETE_MODALITY_FAIL]: deleteModalityFail,
  [Types.UPDATE_MODALITY]: updateModality,
  [Types.UPDATE_MODALITY_SUCCESS]: updateModalitySuccess,
  [Types.UPDATE_MODALITY_FAIL]: updateModalityFail,
  [Types.GET_MODALITY_SUCCESS]: getModalitySuccess,
  [Types.CREATE_MOVEMENT_PREP]: createMovementPrep,
  [Types.CREATE_MOVEMENT_PREP_SUCCESS]: createMovementPrepSuccess,
  [Types.CREATE_MOVEMENT_PREP_FAIL]: createMovementPrepFail,
  [Types.DELETE_MOVEMENT_PREP]: deleteMovementPrep,
  [Types.DELETE_MOVEMENT_PREP_SUCCESS]: deleteMovementPrepSuccess,
  [Types.DELETE_MOVEMENT_PREP_FAIL]: deleteMovementPrepFail,
  [Types.UPDATE_MOVEMENT_PREP]: updateMovementPrep,
  [Types.UPDATE_MOVEMENT_PREP_SUCCESS]: updateMovementPrepSuccess,
  [Types.UPDATE_MOVEMENT_PREP_FAIL]: updateMovementPrepFail,
  [Types.GET_MOVEMENT_PREP_SUCCESS]: getMovementPrepSuccess,
  [Types.CREATE_MUSCLES]: createMuscles,
  [Types.CREATE_MUSCLES_SUCCESS]: createMusclesSuccess,
  [Types.CREATE_MUSCLES_FAIL]: createMusclesFail,
  [Types.DELETE_MUSCLES]: deleteMuscles,
  [Types.DELETE_MUSCLES_SUCCESS]: deleteMusclesSuccess,
  [Types.DELETE_MUSCLES_FAIL]: deleteMusclesFail,
  [Types.UPDATE_MUSCLES]: updateMuscles,
  [Types.UPDATE_MUSCLES_SUCCESS]: updateMusclesSuccess,
  [Types.UPDATE_MUSCLES_FAIL]: updateMusclesFail,
  [Types.GET_MUSCLES_SUCCESS]: getMusclesSuccess,
  [Types.UPDATE_STATE]: updateState,
});
export { Creators, Types, Reducer };
