import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import logo from 'assets/svg/logo.svg';
import logout from 'assets/svg/logout.svg';

import { Creators } from 'modules/auth';
import ArrowUp from 'components/icons/ArrowUp';
import ArrowDown from 'components/icons/ArrowDown';
import Assessments from 'components/icons/Assessments';
import Notifications from 'components/icons/Notifications';
import Programming from 'components/icons/Programming';
import Rewards from 'components/icons/Rewards';
import Settings from 'components/icons/Settings';
import User from 'components/icons/User';

const styles = (theme) => ({
  container: {
    background: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    overflowY: 'overlay',
  },
  label: {
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  icon: {
    minWidth: 40,
  },
  list: {
    background: theme.palette.primary.dark,
    width: '100%',
  },
  listItem: {
    color: theme.palette.primary.main,
    boxShadow: '0px -0.5px 0px rgba(239, 247, 255, 0.2)',
  },
  listItemLogout: {
    color: theme.palette.primary.red,
  },
  logo: {
    boxShadow: '0px -0.5px 0px rgba(239, 247, 255, 0.2)',
    display: 'flex',
    height: 80,
    justifyItems: 'center',
    padding: '0px 16px',
    '& > img': {
      width: '100%',
    },
  },
  nestedListItem: {
    boxShadow: '0px -0.5px 0px rgba(239, 247, 255, 0.2)',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(4),
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
  },
  selectedNestedListItem: {
    background:
      'linear-gradient(90deg, rgba(40, 170, 226, 0.4) 0%, rgba(40, 170, 226, 0) 41.53%)',
    borderLeft: `${theme.palette.primary.main} 4px solid`,
    color: theme.palette.primary.contrastText,
  },
});

const MENU = [
  {
    icon: Programming,
    name: 'programming',
    title: 'Programming',
    items: [
      {
        name: 'exercise-creator',
        title: 'Exercise Creator',
        url: 'programming/exercise-creator',
      },
      {
        name: 'workout-creator',
        title: 'Workout Creator',
        url: 'programming/workout-creator',
      },
      {
        name: 'tags',
        title: 'Tags',
        url: 'programming/tags',
      },
    ],
  },
  {
    icon: Assessments,
    name: 'assessments',
    title: 'Assessments',
    url: 'assessments',
  },
  {
    icon: User,
    name: 'membership',
    title: 'Membership',
    items: [
      {
        name: 'membership-coupons',
        title: 'Coupon Codes',
        url: 'membership/coupons',
      },
      {
        name: 'membership-members',
        title: 'Members',
        url: 'membership/members',
      },
      {
        name: 'membership-plan',
        title: 'Membership Plan',
        url: 'membership/plan',
      },
      {
        name: 'membership-plan',
        title: 'Team Accounts',
        url: 'membership/team-accounts',
      },
      {
        name: 'membership-transactions',
        title: 'Transactions',
        url: 'membership/transactions',
      },
    ],
  },
  {
    icon: Notifications,
    name: 'notifications',
    title: 'Notifications',
    items: [
      {
        name: 'notifications-movement-moments',
        title: 'Movement Moments',
        url: 'notifications/movement-moments',
      },
      {
        name: 'notifications-recommendations',
        title: 'Recommendations',
        url: 'notifications/recommendations',
      },
      {
        name: 'notifications-reminders',
        title: 'Reminders',
        url: 'notifications/reminders',
      },
    ],
  },
  {
    icon: Rewards,
    name: 'rewards',
    title: 'Rewards Programs',
    items: [
      {
        name: 'rewards-invite-friend',
        title: 'Affiliate Program Invite Friend',
        url: 'rewards/invite-friend',
      },
      {
        name: 'rewards-affiliates',
        title: 'Affiliates',
        url: 'rewards/affiliates',
      },
      {
        name: 'rewards-clicks',
        title: 'Clicks',
        url: 'rewards/clicks',
      },
      {
        name: 'rewards-transactions',
        title: 'Transactions',
        url: 'rewards/transactions',
      },
      {
        name: 'rewards-pay-affiliates',
        title: 'Pay Affiliates',
        url: 'rewards/pay-affiliates',
      },
      {
        name: 'rewards-reports',
        title: 'Reports',
        url: 'rewards/reports',
      },
    ],
  },
  {
    icon: Settings,
    name: 'settings',
    title: 'Settings',
    url: 'settings',
  },
];

const BASE_URL = '/admin/dashboard/';

export const SideBar = ({ classes, signOut }) => {
  const theme = useTheme();
  const location = useLocation();
  const [expandedState, setExpandedState] = useState({});

  useEffect(() => {
    const selectedItem = MENU.find(
      (item) =>
        item.items &&
        item.items.some(
          (nestedItem) => BASE_URL + nestedItem.url === location.pathname
        )
    );

    if (selectedItem) setExpandedState({ [selectedItem.name]: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapse = (itemName) => {
    setExpandedState({
      ...expandedState,
      [itemName]: !expandedState[itemName],
    });
  };

  const isItemSelected = (item) => {
    if (!item.items) return BASE_URL + item.url === location.pathname;
    return item.items.some((nestedItem) =>
      location.pathname.includes(BASE_URL + nestedItem.url)
    );
  };

  const getIcon = (Icon, itemSelected) => {
    const fill = itemSelected
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main;
    return <Icon fill={fill} />;
  };

  const ListItem = ({ item }) => {
    const { icon, items, name, title } = item;
    const selected = isItemSelected(item);
    const fill = selected
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main;

    const Item = () => (
      <MUIListItem
        button
        className={cx(
          classes.listItem,
          selected ? classes.selectedListItem : ''
        )}
        onClick={() => handleCollapse(name)}
        selected={selected}
      >
        <ListItemIcon classes={{ root: classes.icon }}>
          {getIcon(icon, selected)}
        </ListItemIcon>
        <ListItemText primary={title} />
        {items &&
          (expandedState[name] ? (
            <ArrowUp fill={fill} />
          ) : (
            <ArrowDown fill={fill} />
          ))}
      </MUIListItem>
    );

    if (items) return <Item />;

    return (
      <NavLink
        exact
        to={`/admin/dashboard/${item.url}`}
        style={{ textDecoration: 'none' }}
      >
        <Item />
      </NavLink>
    );
  };

  const NestedListItem = ({ children, item }) => (
    <NavLink
      exact
      to={`${BASE_URL}${item.url}`}
      activeStyle={{}}
      style={{
        textDecoration: 'none',
      }}
    >
      <MUIListItem
        button
        className={cx(classes.nestedListItem)}
        classes={{ selected: classes.selectedNestedListItem }}
        selected={BASE_URL + item.url === location.pathname}
      >
        {children}
      </MUIListItem>
    </NavLink>
  );

  return (
    <div className={classes.container}>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <div className={classes.logo}>
            <img src={logo} alt='logo' />
          </div>
        }
        className={classes.list}
      >
        {MENU.map((item) => (
          <div key={item.name}>
            <ListItem item={item} />
            {item.items && (
              <Collapse
                in={expandedState[item.name]}
                timeout='auto'
                unmountOnExit
              >
                <List component='div' disablePadding>
                  {item.items.map((nestedItem) => (
                    <NestedListItem key={nestedItem.name} item={nestedItem}>
                      <ListItemText primary={nestedItem.title} />
                    </NestedListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <div>
        <MUIListItem
          button
          className={cx(classes.listItem, classes.listItemLogout)}
          onClick={signOut}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <img src={logout} alt='logout' />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </MUIListItem>
      </div>
    </div>
  );
};

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

const mapDispatchToProps = { signOut: Creators.signOut };

export default connect(null, mapDispatchToProps)(withStyles(styles)(SideBar));
