import React from 'react';
import List from './List';
import { Helmet } from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <title>Recovery</title>
    </Helmet>
    <List title='Recovery' workoutKey='recovery' workoutType='recovery' />
  </>
);
