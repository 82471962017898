import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  error: {
    alignItems: 'center',
    background: theme.palette.messages.error,
    borderRadius: 2,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    fontSize: 12,
    minHeight: 24,
    justifyContent: 'flex-end',
    letterSpacing: theme.letterSpacing.narrow,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1 / 2),
    textTransform: 'uppercase',
  },
});

const FormError = ({ classes, children }) => (
  <div className={classes.error}>{children}</div>
);

FormError.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormError);
