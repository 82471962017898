import { parse } from 'json2csv';
import { saveAs } from 'file-saver';

export const toCSV = ({ dataJSON, fields, fileName }) => {
  const opts = { fields };

  try {
    const csv = parse(dataJSON, opts);
    const blob = new File([csv], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, fileName);
  } catch (err) {
    console.error(err);
  }
};
