export const getWorkoutTags = (workout) => {
  if (!workout) return null;

  const tags = [];

  workout.bodyAreas.forEach((item) => tags.push(item.title));
  workout.modalities.forEach((item) => tags.push(item.title));
  workout.equipments.forEach((item) => tags.push(item.title));
  workout.muscles.forEach((item) => tags.push(item.title));
  workout.movementPreps.forEach((item) => tags.push(item.title));

  return tags;
};

export const getWorkoutFormat = (workout) => {
  if (!workout) return null;
  if (workout.addedExercises?.length === 1) {
    return 'single video';
  }

  if (workout.duration === 'rep-based') {
    return 'multi-video rep based';
  }

  return 'multi-video time based';
};

export const getWorkoutType = (workout) => {
  return workout.workoutTypes?.map((type) => type.title).join(', ');
};
