import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from 'components';
import { Members, EditMember } from './membership/members';
import {
  TeamAcoounts,
  EditTeamAccounts,
  AddTeamAccounts,
} from './membership/team-accounts';
import { Tags } from './programming/tags';
import { CreateNewRule } from './affiliate';
import { CreateNewCoupon, Coupons, ViewCoupon } from './membership/coupons';
import { CreateNewTask, EditTask, Tasks } from './assessment';
import {
  CreateChildPlan,
  EditChildPlan,
  CreateParentPlan,
  EditParentPlan,
  Plans,
} from './membership/plan';
import {
  AddExercises,
  EditWorkout,
  CreateNewWorkout,
  Workouts,
} from './programming/workout-creator';
import {
  EditExercise,
  CreateNewExercise,
  Exercises,
} from './programming/exercise-creator';
import SideBar from './components/SideBar';
import { Movements, NotificationForm } from './notifications/movementMoments';
import {
  Recommendations,
  RecommendationsForm,
} from './notifications/recommendations';
import { ReminderForm, Reminders } from './notifications/reminders';
import { TeamDashboard } from 'modules/dashboard/team-dashboard';

const styles = (theme) => ({
  container: {
    background: theme.palette.background.default,
    display: 'flex',
    height: '100vh',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
  sidebar: {
    left: 0,
    top: 0,
    width: 280,
    zIndex: 1,
  },
});

const Routes = ({ classes, match: { url } }) => (
  <div className={classes.container}>
    <div className={classes.sidebar}>
      <SideBar />
    </div>
    <div className={classes.content}>
      <Switch>
        <Route
          exact={true}
          path={`${url}/membership/coupons/create`}
          component={CreateNewCoupon}
        />
        <Route
          exact={true}
          path={`${url}/membership/coupons`}
          component={Coupons}
        />

        <Route
          exact={true}
          path={`${url}/membership/coupons/:id`}
          component={ViewCoupon}
        />
        <Route
          exact={true}
          path={`${url}/membership/members`}
          component={Members}
        />
        <Route
          exact={true}
          path={`${url}/membership/members/:id`}
          component={EditMember}
        />
        <Route
          exact={true}
          path={`${url}/membership/team-accounts`}
          component={TeamAcoounts}
        />
        <Route
          exact={true}
          path={`${url}/membership/team-accounts/create`}
          component={AddTeamAccounts}
        />
        <Route
          exact={true}
          path={`${url}/membership/team-accounts/:id`}
          component={EditTeamAccounts}
        />
        <Route
          exact={true}
          path={`${url}/membership/team-accounts-dashboard/:teamAccountId`}
          component={TeamDashboard}
        />
        <Route exact={true} path={`${url}/membership/plan`} component={Plans} />
        <Route
          exact={true}
          path={`${url}/membership/plan/parent/create`}
          component={CreateParentPlan}
        />
        <Route
          exact={true}
          path={`${url}/membership/plan/parent/:id`}
          component={EditParentPlan}
        />
        <Route
          exact={true}
          path={`${url}/membership/plan/child/create`}
          component={CreateChildPlan}
        />
        <Route
          exact={true}
          path={`${url}/membership/plan/child/:id`}
          component={EditChildPlan}
        />
        <Route exact={true} path={`${url}/programming/tags`} component={Tags} />
        <Route
          exact={true}
          path={`${url}/programming/exercise-creator`}
          component={Exercises}
        />
        <Route
          exact={true}
          path={`${url}/programming/exercise-creator/create`}
          component={CreateNewExercise}
        />
        <Route
          exact={true}
          path={`${url}/programming/exercise-creator/edit/:id`}
          component={EditExercise}
        />
        <Route
          exact={true}
          path={`${url}/programming/workout-creator`}
          component={Workouts}
        />
        <Route
          exact={true}
          path={`${url}/programming/workout-creator/add-exercises/:type/:workoutId?`}
          component={AddExercises}
        />
        <Route
          exact={true}
          path={`${url}/programming/workout-creator/create`}
          component={CreateNewWorkout}
        />
        <Route
          exact={true}
          path={`${url}/programming/workout-creator/edit/:id`}
          component={EditWorkout}
        />
        <Route exact={true} path={`${url}/assessments`} component={Tasks} />
        <Route
          exact={true}
          path={`${url}/assessments/create`}
          component={CreateNewTask}
        />
        <Route
          exact={true}
          path={`${url}/assessments/:id`}
          component={EditTask}
        />
        <Route
          exact={true}
          path={`${url}/rewards/invite-friend`}
          component={CreateNewRule}
        />
        <Route
          exact={true}
          path={`${url}/notifications/movement-moments`}
          component={Movements}
        />
        <Route
          exact={true}
          path={`${url}/notifications/movement-moments/create`}
          component={NotificationForm}
        />
        <Route
          exact={true}
          path={`${url}/notifications/movement-moments/edit/:id`}
          component={(props) => <NotificationForm isEdit {...props} />}
        />
        <Route
          exact={true}
          path={`${url}/notifications/recommendations`}
          component={Recommendations}
        />
        <Route
          exact={true}
          path={`${url}/notifications/recommendations/create`}
          component={RecommendationsForm}
        />
        <Route
          exact={true}
          path={`${url}/notifications/recommendations/edit/:id`}
          component={(props) => <RecommendationsForm isEdit {...props} />}
        />
        <Route
          exact={true}
          path={`${url}/notifications/reminders`}
          component={Reminders}
        />
        <Route
          exact={true}
          path={`${url}/notifications/reminder/create`}
          component={ReminderForm}
        />
        <Route
          exact={true}
          path={`${url}/notifications/reminder/edit/:id`}
          component={(props) => <ReminderForm isEdit {...props} />}
        />
        <Redirect exact={true} from={url} to={`${url}/membership/members`} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </div>
);

Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Routes);
