import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ListItem } from '../../index';
import { ActionButtons } from '../../index';
import Placeholder from 'components/Placeholder';

const useStyles = makeStyles(
  ({ palette: { background, primary, secondary } }) => ({
    container: {
      maxWidth: 1200,
      width: 'min(100%, 800px)',
      padding: '1rem',
      margin: '0 auto',
    },
    content: {
      background: background.paper,
      borderRadius: 6,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
      maxHeight: 700,
      minHeight: 318,
      overflow: 'auto',
      padding: 24,
    },
    placeholder: {},
  })
);

const Details = ({
  title,
  text,
  baseUri,
  id,
  showModal,
  postingDate,
  deleteFunction,
  type,
}) => {
  const classes = useStyles();

  const handleDelete = (id) => {
    showModal(
      'deleteConfirmation',
      () => {
        if (typeof deleteFunction === 'function') {
          deleteFunction(id);
        }
      },
      'Are you sure you want to delete this item?'
    );
  };

  if (!id) {
    return (
      <Box className={classes.container}>
        <Placeholder message={`No ${type}s available`} />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <ActionButtons
        baseUri={baseUri}
        id={id}
        onDelete={handleDelete}
        classes={classes}
      />
      <Box className={classes.content}>
        <ListItem
          text={text}
          title={title}
          postingDate={postingDate}
          isCursorPointer={false}
        />
      </Box>
    </Box>
  );
};

export default Details;
