import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext, useField } from 'formik';
import { object } from 'yup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Button, Form, FormInput, FormRow, FormSelect } from 'components';
import { Box, FormLabel } from '@material-ui/core';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  fileUploaders: {
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      marginRight: 15,
    },
  },
  form: {
    padding: '24px 0px',
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  fullRow: {
    padding: '8px 24px',
    margin: 0,
  },
  countInput: {
    width: '30%',
    paddingRight: '5px',
  },
  costInput: {
    width: '100px',
    paddingRight: '5px',
  },
  input: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  '& video': {
    height: 200,
    objectFit: 'cover',
    width: '100%',
  },
  label: {
    color: theme.palette.primary.generalText,
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
});

const RadioControl = () => {
  const [field] = useField('bonusType');
  return (
    <RadioGroup
      row
      aria-label='position'
      name='bonusType'
      defaultValue='top'
      value={field.value}
      onChange={field.onChange('bonusType')}
    >
      <FormControlLabel
        value='coupon'
        control={<Radio color='primary' />}
        label='coupon'
      />
      <FormControlLabel
        value='credit'
        control={<Radio color='primary' />}
        label='Credit'
      />
    </RadioGroup>
  );
};

const RadioControlTiming = ({ classes }) => {
  const [field] = useField('commissionTiming');
  return (
    <Box display='flex' flexDirection='column' mt={2}>
      <FormLabel className={classes.label}>Commission Timing</FormLabel>
      <RadioGroup
        row
        aria-label='position'
        name='commissionTiming'
        defaultValue='top'
        value={field.value}
        onChange={field.onChange('commissionTiming')}
      >
        <FormControlLabel
          value='firstPayment'
          control={<Radio color='primary' />}
          label='First Payment'
        />
        <FormControlLabel
          value='continuous'
          control={<Radio color='primary' />}
          label='Continuous'
        />
      </RadioGroup>
    </Box>
  );
};

const InviteRuleForm = ({
  coupons,
  classes,
  error,
  onSubmit,
  pageTitle,
  processing,
  rule,
}) => {
  const FormBody = () => {
    const { isValid, values } = useFormikContext();
    return (
      <Form
        error={error}
        containerClass={classes.form}
        pageTitle={pageTitle}
        showDivider={false}
      >
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>Invitee Section</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <RadioControl />
        </FormRow>
        {values.bonusType === 'coupon' ? (
          <FormRow containerClass={classes.fullRow}>
            <FormSelect
              height={50}
              label='Select coupon to give the invitee'
              name='couponCode'
              width='100%'
              options={coupons}
            />
          </FormRow>
        ) : (
          <FormRow containerClass={classes.formRow}>
            <div className={classes.costInput}>
              <FormInput
                containerClass={classes.countInput}
                error={error}
                label={`Amount`}
                name='creditAmount'
                placeholder=''
              />
            </div>
          </FormRow>
        )}
        <FormRow>
          <div className={classes.fullRow}>
            <FormInput
              error={error}
              rows={3}
              label={`Invite Text Sent to Users`}
              name='inviteText'
              placeholder=''
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>Inviter Section</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.costInput}>
            <FormInput
              containerClass={classes.countInput}
              error={error}
              label={`Amount`}
              name='commissionAmount'
              placeholder=''
            />
          </div>
          <div className={classes.costInput}>
            <FormSelect
              height={50}
              label='Type'
              name='commissionType'
              width='100%'
              options={[
                {
                  label: '%',
                  value: 'percent',
                },
                {
                  label: '$',
                  value: 'amount',
                },
                {
                  label: 'credit',
                  value: 'credit',
                },
              ]}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <RadioControlTiming classes={classes} />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.costInput}>
            <FormInput
              containerClass={classes.countInput}
              error={error}
              label={`Code Tracking`}
              name='codeTracking'
              placeholder=''
            />
          </div>
          <Box pt={2}>
            <FormLabel className={classes.label}>DAYS</FormLabel>
          </Box>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button
                id='btnSave'
                disabled={!isValid}
                processing={processing}
                processingLabel='saving...'
                title='save'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };
  const transformValues = (values) => {
    return {
      ...values,
    };
  };
  const validationSchema = object().shape({
    // access: string().required('Required field'),
    // difficulty: string().required('Required field'),
    // frontEndVisible: string().required('Required field'),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={
        rule || { commissionTiming: 'firstPayment', bonusType: 'credit' }
      }
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};
const flattenCoupons = (coupons) => {
  return coupons?.map((coupon) => {
    return { label: coupon.code, value: coupon.code };
  });
};
export default connect(({ coupons: { coupons } }) => ({
  coupons: flattenCoupons(coupons),
}))(withStyles(styles)(InviteRuleForm));
