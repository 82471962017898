import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  tasks: [],
  isDirty: false,
  processing: false,
  activeTask: 0,
  results : null
};

// Action creators
const { Types, Creators } = createActions(
  {
    updateUI: ['ui'],
    postResultsSuccess: ['response'],
    postResultsFail: ['error'],
    postResults: (answers) => {
      console.log(answers, 'GULI');
      return {
        type: 'userAssessment/POST_RESULTS',
        payload: {
          request: {
            url: '/assessment-tasks/results',
            method: 'post',
            data: { answers },
          },
        },
      };
    },
    getUserAssessmentFail: ['error'],
    getUserAssessmentSuccess: ['response'],
    getUserAssessment: () => {
      return {
        type: 'userAssessment/GET_USER_ASSESSMENT',
        payload: {
          request: {
            url: '/assessment-tasks',
            method: 'get',
         },
        },
      };
    },
  },
  { prefix: 'userAssessment/' }
);

// Reducers
const getUserAssessment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getUserAssessmentSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  console.log("WORLD")
  return {
    ...state,
    tasks: data,
    processing: false,
  };
};

const postResultsSuccess1 = (state = INITIAL_STATE, { payload: { data } }) => {
  console.log('KUKUSHA');

  return {
    ...state,
    result: [...data],
  };
};

const postResultsSuccess = (state = INITIAL_STATE , { payload: { data } }) => {
  console.log('HELLOOO111');
  return {
    ...state,
    results: data,
    processing: false,
  };
};

const postResultsFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_ASSESSMENT]: getUserAssessment,
  [Types.GET_USER_ASSESSMENT_SUCCESS]: getUserAssessmentSuccess,
  [Types.POST_RESULTS_SUCCESS]: postResultsSuccess,
  [Types.POST_RESULTS_FAIL]: postResultsFail,
  // [Types.POST_RESULTS]: postResults,
});

export { Creators, Types, Reducer, INITIAL_STATE };

// postResults
