import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import { Creators as AuthCreators } from 'modules/auth';
import { push } from 'connected-react-router';

import Layout from './Layout';
import { RedeemModal } from '../index';
import { Button } from '../../../../components';
import { MixpanelEvents, track } from 'core/utils/mixpanel';

const styles = ({ breakpoints, palette: { primary } }) => ({
  button: {
    height: 64,
    width: 240,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  statLabel: {
    color: primary.darkGray,
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: 16,
  },
  statValue: {
    color: primary.generalText,
    fontSize: 32,
    fontWeight: 600,
    marginBottom: 16,
  },
  statContainer: {
    flex: '1 1 0',
    padding: '15px',
    margin: '15px 10px',
    backgroundColor: '#fff',
    minHeight: '168px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.04)',
  },
  statContents: {
    borderLeft: '5px solid',
    display: 'flex',
    paddingLeft: '10px',
    minHeight: '120px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  borderColor: {
    borderColor: (props) => props.color || 'transparent',
  },
  buttonContainer: {
    width: 240,
    height: 64,
  },
});

const StatBlockRaw = ({ classes, statName, value }) => {
  return (
    <div className={classes.statContainer}>
      <div className={cx(classes.statContents, classes.borderColor)}>
        <div className={classes.statLabel}>{statName}</div>
        <div className={classes.statValue}>{value}</div>
      </div>
    </div>
  );
};
const StatBlock = withStyles(styles)(StatBlockRaw);

const Credits = ({ classes, user, me, redeemCredits, goTo }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    me({ loadingVisible: false });
    track(MixpanelEvents.viewedReferralLandingPage);
  }, [me]);

  const onRedeemClick = async () => {
    if (user.method) {
      await redeemCredits();
      setIsOpen(true);
    } else {
      goTo('/dashboard/payment/choose-plan');
    }
    track(MixpanelEvents.pressedRedeemNowButtonLandingPage);
  };

  return (
    <Layout title='MY CREDITS' background='#FEFEFE'>
      <StatBlock
        color='#28aae2'
        statName='Total Balance'
        value={`$${user.credits || 0}`}
      />
      <div>
        {user.method !== 'app' ? (
          <Button
            title='Redeem'
            className={classes.button}
            onClick={onRedeemClick}
          />
        ) : (
          <div>You need to redeem this through the iOS app.</div>
        )}
      </div>
      <RedeemModal isOpen={isOpen} setIsOpen={setIsOpen} method={user.method} />
    </Layout>
  );
};

export default connect(({ auth: { user } }) => ({ user }), {
  me: AuthCreators.me,
  redeemCredits: AuthCreators.redeemCredits,
  goTo: push,
})(withStyles(styles)(Credits));
