import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { Form as FormikForm } from 'formik';
import PropTypes from 'prop-types';

import FormError from './FormError';

const styles = (theme) => ({
  container: {
    background: theme.palette.background.paper,
    boxShadow: '0 8px 16px 2px rgba(15, 18, 26, 0.08)',
    borderRadius: theme.radius,
    padding: `64px 92px`,
    width: 684,
    // width : "100%",
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: 24,
      width: 'calc(100vw - 32px)',
    },
  },
  divider: {
    border: `1px solid ${theme.palette.primary.gray}`,
    margin: `${theme.spacing(3)}px 0px`,
  },
  title: {
    color: theme.palette.primary.dark,
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: theme.letterSpacing.narrow,
    textTransform: 'uppercase',
  },
});

export const Form = ({
  classes,
  children,
  containerClass,
  error = {},
  pageTitle = '',
  renderHeader = () => {},
  showDivider = true,
  title,
}) => (
  <>
    <Helmet>
      <title>{pageTitle || title}</title>
    </Helmet>
    <FormikForm className={cx(classes.container, containerClass)}>
      <div className={classes.title}>{title}</div>
      {renderHeader()}
      {showDivider && <hr className={classes.divider} />}
      <div className={classes.children}>{children}</div>
      {error['_general'] && <FormError>{error['_general']}</FormError>}
    </FormikForm>
  </>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  renderHeader: PropTypes.func,
  title: PropTypes.string,
};

export default withStyles(styles)(Form);
