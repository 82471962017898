import React, { useEffect } from 'react';
import { useTheme, withStyles } from '@material-ui/styles';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from '@material-ui/core';

const styles = ({ palette: { primary } }) => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    '& > li': {
      color: primary.generalText,
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.06em',
      margin: 12,
    },
    '& li:first-child': {
      color: primary.main,
    },
    '& li:last-child': {
      color: primary.main,
    },
    '& > li.disabled': {
      color: 'rgba(74, 75, 107, 0.4)',
      cursor: 'initial',
    },
    '& > li.selected': {
      color: primary.main,
      cursor: 'initial',
    },
  },
});

const Paginate = ({
  classes,
  forcePage,
  onPageChange,
  marginPagesDisplayed = 2,
  pageCount,
  pageRangeDisplayed = 5,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!window.history.pushState) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (isNaN(forcePage)) {
      return;
    }

    searchParams.set('page', forcePage + 1);

    const url = new URL(window.location);
    url.search = searchParams.toString();

    const path = new URL(url).toString();

    window.history.pushState({ path }, '', path);
  }, [forcePage]);

  const _pageRangeDisplayed = isSmallScreen
    ? Math.min(pageRangeDisplayed, 1)
    : pageRangeDisplayed;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ReactPaginate
        breakLabel={'...'}
        containerClassName={classes.pagination}
        forcePage={forcePage}
        marginPagesDisplayed={marginPagesDisplayed}
        nextLabel={'NEXT'}
        onPageChange={onPageChange}
        pageCount={pageCount}
        pageRangeDisplayed={_pageRangeDisplayed}
        previousLabel={'PREV'}
        subContainerClassName={'pages pagination'}
      />
    </div>
  );
};

export default withStyles(styles)(Paginate);

export const getPageFromLocation = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const _currentPage = searchParams.get('page');
  if (!_currentPage) {
    return 0;
  }

  const page = Number(_currentPage);
  if (isNaN(page)) {
    return 0;
  }

  return page - 1;
};
