import React, { useEffect, useState, useRef } from 'react';
import { useTheme, withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { push } from 'connected-react-router';
import moment from 'moment';

import { Button, Dialog, Loader } from 'components';
import { Favorite, ListItem, TagCard } from 'modules/dashboard';
import { ExercisePlayer } from 'modules/dashboard/exercises';
import WorkoutPlayer from './WorkoutPlayer';
import { Creators } from '../reducer';
import { Creators as ExerciseCreators } from 'modules/dashboard/exercises';
import { useMediaQuery } from '@material-ui/core';
import { sortByTitle } from 'core/utils/sort';
import { mixpanelAtoms, MixpanelEvents, track } from 'core/utils/mixpanel';
import {
  getWorkoutFormat,
  getWorkoutTags,
  getWorkoutType,
} from 'core/utils/workouts';
import { useRecoilValue } from 'recoil';

const styles = ({ breakpoints, palette: { primary } }) => ({
  button: {
    height: 55,
    width: 200,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  container: {},
  dialog: {
    maxWidth: 800,
    overflow: 'auto',
    width: 800,
  },
  exercisePlayerFullScreen: {
    overflow: 'hidden',
    maxWidth: '100vw',
    width: '100vw',
    '& > img[data-id="close-button"]': {
      display: 'none !important',
    },
  },
  hero: {
    maxWidth: 1200,
    '& > img': {
      height: '100%',
      objectFit: 'cover',
      width: '100%',
      [breakpoints.down('xs')]: {
        height: 250,
      },
    },
    backgroundColor: primary.main,
    height: 500,
    position: 'relative',
    [breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto',
    },
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    [breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto',
      margin: '0 15px',
    },
  },
  dialogText: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    textTransform: 'uppercase',
  },
  heroInfo: {
    alignItems: 'center',
    background: `rgba(0,0,0, 0.6)`,
    bottom: 0,
    color: primary.contrastText,
    display: 'flex',
    fontSize: 42,
    fontWeight: 900,
    justifyContent: 'space-between',
    letterSpacing: 0.6,
    padding: '1rem 2rem',
    position: 'absolute',
    textTransform: 'uppercase',
    width: '100%',
    '& img': {
      cursor: 'pointer',
      height: 40,
      width: 40,
    },
    [breakpoints.down('xs')]: {
      fontSize: 24,
      padding: 10,
    },
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
    [breakpoints.down('xs')]: {
      marginTop: '5px',
      width: '100%',
    },
  },
  quitDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 632,
    padding: 64,
    [breakpoints.down('xs')]: {
      padding: 16,
    },
  },
  quitDialogText: {
    fontSize: 14,
    lineHeight: '24px',
    padding: '24px 0px',
  },
  section: {
    margin: '2rem auto',
    [breakpoints.down('xs')]: {},
  },
  sectionSubtitle: {
    color: primary.hunterGreen,
    fontSize: 22,
    fontWeight: '900',
    letterSpacing: 0.25,
    lineHeight: 1.75,
    textTransform: 'capitalize',
  },
  sectionTitle: {
    color: primary.hunterGreen,
    fontSize: 24,
    fontWeight: '900',
    letterSpacing: 0.4,
    lineHeight: 2,
    textTransform: 'uppercase',
  },
  tagCards: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: '32px 0px',
    [breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  textGray: {
    color: primary.darkGray,
    lineHeight: 1.75,
  },
  text: {
    whiteSpace: 'pre-line',
    color: primary.black,
    lineHeight: 1.75,
  },
  title: {
    color: primary.hunterGreen,
    fontSize: 32,
    fontWeight: '900',
    letterSpacing: 0.25,
    marginBottom: 20,
    textTransform: 'capitalize',
    [breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: 1.42,
      letterSpacing: 0.35,
      margin: '28px 15px',
    },
  },
  trainer: {
    '& > div:last-child': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 32,
      [breakpoints.down('md')]: {
        marginLeft: 12,
        alignSelf: 'center',
      },
    },
    '& img': {
      objectFit: 'cover',
      borderRadius: '50%',
      height: 200,
      width: 200,
      [breakpoints.down('md')]: {
        height: 75,
        width: 75,
      },
    },
    display: 'flex',
    marginTop: 20,
  },
  [breakpoints.down('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const Preview = ({
  addExerciseFavorite,
  addFavorite,
  classes,
  deleteExerciseFavorite,
  deleteFavorite,
  exerciseProcessing,
  exercises,
  getWorkout,
  goTo,
  processedExerciseItemId,
  processedWorkoutItemId,
  processing,
  sendStats,
  title,
  updateResults,
  workout,
  workouts,
  workoutId,
  isPremium,
  user
}) => {
  const [selectedExerciseId, setSelectedExerciseId] = useState(null);
  const [playlistToPlay, setPlaylistToPlay] = useState([]);
  const [isExercisePlayerOpen, setIsExercisePlayerOpen] = useState(false);
  const [isExercisePlayerFullScreen, setIsExercisePlayerFullScreen] = useState(
    false
  );
  const [isWorkoutPlayerOpen, setIsWorkoutPlayerOpen] = useState(false);
  const [isQuitDialogOpen, setIsQuitDialogOpen] = useState(false);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  const completedExercises = useRef({});
  const [startTime, setStartTime] = useState(null);
  const duration = useRef(0);

  const workoutPercentageCompleted = useRecoilValue(
    mixpanelAtoms.currentWorkoutPercentageCompleted
  );

  const redirectToPricing = () => {
    goTo('/dashboard/payment/choose-plan');
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setStartTime(new Date());
  }, [setStartTime]);

  useEffect(() => {
    getWorkout(workoutId);
  }, [getWorkout, workoutId]);

  useEffect(() => {
    if (!workout) {
      return;
    }

    track(MixpanelEvents.viewedWorkoutPreview, {
      type: getWorkoutType(workout),
      title: workout.title,
      tags: getWorkoutTags(workout),
      duration: workout.duration,
      format: getWorkoutFormat(workout),
      trainer: workout.trainer.title,
    });
  }, [workout]);

  let exerciseList = [];

  if (workout?.addedExercises?.length > 0) {
    exerciseList = workout.addedExercises.map((e) => {
      const thisExcersise = exercises.find((excersise) => excersise.id === e.id)
      return {
        exercise: {
          ...e,
          isFavorite: thisExcersise?thisExcersise.isFavorite:e.isFavorite
        },
      };
    });
  }
  const selectedWorkout = workouts.find((w) => w.id === workoutId);
  const isFavorite = selectedWorkout?.isFavorite || false;

  const selectedExercise = exercises.find((e) => e.id === selectedExerciseId);
  const exerciseToPlay = {
    ...selectedExercise,
    ...workout?.addedExercises?.find((e) => e.id === selectedExerciseId),
    isFavorite: selectedExercise?selectedExercise.isFavorite:workout?.isFavorite
  };

  const onExerciseCompleted = (exerciseId) => {
    if (!completedExercises.current[exerciseId]) {
      completedExercises.current = {
        ...completedExercises.current,
        [exerciseId]: true,
      };
    }
  };

  const onWorkoutCompleted = (startTime) => {
    const addedExercisesCount = workout?.addedExercises?.length || 0;

    let playlistCount = playlistToPlay.length;
    let completedCount = Object.keys(completedExercises.current).length;

    if (
      playlistCount === 1 &&
      addedExercisesCount > 0 &&
      completedExercises.length === 1
    ) {
      completedCount = addedExercisesCount;
      playlistCount = addedExercisesCount;
    }

    updateResults({
      duration: duration.current,
      completed: completedCount,
      count: playlistCount,
    });

    sendStats({
      routineType: 'class',
      routineId: workout?.id,
      duration: moment().diff(moment(startTime), 'second'),
      completed: completedCount === playlistCount,
    });

    goTo(`/dashboard/workout-results`);
  };

  const onDurationUpdated = (newDuration) => {
    duration.current = newDuration;
  };
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Workout Preview</title>
      </Helmet>
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
      </div>
      {processing && <Loader />}
      {workout && !processing && (
        <div className={classes.content}>
          <div className={classes.hero}>
            {workout.thumbnailWide?.location && (
              <img
                loading='lazy'
                alt=''
                src={`${workout.thumbnailWide?.publicLocation}?width=1200`}
              />
            )}
            <div className={classes.heroInfo}>
              <div>{workout.title}</div>
              <Favorite
                isFavorite={isFavorite}
                onAddFavorite={() => addFavorite({ id: workout.id })}
                onDeleteFavorite={() => deleteFavorite({ id: workout.id })}
                processing={workout.id === processedWorkoutItemId}
                size={36}
              />
            </div>
          </div>
          <div className={classes.tagCards}>
            <TagCard
              text={workout.equipments
                ?.sort(sortByTitle)
                .map((e) => e.title)
                .join(', ')}
              title='Equipment Needed'
            />
            <TagCard
              text={workout.bodyAreas
                ?.sort(sortByTitle)
                .map((a) => a.title)
                .join(', ')}
              title='Area of the Body'
            />
            <TagCard
              text={workout.movementPreps
                ?.sort(sortByTitle)
                .map((m) => m.title)
                .join(', ')}
              title='Movement Prep'
            />
            <TagCard
              text={workout.modalities
                ?.sort(sortByTitle)
                .map((e) => e.title)
                .join(', ')}
              title='Modality'
            />
            <TagCard
              text={workout.muscles
                ?.sort(sortByTitle)
                .map((a) => a.title)
                .join(', ')}
              title='Muscles'
            />
            <TagCard
              text={
                isNaN(workout.duration)
                  ? workout.duration
                  : moment.duration(workout.duration, 'seconds').humanize()
              }
              title='Duration'
            />
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>about the class</div>
            <div className={classes.text}>{workout.description}</div>
          </div>
          <div className={classes.section}>
            {exerciseProcessing && <Loader />}
            {!exerciseProcessing &&
              exerciseList.map(({ exercise }) => {
                console.log(exercise, exercise.id ,exercise.isFavorite);
                return (
                  <div 
                  onClick={
                    user.status === "freemium" ? () => redirectToPricing() : null
                  }
                  >
                  <ListItem
                    chips={exercise.modalities?.map((m) => m.title) || []}
                    isFavorite={exercise.isFavorite}
                    item={exercise}
                    key={exercise.id}
                    onAddFavorite={async () =>{
                      exercise.isFavorite = true;
                      await addExerciseFavorite({ id: exercise.id })
                      
                    }
                    }
                    onClick={(exercise ) => {

                      if (
                        !exercise.video &&
                        !exercise.videoHi &&
                        !exercise.videoLo
                      )
                        return;
                     
                      setSelectedExerciseId(exercise.id);
                      setIsExercisePlayerOpen(true);
                    }}
                    onDeleteFavorite={async () =>{
                      exercise.isFavorite = false;
                      await deleteExerciseFavorite({ id: exercise.id })
                      
                    }}
                    processing={exercise.id === processedExerciseItemId}
                    noXMargin
                  />
                  </div>
                );
              })}
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>meet the instructor</div>
            <div className={classes.trainer}>
              <div>

                <img
                  alt='instructor avatar'
                  className={classes.avatar}
                  src={`${workout.trainer.thumbnail}?width=200`}
                />
              </div>
              <div>
                <div className={classes.sectionSubtitle}>
                  {workout.trainer.title}
                </div>
                {!isSmallScreen && (
                  <div className={classes.textGray}>
                    {workout.trainer.description}
                  </div>
                )}
              </div>
            </div>
            {isSmallScreen && (
              <div className={classes.textGray}>
                {workout.trainer.description}
              </div>
            )}
          </div>
          <div
              onClick={
                    user.status === "freemium" ? () => redirectToPricing() : null
                  } 
                   className={classes.section}>
            <Button
              className={classes.button}
              onClick={() => {
                setIsWorkoutPlayerOpen(true);
                setPlaylistToPlay(
                  workout?.playlist
                    .filter(
                      ({ exercise }) =>
                        exercise.video || exercise.videoHi || exercise.videoLo
                    )
                    .sort((a, b) => a.order - b.order) || []
                );
              }}
              title='start class'
            />
          </div>
        </div>
      )}
      <Dialog
        className={[
          classes.dialog,
          isExercisePlayerFullScreen ? classes.exercisePlayerFullScreen : '',
        ]}
        onClose={() => setIsExercisePlayerOpen(false)}
        open={isExercisePlayerOpen}
        fullScreen={isSmallScreen || isExercisePlayerFullScreen}
      >
        <ExercisePlayer
          exercise={exerciseToPlay}
          processedItemId={processedExerciseItemId}
          onFullscreenStatusChange={setIsExercisePlayerFullScreen}
        />
      </Dialog>
      <Dialog
        className={[
          classes.dialog,
          isExercisePlayerFullScreen ? classes.exercisePlayerFullScreen : '',
        ]}
        onClose={() => setIsQuitDialogOpen(true)}
        open={isWorkoutPlayerOpen}
        fullScreen={isSmallScreen || isExercisePlayerFullScreen}
      >
        <WorkoutPlayer
          isFavorite={isFavorite}
          onDurationUpdated={onDurationUpdated}
          onExerciseCompleted={onExerciseCompleted}
          onWorkoutCompleted={onWorkoutCompleted}
          playlist={playlistToPlay}
          title={workout?.title}
          startTime={startTime}
          workoutDuration={workout?.duration || 0}
          workoutId={workout?.id}
          onFullscreenStatusChange={setIsExercisePlayerFullScreen}
        />
      </Dialog>
      <Dialog
        open={isQuitDialogOpen}
        onClose={() => setIsQuitDialogOpen(false)}
      >
        <div className={classes.quitDialog}>
          <div className={classes.dialogText}>
            Are you sure you want to quit?
          </div>
          <div className={classes.quitDialogText}>
            Spend a few more minutes giving your body what it needs. You will
            feel amazing afterward!
          </div>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button
                title='quit'
                outlined
                onClick={() => {
                  track(MixpanelEvents.quitWorkout, {
                    type: getWorkoutType(workout),
                    percentageCompleted: workoutPercentageCompleted,
                    exerciseCount: workout?.addedExercises.length,
                    exercises:
                      workout?.addedExercises?.map(
                        (workout) => workout.title
                      ) || [],
                    title: workout?.title,
                    tags: getWorkoutTags(workout),
                    duration: workout?.duration,
                    format: getWorkoutFormat(workout),
                    trainer: workout?.trainer.title,
                  });
                  setIsQuitDialogOpen(false);
                  setIsWorkoutPlayerOpen(false);
                  onWorkoutCompleted(startTime);
                }}
              />
            </div>
            <div className={classes.input}>
              <Button
                title='resume'
                onClick={() => setIsQuitDialogOpen(false)}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};


// const mapStateToPropss = ({ auth: { user } }) => ({
//   user,
// });


const mapStateToProps = (
  {
    auth : {user},
    exercise: {
      exercises,
      processing: exerciseProcessing,
      processedItemId: processedExerciseItemId,
    },
    workout,
  },
  { workoutType }
) => {
  const {
    currentWorkout,
    processedItemId: processedWorkoutItemId,
    processing,
  } = workout;

  return {
    user,
    exercises,
    exerciseProcessing,
    processedExerciseItemId,
    processedWorkoutItemId,
    processing,
    workout: currentWorkout,
    workouts: workout[workoutType],
  };
};

const mapDispatchToProps = {
  addExerciseFavorite: ExerciseCreators.addFavorite,
  addFavorite: Creators.addFavorite,
  deleteExerciseFavorite: ExerciseCreators.deleteFavorite,
  deleteFavorite: Creators.deleteFavorite,
  getWorkout: Creators.getWorkout,
  goTo: push,
  sendStats: Creators.sendStats,
  updateResults: Creators.updateResults,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Preview));
