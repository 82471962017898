import { all, call, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { parseError } from 'core';
import { Types } from './reducer';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_MOVEMENT_PREP_FAIL, requestFailed),
    takeEvery(Types.CREATE_MUSCLES_FAIL, requestFailed),
    takeEvery(Types.CREATE_WORKOUT_TYPE_FAIL, requestFailed),
    takeEvery(Types.CREATE_MODALITY_FAIL, requestFailed),
    takeEvery(Types.CREATE_EQUIPMENT_FAIL, requestFailed),
    takeEvery(Types.CREATE_BODY_AREA_FAIL, requestFailed),
  ]);
}
