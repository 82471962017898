/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

import { Checkbox, Paginate } from 'components';
import ListItemDetails from './ListItemDetails';
import ListItemProperty from './ListItemProperty';
import SearchBar from './SearchBar';
import { sortByActiveSince, sortByTitle } from 'core/utils/sort';

const PAGE_SIZE = 10;

const styles = ({ palette: { background, primary, secondary } }) => ({
  checkboxLabel: {
    color: primary.dark,
    fontSize: 18,
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    display: 'flex',
  },
  flexBetween: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
  },
  label: {
    color: secondary.grayText,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
    minWidth: '6rem',
    textAlign: 'right',
  },
  list: {
    background: background.paper,
    borderRight: `1px solid ${primary.gray}`,
    borderBottom: `1px solid ${primary.gray}`,
    height: 'calc(100vh - 128px)',
    overflowY: 'auto',
    maxWidth: 600,
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  listItem: {
    padding: '1.5rem 1rem',
    borderBottom: `1px solid ${primary.gray}`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  listItemSelected: {
    background: secondary.inactive,
  },
  listItemProperties: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  message: {
    padding: 16,
    fontSize: 16,
    color: primary.dark,
    '& > span': {
      color: primary.main,
    },
  },
});

const getFormattedDate = (date) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

let ListItem = ({ checked, classes, item, isSelected, onCheck, onClick }) => {
  const {
    palette: { primary, secondary },
  } = useTheme();

  return (
    <div
      className={cx(
        classes.listItem,
        isSelected ? classes.listItemSelected : ''
      )}
      onClick={onClick}
    >
      
      <div className={classes.flexBetween}>
        <Checkbox
          checked={checked}
          label={item.title}
          labelClass={classes.checkboxLabel}
          onCheck={(e) => {
            e.stopPropagation();
            onCheck(!checked, item.id);
          }}
        />
        <div className={classes.label}>
          {getFormattedDate(item.activeSince)}
        </div>
      </div>
      <div className={classes.listItemProperties}>
        <ListItemProperty
          label='usage area'
          text={item.access}
          style={{
            color: item.access === 'free' ? secondary.dark : primary.main,
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        />
        <ListItemProperty label='difficulty' text={item.difficulty} />
        <ListItemProperty
          label='modality'
          text={item.modalities
            ?.sort(sortByTitle)
            .map((m) => m.title)
            .join(', ')}
        />
      </div>
      <div className={classes.listItemProperties}>
        <ListItemProperty
          label='movement prep'
          text={item.movementPreps
            ?.sort(sortByTitle)
            .map((m) => m.title)
            .join(', ')}
        />
        <ListItemProperty
          label='equipment'
          text={item.equipments
            ?.sort(sortByTitle)
            .map((e) => e.title)
            .join(', ')}
        />
        <ListItemProperty
          label='area of the body'
          text={item.bodyAreas
            ?.sort(sortByTitle)
            .map((m) => m.title)
            .join(', ')}
        />
      </div>
      
    </div>
  );
};

ListItem = withStyles(styles)(ListItem);

const List = ({
  baseUri,
  buttonTitle,
  classes,
  fetchDetails = () => {},
  items,
  onBulkDelete,
  onDelete,
  onDuplicate,
  processing,
  showModal,
  updateUI,
  ui,
}) => {
  const {
    checkedItems,
    currentPage,
    selectedItemId,
    selectedItemIndex,
    filter,
  } = ui;

  const [displayedItems, setDisplayedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const filterItems = () => {
    const { month: monthObj, search, tags } = filter;
    const [month, year] = monthObj.split('-');

    let result = items
      .filter((item) => {
        if (!monthObj || !item.activeSince) return true;

        return (
          new Date(item.activeSince).getFullYear() === parseInt(year) &&
          new Date(item.activeSince).getMonth() === parseInt(month.toString())
        );
      })
      .filter((item) => {
        if (tags && tags['Status'].length > 0) {
          return tags['Status'].includes(item.status);
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Movement Prep'].length > 0) {
          return item.movementPreps.some((m) =>
            tags['Movement Prep'].includes(m.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Equipment'].length > 0) {
          return item.equipments.some((eq) =>
            tags['Equipment'].includes(eq.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Area of the Body'].length > 0) {
          return item.bodyAreas.some((b) =>
            tags['Area of the Body'].includes(b.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Modality'].length > 0) {
          return item.modalities.some((b) => tags['Modality'].includes(b.id));
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Muscles'].length > 0) {
          return item.muscles.some((b) => tags['Muscles'].includes(b.id));
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Usage Area'].length > 0) {
          return tags['Usage Area'].includes(item.access);
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Difficulty'].length > 0) {
          return tags['Difficulty'].includes(item.difficulty);
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Frontend Visible']?.length > 0) {
          return tags['Frontend Visible'].includes(
            item.frontEndVisible?.toString() || ''
          );
        }
        return true;
      })
      .filter((item) => {
        if (tags && tags['Workout Type']?.length > 0) {
          return item.workoutTypes.some((w) =>
            tags['Workout Type'].includes(w.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (search) {
          return (
            item?.title?.toLowerCase().includes(search.toLowerCase()) ||
            item?.description?.toLowerCase().includes(search.toLowerCase())
          );
        }
        return true;
      })
      .sort(sortByActiveSince);

    const start = currentPage * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    setPageCount(Math.ceil(result.length / PAGE_SIZE));
    result = result.slice(start, end);

    return result;
  };

  useEffect(() => {
    const filteredItems = filterItems();
    updateUI({
      ...ui,
      selectedItemId: filteredItems[0]?.id || -1,
      selectedItemIndex: 0,
    });
    setDisplayedItems(filteredItems);
    const el = document.getElementById('mv-generic-list');
    if (el) el.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const filteredItems = filterItems();
    updateUI({
      ...ui,
      selectedItemId: filteredItems[0]?.id || -1,
      selectedItemIndex: 0,
      currentPage: 0,
    });
    setDisplayedItems(filteredItems);
    const el = document.getElementById('mv-generic-list');
    if (el) el.scrollTo(0, 0);
  }, [filter]);

  useEffect(() => {
    const filteredItems = filterItems();
    setDisplayedItems(filteredItems);
  }, [items]);

  useEffect(() => {
    if (selectedItemId && selectedItemId !== -1) fetchDetails(selectedItemId);
  }, [selectedItemId]);

  const updateUIState = (fieldName, value) => {
    updateUI({
      ...ui,
      [fieldName]: value,
    });
  };

  const visibleCheckedItems = displayedItems
    .filter((di) => checkedItems.includes(di.id))
    .map((item) => item.id);

  const visibleCheckedItemsCount = visibleCheckedItems.length;

  return (
    <div className={classes.container}>
      <SearchBar
        baseUri={baseUri}
        buttonTitle={buttonTitle}
        filter={filter}
        onBulkDelete={() =>
          showModal(
            'deleteConfirmation',
            () =>
              onBulkDelete({
                ids: visibleCheckedItems,
              }),
            `Are you sure you want to delete ${visibleCheckedItemsCount} item${
              visibleCheckedItemsCount > 1 ? 's' : ''
            }?`
          )
        }
        onSelectAll={(checked) => {
          if (checked) {
            updateUIState(
              'checkedItems',
              displayedItems.map((i) => i.id)
            );
          } else {
            updateUIState('checkedItems', []);
          }
        }}
        onMonthSelected={(value) =>
          updateUIState('filter', { ...filter, month: value })
        }
        onTagFilterChanged={(value) =>
          updateUIState('filter', { ...filter, tags: value })
        }
        onSearchChanged={(value) =>
          updateUIState('filter', { ...filter, search: value })
        }
        showDelete={checkedItems.length > 0}
        selectAllChecked={
          displayedItems.length > 0 &&
          checkedItems.length === displayedItems.length
        }
      />
      {processing ? (
        <div className={classes.message}>Loading...</div>
      ) : displayedItems.length > 0 ? (
        <div className={classes.content}>
          <div>
            <div className={classes.list} id='mv-generic-list'>
              {displayedItems.sort(sortByActiveSince).map((item, i) => (
                <ListItem
                  checked={checkedItems.includes(item.id)}
                  item={item}
                  key={item.id}
                  onClick={() => {
                    updateUI({
                      ...ui,
                      selectedItemIndex: i,
                      selectedItemId: item.id,
                    });
                  }}
                  onCheck={(checked, id) => {
                    if (checked) {
                      updateUIState('checkedItems', checkedItems.concat(id));
                    } else {
                      updateUIState(
                        'checkedItems',
                        checkedItems.filter((c) => c !== id)
                      );
                    }
                  }}
                  isSelected={i === selectedItemIndex}
                />
              ))}
              
            </div>
            <Paginate
              forcePage={currentPage}
              pageCount={pageCount}
              onPageChange={({ selected }) =>
                updateUIState('currentPage', selected)
              }
            />
          </div>
          <ListItemDetails
            baseUri={baseUri}
            item={displayedItems[selectedItemIndex]}
            onDelete={(id) => {
              showModal(
                'deleteConfirmation',
                () => {
                  onDelete(id);

                  if (displayedItems.length - 1 === selectedItemIndex) {
                    updateUIState(
                      'selectedItemIndex',
                      Math.max(selectedItemIndex - 1, 0)
                    );
                  }
                },
                'Are you sure you want to delete this item?'
              );
            }}
            onDuplicate={
              Boolean(onDuplicate)
                ? (id) => {
                    showModal(
                      'duplicateConfirmation',
                      () => {
                        onDuplicate(id);
                      },
                      'Are you sure you want to duplicate this item?'
                    );
                  }
                : undefined
            }
            title='class name'
          />
          
        </div>
      ) : (
        <div className={classes.message}>
          No results. Please try selecting fewer filters and/or using different
          search terms.
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(List);
