import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  footer: {
    alignItems: 'center',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    height: 60,
    fontSize: 14,
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
  },
});

const Footer = ({ classes }) => (
  <div className={classes.footer}>
    {`Movement Vault, LLC © ${new Date().getFullYear()}. All rights reserved`}
  </div>
);

export default withStyles(styles)(Footer);
