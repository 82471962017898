import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';

import {
  Button,
  FormCheckbox,
  Form,
  FormInput,
  FormRow,
  Link,
} from 'components';

import { Creators, AdminLayout } from 'modules/auth';

const styles = (theme) => ({
  button: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

const SignInForm = ({ classes, error, processing, signIn }) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form error={error} title='Sign In'>
        <FormRow>
          <FormInput
            label='Email address'
            name='email'
            placeholder='Email address'
          />
        </FormRow>
        <FormRow>
          <FormInput
            error={error}
            label='Password'
            name='password'
            placeholder='Password'
            type='password'
          />
        </FormRow>
        <FormRow>
          <FormCheckbox label='Remember Me' name='rememberMe' />
        </FormRow>
        <FormRow>
          <div className={classes.button}>
            <Button
              disabled={!isValid}
              processing={processing}
              processingLabel='Signing in...'
              title='Sign In'
              type='submit'
            />
          </div>
        </FormRow>
        <FormRow>
          <Link to='/auth/forgot-password' title='Forgot Password?' />
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    email: string().email().required('Email is required'),
    password: string().min(6).required('Password is required'),
  });

  return (
    <AdminLayout>
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        onSubmit={(values) => signIn({ ...values, persona: 'admin' })}
        validationSchema={validationSchema}
      >
        <FormBody />
      </Formik>
    </AdminLayout>
  );
};

const mapStateToProps = ({ auth: { error, processing } }) => ({
  error,
  processing,
});

const mapDispatchToProps = { signIn: Creators.signIn };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignInForm));
