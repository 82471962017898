import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Creators as ModalCreators } from 'modules/modal';
import { Creators as TagCreators } from 'modules/admin/dashboard/programming/tags';
import { Creators as WorkoutCreators } from 'modules/admin/dashboard/programming/workout-creator';

import { Creators } from '../reducer';

import List from '../../components/List';

const Exercises = ({
  deleteExercise,
  deleteExercises,
  duplicateExercise,
  tagsDirty,
  getExercises,
  getWorkouts,
  items,
  processing,
  showModal,
  ui,
  updateTagState,
  updateUI,
}) => {
  useEffect(() => {
    if (tagsDirty) {
      getExercises();
      getWorkouts();
      updateTagState({ key: 'isDirty', value: false });
    }
  }, [getExercises, tagsDirty, getWorkouts, updateTagState]);

  return (
    <>
      <Helmet>
        <title>Exercises</title>
      </Helmet>
      <List
        baseUri='exercise-creator'
        buttonTitle='create new exercise'
        items={items}
        onBulkDelete={deleteExercises}
        onDelete={deleteExercise}
        onDuplicate={duplicateExercise}
        processing={processing}
        showModal={showModal}
        ui={ui}
        updateUI={updateUI}
      />
    </>
  );
};

const mapDispatchToProps = {
  deleteExercise: Creators.deleteExercise,
  deleteExercises: Creators.deleteExercises,
  duplicateExercise: Creators.duplicateExercise,
  getExercises: Creators.getExercises,
  getWorkouts: WorkoutCreators.getWorkouts,
  showModal: ModalCreators.showModal,
  updateTagState: TagCreators.updateState,
  updateUI: Creators.updateUI,
};

const mapStateToProps = ({
  exerciseCreator: { exercises, processing, ui },
  tags: { isDirty },
}) => {
  return {
    tagsDirty: isDirty,
    items: exercises,
    processing,
    ui,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Exercises);
