import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ExerciseFavorites } from 'modules/dashboard/exercises';
import List from './List';
import { useRecoilState } from 'recoil';
import { mixpanelAtoms } from 'core/utils/mixpanel';

const styles = ({ breakpoints, palette: { primary } }) => ({
  tab: {
    color: primary.darkGray,
    fontSize: 20,
    textAlign: 'left',
    letterSpacing: 0.25,
    minWidth: 100,
    padding: '0 1rem',
    textTransform: 'capitalize',
  },
  tabPanel: {
    paddingLeft: 40,
    width: '100%',
    [breakpoints.down('md')]: {
      padding: 0,
    },
  },
  tabsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflow: 'visible',
    position: 'relative',
  },
  tabsContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    width: '100%',
  },
  tabs: {
    width: '100%',
    [breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  tabSelected: {
    color: primary.black,
    fontSize: 20,
    fontWeight: 900,
    letterSpacing: 0.25,
    textTransform: 'capitalize',
    [breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  tabsFlexContainer: {
    background: '#f9faff',
    height: 80,
    paddingLeft: 40,
    [breakpoints.down('md')]: {
      overflow: 'auto',
      paddingLeft: 0,
    },
  },
  tabWrapper: {
    alignItems: 'start',
  },
});

const TabPanel = withStyles(styles)(
  ({ children, value, index, classes, ...other }) => (
    <div
      aria-labelledby={`wrapped-tab-${index}`}
      className={classes.tabPanel}
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {<div>{children}</div>}
    </div>
  )
);

const tabTitles = [
  'Exercises',
  'Daily Class',
  'Pre-Workout',
  'Recovery',
  'Work Routines',
  'Pain & Injury',
];

const Favorites = ({ classes }) => {
  const [value, setValue] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [currentFavoriteTab, setCurrentFavoriteTab] = useRecoilState(
    mixpanelAtoms.currentFavoriteTab
  );

  useEffect(() => {
    setCurrentFavoriteTab(tabTitles[value]);
  }, [setCurrentFavoriteTab, value]);

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Favorites</title>
      </Helmet>
      <div className={classes.tabsContainer}>
        <Tabs
          aria-label='favorites'
          classes={{
            root: classes.tabs,
            flexContainer: classes.tabsFlexContainer,
          }}
          indicatorColor='secondary'
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          TabIndicatorProps={{
            style: {
              height: 4,
            },
          }}
          value={value}
        >
          {tabTitles.map((tabName) => (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              key={tabName}
              label={tabName}
            />
          ))}
        </Tabs>
        <div className={classes.tabsContent}>
          <TabPanel value={value} index={0}>
            <ExerciseFavorites title='Exercises' />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <List
              isFavoriteList
              isDailyClassList
              title='Daily Class'
              workoutKey='daily-class'
              workoutType='dailyClass'
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <List
              isFavoriteList
              title='Pre-Workout'
              workoutKey='pre-workout'
              workoutType='preWorkout'
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <List
              isFavoriteList
              title='Recovery'
              workoutKey='recovery'
              workoutType='recovery'
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <List
              isFavoriteList
              title='Work Routines'
              workoutKey='work-routine'
              workoutType='workRoutine'
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <List
              isFavoriteList
              title='Programs'
              workoutKey='pain-and-injury'
              workoutType='painAndInjury'
            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Favorites);
