import React from 'react';
import List from './List';
import { Helmet } from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <title>Pre-Workout</title>
    </Helmet>
    <List
      title='Pre-Workout'
      workoutKey='pre-workout'
      workoutType='preWorkout'
    />
  </>
);
