import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';

import {
  Button,
  FileUploader,
  Form,
  FormDateTimePicker,
  FormInput,
  FormRow,
  FormSelect,
} from 'components';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  fileUploaders: {
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      marginRight: 15,
    },
  },
  form: {
    padding: '24px 0px',
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

let FileUploaders = React.memo(
  ({ classes, thumbnailRef, videoRef, videoHiRef, videoLoRef }) => (
    <>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={thumbnailRef.current.url}
            onFileUploaded={({ id, url }) => {
              thumbnailRef.current = { id, url };
            }}
          />
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoRef.current = { id, url };
            }}
            title='Video 720p'
          />
        </div>
      </FormRow>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoLoRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoLoRef.current = { id, url };
            }}
            title='Video 480p'
          />
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoHiRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoHiRef.current = { id, url };
            }}
            title='Video 1080p'
          />
        </div>
      </FormRow>
    </>
  )
);

FileUploaders = withStyles(styles)(FileUploaders);

const ExerciseForm = ({
  bodyArea,
  classes,
  difficulty,
  equipment,
  exercise,
  frontEndVisible,
  error,
  isEdit = false,
  modality,
  movementPrep,
  muscles,
  onSubmit,
  pageTitle,
  processing,
  usageArea,
}) => {
  const thumbnailRef = useRef(exercise?.thumbnail || { id: '', url: '' });
  const videoRef = useRef(exercise?.video || { id: '', url: '' });
  const videoHiRef = useRef(exercise?.videoHi || { id: '', url: '' });
  const videoLoRef = useRef(exercise?.videoLo || { id: '', url: '' });
  console.log(exercise?.thumbnail.url , "EXE")

  const canPublish = (values) => {
    return (
      !!thumbnailRef.current.url &&
      !!values.access &&
      !!values.activeSince &&
      //!!(values.bodyAreas?.length > 0) &&
      !!values.description &&
      !!values.difficulty &&
      //!!(values.equipments?.length > 0) &&
      !!values.frontEndVisible &&
      //!!(values.modalities?.length > 0) &&
      //!!(values.movementPreps?.length > 0) &&
      //!!(values.muscles?.length > 0) &&
      !!values.title &&
      !!videoRef.current.url
    );
  };

  const FormBody = () => {
    const { isValid, values } = useFormikContext();

    return (
      <Form
        error={error}
        containerClass={classes.form}
        pageTitle={pageTitle}
        showDivider={false}
      >
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>general info</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label='title'
            name='title'
            placeholder='Enter a title...'
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label='description'
            name='description'
            placeholder='Enter a description...'
            rows={4}
          />
        </FormRow>
        <FileUploaders
          thumbnailRef={thumbnailRef}
          videoRef={videoRef}
          videoHiRef={videoHiRef}
          videoLoRef={videoLoRef}
        />
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>tags</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.inputs}>
            <div>
              <FormSelect
                height={50}
                width='100%'
                label='Usage area'
                name='access'
                options={usageArea}
              />
            </div>
            <div>
              <FormSelect
                height={50}
                width='100%'
                label='Difficulty'
                name='difficulty'
                options={difficulty}
                avoidSorting
              />
            </div>
            <div className={classes.input}>
              <FormSelect
                height={50}
                width='100%'
                label='Frontend visible'
                name='frontEndVisible'
                options={frontEndVisible}
              />
            </div>
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Modality'
              name='modalities'
              width='100%'
              options={modality}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Movement Prep'
              name='movementPreps'
              width='100%'
              options={movementPrep}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Equipment'
              name='equipments'
              width='100%'
              options={equipment}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Area of the body'
              name='bodyAreas'
              width='100%'
              options={bodyArea}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Muscles'
              name='muscles'
              width='100%'
              options={muscles}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>date of publishing</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormDateTimePicker label='Time of Publishing' name='activeSince' />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button
                disabled={!isValid}
                outlined
                processing={processing}
                processingLabel='saving...'
                title='save draft'
                type='submit'
              />
            </div>
            <div className={classes.button}>
              <Button
                id='btnPublish'
                disabled={!canPublish(values)}
                processing={processing}
                processingLabel='saving...'
                title='publish'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };
  const transformValues = (values) => {
    let activeSince = null;
    const isPublisButtonClicked = window.event.submitter.id === 'btnPublish';

    if (canPublish(values) && isPublisButtonClicked) {
      activeSince = Number.isInteger(values.activeSince)
        ? new Date(parseInt(values.activeSince))
        : values.activeSince;
    }

    return {
      access: values.access || usageArea[0].value,
      activeSince,
      bodyAreas: values.bodyAreas?.map((b) => b.value) || [],
      description: values.description,
      difficulty: values.difficulty || difficulty[0].value,
      equipments: values.equipments?.map((e) => e.value) || [],
      frontEndVisible: values.frontEndVisible || false,
      modalities: values.modalities?.map((m) => m.value) || [],
      movementPreps: values.movementPreps?.map((m) => m.value) || [],
      muscles: values.muscles?.map((m) => m.value) || [],
      side: 'left',
      thumbnail: thumbnailRef.current.id || null,
      title: values.title,
      video: videoRef.current.id || null,
      videoLo: videoLoRef.current.id || null,
      videoHi: videoHiRef.current.id || null,
    };
  };

  if (isEdit && !exercise) return null;

  const validationSchema = object().shape({
    title: string().required('Required field'),
  });

  return (
    <Formik
      initialValues={
        exercise || {
          access: '',
          activeSince: Date.now(),
          bodyAreas: [],
          description: '',
          difficulty: '',
          equipments: [],
          modalities: [],
          movementPreps: [],
          muscles: [],
          title: '',
        }
      }
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default connect(
  ({
    tags: {
      bodyArea,
      difficulty,
      equipment,
      frontEndVisible,
      modality,
      movementPrep,
      muscles,
      usageArea,
    },
  }) => ({
    bodyArea,
    difficulty,
    frontEndVisible,
    equipment,
    modality,
    movementPrep,
    muscles,
    usageArea,
  })
)(withStyles(styles)(ExerciseForm));
