import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useTheme, withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { Button, Slider } from 'components';
import filterFilledImg from 'assets/svg/filter-filled.svg';
import filterImg from 'assets/svg/filter.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { mixpanelAtoms } from 'core/utils/mixpanel';
const styles = ({
  breakpoints,
  palette: { background, messages, primary, secondary },
}) => ({
  action: {
    alignItems: 'center',
    color: primary.main,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: 14,
    fontWeight: 'bold',
    height: 60,
    letterSpacing: 0.2,
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  separator: {
    border: 'none',
    borderBottom: `1px solid ${primary.gray}`,
  },
  button: {
    width: 200,
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttons: {
    position: 'sticky',
    alignItems: 'center',
    display: 'flex',
    padding: '3rem 0',
    bottom: 0,
    gap: '0.5rem',
    background: `linear-gradient(180deg, transparent 0%, ${background.paper} 25%)`,
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  container: {
    outline: 'none',
    width: 140,
    [breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  content: {
    background: background.paper,
    left: 0,
    outline: 'none',
    position: 'absolute',
    top: 40,
    borderRadius: '2px',
    boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.1)',
    padding: 40,
    marginTop: 16,
    zIndex: 1000,
    width: '100%',
    [breakpoints.down('sm')]: {
      left: 0,
      padding: '20px',
      width: '100%',
    },
  },
  error: {
    color: messages.error,
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: primary.main,
    cursor: 'pointer',
    display: 'flex',
    [breakpoints.down('xs')]: {
      padding: 0,
      '& > img': {
        height: 24,
        width: 24,
        marginLeft: 12,
      },
    },
  },
  iconText: {
    fontWeight: 'bold',
    marginLeft: 4,
    textTransform: 'capitalize',
  },
  tag: {
    alignItems: 'center',
    border: `2px solid ${primary.darkGray}`,
    borderRadius: 8,
    color: primary.generalText,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    height: 48,
    justifyContent: 'center',
    letterSpacing: 0.25,
    padding: '4px 16px',
    textAlign: 'center',
  },
  tags: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(4, 1fr)',
    [breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  tagSelected: {
    background: secondary.main,
    border: `2px solid ${secondary.main}`,
    borderWidth: 0,
    color: primary.black,
  },
  tagTypes: {
    display: 'grid',
    gridGap: '2rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  tagTypeTitle: {
    color: primary.black,
    fontWeight: 900,
    letterSpacing: 0.25,
    lineHeight: 3,
    textTransform: 'uppercase',
  },
  sliderContainer: {
    margin: '1rem',
    minHeight: '7rem',
    '& .MuiSlider-track': {
      background: 'none',
    },
    [breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
});
const Filter = ({
  classes,
  onChange,
  onClearAll,
  tagTypes,
  selections,
  totalResults,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  // eslint-disable-next-line no-unused-vars
  const [currentFilterSelection, setCurrentFilterSelection] = useRecoilState(
    mixpanelAtoms.currentFilterSelection
  );

  const updateMixpanelSelections = useCallback(() => {
    const selectedIDs = Object.values(selections).flat();
    const allTags = Object.values(tagTypes)
      .map((tagType) => tagType.tags)
      .flat()
      .filter((tag) => selectedIDs.includes(tag.value));

    setCurrentFilterSelection([
      ...allTags.map((tag) => tag.label),
      `difficulty: ${selections.difficulty}`,
    ]);
  }, [selections, setCurrentFilterSelection, tagTypes]);

  useEffect(() => {
    updateMixpanelSelections();
  }, [selections, updateMixpanelSelections]);

  const noResults = totalResults === 0;
  const Tag = ({ tag, typeKey }) => {
    const isSelected = selections[typeKey].includes(tag.value);
    return (
      <div
        className={cx(classes.tag, isSelected ? classes.tagSelected : '')}
        onClick={() => {
          onChange({
            ...selections,
            [typeKey]: isSelected
              ? selections[typeKey].filter((tagId) => tagId !== tag.value)
              : selections[typeKey].concat(tag.value),
          });
        }}
      >
        {tag.label}
      </div>
    );
  };

  const TagType = ({ tags, typeKey, title }) => {
    const sortedTags = orderBy(tags, ['label'], ['asc']);
    return (
      <div className={classes.tagType}>
        <div className={classes.tagTypeTitle}>{title}</div>
        <div className={classes.tags}>
          {sortedTags.map((tag) => (
            <Tag key={tag.value} tag={tag} typeKey={typeKey} />
          ))}
        </div>
      </div>
    );
  };

  const getFilterCount = () => {
    let result = 0;
    if (selections.difficulty > 1) result++;
    Object.keys(selections).forEach((key) => {
      if (key !== 'difficulty') result += selections[key].length;
    });
    return result;
  };

  const filterCount = getFilterCount();

  return (
    <div
      className={classes.container}
      onKeyUp={(e) => {
        if (e.key === 'Escape') setIsVisible(false);
      }}
      tabIndex='0'
    >
      <div className={classes.icon} onClick={() => setIsVisible(!isVisible)}>
        <img src={filterCount > 0 ? filterFilledImg : filterImg} alt='filter' />
        {!isSmallScreen && (
          <div className={classes.iconText}>{`filter ${
            filterCount > 0 ? '(' + filterCount + ')' : ''
          }`}</div>
        )}
      </div>
      {isVisible && (
        <div
          className={classes.content}
          onKeyUp={(e) => {
            if (e.key === 'Escape') setIsVisible(false);
          }}
          tabIndex='0'
        >
          <div className={classes.action} onClick={onClearAll}>
            clear all filters
          </div>
          <hr className={classes.separator} />
          <div className={classes.tagTypes}>
            {tagTypes.map((tagType) => (
              <TagType
                tags={tagType.tags}
                key={tagType.key}
                title={tagType.title}
                typeKey={tagType.key}
              />
            ))}
            <div>
              <div className={classes.tagTypeTitle}>difficulty</div>
              <div className={classes.sliderContainer}>
                <Slider
                  max={4}
                  min={1}
                  marks={[
                    {
                      label: 'All Difficulty Levels',
                      value: 1,
                    },
                    {
                      label: 'Easy',
                      value: 2,
                    },
                    {
                      label: 'Medium',
                      value: 3,
                    },
                    {
                      label: 'Hard',
                      value: 4,
                    },
                  ]}
                  onChangeCommitted={(e, value) =>
                    onChange({ ...selections, difficulty: value })
                  }
                  step={1}
                  value={parseInt(selections.difficulty)}
                />
              </div>
            </div>
          </div>
          <div className={classes.buttons}>
            <Button
              onClick={() => setIsVisible(false)}
              className={classes.button}
              title='close filters'
              outlined
            />
            <Button
              className={classes.button}
              disabled={noResults}
              onClick={() => setIsVisible(false)}
              title={noResults ? 'no results' : `${totalResults} results`}
            />
            {noResults && !isSmallScreen && (
              <Box bgcolor='red' color='white'>
                No results for the filters chosen. Try choosing less filters and
                search again.
              </Box>
            )}
          </div>
          {noResults && isSmallScreen && (
            <Box
              position='sticky'
              bottom='1px'
              marginTop='15px'
              paddingTop='3px'
              paddingBottom='3px'
              bgcolor='red'
            >
              <Box paddingLeft='15px' paddingRight='15px' color='white'>
                No results for the filters chosen. Try choosing less filters and
                search again.
              </Box>
            </Box>
          )}
        </div>
      )}
    </div>
  );
};
const mapStateToProps = ({
  tags: { bodyArea, equipment, modality, movementPrep, muscles },
}) => ({
  tagTypes: [
    { title: 'area of the body', key: 'bodyArea', tags: bodyArea },
    { title: 'movement prep', key: 'movementPrep', tags: movementPrep },
    { title: 'modality', key: 'modality', tags: modality },
    { title: 'equipment', key: 'equipment', tags: equipment },
    { title: 'muscles', key: 'muscles', tags: muscles },
  ],
});
export default connect(mapStateToProps)(withStyles(styles)(Filter));
