import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme, withStyles } from '@material-ui/styles';

import { Dialog } from 'components';
import { Creators } from '../reducer';
import ExercisePlayer from './ExercisePlayer';
import { List as GenericList } from 'modules/dashboard';
import { useMediaQuery } from '@material-ui/core';
import { push } from 'connected-react-router';


const styles = ({ breakpoints }) => ({
  dialog: {
    maxWidth: 800,
    overflow: 'auto',
    width: 800,
    [breakpoints.down('xs')]: {
      borderRadius: 0,
      margin: 0,
    },
  },
  exercisePlayerFullScreen: {
    overflow: 'hidden',
    width: '100vw',
    maxWidth: '100vw',
    '& > img[data-id="close-button"]': {
      display: 'none !important',
    },
  },
});

const List = ({
  addFavorite,
  classes,
  deleteFavorite,
  favorites,
  items,
  isFavoriteList = false,
  processedItemId,
  processing,
  title = '',
  goTo,
  status
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedExerciseId, setSelectedExerciseId] = useState(null);
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  const [isExercisePlayerFullScreen, setIsExercisePlayerFullScreen] = useState(
    false
  );

  const exerciseToPlay = items.find((e) => e.id === selectedExerciseId);

  const redirectToPricing = () => {
    goTo('/dashboard/payment/choose-plan');
  };


  return (
    <>
                  {status === "freemium" ? () => redirectToPricing() : null}

      <GenericList
        addFavorite={addFavorite}
        deleteFavorite={deleteFavorite}
        isFavoriteList={isFavoriteList}
        isExerciseList
        onClick={(exercise) => {
          setSelectedExerciseId(exercise.id);
          setIsDialogOpen(true);
        }}
        items={isFavoriteList ? favorites : items}
        processing={processing}
        processedItemId={processedItemId}
        showTitle={isFavoriteList ? false : true}
        title={title}
      />
      
      <Dialog
        className={[
          classes.dialog,
          isExercisePlayerFullScreen ? classes.exercisePlayerFullScreen : '',
        ]}
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        fullScreen={isSmallScreen || isExercisePlayerFullScreen}
      >
        <ExercisePlayer
          exercise={exerciseToPlay}
          processing={processedItemId}
          onFullscreenStatusChange={setIsExercisePlayerFullScreen}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  auth: {
    user: { status },
  },
  exercise: { exercises, processing, processedItemId },
}) => {
  return {
    favorites: exercises.filter((e) => e.isFavorite),
    items: exercises,
    processing,
    processedItemId,
    status
  };
};

const mapDispatchToProps = {
  addFavorite: Creators.addFavorite,
  deleteFavorite: Creators.deleteFavorite,
  goTo: push,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(List));
