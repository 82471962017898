export const parseError = (errorObj) => {
  if (!errorObj?.errors) return 'An error occurred.';
  if (typeof errorObj.errors[0] === 'string') return errorObj.errors[0];

  const firstError = errorObj.errors[0];
  const firstKey = Object.keys(firstError)[0];

  return `${firstKey}: ${firstError[firstKey][0]}`;
};

export const toFormErrorObject = (errorObject) => {
  if (!errorObject) return { _general: 'Unknown error occured.' };

  const result = {};

  if (typeof errorObject.errors[0] !== 'string') {
    errorObject.errors.forEach((error) => {
      const key = Object.keys(error)[0];
      result[key] = error[key][0];
    });
  }

  if (Object.keys(result).length === 0) {
    result._general = errorObject.message;
  }

  return result;
};
