import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { parseError } from 'core';
import { Types } from './reducer';
import { HOME_URL } from './';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* momentDeleted() {
  yield call(toastr.success, 'Info', 'Movement moment  deleted successfully');
}

export function* momentSaved() {
  yield call(toastr.success, 'Info', 'Movement moment saved successfully');
  yield put(push(HOME_URL));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_MOVEMENT_MOMENT_SUCCESS, momentSaved),
    takeEvery(Types.UPDATE_MOVEMENT_MOMENT_SUCCESS, momentSaved),
    takeEvery(Types.DELETE_MOVEMENT_MOMENT_SUCCESS, momentDeleted),
    takeEvery(Types.CREATE_MOVEMENT_MOMENT_FAIL, requestFailed),
    takeEvery(Types.UPDATE_MOVEMENT_MOMENT_FAIL, requestFailed),
    takeEvery(Types.DELETE_MOVEMENT_MOMENT_FAIL, requestFailed),
  ]);
}
