import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string, bool } from 'yup';
import { useParams, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';


import {
  Button,
  FormCheckbox,
  Form,
  FormInput,
  FormRow,
  Link,
  Text,
} from 'components';
import MainLayout from './MainLayout';
import { Creators } from '../reducer';
import FacebookButton from './FacebookButton';
import Footer from './Footer';
import Header from './Header';

import iconChecked from 'assets/svg/checked.svg';
import exercise from 'assets/img/exercise-1.png';

const styles = (theme) => ({
  formContainer: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 440,
    },
  },
  button: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  checkedLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '1rem',
  },
  checkedLabelIcon: {
    marginTop: '0.4rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  content: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.main,
      display: 'block',
    },
  },
  formPanel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  imagePanel: {
    alignItems: 'center',
    background: `url(${exercise})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '50%',
    zIndex: 2,
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  label: {
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    lineHeight: 1.3,
    marginLeft: theme.spacing(2),
  },
  labelXl: {
    color: theme.palette.primary.contrastText,
    fontWeight: 900,
    fontSize: 30,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  mb6: {
    marginBottom: theme.spacing(6),
  },
});

const SignUpForm = ({ classes, error, processing, signUp ,goTo }) => {
  const theme = useTheme();
  const { planId, teamToken  } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const query = new URLSearchParams(useLocation().search);
  const params = new URLSearchParams(window.location.pathname);
  // const teamToken = query.get('teamToken')
  console.log(teamToken , "TEAM AACCCCC")
  console.log(planId , "PLAN ID")
  const token = teamToken === undefined  ? null : teamToken
  console.log(token , "TOKENA")

  const FormBody = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    

    return (
      <Form
        containerClass={classes.formContainer}
        error={error}
        title='Sign Up'
        renderHeader={() => (
          <>
            <FormRow>
              <Text>
                {`Already have an account? `}
                <Link to='/auth/signin' title='Sign In' /> here.
              </Text>
            </FormRow>
            <FormRow>
              <FacebookButton />
            </FormRow>
          </>
        )}
      >
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='First Name *'
                name='firstName'
                placeholder='First Name'
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Last Name *'
                name='lastName'
                placeholder='Last Name'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <FormInput
            error={error}
            label='Email address *'
            name='email'
            placeholder='Email address'
          />
        </FormRow>
        <FormRow>
          <FormInput
            error={error}
            label='Password *'
            name='password'
            placeholder='Password'
            type='password'
          />
        </FormRow>
        {/* <FormRow>
          <FormInput
            error={error}
            label='Invite code'
            name='inviteCode'
            placeholder='Invite Code'
          />
        </FormRow> */}
        <FormRow>
          <FormCheckbox
            label={
              <Text>
                {`By continuing you agree to `}
                <Link
                  target='_blank'
                  to='terms-and-conditions'
                  title={`Movement Vault's Terms and conditions`}
                />
                {` and `}
                <Link
                  target='_blank'
                  to='privacy-policy'
                  title={`Privacy Policy`}
                />
              </Text>
            }
            name='terms'
          />
        </FormRow>
        <FormRow>
          <div className={classes.button}>
            <Button
              processing={processing}
              processingLabel='Signing up...'
              title='Sign Up'
              type='submit'
            />
          </div>
        </FormRow>
        <FormRow>
          <Link to='forgot-password' title='Forgot Password?' />
        </FormRow>
      </Form>
    );
  };

  const CheckedLabel = ({ text }) => (
    <div className={classes.checkedLabel}>
      <img
        className={classes.checkedLabelIcon}
        src={iconChecked}
        alt='checked'
      />
      <div className={classes.label}>{text}</div>
    </div>
  );

  const validationSchema = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().email().required('Email is required'),
    password: string().min(6).required('Password is required'),
    terms: bool().oneOf([true], 'To proceed you must agree.'),
  });

  return (
    <div className={classes.container}>
      {!isSmallScreen ? <Header /> : null}
      <div className={classes.content}>
        <div className={classes.imagePanel}>
          <div>
            <div className={classes.mb6}>
              <div className={classes.labelXl}>Finally Fix Your Pain.</div>
              <div className={classes.labelXl}>Achieve Lasting Mobility Results.</div>
              <div className={classes.labelXl}>Feel and Perform Better Than Ever.</div>
            </div>
            <CheckedLabel text='Daily stretching and mobility classes' />
            <CheckedLabel text='1500+ stretching and mobility classes to choose from' />
            <CheckedLabel text='Variety of multi-week Pain and Injury Rehab programs' />
            <CheckedLabel text='Created by the top Doctors of Physical Therapy' />
          </div>
        </div>
        <div className={classes.formPanel}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              inviteCode: query.get('invite'),
              terms: false,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => signUp({ ...values, planId ,teamToken })}
          >
            {isSmallScreen ? (
              <MainLayout>
                <FormBody />
              </MainLayout>
            ) : (
              <FormBody />
            )}
          </Formik>
        </div>
      </div>
      {!isSmallScreen ? <Footer /> : null}
    </div>
  );
};

const mapStateToProps = ({ auth: { error, processing } }) => ({
  error,
  processing,
});

const mapDispatchToProps = { signUp: Creators.signUp , goTo: push,};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUpForm));


// query.get('teamToken')
