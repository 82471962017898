import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';

import { toTime } from 'core';
import { Chip, Favorite } from 'modules/dashboard';
import clockImg from 'assets/svg/clock.svg';
import playImg from 'assets/svg/play.svg';

import { Button } from 'components';






const styles = ({ breakpoints, palette: { background, primary } }) => ({
  actionButton: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    width: 45,
    [breakpoints.down('lg')]: {
      height: 'unset',
      width: 'unset',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'actions',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    [breakpoints.down('lg')]: {
      gap: '1rem',
      justifyContent: 'flex-start',
      marginTop: '0.5rem',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chips: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '0.3rem',
    gridArea: 'tags',
    [breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
//   container: {
//     cursor: 'pointer',
//     alignItems: 'center',
//     // borderBottom: 'solid thin #dfe2e6',
//     display: 'flex',
//     justifyContent:"space-between",
//     // gridTemplateAreas: '"thumbnail title tags duration actions"',
//     // gridTemplateColumns: '160px auto auto 120px 65px',
//     gap: '0.5rem 2rem',
//     // margin: '0 -1rem',
//     padding: '1rem',
//     borderRadius: 4,
//     backgroundColor : "#0000009C",
//     marginTop : "18px",
//     '&:hover': {
//       background: 'rgba(0, 0, 0, 0.7)',
//     },
//     '& > div': {
//       minHeight: 45,
//     },
// //     [breakpoints.down('lg')]: {
// //       margin: 'unset',
// //       gridTemplateColumns: '160px auto 120px',
// //       gridTemplateAreas: `"thumbnail title title"
// // "thumbnail tags duration"
// // "thumbnail actions actions"`,
// //     },
// //     [breakpoints.down('md')]: {
// //       gap: '0.5rem 1rem',
// //       gridTemplateColumns: '90px auto',
// //       gridTemplateAreas: `"thumbnail title"
// // "thumbnail tags"
// // "thumbnail duration"
// // "thumbnail actions"`,
// //       '& > div': {
// //         minHeight: 'unset',
// //       },
// //     },
// [breakpoints.down('xs')]: {
//   display: 'none',
// },
//   },

container: {
  cursor: 'pointer',
  alignItems: 'center',
  borderBottom: 'solid thin #dfe2e6',
  display: 'flex',
  gridTemplateAreas: '"thumbnail title tags duration actions"',
  gridTemplateColumns: '160px auto auto 120px 65px',
  // gap: '485px',
  justifyContent : "space-between",
  margin: '0 -1rem',
  padding: '1rem',
  borderRadius: 4,
  '&:hover': {
    background: 'rgba(0, 0, 10, 0.05)',
  },
  '& > div': {
    minHeight: 45,
  },
  [breakpoints.down('lg')]: {
//     margin: 'unset',
//     gridTemplateColumns: '160px auto 120px',
//     gridTemplateAreas: `"thumbnail title title"
// "thumbnail tags duration"
// "thumbnail actions actions"`,
display :"none"
  },
  [breakpoints.down('md')]: {
//     gap: '0.5rem 1rem',
//     gridTemplateColumns: '90px auto',
//     gridTemplateAreas: `"thumbnail title"
// "thumbnail tags"
// "thumbnail duration"
// "thumbnail actions"`,
//     '& > div': {
//       minHeight: 'unset',
//     },
display :"none"

  },
},
  container1: {
    cursor: 'pointer',
    alignItems: 'center',
    borderBottom: 'solid thin #dfe2e6',
    display: 'grid',
    gridTemplateAreas: '"thumbnail title tags duration actions"',
    gridTemplateColumns: '160px auto auto 120px 65px',
    gap: '0.5rem 2rem',
    margin: '0 -1rem',
    padding: '1rem',
    borderRadius: 4,
    '&:hover': {
      background: 'rgba(0, 0, 10, 0.05)',
    },
    '& > div': {
      minHeight: 45,
    },
    [breakpoints.down('lg')]: {
      margin: 'unset',
      gridTemplateColumns: '160px auto 120px',
      gridTemplateAreas: `"thumbnail title title"
"thumbnail tags duration"
"thumbnail actions actions"`,
    },
    [breakpoints.down('md')]: {
      gap: '0.5rem 1rem',
      gridTemplateColumns: '90px auto',
      gridTemplateAreas: `"thumbnail title"
"thumbnail tags"
"thumbnail duration"
"thumbnail actions"`,
      '& > div': {
        minHeight: 'unset',
      },
    },
  },
  dialogText: {
    fontSize: 22,
    fontWeight: 900,
    letterSpacing: 0.35,
    lineHeight: 1.42,
    // textTransform: 'uppercase',
  },
  duration: {
    gridArea: 'duration',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: 0.2,
    gap: '0.5rem',
    [breakpoints.down('lg')]: {
      justifyContent: 'flex-end',
    },
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  thumbnail: {
    gridArea: 'thumbnail',
    height: '100%',
    '& > img': {
      borderRadius: 4,
      cursor: 'pointer',
      objectFit: 'cover',
      // height: '160px',
      height: '160px',
      width: '168px',
      // width: '160px',
    },
    [breakpoints.down('md')]: {
      width: '100%',
      '& > img': {
        height: '100px',
        width: '100px',
      },
    },
  },
  itemTitle: {
    // minWidth: 300,
    gridArea: 'title',
    paddingLeft : "23px",
    [breakpoints.down('sm')]: {
      // minWidth: 'unset',
      paddingLeft : "0px",

    },
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    width: 550,
    justifyContent: 'space-between',
    padding: 64,
  },
  descriptionBlock: {
    height: '100%',
    overflow: 'hidden',
  },
  text: {
    whiteSpace: 'pre-line',
    color: primary.generalText,
    fontSize: 16,
    lineHeight: 1.5,
    marginTop: '1rem',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
  title: {
    color: "#44444B",
    fontSize: 20,
    fontWeight: 500,
    textTransform : "capitalize",
    
    [breakpoints.down('xs')]: {
      fontSize: 17,
      lineHeight: 1.44,
    },
  },
  titleAndPicContainer:{
    display : "flex",
    flexDirection : "row",
    alignItems : "center"
  },
  buttonContainer:{
    marginRight : "159px",
    display: "flex",
    alignItems: "center",
    [breakpoints.down('xs')]: {
      marginRight : "0px",
      marginTop : "17px",
      // marginRight : "159px",

    },
  },
  button:{
    width : "150px",
    height : "40px",
    fontSize : "11px",
    paddingLeft : "19px",
    paddingRight : "19px",
    [breakpoints.down('xs')]: {
    //  display : "none"
    },
  },
  mobileContainer:{
    display : "none",
    [breakpoints.down('xs')]: {
      cursor: 'pointer',
      alignItems: 'end',
      display: 'flex',
      justifyContent : "center",
      borderRadius: 4,
      height : "205px",
      width : "100%",
      marginTop : "18px",
     
     },
   
  },
  mobileThumbnail:{
    width : "100%",
    height: "245px",
    borderRadius : "12px"
  },
  mobileTitle:{
    color: "#FFFFFF",
    fontSize: 32,
    fontWeight: 900,
    // position:"absolute",
    textTransform : "uppercase",
    paddingBottom : "18px",
    display:"flex",
    textAlign:"center",
  },
  buttonAndTitleContainer:{
    paddingLeft : "15px"
  },
  opa:{
    [breakpoints.down('xs')]: {
      paddingLeft : "15px",
      paddingRight : "15px",

      },
  }
});

const noop = () => {};

const WorkOutListItem = ({
  chips = [],
  classes,
  isExerciseList = false,
  isFavorite,
  isFavoriteList = false,
  item,
  onAddFavorite = noop,
  onClick = noop,
  onPlay = () => {},
  onDeleteFavorite = noop,
  processing,
  noXMargin = false,
}) => {
  const itemChips = item.chips || chips;
  const itemDuration =
    item.duration || item.video?.additionalData?.duration || 0;
  return (
    <div  className={classes.opa} >
    <div
      className={classes.container}
      onClick={() => onClick(item)}
      data-active-since={item.activeSince}
    >
      <div className={classes.titleAndPicContainer} >
      <div className={classes.thumbnail} >
        <img
          alt={item.title}
          src={`${item.workout?.thumbnail?.publicLocation}?width=280`}
          loading='lazy'
        />
      </div>
      <div className={classes.itemTitle}>
        <div className={classes.title}>{item.title} </div>
      </div>
      </div>
      <div className={classes.buttonContainer} >
      <Button className={classes.button} title='START PROGRAM'  />

        </div>
    </div>


    {/* AQ IKOFA */}

{/* 
    <div
      className={classes.mobileContainer}
      onClick={() => onClick(item)}
      data-active-since={item.activeSince}
    >
      <div className={classes.titleAndPicContainer} >
      <div className={classes.thumbnail} >
        <img
          alt={item.title}
          src={`${item.workout.thumbnail?.publicLocation}?width=280`}
          loading='lazy'
        />
      </div>
     
      </div>
      <div className={classes.buttonAndTitleContainer} >

      <div className={classes.itemTitle}>
        <div className={classes.title}>{item.title} </div>
      </div>
          <div className={classes.buttonContainer} >

<Button className={classes.button} title='START PROGRAM'  />

  </div>
      </div>
    
        </div> */}


 <div className={classes.mobileContainer} style={{
      backgroundImage : `url(${item.workout?.thumbnail?.publicLocation})` ,
      backgroundSize: 'cover',
      backgroundPosition : "center",
    }} >
 
           <div className={classes.mobileTitle}>{item.title}</div>

 </div>



    </div>
  );
};
export default withStyles(styles)(WorkOutListItem);
 
// {/* <div className={classes.mobileContainer} >
// {/* <div className={classes.mobileThumbnail} > */}
// <img
//     alt={item.programTitle}
//     src={`${item.workout?.thumbnail?.publicLocation}?width=280`}
//     loading='lazy'
//     className={classes.mobileThumbnail}
//   />
//           <div className={classes.mobileTitle}>{item.title}</div>

//   {/* </div> */}
//   {/* <div className={classes.mobileThumbnail} style={{backgroundImage :item.thumbnail?.publicLocation }}  ></div> */}
// </div>
//  */}



// <div className={classes.mobileContainer} style={{
//   backgroundImage : `url(${item.workout?.thumbnail?.publicLocation})` ,
//   objectFit: 'cover',
// }} >
// {/* <div className={classes.mobileThumbnail} > */}
// {/* <img
//  alt={item.programTitle}
//  src={`${item.workout?.thumbnail?.publicLocation}?width=280`}
//  loading='lazy'
//  className={classes.mobileThumbnail}
// /> */}
//        <div className={classes.mobileTitle}>{item.title}</div>

// {/* </div> */}
// {/* <div className={classes.mobileThumbnail} style={{backgroundImage :item.thumbnail?.publicLocation }}  ></div>  */}
// </div>
