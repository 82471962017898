import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const NotFoundComponent = ({ user, url }) => {
  if (user.status === 'freemium') {
    return <Redirect to={`/dashboard/payment/choose-plan`} />;
  }
  return <Redirect exact={true} to={`/dashboard/home`} />;
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export const NotFoundDashboard = connect(mapStateToProps)(NotFoundComponent);
