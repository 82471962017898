import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Button, Link, Loader } from 'components';
import { Details } from '../../movementMoments';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Creators as ModalCreators } from '../../../../../modal';
import { Creators } from '../reducer';

const useStyles = makeStyles(
  ({ palette: { background, primary, secondary } }) => ({
    container: {
      minHeight: '100vh',
      width: '100%',
      paddingTop: 40,
    },
    header: {
      borderBottom: `1px solid ${primary.gray}`,
      height: 80,
      padding: '0px 16px',
    },
    button: {
      marginTop: 32,
      textTransform: 'capitalize',
    },
  })
);

const Reminders = ({ showModal, data, getReminder, deleteReminder }) => {
  const [reminder, setReminder] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const classes = useStyles();
  const baseUri = 'reminder';

  useEffect(() => {
    getReminder();
    setIsLoaded(true);
  }, [getReminder]);

  useEffect(() => {
    setReminder(data?.reminder?.[0] || null);
  }, [data]);

  if (!isLoaded || data?.processing) {
    return <Loader height='100vh' />;
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      className={classes.container}
    >
      {reminder ? (
        <Details
          id={reminder.id}
          baseUri={baseUri}
          title={reminder.title}
          text={reminder.message}
          showModal={showModal}
          deleteFunction={deleteReminder}
          type='reminder'
        />
      ) : (
        <Box pt={20}>
          <Box>No Reminder has been created at this time.</Box>
          <Link style={{ textDecoration: 'none' }} to={`${baseUri}/create`}>
            <Box className={classes.button}>
              <Button title='Create New Reminder' type='button' />
            </Box>
          </Link>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.reminder,
  };
};

export default connect(mapStateToProps, {
  getReminder: Creators.getReminder,
  deleteReminder: Creators.deleteReminder,
  showModal: ModalCreators.showModal,
})(Reminders);
