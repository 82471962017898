import React from 'react';

import List from './List';
import { ANNOUNCEMENTS } from './data';

export default () => {
  return (
    <List baseUrl='announcements' items={ANNOUNCEMENTS} title='Announcements' />
  );
};
