import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  ({ palette: { background, primary, secondary } }) => ({
    container: {
      padding: 16,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${primary.gray}`,
      },
      cursor: ({ isCursorPointer }) => isCursorPointer && 'pointer',
      background: ({ isSelected }) => (isSelected ? secondary.inactive : ''),
    },
    label: {
      color: secondary.grayText,
      fontSize: 10,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    title: {
      marginTop: 3,
      fontSize: 18,
      fontWeight: 'bold',
    },
    text: {
      marginTop: 3,
      fontSize: 16,
    },
  })
);

const ListItem = ({
  isSelected,
  titleLabel = 'title',
  title,
  textLabel = 'message',
  text,
  onClick,
  postingDate,
  isCursorPointer = true,
}) => {
  const classes = useStyles({ isSelected, isCursorPointer });
  return (
    <Box className={classes.container} onClick={onClick}>
      <Box display='flex' flexDirection='column'>
        <Box className={classes.label}>{titleLabel}</Box>
        <Box className={classes.title}>{title}</Box>
        <Box pt={2} className={classes.label}>
          {textLabel}
        </Box>
        <Box className={classes.text}>{text}</Box>
        {postingDate && (
          <>
            <Box pt={2} className={classes.label}>
              posting date
            </Box>
            <Box className={classes.text}>{postingDate}</Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ListItem;
