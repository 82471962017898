import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from '../../../../components';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import { Creators } from '../reducer';
import { Dialog, DialogTitle } from '@material-ui/core';

const styles = ({ palette, breakpoints }) => ({
  button: {
    backgroundColor: palette.primary.main,
    borderRadius: 2,
    color: palette.primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: 15,
  },
  container: {
    height: '100%',
    width: '100%',
  },
  fileUploaded: {
    '& > img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > video': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > label': {
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      bottom: 0,
      color: palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      height: 40,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      width: '100%',
    },
  },
  fileNotUploaded: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: 16,
  },
  text: {
    color: palette.primary.generalText,
    fontSize: 14,
    lineHeight: 1.5,
  },
  textS: {
    color: palette.secondary.grayText,
    fontSize: 14,
    lineHeight: 1.2,
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  inputContainer: {
    paddingTop: '16px',
    // paddingBottom: '16px',
  },
  inputFieldNames: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#A3A3B3',
  },
  inputField: {
    // height: 40,
    borderColor: '#DFE2E6',
    borderStyle: 'solid',
    // width: '100%',
    marginTop: '2px',
    outline: 'none',
    padding: '4px',
    height: 40,
    display: 'flex',
    alignItems: 'center',

    '&:focus': {
      borderColor: '#28AAE2',
    },
  },
  uploadedFilesText: {
    // width : "100%",
    // height: "100%",
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#DFE2E6',
    padding: '8px',
    height: '100%',
    marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#020301',
  },
  importTeamMembersPopUp: {
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    height: '100%',
    display: 'flex',
    // alignItems : "center",
    // justifyContent : "center",
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 40,
      paddingBottom: 40,
      height: '100%',
    },
  },
  popupText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    color: '#010200',
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  addMemberPopUpButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  popUpButtonCancel: {
    width: '208px',
    height: '56px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    fontSize: '16px',
    fontWeight: 700,
    '&:hover': {
      background: 'white',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  paper: {
    minWidth: '632px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  test: {
    padding: '0px !important',
    minHeight: '30px !important',
    display: 'flex',
    flexDirection: 'row',
  },
});

const ImoprtTeamMembersByCsv = ({ classes, createMultipleTeamMemberAccount, onOpen, onClose ,teamAccountId }) => {
  //const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  // const [tableRows, setTableRows] = useState([]);

  //State to store the values
  // const [values, setValues] = useState([]);

  // const [fileName, setFileName] = useState('');

  console.log(teamAccountId , "teamAccountId")
  const closeModal = () => {
    onClose(!onClose);
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        
        const newAccounts = results.data.map((d) => {
          return {
            email : d[0],
            firstName: d[1],
            lastName: d[2],
          }
        });

        // setFileName(event.target.files[0].name);

        //setParsedData(results.data);

        //   setTableRows(rowsArray[0]);

        // setValues(valuesArray);

        createMultipleTeamMemberAccount(
          newAccounts,
          teamAccountId
       );
       closeModal();
        // console.log(`Selected file - ${event.target.files[0].name}`);
      },

      
    });
  };


  function handleChange1(event) {
    console.log(`Selected file - ${event.target.files[0].name}`);
  }
  


  // console.log(values, "THIS IS VALUES");

  // console.log(values, 'VALUEs');
  return (
    // <div>
    //   File Uploader
    //   <input
    //     type="file"
    //     // name="file"
    //     onChange={changeHandler}
    //     // onChange={async () => {
    //     //     changeHandler()
    //     //     await createTeamMemberAccount({
    //     //       ...values,
    //     //       teamAccountId: '',
    //     //     });
    //     //   }}
    //           accept=".csv"
    //     style={{ display: "block", margin: "10px auto" }}
    //   />
    //   <br />
    //   <br />
    //   {/* Table */}
    //   <table>
    //     <thead>
    //       {/* <tr>
    //         {tableRows.map((rows, index) => {
    //           return <th key={index}>{rows}</th>;
    //         })}
    //       </tr> */}
    //     </thead>
    //     <tbody>
    //       {values.map((value, index) => {
    //         return (
    //             <>
    //           <tr key={index}>
    //             {value.map((val, i) => {
    //               return <td key={i}>{val}</td>;
    //             })}

    //           </tr>
    //           </>
    //         );
    //       })}
    //     </tbody>
    //   </table>
    // </div>

    <Dialog open={onOpen} classes={{ paper: classes.paper }}>
      <div className={classes.importTeamMembersPopUp}>
        <DialogTitle style={{ padding: '0px' }}>
          <span className={classes.popupText}> IMPORT TEAM MEMBERS </span>
        </DialogTitle>
        <div>
          <div className={classes.inputFieldContianer}>
            <div className={classes.inputContainer}>
              <div className={classes.inputFieldNames}>
                Choose .csv file to import
              </div>
              <div className={classes.inputField}>
                {/* <div className={classes.uploadedFiles}> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <span className={classes.uploadedFilesText}>{fileName.map((t)=>{
                    return <>{t}</>
                  })}</span> */}
                  <span className={classes.uploadedFilesText}></span>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.addMemberPopUpButtonContainer}>
            <Button
              className={classes.popUpButtonCancel}
              title='Cancel'
              type='button'
              onClick={onClose}
            >
              Cancel
            </Button>
            {/* <label className={classes.submitButton}>
        Import File
        <input multiple type='file' style={{ display: 'none' }} />
      </label> */}

            <div>
              <label className={classes.submitButton}>
                Import File
                <input
                  style={{ display: 'none' }}
                  type='file'
                  accept='.csv'
                  multiple
                  // onChange={async()=>{
                  //   changeHandler(),
                  //   closeModal()

                  // }}

                  onChange={changeHandler}

                />
              </label>
            </div>
          </div>
        </div>
        {/* {values.map((value, index) => {
          console.log(value, 'VAX CHEMIIIII');
          return (
            <>
              <div key={index}>
                {value.map((val, i) => {
                  return <div key={i}>{val}</div>;
                })}
              </div>
            </>
          );
        })} */}
      </div>
    </Dialog>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createMultipleTeamMemberAccount: Creators.createMultipleTeamMemberAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ImoprtTeamMembersByCsv));

