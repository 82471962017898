import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// import { WorkOutList as GenericList } from 'modules/dashboard';
import { List as GenericList } from 'modules/dashboard';
import { Creators } from '../reducer';

const List = ({
  addFavorite,
  deleteFavorite,
  isDailyClassList = false,
  items = [],
  goTo,
  isFavoriteList = false,
  processedItemId,
  processing,
  title,
  workoutKey,
  orderByField,
  orderByDirection,
}) => {
  console.log(workoutKey,"WORK")

console.log(items , "ITEMS")
  

  return (
    <GenericList
      addFavorite={addFavorite}
      deleteFavorite={deleteFavorite}
      items={items}
      isDailyClassList={isDailyClassList}
      isFavoriteList={isFavoriteList}
      onClick={(workout) => goTo(`${workoutKey}/${workout.id}`)}
      processedItemId={processedItemId}
      processing={processing}
      showTitle={!isFavoriteList}
      title={title}
      orderByField={orderByField}
      orderByDirection={orderByDirection}
    />
  );
};

const mapDispatchToProps = {
  goTo: push,
  addFavorite: Creators.addFavorite,
  deleteFavorite: Creators.deleteFavorite,
};

const mapStateToProps = (
  { workout },
  { workoutKey, workoutType, isFavoriteList }
) => {
  const { processing, processedItemId } = workout;
  return {
    items: workout[workoutType]?.filter(
      (workout) =>
        (isFavoriteList ? workout.isFavorite : true) &&
        workout.workoutTypes.some((wt) => wt.key === workoutKey)
    ),
    processedItemId,
    processing,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
