import React from 'react';

export default ({ fill }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='15'
    height='12'
    viewBox='0 0 15 12'
  >
    <g fill='none' fillRule='evenodd'>
      <g fill={fill}>
        <g>
          <g>
            <path
              d='M.167 6.063C.056 5.953 0 5.788 0 5.678c0-.11.056-.276.167-.386l.777-.772c.222-.22.555-.22.777 0l.055.055L4.83 7.827c.111.11.278.11.389 0L12.656.165h.055c.222-.22.555-.22.777 0l.777.772c.223.22.223.551 0 .772l-8.88 9.15c-.112.11-.223.165-.39.165-.166 0-.277-.055-.388-.165L.277 6.23l-.11-.166z'
              transform='translate(-902 -1024) translate(877 945) translate(20 72) translate(5 7)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
