export const getExerciseTags = (exercise) => {
  const tags = [];

  (exercise.bodyAreas || []).forEach((item) => tags.push(item.title));
  (exercise.equipments || []).forEach((item) => tags.push(item.title));
  (exercise.modalities || []).forEach((item) => tags.push(item.title));
  (exercise.movementPreps || []).forEach((item) => tags.push(item.title));
  (exercise.muscles || []).forEach((item) => tags.push(item.title));

  return tags;
};
