import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NotFound } from 'components';
import SignIn from './components/SignInForm';

const Routes = ({ match: { url } }) => (
  <Switch>
    <Route exact={true} path={`${url}/signin`} component={SignIn} />
    <Redirect exact={true} from={url} to={`${url}/signin`} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
