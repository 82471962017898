import React, { useCallback, useEffect,  useRef,  useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { Button, Loader } from 'components';
// import { Creators as ModalCreators } from 'modules/modal';
// import { Creators } from '../reducer';
import { Dialog } from '@material-ui/core';
import QuestionAndAnswer from './QuestionAndAnswer';
import { Creators } from '../reducer';

const styles = ({ theme, breakpoints }) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  singleParagraph1: {
    paddingTop: '25px',
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
  },
  singleParagraph: {
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
  },
  button: {
    width: '240px',
    height: '64px',
    marginTop: '30px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paper: {
    minWidth: '1128px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: '100%'
    },
    [breakpoints.down('md')]: {
      minWidth: '100%',
      minHeight: '100%'
    },
  },
  popUpButtonCancel: {
    marginTop: '40px',
    width: '208px',
    height: '56px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '72px',
    [breakpoints.down('xs')]: {
      alignItems: 'center',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  closeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  closeTitle: {
    fontSize: '24px',
    fontWeight: 900,
    color: '#020401',
    width: '424px',
    textTransform: 'uppercase',
    marginRight : "26px",
    
    [breakpoints.down('xs')]: {
      fontSize: '20px',
      lineHeight: '30px',
      width: '100%',
      marginRight : "0px",

    },
  },
  closeSubTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#020401',
    paddingTop: '20px',
    paddingRight :"50px",

    [breakpoints.down('xs')]: {
      lineHeight: '24px',
      paddingTop: '5px',
      paddingRight :"0px",

    },
  },
  closePaper: {
    paddingTop: '64px',
    paddingBottom: '64px',
    minWidth: '632px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  quitButton: {
    width: '208px',
    color: '#28AAE2',
    borderStyle: 'solid',
    borderColor: '#28AAE2',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  quitButtonSwitch: {
    width: '208px',
    color: '#28AAE2',
    borderStyle: 'solid',
    borderColor: '#28AAE2',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '80px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  closeButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '32px',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  resumeButton: {
    width: '208px',
    height: '56px',
    marginLeft: '24px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      marginTop: '10px',
    },
  },
  closePaper: {
    paddingTop: '64px',
    paddingBottom: '64px',
    minWidth: '632px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
      paddingTop: '40px',
      paddingBottom: '40px',
    },
  },
});

const Assessment = ({
  classes,
  questions,
  deleteTask,
  activeTask,
  getUserAssessment,
  setActiveTask,
  onClose,
  open,
  close,
  postResults,
  userAssessment,
  results
}) => {
  const theme = useTheme();



  useEffect(() => {
    getUserAssessment();
  }, [getUserAssessment]);

  const [switchBetween, setSwitchBetween] = useState(true);

  const [showCloseMessage, setShowCloseMessage] = useState(false);

  const handleClickOpen = () => {
    setShowCloseMessage(true);
  };

  const handleClose = () => {
    setShowCloseMessage(false);
  };
  const [answersArray, setAnswersArray] = useState([]);

  const currentQuestion = questions?.data && questions?.data[answersArray.length];



  const addAnswer = useCallback(
    (p) => {
      const arr = [...answersArray, p];
      // console.log("asdasdasdasdasdasdiiii",answersArray,arr,p, questions.length)
      console.log(answersArray , "ARRRRRR")
      console.log(arr , "ARRRRRR22222")
      setAnswersArray(arr);
      if(arr.length == questions?.data?.length){
        postResults([...arr])
        setAnswersArray([])
        onClose();
        // toastr.success('Succes', 'Assessment successfully completed');

      }
    },

    [answersArray]
  );

  console.log(answersArray.length, questions.data, 'HALO');

  // const lastAnswer = answersArray[answersArray.length - 1];
  // const lastArrayItem = questions[questions.length - 1];

  // console.log(lastAnswer?.task, 'LAST ONE');

  // if (lastArrayItem?.id == lastAnswer?.task) {
  //   postResults([...answersArray])
  
  // } 


  console.log(questions?.data?.length , "LOLO")
  console.log(answersArray , "ARRRRRRR111")


  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()    

  return (
    <>
    {/* {questions.length == 0 ? <Loader/> : */}
    <Dialog
        classes={
          showCloseMessage == true
            ? { paper: classes.closePaper }
            : { paper: classes.paper }
        }
        open={open}
      >
        {showCloseMessage == true ? (
          <div className={classes.closeContainer}>
            <div className={classes.closeTitle}>
              Are you sure you want to quit the assessment?
            </div>
            <div className={classes.closeSubTitle}>
              You must complete all tests in order to generate a mobility score.
            </div>
            <div className={classes.closeButtonsContainer}>
              <div
                className={classes.quitButton}
                //   onClick={onClose}
                //   onClick={Open}
                onClick={() => {
                  onClose();
                  handleClose();
                }}
              >
                Quit Assessment
              </div>
              <Button
                className={classes.resumeButton}
                title='Resume'
                type='button'
                onClick={() => {
                  handleClose();
                }}
              >
                Resume
              </Button>
            </div>
          </div>
        ) : (
          <>



 {/* {lastArrayItem?.id == lastAnswer?.task ? ( */}
   {/* {answersArray.length == questions.length ? (

    <div onClick={()=>{close()}} >close</div>
  ) : (
    <QuestionAndAnswer
      question={currentQuestion}
      onClose={handleClickOpen}
      addAnswer={addAnswer}
    />
  )} */}
      

{!questions?.data ? <Loader/> : <QuestionAndAnswer
                question={currentQuestion}
                onClose={handleClickOpen}
                addAnswer={addAnswer}
                videoIndex={answersArray.length + 1}
              />   } 
{/* 
<QuestionAndAnswer
                question={currentQuestion}
                onClose={handleClickOpen}
                addAnswer={addAnswer}
                videoIndex={answersArray.length + 1}
              />  */}




          </>
        )}
      </Dialog> 
      
    </>
  );
};

const mapDispatchToProps = {
  getUserAssessment: Creators.getUserAssessment,
  postResults: Creators.postResults,

};

const mapStateToProps = ({ userAssessment }) => ({
  // activeTask: assessment.activeTask,
  questions: userAssessment.tasks,
  assessment: userAssessment,
  results : userAssessment.results
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Assessment));
