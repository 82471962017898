import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Auth } from 'modules/admin/auth';
import { Dashboard } from 'modules/admin/dashboard';
import { NotFound } from 'components';

export default ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/auth`} component={Auth} />
    <Route path={`${url}/dashboard`} component={Dashboard} />
    <Redirect exact={true} from={url} to={`${url}/auth`} />
    <Route component={NotFound} />
  </Switch>
);
