import React from 'react';

export const ANNOUNCEMENTS = [
  {
    description:
      'Did you know that you can use both our web-app and iOS app with the same login info? You get access to both platforms with Free and Premium Movement Vault. Premium Movement Vault users get access to every feature that we offer, while Free users have limited access.',
    id: '1',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1609960706402gKUoajb_-wPiuXSB.jpg',
    text: (
      <>
        You can download the Movement Vault iOS app by clicking this link:{' '}
        <a
          href='https://apps.apple.com/us/app/movement-vault/id1474254318'
          target='_blank'
          rel='noopener noreferrer'
        >
         Movement Vault
        </a>
        . And if you are an Android user, no worries, we are currently working
        on our Android app. Stay tuned!
      </>
    ),
    title: 'Download The Movement Vault iOS App',
  },
];

export const GETTING_STARTED = [
  // {
  //   description:
  //     'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.',
  //   id: '1',
  //   image:
  //     'https://d1glqvkiwyy8rv.cloudfront.net/1609960706402gKUoajb_-wPiuXSB.jpg',
  //   text: `<p>Nunc quis lorem velit. Nunc pellentesque tempor augue. Vestibulum in elit nec mi volutpat finibus molestie eget ex. Curabitur at rutrum erat. Quisque gravida nisi vel est dapibus tempor. Proin nec dapibus ante, id egestas arcu. Duis dui lectus, finibus sed augue non, interdum aliquam est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In gravida rutrum nisi, nec egestas magna vulputate vel. Aenean sed felis feugiat, finibus lorem ac, egestas purus. Nunc dignissim arcu nisi. Morbi vel vehicula metus.</p>
  //          <p>Nam vel nisi magna. In eu odio gravida, fringilla metus et, porttitor leo. Integer semper ultricies elit, eu fermentum nulla iaculis nec. Aenean ac gravida felis. Aliquam egestas nulla non porttitor tincidunt. Aenean nulla justo, malesuada eleifend neque non, ullamcorper suscipit quam. Aliquam volutpat lorem justo, id fringilla ante fringilla eu. Maecenas vehicula elementum velit at eleifend.</p>`,
  //   title: 'Ut enim ad minima veniam',
  //   video:
  //     'https://d1glqvkiwyy8rv.cloudfront.net/16091087646888QM6Stc-d-RXkjer.mp4',
  // },
  {
    id: '1',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_1.jpg',
    description:
      'You just took a huge step forward toward being pain-free, injury free, and improving your flexibility and mobility. We have dedicated over a decade creating the Movement Vault program using the most effective techniques that get results in the shortest amount of time. Our program is backed by science and research as well as the amazing success stories we continue to receive everyday from over 50 countries. We want to hear your story soon!',
    text: (
      <>
        <p>
          By now, you may have read or figured out that Movement Vault was
          created by myself, Dr. Grayson Wickham. I'm a Doctor of Physical
          Therapy, Certified Strength and Conditioning Specialist, and overall
          health and fitness obsessed nerd.
        </p>
        <p>
          Movement Vault takes the guesswork out of your joint health,
          stretching, and mobility routine. Every movement that is included in
          our program has been thoroughly vetted to be as effective as possible.
          We have taken the “fluff” out so that you don't have to waste your
          time performing the same old stretches and exercises, leading to
          little progress.
        </p>

        <p>
          Movement Vault requires minimal equipment outside of a foam roller,
          mobility ball, and padded mat. If you have extra equipment, you can
          also incorporate long and short bands, stretch straps, kettlebells,
          and dumbbells.{' '}
        </p>
        <p>
          I guarantee you that if you stay consistent with Movement Vault, you
          will alleviate your pain, decrease your injury risk, move better, and
          perform better.{' '}
        </p>
        <p>
          If you have a body, we can help you optimize it. Movement Vault is for
          all ages and fitness levels. We have easy, medium, and hard routines.
        </p>
        <p>
          Aim to dedicate 15-minutes per day, at least 3-4 per week. Even
          better, if you can create a routine of dedicating 15-minutes a day,
          you will be performing everything in your life and in the gym at a
          higher level and pain-free. Just imagine running with no knee or hip
          pain, being able to perform a deep squat with correct technique and
          form, waking up in the morning without back or foot pain, playing with
          your kids without nagging back and neck pain, and the list goes on.
        </p>
        <p>
          I am giving you the bricks, it's time for you to build the castle. You
          can do it!
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
    title: 'Welcome To Movement Vault',
  },
  {
    id: '2',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_2.jpg',
    title: 'Daily Classes - A New Routine Everyday',
    description:
      "Today I'll give you an overview of how to use and navigate Movement Vault's website and app most effectively.",
    text: (
      <>
        <p>
          First off, yes we have an iOS app! You can download the Movement Vault
          app in the Apple App Store using this link or by searching for:
          Movement Vault. If you don't have an iPhone or iPad, no worries, we
          are currently working on our Android app, which will be released in
          the near future.
        </p>
        <p>
          Movement Vault's website can do almost everything the iOS Apple App
          can do, and you can use it on any device including your Android phone.
          I'll discuss the differences between the two a little later.
        </p>
        <p>
          So where do you start? First login on to the website or open app and
          you will be brought to your Home Screen. Here you will find a direct
          link to that day's Daily Class. We have a new Daily Class everyday
          that is typically between 5-20 minutes long. Each daily class focuses
          on different areas of the body. For example - Monday may be a Back and
          Hips focused Daily Class, and Tuesday may be a Shoulders and Upper
          Back focused Daily Class.
        </p>
        <p>
          Following the Daily Classes everyday will ensure you will dedicate
          time to every joint in your body. I recommend everyone follow along
          and perform the Daily Class Programming. Keep in mind that as a
          Premium Movement Vault user, you have access to all of our Daily
          classes. Free users only have limited access to Daily Classes.
        </p>
        <p>
          But what if you don't want to perform the Daily Class on a given day
          and want to focus on another area of your body? No worries, we've got
          you covered!{' '}
        </p>
        <p>
          Using our Apple App, press the workouts icon on the bottom of the
          screen and choose “Classes” from the top menu.
        </p>
        <p>
          Using our website, click “Daily Classes” from the side bar menu if
          using your desktop computer or the collapsible menu if using your
          mobile device.
        </p>
        <p>
          You will now see a list of over 500+ and counting Daily Classes to
          choose from. You can then use the filter icon or search bar to search
          for specific areas of the body, modalities, equipment needed, movement
          prep, and difficulty.
        </p>
        <p>From there, choose your routine and you are ready to go!</p>
        <p>
          Again, the key to alleviating your pain, decreasing your future injury
          risk, improving your flexibility and mobility, and taking your
          performance to the next level is consistency. Following the Daily
          Class routine is a great option to start out. As you get more familiar
          with Movement Vault, you can perform additional routines, create your
          own routines, take our assessment, and a whole lot more, which we will
          discuss in future emails.
        </p>
        <p>For now, have fun!</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '3',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_3.jpg',
    title: 'Our Virtual Mobility Assessment is a Game Changer',
    description:
      "Today I want to discuss the Apple App and Movement Vault's Virtual Mobility Assessment. Don't worry Android users, we are currently working on our Android app and it will be released in the near future.",
    text: (
      <>
        <p>
          Movement Vault's website can do almost everything the app can do. The
          Apple app does include two features that the website does not include
          (yet): 1. The Movement Vault Virtual Mobility Assessment 2. The
          ability to create Custom Routines.{' '}
        </p>
        <p>
          The Movement Vault Virtual Mobility Assessment is a 14-step assessment
          that will help you determine which areas of your body are tight and
          which are moving well. You simply start the Assessment, and watch the
          test video where I will show a specific movement to perform. You then
          perform the movement and then choose your results from four picture
          options that best match what you looked like when performing the
          movement. It is best to perform the assessment near a mirror so that
          you can see yourself, or even better by video recording yourself. You
          can perform the assessment without out these though.
        </p>
        <p>
          You will then complete all 14-test movements and enter your results
          for each side and joint in your body.
        </p>
        <p>
          When you complete the entire assessment, the app will generate a total
          mobility score. This total mobility score is a percentage out of a
          possible 100%. You will also have a score generated for each major
          joint/area of your body. These individual areas of the body scores
          will help guide and tell you what joints you should dedicate
          additional focus to.{' '}
        </p>
        <p>
          The virtual mobility assessment is like coming to see me in my clinic
          and me performing an in-person assessment on you. The app does this in
          a way that allows you to assess yourself at any time with my guidance.
        </p>
        <p>
          You can also perform the assessment monthly or multiple times per year
          to help track your progress. Keep in mind that as a Premium Movement
          Vault user, you can take as many Assessments as you would like. Free
          users are limited to performing one Assessment.
        </p>
        <p>
          The Movement Vault Assessment is a great way to determine how well
          your joints currently move, which joints are tight, and how good your
          mobility is. The Assessment score is not the only thing you should pay
          attention to however. You also want to take a mental note of how your
          movement feels. Do you have pain when moving your right hip in a
          specific direction? Do you feel more connected to your ankle and feet
          muscles? Are you able to sit at your desk without pain? Keep track of
          these things as you stay consistent with Movement Vault.
        </p>
        <p>
          I guarantee that you will make progress in all of these areas as long
          as you stay consistent.{' '}
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '4',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_4.jpg',
    title: 'So Many Mobility Routine Types To Choose From',
    description:
      'Today I want to tell you about our other workout types, and our “My Stats” feature.',
    text: (
      <>
        <p>
          Movement Vault offers the following workout types: Daily Classes,
          Pre-Workout, Recovery, Work Routines, and Pain and Injury. When I say
          workout, these are “workouts” that are focused on optimizing your
          joint range of motion, muscle activation, and joint health. They will
          help you improve your tight muscles and joints, so that every joint in
          your body will move like it was designed to move.
        </p>
        <p>
          These workouts are not meant to help you achieve six-pack abs, or
          increase your absolute strength, muscle mass, or lose weight like a
          “typical” workout would. Stay tuned though because we will be adding
          multiple programs that include functional movements to help you
          achieve everything I just mentioned and more.{' '}
        </p>
        <p>
          Movement Vault's workouts are aimed to keep your joints, muscles,
          tendons, ligaments and fascia as healthy as possible, so that you can
          then perform your workouts without aches and pains, and restricted
          mobility.
        </p>
        <p>
          All of our workouts are a follow along format. All you have to do is
          simply open up the app or website, press play, and follow along. It is
          that simple!
        </p>
        <p>
          Most workouts are in the 5- 20 minute range, but we do have workouts
          as long as 90 minutes. Most workouts focus on 1-3 areas of the body.
          Longer duration classes often focus on every joint in your body.
        </p>
        <p>
          As a Premium Movement Vault user, you get access to all of our
          classes. Free users only have limited access.
        </p>
        <p>Here is a breakdown of Movement Vault's workouts:</p>
        <p>
          Daily Classes: A new mobility workout everyday. We have Flow days on
          Thursdays and Sundays. Flow days are full body and only a padded mat
          is needed. Daily Classes are designed to be performed at any time of
          the day, including before your workouts if you are working out that
          day.
        </p>
        <p>
          Pre-Workout: Designed to get you ready for your workout. Pre-workout
          routines are designed as your warm-up to get you ready for specific
          exercises such as: running, squats, lunges, deadlifts, push-ups,
          overhead press, and the list goes on.
        </p>
        <p>
          Recovery: Less intense and gentler routines. Recovery workouts will
          aid your body's recovery on a rest day, or after a workout. They are
          also perfect for when you want a relaxing routine.
        </p>
        <p>
          Work Routines: Short routines that are designed to be performed while
          you are working. They can be performed sitting at your desk or
          standing up at your desk. Work Routines are the perfect stretching
          routine to be performed on your work break when your neck and low back
          is aching from all of your work sitting.
        </p>
        <p>
          Pain and Injury: Workouts designed for specific injuries. Pain and
          Injury workouts have a sequence and progress throughout different
          workouts. You can think of these as rehab workouts.
        </p>
        <p>
          Custom Routines: Routines you create specific to your goals. I will
          discuss Custom Routines in more detail in future emails.
        </p>
        <p>
          As you can see, Movement Vault has your back, literally. Our thousands
          of diverse and effective workout video routines make us the number one
          stretching, flexibility and mobility, and joint health program in the
          world. I am sure you are seeing this and experiencing this for
          yourself.
        </p>
        <p>
          If you haven't performed your Movement Vault today, get after it and
          have fun! If you have completed your daily Movement Vault, I'm sending
          you a huge high five!
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '5',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_5.jpg',
    title: 'We Have The Largest Exercise Library In The World',
    description:
      'Today I want to tell you about our Exercise library aka our Exercise Vault.',
    text: (
      <>
        <p>
          Movement Vault has the largest exercise library in the world! We
          literally have thousands of the most effective muscle and fascia
          release techniques, active stretches, recovery stretches, and muscle
          activation exercises. We are constantly adding to the Exercise Vault
          as well.
        </p>
        <p>
          Every movement and technique that makes the cut of being added to the
          Movement Vault Exercise Vault has been thoroughly vetted and tested.
          We don't just add any old stretch or exercise to the vault just to add
          to our total exercise count. We only include those that have been
          proven to be the most effective.
        </p>
        <p>
          You will also notice that we take great pride in the details. Movement
          Vault's videos, audio instruction, and written instructions have been
          created to show you how to perform each exercise perfectly. You can
          watch, listen, and read to help you dial in and perfect each exercise.
        </p>
        <p>
          As a Premium Movement Vault user, you have access to our entire
          Exercise library including thousands of exercises. Free users have
          limited access to our Exercise library.
        </p>
        <p>
          We also show you a preview of every exercise that will be included in
          a workout before you start any workout type. You can play each
          exercise on the workout preview screen to get to know the exercises
          before starting your workouts.
        </p>
        <p>
          You can also press the filter icon or use the search bar to help you
          find specific exercises that you would like to watch. You can even add
          exercises to your favorites and download them to your phone to be
          viewed without using your data or wifi. As a premium user, you can use
          our apple app to download any video you want.
        </p>
        <p>
          Remember, your body loves you for giving your muscles, and joints
          exactly what they need to perform at their best. Have fun!
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '6',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_6.jpg',
    title: 'Track Your Mobility Stats and Progress',
    description:
      "You are on a roll! This isn't the type of short term makeover in which you put something on your skin to help “hide” things. This is the type of makeover that gets to the root cause of your limited joint  mobility, aches and pains, and fixes them, not just covers them up. Movement Vault is all about creating long lasting results and improvements.",
    text: (
      <>
        <p>Today I want to talk about a cool feature called “My Stats.”</p>
        <p>
          My Stats allows you to track your Movement Vault Stats. Your My Stats
          screen includes the following stats:
        </p>
        <p>
          Current Streak: Shows you how many consecutive days in a row you have
          performed a Movement Vault workout. All workouts count for this stat.
          Watching a single exercise video and/or the Assessment does not count.
        </p>
        <p>
          Longest Streak: Shows your longest all time Movement Vault streak.
        </p>
        <p>
          Sessions Completed: Shows you the total number of Movement Vault
          workouts you have completed. The key word here is ‘completed.' If you
          watch half of a workout, it will not count toward your sessions
          completed.
        </p>
        <p>
          Total Mobility Time: Shows you the total amount of time that you have
          spent performing Movement Vault workouts.
        </p>
        <p>
          Average Mobility Time: Shows your average duration per Movement Vault
          workout.
        </p>
        <p>
          One thing to note: My Stats are not tracked when using the app in
          offline mode. This means your stats will not be tracked when
          performing a downloaded workout when you are not using your data
          coverage or wifi.
        </p>
        <p>
          Keep an eye on your stats and watch them grow as you progress. My
          Stats are a great way to help keep you consistent. And as I always
          say, “Consistency is not the key to success. Consistently performing
          EFFECTIVE techniques is the key to long lasting changes.”
        </p>
        <p>
          You can view your “My Stats” at any time by following these steps:{' '}
        </p>
        <p>
          Apple App - press the ‘Profile' icon on the bottom right. Next toggle
          to the right to “My Stats.”
        </p>
        <p>
          Website: Computer or tablet - press ‘Profile' from the side menu.
          Mobile - press ‘Profile' from the collapsible menu using the three
          lines icon. Next press “My Stats.”
        </p>
        <p>
          Dedicating time to proactively care for your body and joints is one of
          the healthiest things you can do for your body. What will your longest
          streak be?{' '}
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '7',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_7.jpg',
    title: 'Create Your Own Custom Mobility Routines',
    description:
      'Today I want to discuss an awesome feature that is currently in the iOS Apple app only. We will be including this feature in the website and Android app in the near future. The feature is called Custom Routines.',
    text: (
      <>
        <p>
          Custom Routines allows you to create a routine specifically tailored
          to you and your goals. You get to choose how long you want the routine
          to be, which areas of your body you want to focus on, what equipment
          you want to include, what types of techniques to include, what
          movements you may want to prepare for such as squats, and what
          difficulty level you would like the routine to be. You can then choose
          your own exercises from our Movement Library, which is the largest
          library of effective muscle and fascia release techniques, active
          stretches, and muscle activation exercises in the world!
        </p>
        <p>
          When choosing your exercises, you will want to note that most
          exercises have two sides of the body, a right side and left side. An
          example is the Lat End-Range Stretch Isometrics - Left Side, and Lat
          End-Range Stretch Isometrics - Right Side. Each exercise video focuses
          on either your right or left shoulder. In most cases, you will want to
          include both right and left sides when choosing your exercises.
        </p>
        <p>
          After choosing your exercises, you will be brought to the Workout
          Preview screen. Here you can name your workout by pressing on the
          title.{' '}
        </p>
        <p>
          You will also see all of the exercises you previously chose. From here
          you can remove an exercise by pressing the minus icon or you can
          re-order the exercises by dragging and dropping them.{' '}
        </p>
        <p>
          Due to the effective workout algorithm we have created, exercises that
          are either muscle release or joint mobilizations will always remain
          first in your workout and you will not be able to drag and drop these.
          This is due to the fact that muscle release and joint mobilizations
          should always be performed first in a workout in order to be maximally
          effective.
        </p>
        <p>
          You can also change specific parameters for your custom routine by
          pressing the gear icon located at the top right of this screen. Here
          you can change the exercise length, add rest breaks, shuffle
          exercises, and turn off the demo pause.
        </p>
        <p>
          Your Custom Routine will automatically save when you start your
          routine.{' '}
        </p>
        <p>
          As a Premium Movement Vault user you can create as many Custom
          Routines as you would like using our entire Exercise Library. Free
          users are limited to creating only one custom routine using a small
          selection from our Exercise Library.{' '}
        </p>
        <p>
          Premium Movement Vault users can also download Custom Routines to be
          viewed offline without data or Wi-Fi.
        </p>
        <p>Start dialing in your workouts using Custom Routines. Have fun!</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '8',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_8.jpg',
    title: 'Stay Consistent With Customized Notifications',
    description:
      "Today let's briefly talk about Movement Vault's Apple app notifications.",
    text: (
      <>
        <p>
          First off, why even turn on notifications? I get it, some app
          notifications are annoying. Movement Vault's notifications are
          important and I suggest that you turn them on. You have the ability to
          customize your notifications so that we won't annoy you.
        </p>
        <p>
          If you turned on notifications when you signed up on our app, you are
          set. If you signed up on the website or didn't turn on notifications
          when you signed up on the app, you can simply go to your iOS settings,
          scroll to notifications, find Movement Vault, and turn on
          notifications.
        </p>
        <p>Movement Vault has three notification types.</p>
        <p>
          Reminders: This notification reminds you to perform your Movement
          Vault at a specific time. You will receive this notification one time
          per day.
        </p>
        <p>
          Movement Moments: This notification sends you motivating messages
          throughout your day such as “How is your posture? Stand up and perform
          3 side bends to each side” or “It's time to stretch your back. Perform
          3 seated segmented cat camels right now.” The cool thing about
          Movement Movements is that you can choose how many you want to receive
          each day, from one to five notifications. These have been a game
          changer for a lot of people that find themselves forgetting about
          their body and posture throughout their work day.
        </p>
        <p>
          Recommendations: These are periodic messages that I will send out from
          time to time. You can expect that you won't be receiving many of
          these. A couple per month most likely.{' '}
        </p>
        <p>If you want to customize your notification settings simply:</p>

        <ol>
          <li>Press the Profile icon in the bottom right corner.</li>
          <li>Press the gear icon in the top right corner. </li>
          <li>Press Notifications </li>
          <li>
            From here you can choose any notification type and then change each
            of the settings.
          </li>
        </ol>

        <p>
          Turning on Movement Vault notifications can be a game changer. It can
          mean the difference between you optimizing your body and joints or
          not. It can be the difference between you doing your Movement Vault or
          not. You will not make progress without staying consistent.{' '}
        </p>
        <p>
          One of the biggest roadblocks for any healthy habit is remembering to
          do the “thing” and then actually doing it. When you turn on your
          Notifications and then choose the settings that work best for you, you
          are setting yourself up for success.
        </p>
        <p>Have an amazing day and stay consistent!</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '9',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_9.jpg',
    title: 'Favorite and Download Your Favorite Routines',
    description:
      "Today I want to discuss Movement Vault's Favorites and Downloads features.",
    text: (
      <>
        <p>
          Our Favorites feature allows you to favorite any exercise or workout.
          You can do this by pressing the ‘star' icon anywhere it appears. You
          will see the star icon change to a filled in star when you have
          successfully favorited an exercise or routine. You can also press a
          filled in star to then unfavorite an exercise or workout.
        </p>
        <p>You can find all of your favorited exercises and workouts by:</p>
        <p>
          Using the Apple app - press the Favorites icon from the bottom menu.
        </p>
        <p>
          Using the website - on desktop: press the Favorites icon from the side
          menu or on your mobile device: from the collapsible menu.
        </p>
        <p>
          From here you will see all of your favorites organized by the workout
          type. You can then press any exercise or workout to start watching.
        </p>
        <p>You can also remove exercises or workouts by:</p>
        <ul>
          <li>Using the Apple App - swiping left.</li>
          <li>Using the website - click the trash icon.</li>
        </ul>
        <p>
          Movement Vault's Download feature takes Favorites to another level. As
          a Premium Movement Vault user you can download any Exercise or Workout
          to your phone using our app to be viewed offline without data or
          Wi-Fi. Free users do not have the download feature. This feature is
          only available using the app.
        </p>
        <p>
          To download a video or exercise simply click the download icon anytime
          you see it. Once your download is complete the download icon will
          change to a checkmark icon.
        </p>
        <p>You can access your downloads by:</p>
        <ul>
          <li>Navigating to your Favorites screen.</li>
          <li>Pressing the Download icon in the top left corner.</li>
        </ul>
        <p>
          Here you will see all of your downloaded exercises and videos
          organized by workout type.
        </p>
        <p>
          Anytime you open the app, but don't have data coverage or Wi-Fi, you
          will see an “access my downloads” button. Press this and you will be
          taken to your Movement Vault downloads library. You must have
          downloaded videos previously or they will not show up here.
        </p>
        <p>
          Favorites and Downloads are both great features to help you find and
          organize your favorite Movement Vault exercises and workouts.
        </p>
        <p>
          At this point I know you are feeling the benefits of Movement Vault.
          Keep it up!
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '10',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_10.jpg',
    title: 'Why Flexibility and Mobility Is So Important',
    description:
      'You may be asking yourself, “Why do I even need to spend time on my flexibility and mobility? Why do I need to stretch? I remember all my stretches from high school gym class.”',
    text: (
      <>
        <p>
          First off, I can almost guarantee you that the stretches you were
          performing in high school were most likely a waste of time. The sad
          part is that most people that still do stretch regularly are
          performing these same old generic stretches.
        </p>
        <p>
          The importance of flexibility and mobility primarily revolves around
          ensuring that your joints move the way they were designed to move.
          This includes having the soft tissue extensibility needed to move your
          joint through your full range of motion. This is a technical way of
          saying you need to make sure your soft tissues aka your muscles,
          tendons, fascia, ligaments, and nerves are not tight. When these
          tissues are tight, they restrict your movement.
        </p>
        <p>
          You also need to have the capability to actively contract your muscles
          around a joint well enough that you can actively move your joint
          through your full range of motion.
        </p>
        <p>
          You run into problems when you have one or more joints that don't move
          like they should due to either having muscles that are tight and
          restricting or from having difficulty contracting and actively moving
          your joint through your full range of motion. A lot of times you will
          have problems with both.
        </p>
        <p>
          When you have a joint that doesn't move like it should, it causes
          another joint or multiple joints to have to compensate for it because
          it is essentially not doing its job. This causes wear and tear to
          these compensating joints. This wear and tear eventually then leads to
          pain and injury. This is why most injuries are termed repetitive
          injuries.{' '}
        </p>
        <p>
          So how do you prevent 90% of the injuries that occur? You prevent the
          above cascade from happening simply by consistently performing
          effective joint maintenance in the form of an effective stretching and
          mobility program.
        </p>
        <p>
          This about this. What if you: never change the oil in your car, never
          clean your house, never walk your dog, never tune up your bike? The
          answer is, your car's engine will eventually seize up and stop
          running, your house will become a complete disaster, your dog will be
          a mess and will make a mess, and your bike will eventually stop riding
          smoothly. So you would probably agree that providing basic maintenance
          on most things is needed.
        </p>
        <p>
          This is the exact same principle for your most prized possession in
          the world, your body. You need to perform routine joint maintenance in
          order for your body to move and perform properly and at its best.{' '}
        </p>
        <p>
          An effective stretching and joint mobility program = joint
          preventative maintenance.{' '}
        </p>
        <p>
          Don't be one of those people that thinks they can get away with
          skipping their stretching and mobility program. And the fact is, they
          will be able to get away with it, until they can't. Fast forward 40
          years and now they have bone on bone pain in their knee or hip and
          it's time to go under the knife and have a major joint replacement
          surgery performed.
        </p>
        <p>
          I said earlier that 90% of injuries could have been avoided by
          performing a proactive joint mobility program. The same goes for joint
          replacements. Most joint replacements, again like 95%, could have been
          avoided. I am friends with some of the top orthopedic surgeons in the
          world and they all agree.
        </p>
        <p>
          The solution is to be performing effective mobility exercises
          consistently. This will ensure your joints are healthy, you are pain
          free and are able to do everything you want to do in life and in the
          gym well into your senior years.
        </p>
        <p>
          Read this again and let it marinate. This is advice a lot of people
          had wished they heard decades ago. Have fun!
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_11.jpg',
    title: 'The Real Difference Between Flexibility and Mobility',
    description:
      "The million dollar question is: What is the difference between flexibility and mobility? Let's dive in.",
    text: (
      <>
        <p>
          Although you may have heard these two terms interchangeably used,
          flexibility and mobility are similar, but different. Flexibility does
          not equal mobility. But in order to have good mobility, you need to be
          flexible. Confused yet? I know, these nuances can get confusing, but
          stick with me, you'll be a pro in 3-minutes.
        </p>
        <p>
          First off, think of flexibility in a passive capacity and mobility in
          an active capacity.
        </p>
        <p>
          Flexibility is how much you are able to passively move one of your
          joints. In order to passively move a joint, the soft tissue
          surrounding that joint needs to be compliant enough to allow movement.
          If these soft tissues aren't compliant, you will have tight tissues
          that limit your passive joint movement. These soft tissues include
          your muscles, tendons, fascia, ligaments, and nerves.
        </p>
        <p>
          Here is an example of a flexibility test. You lie down on your back
          with your legs straight. I then lift your straight leg upward and
          toward your chest as far as possible, while you stay completely
          relaxed. When your leg can't move any further, I stop pushing and we
          measure your hip angle. This is a measure of your passive hip flexion
          and hamstring flexibility. The key in this test is that you are
          completely relaxed aka passive the entire time.
        </p>
        <p>
          Here is an example of a mobility test. You lie down on your back with
          your legs straight. I now ask you to actively lift your leg up and
          toward your chest as far as possible while keeping your knee straight.
          You stop when you can not actively lift your leg any further or your
          knee bends. This is a measure of your active hip flexion and hamstring
          flexibility.{' '}
        </p>
        <p>
          Two things can happen here. Your passive hip flexion and active hip
          flexion will be exactly the same. The other scenario is that your
          active hip flexion is less than your passive hip flexion and hamstring
          length. This tells me that your hamstring flexibility is good, but you
          are having trouble actively contracting your hip muscles in your hip's
          end-range of motion.{' '}
        </p>
        <p>
          The solution to both of these scenarios is to perform active
          stretches. Why, you ask?
        </p>
        <p>
          First, passive stretching has been shown to increase injury risk and
          decrease performance when performed before an athletic event or
          workout. Yes you read that correctly, it has been shown to increase
          injury risk.
        </p>
        <p>
          Active stretching before an athletic event or workout has the opposite
          effect. Active stretching will improve your performance and decrease
          your risk of injury.
        </p>
        <p>
          On top of that, passive stretching is not effective when it comes to
          the goal of improving your mobility. You can spend hours passive
          stretching with little improvement. You will eventually get really
          good at passive stretching and passive joint movement. But last I
          checked, the only thing we do in life that is passive is sitting or
          laying down. Anything that involves moving is an “active” activity.
        </p>
        <p>
          To sum it up, mobility is king. In order to effectively improve your
          joint's active mobility, you need to be active stretching. Active
          stretching will improve your performance, and recreate resilient
          joints that are far less prone to pain and injury. Passive stretching
          is essentially a waste of time, unless you are using it for a cool
          down, or light recovery day. Never passively stretch before a workout.
        </p>
        <p>Have an amazing day and make healthy decisions.</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_12.jpg',
    title: 'Free Premium Movement Vault? Yes!',
    description:
      "Did someone say FREE? I love the word free. Freestyle, Free motion, Freedom….the list goes on. But don't they say, nothing in this world is free? This is not true at Movement Vault.",
    text: (
      <>
        <p>
          At this point, I can guarantee that you are seeing and feeling the
          benefits of Movement Vault and why we are the world's most effective
          stretching, flexibility and mobility, and joint health program. I am
          not one to brag, but we have literally put a decade plus of work into
          creating Movement Vault.
        </p>
        <p>
          Ok, so how can you get FREE Premium Movement Vault? Simply by helping
          a friend out. We have a feature called Invite Friends. You have a
          unique Invite Friend code and link. When you share this link or code
          with someone and they use it during their sign-up, they receive a $5
          credit to be used toward their first Premium Movement Vault payment.
          When they use their credit and make their first payment, you also
          receive a $5 credit that can then be applied to your future payments.
          It's that simple. Give $5, get $5.
        </p>
        <p>
          Movement Vault can literally change your life and improve a friend's
          life.
        </p>
        <p>
          We have an internal Movement Vault file with thousands of messages and
          emails from our users telling us how Movement Vault has improved their
          life.{' '}
        </p>
        <p>
          We have heard from moms that have suffered with low back pain for
          years post pregnancy that have tried everything. After a few months of
          Movement Vault, they are pain-free and doing all of the things with
          their kids that they previously missed out on.
        </p>
        <p>
          We have heard from Police officers and Firemen that have dealt with
          low back, upper back, shoulder, and you name it pain from the demands
          of their job. Again, after consistently performing Movement Vault
          routines, they are pain free and essentially have a new lease on life.
        </p>
        <p>
          We have heard from athletes in every sport you can think of that had
          been dealing with every pain and injury you can think of. We have
          probably heard from runners with hip, knee, and foot pain the most.
          And again, the same story. They found Movement Vault after trying many
          other programs, and or PTs, chiropractors, etc and within a few months
          their joint health improved, pain decreased, and they were back doing
          what they love.
        </p>
        <p>
          I say this because you probably have at least one friend if not 10
          that can benefit from Movement Vault. I literally can't think of
          anyone that wouldn't benefit from adding the healthy habit of Movement
          Vault into their daily/weekly routine.
        </p>
        <p>You can find your invite code and link and invite friends by:</p>
        <p>Using the website - click “Invite Friends” from the side menu.</p>
        <p>
          Using the Apple App - From the Home screen, press the “Invite Friends”
          icon located in the top left corner.
        </p>
        <p>
          Help a friend out, and we help you out for helping them out. We are on
          a mission to significantly decrease the amount of pain and injuries in
          the world. It sounds bold, but we will do it.{' '}
        </p>
        <p>
          We really appreciate you helping us spread the word about Movement
          Vault and changing how the world feels, moves, and performs.
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_13.jpg',
    title: 'Never Get Injured Again',
    description:
      "Raise your hand if you like to get injured. No hands raised. It seems pretty obvious that on one likes to get injured, yet we all end up getting injured at some point. Let's break down why injuries are no fun and how to avoid them.",
    text: (
      <>
        <p>
          90% of injuries occur due to having tight muscles and joints, which
          then causes compensations in other joints, which then causes wear and
          tear in these compensating muscles and joints. This wear and tear will
          then eventually lead to pain and injury. It is a vicious cycle.
        </p>
        <h3>Pain and injuries:</h3>
        <ol>
          <li>
            Hurt! Everyone has been there. You have low back pain, knee pain,
            neck pain etc. It does not feel good and makes every waking minute
            worse. Essentially, you aren't living your best life when you have
            to deal with pain and injuries.
          </li>
          <li>
            Take you out of the game. The game I'm referring to is the game of
            life. Pain and injuries can prevent you from doing the things you
            enjoy most in life like: playing with your kids, doing activities
            with your friends, performing your job well, going to the gym,
            walking or running, gardening, playing your sport, and the list goes
            on. When you are in pain or injured you are not truly in the game of
            life anymore, you are now watching from the sidelines. You can't win
            from the sidelines.
          </li>
          <li>
            Costs Money! Injuries can hit your bank account in a BIG way. You
            may be able to deal with your pain and injury for a certain amount
            of time, but after it doesn't get better and even gets worse, you
            should probably go see your doctor to get it checked out. This
            doctor's visit costs you money. You may even have to take off of
            work to go to the appointment. Your doctor may then prescribe
            Physical Therapy. Depending on your injury, you will then have to go
            to your PT appointments 1-3 times per week for many weeks. These
            appointments also cost money and you again may have to take off of
            work. Your pain and injury may get to the point where you need
            injections, medication, or even surgery, all of which are no fun and
            can cost you a LOT of money.
          </li>
          <li>
            Your pain and injury could also prevent you from working, and unless
            you got injured on the job, you will not be getting paid. Your paid
            vacations should be used for vacations, not when you get injured.
            You can see pain and injuries can drain your bank account.
          </li>
          <li>
            Waste Your Time and Your Life. Dealing with your pain and injury
            week in and week out, hustling back and forth to and from various
            doctors, and rehab specialists can be a huge waste of time. On top
            of that, if you are not able to do everything you want to do in life
            because you are in pain and injured, you are not living your life to
            your fullest potential.
          </li>
        </ol>

        <p>
          Wow! Who would have thought pain and injuries can negatively impact
          your life in so many ways.{' '}
        </p>
        <p>
          The beautiful thing is that most pain and injuries are 100% avoidable
          by proactively ensuring that you have joints that move well, and are
          healthy. You create pain-free, healthy joints that move well by
          proactively performing an effective stretching and mobility program.
          This is exactly what Movement Vault does and we take great pride to
          say that we have created the most effective program out there.
        </p>
        <p>Kiss pain and injury goodbye for good!</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_14.jpg',
    title: 'Low Back Pain is Curable and Preventable',
    description:
      "Movement Vault has your back, literally! Low back pain is one of the most frustrating and annoying things to constantly have to deal with. Do you deal with constant or intermittent low back pain? Well, you shouldn't and don't have to as long as you do one thing.",
    text: (
      <>
        <p>
          Did you know that in the US, low back is the number one reason that
          you will go to see your doctor. Studies have shown that in the US, 80%
          of the population will suffer from low back pain at least one time
          that is painful enough that they will be forced to see their doctor.
          Even scarier than that, 90% of that 80% will experience low back pain
          severe enough at least two or more times in their lifetime. These
          statistics are also very similar in most countries around the world.
        </p>
        <p>
          80% of the population will suffer from back pain, and 90% will
          experience low back pain at least twice. Let these numbers sink in!{' '}
        </p>
        <p>
          The sad truth is that most cases of low back pain could have been
          prevented. When I say most cases, I am talking in the range of 95% of
          all low back pain cases could have been prevented.
        </p>
        <p>
          How is it possible to prevent 95% of low back pain? What causes the
          majority of low back pain cases?{' '}
        </p>
        <p>
          Similar to every other pain and injury, low back pain is primarily
          caused by having tight muscles and tight joints. When it comes to low
          back pain and injury specifically, you are typically dealing with
          tight hips, tight ankles, and a tight and unstable midsection core.
        </p>
        <p>
          When these tight areas don't move well and are tight, it causes
          compensation in your low back. This compensation then leads to wear
          and tear on the ligaments that surround your lower back bones called
          vertebrae, as well as the discs that are located between your
          vertebrae. When you damage the discs between your vertebrae,
          compression is then placed on your nerves that travel out of your
          spinal cord.{' '}
        </p>
        <p>
          The most common disc to be injured is your lowest disc vertebrae
          called your L5-S1 disc. This then causes compression of your sciatic
          nerve which runs all the way down the back of your leg into your feet.
          When this L5-S1 disc injury occurs and compression is placed on your
          sciatic nerve you can experience a dull or sharp pain, numbness and
          tingling, burning pain, and even muscle weakness that can be localized
          to your low back area or travel all the way down your leg, even as far
          as your big toe.
        </p>
        <p>
          What specifically causes low back pain and injury? More often than not
          it is due to having tight hamstrings and a tight and unstable
          midsection core.{' '}
        </p>
        <p>
          Here is a real life example that I have seen more times than I can
          count. You have tight hamstrings and a non-mobile, unstable midsection
          core. You then bend over to pick something up. Since your hamstrings
          are tight, they will restrict your movement, specifically limiting how
          far you can bend over while picking something up. This means you will
          be able to bend over part of the way, but then your hamstrings will
          stop you from going any further.{' '}
        </p>
        <p>
          You now have two choices, you can stop and not pick the box up or you
          can compensate for your tight hamstrings at another joint. The joints
          that are compensating in this case are your low back joints. In order
          to continue to fully bend over to reach the box you are trying to pick
          up, your low back joints will have to round and flex more than they
          should when trying to safely pick up a box.
        </p>
        <p>
          This flexed low back position is not a good position for your low back
          while picking something up off of the ground. This puts increased
          pressure on the front of your spinal discs, which causes them to want
          to shoot backwards. This is how a disc bulge or disc herniation
          happens. When the disc gets pushed backwards, it then puts pressure on
          and compresses your nerves that travel out of your spinal cord. One of
          these nerves is your sciatic nerve, which we discussed before.{' '}
        </p>
        <p>
          This entire situation could have been avoided if you had good hip and
          hamstring mobility. If you had good hip and hamstring mobility you
          would have been able to bend down as far as you needed to, while
          maintaining a flat neutral back. You would have then been able to use
          your hip gluteus and hamstring muscles to pick the box up, instead of
          your back muscles.
        </p>
        <p>
          So to prevent low back pain you need to have good hip and hamstring
          mobility, as well as midsection core stability to help keep your spine
          in a good position. You don't achieve good hip, hamstring and
          midsection core mobility by accident. You need to proactively work on
          your mobility using effective techniques. An effective mobility
          program will include muscle and fascia release techniques, active
          stretches, and muscle activation exercises. This is exactly what we do
          here at Movement Vault and pride ourselves on delivering a program
          that gets the best results in the shortest amount of time.
        </p>
        <p>We are on a mission to prevent low back pain, one back at a time.</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_15.jpg',
    title: 'Rehab Your Injuries With Movement Vault',
    description:
      'So you are currently dealing with pain and/or an injury? What do you do? ',
    text: (
      <>
        <p>
          One option is to consult Dr. Google or Dr. YouTube. You may get lucky
          and find exactly what you need to know. The chances are not very high
          of that happening however. You may even make your pain and injury
          worse.
        </p>
        <p>
          It is very challenging to figure out what is good advice and what is
          bad advice when searching on Google or YouTube. Even if you get lucky
          and find some good advice, how do you know it will apply to your
          specific situation?
        </p>
        <p>
          When it comes to rehabbing a pain and injury you need to follow 3
          rules.
        </p>
        <p>
          Rule 1: Stop performing any movement or exercise that exacerbates your
          pain.
        </p>
        <p>
          Rule 2: Start performing specific rehabilitation exercises and
          techniques to speed up the healing process AND help fix the reason the
          pain and injury happened in the first place. Most of the time your
          pain and injury was caused by repetitive joint wear and tear as a
          direct result of having poor joint mobility. The other causes include
          trauma, think a fall on the ice or a car accident or severe genetic
          predisposition.
        </p>
        <p>Rule 3: Give your body time to heal.</p>
        <p>
          In order to rehab your injury properly and allow your body to heal as
          quickly as possible you need to follow all 3 rules. Rule 1 and 3 are
          pretty straight forward. Rule 2 can get very tricky. Knowing what
          exercise to perform and how to perform it at a specific time in your
          rehab journey is extremely important.
        </p>
        <p>
          You could be doing great exercises and performing them with good
          technique, but if you perform them at the wrong time in your rehab
          journey you could be delaying your rehab process and potentially even
          making things worse.{' '}
        </p>
        <p>
          Another key principle in an effective rehab workout or program is
          doing the exercises in the correct order. If I gave you 5 perfect
          exercises for you at the correct time in your rehab journey, but
          didn't tell you what order to perform them in, you will not get the
          same results as if I told you the correct order to perform them in.
        </p>
        <p>Exercise order matters.</p>
        <p>
          This is the problem I hear all the time when someone says they have
          been dealing with pain and/or an injury for 6 months. They tell me
          that they searched YouTube for “low back pain exercises” or “rotator
          cuff rehab” and found a well known fitness influencer that created a
          nice looking video. I then ask if they are getting better. A majority
          of the time they say I did get better but then the pain came back two
          months later or they will tell me it hasn't gotten any better, but
          they are still pushing through the pain.
        </p>
        <p>
          In both situations, the above person is simply wasting their time
          trying to piece together random exercises and workouts, hoping they
          will “fix the issue.”
        </p>
        <p>
          This is where Movement Vault's Pain and Injury programs come into
          play. I have crafted a series of rehab workouts for you based on a
          specific pain and injury. These workouts start out gentle, and slowly
          progress over weeks and months. They are meant to be followed in a
          sequential order.
        </p>
        <p>
          Each workout and program was methodically designed by myself using the
          many years of rehab experience I have accumulated working with
          professional athletes such as NFL, MLB, and tennis players as well as
          everyone else just playing the “game of life.” I have worked all ages
          from 7 years old, up to over 90 years old.{' '}
        </p>
        <p>
          These pain and injury programs are like having me, a Doctor of
          Physical Therapy in your pocket at all times.{' '}
        </p>
        <p>How to use Movement Vault's Pain and Injury Workouts:</p>
        <p>
          Choose the pain and injury workout program that you want to follow and
          start from “workout 1.”
        </p>
        <p>
          Read and follow all directions laid out on the workout preview screen
          before you start every workout.
        </p>
        <p>
          Progress through your workout program while following the directions
          for each workout.{' '}
        </p>
        <p>
          For example - start with Low Back Pain - Sciatica Workout 1, then
          eventually move to Low Back Pain - Sciatica Workout 2, and then Low
          Back Pain - Sciatica Workout 3, and so on.
        </p>
        <p>
          You will see how you are able to systematically progress through the
          program while decreasing your pain, improving your movement and joint
          health, as well as fixing the root cause as to why the pain and injury
          happened to begin with.
        </p>
        <p>
          If you have a body, we can help you optimize it even if you are
          currently in pain or have an injury.
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1662785766394_gettingstarted_16.jpg',
    title: 'Stretch At Work With Movement Vault',
    description:
      'I love sitting at my desk for 8 hours everyday! -said no one ever',
    text: (
      <>
        <p>
          Let's face it, it can be hard to avoid sitting for long periods of
          time, especially while at work. This applies to whether you work in
          your company's actual office or you work from home.
        </p>
        <p>
          When you sit for long periods of time all kinds of bad stuff happens
          to your body. Typically your head and neck will stick out forward,
          your shoulders will round forward, you will slump in your seat
          allowing your entire back to round, and your hips and knees will be
          bent.{' '}
        </p>
        <p>
          It is perfectly ok to sit for short periods of time, but to stay in
          one position for long periods of time is very unhealthy. Even worse is
          sitting in one, non-moving position with poor posture.
        </p>
        <p>
          Have you ever wondered why you have neck and shoulder pain, tight
          shoulders, a rounded upper back, a weak core, and tight hamstrings?
          I'll give you a clue, one of the primary causes rhymes with
          “knitting.”
        </p>
        <p>
          Your body conforms to the positions you spend the most time in. You
          literally spend most of your life either sitting or lying down. And if
          you add up all of the hours in your waking day that you are sitting,
          you will see that you are probably sitting 80% of the time.{' '}
        </p>
        <p>
          So what can you do while you are at work sitting? You can fit in a
          Movement Vault Work Routine. Movement Vault Work Routines are designed
          to be performed either sitting or standing at your desk. They require
          little to no equipment and are typically 5 minutes or less.{' '}
        </p>
        <p>
          They are the perfect way to move and stretch out while taking a five
          minute brain break. Instead of scrolling through facebook, instagram,
          twitter, reddit, or youtube mindlessly, you can be giving your body
          and joints exactly what they need to be pain free and to help you feel
          your best.
        </p>
        <p>
          Studies have shown that performing movements during your break also
          leads to greater mental performance.{' '}
        </p>
        <p>
          Do you want the solution to never having to deal with neck, back,
          shoulder, or hip pain after sitting at your desk for long periods of
          time? Movement Vault Work Routines are your solution.
        </p>
        <p>
          I guarantee you that if you perform 1-2 Movement Vault Work Routines
          per day, you will be more productive and feel 10-times better during
          and after your work day.{' '}
        </p>
        <p>
          And if you are reading this and want to give your employees or team
          the benefits of Movement Vault, we offer Movement Vault for Groups and
          Work. You will be able to receive group discounts and easily manage
          your entire team within our Team Dashboard. Reply to this email or
          email support@movementvault.com for more information.
        </p>
        <p>
          Next time you are sitting at your desk, staring off into the abyss
          with a tight neck and back, remember you have options. Movement Vault
          Work Routines has your back and is your solution.
        </p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
  {
    id: '11',
    image:
      'https://d1glqvkiwyy8rv.cloudfront.net/1609960706402gKUoajb_-wPiuXSB.jpg',
    title: 'Enhance Your Recovery With Recovery Focused Routines',
    description:
      'Have you heard the saying, “All workout and no recovery leaves Max a tired and sore man.” Me either, I just made it up.',
    text: (
      <>
        <p>
          This saying is true though. The process of working out and performing
          any type of strenuous physical activity is a form of a stressor. This
          stressor is placed on all of your connective tissue including your
          muscles, tendons, fascia, ligaments, and nerves. Working out also
          stresses your nervous system. Your nervous system includes your brain,
          spinal cord and the nerves that travel to and from your spinal cord.
        </p>
        <p>
          In the proper dose, exercising and working out is a healthy stressor,
          and literally one of the healthiest things you can do for your body.
          When the dose is too much, too often, working out can actually have a
          negative effect on your health. The effectiveness is all in the dose.
        </p>
        <p>
          The workout dose that is optimal for you will most likely not be the
          dose that is optimal for me. When I say dose I am referring to the
          intensity and amount that you exercise. To get more technical, the
          dose is the amount of exercise volume and intensity.
        </p>
        <p>
          You can get really technical with dosing, but that isn't what this
          article is about.{' '}
        </p>
        <p>
          I am going to assume you are following a well thought out workout
          program that includes the proper workout dose for you. During your
          workouts you are creating micro damage to all of the connective tissue
          I previously mentioned as well as placing stress on your nervous
          system.{' '}
        </p>
        <p>
          In order to get stronger, faster, build more muscle, lose weight, and
          or improve your cardiovascular health you need to repair and recover.{' '}
        </p>
        <p>
          During a workout, you are stressing out your body and somewhat
          breaking it down. This is why soreness is common during the days
          following a workout.
        </p>
        <p>
          The magic happens during the recovery phase after your workout. During
          the recovery phase, your body repairs all of this micro damage that
          was created during your workout. Once fully recovered and repaired,
          all of your muscles, tendons, fascia, and ligaments will be stronger
          and have a greater capacity for more stress aka you will be able to
          lift more weight, perform more reps, become faster and more powerful,
          etc.{' '}
        </p>
        <p>
          Your recovery after a workout is just as important as your workout
          itself. If you continue to workout and are not recovering properly,
          you will end up weaker, constantly fatigued, less fit, and even less
          healthy. You need to recover well.
        </p>
        <p>
          The biggest factors to enhance recovery include: plenty of quality
          sleep, proper nutrition, stress management, and specific mobility
          techniques.
        </p>
        <p>
          Let's touch on specific mobility techniques that can help improve your
          recovery process. Techniques such as specific muscle and fascia
          release techniques, range of motion exercises, as well as stretches
          and breathwork can help your recovery. This is achieved by improving
          blood flow, moving lymphatic fluid, sending signals to your central
          nervous system, as well as decreasing muscle tightness.
        </p>
        <p>
          Movement Vault Recovery focused routines do all of the above. Our
          Recovery focused routines are the perfect supplements to your workouts
          to help you improve your recovery after a workout or on your rest
          days. They are also great options on days that you want a less intense
          routine that offers gentler techniques.{' '}
        </p>
        <p>
          Movement Vault's recovery focused routines will help you improve and
          speed up your recovery. They are a crowd favorite for a reason. See
          for yourself!
        </p>
        <p>Happy recovering!</p>
        <p>Sending you amazing health,</p>
        <p>Dr. Grayson Wickham</p>
      </>
    ),
  },
];
