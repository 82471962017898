import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import Envelope from 'components/icons/Envelope';
import Shield from 'components/icons/Shield';
import { Creators } from '../reducer';
import { ContextMenu } from 'components';
import { Creators as ModalCreators } from 'modules/modal';

export const TableMenu = ({
  deleteUser,
  goTo,
  resendEmail,
  showModal,
  userId,
}) => {
  const theme = useTheme();
  const textColor = theme.palette.primary.generalText;
  const redColor = theme.palette.secondary.red;

  const options = [
    {
      onClick: () => {
        resendEmail(userId, 'welcome');
        ref.current.handleClose();
      },
      icon: <Envelope fill={textColor} />,
      text: 'Resend Welcome Email',
    },
    {
      onClick: () => {
        resendEmail(userId, 'reset');
        ref.current.handleClose();
      },
      icon: <Shield fill={textColor} />,
      text: 'Send Link to Reset Password',
    },
    {
      onClick: () => goTo(`team-accounts/${userId}`),
      icon: <Edit fill={textColor} />,
      text: 'Edit',
    },
    {
      onClick: () => goTo(`team-accounts-dashboard/${userId}`),
      icon: <Edit fill={textColor} />,
      text: 'Edit Team Account Dashboard',
    },
    {
      onClick: () =>
        showModal(
          'deleteConfirmation',
          () => deleteUser(userId),
          'Are you sure you want to delete Member?'
        ),
      icon: <Bin fill={redColor} />,
      text: 'Delete',
      textColor: redColor,
    },
  ];

  const ref = React.useRef();

  return <ContextMenu ref={ref} options={options} />;
};

TableMenu.propTypes = {
  deleteUser: PropTypes.func,
  goTo: PropTypes.func,
  resendEmail: PropTypes.func,
  showModal: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  deleteUser: Creators.deleteUser,
  resendEmail: Creators.resendEmail,
  showModal: ModalCreators.showModal,
  goTo: push,
};

export default connect(null, mapDispatchToProps)(TableMenu);
