import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { NavBar } from 'modules/admin/dashboard';
import { Creators } from '../reducer';
import ExerciseForm from './ExerciseForm';

import { HOME_URL } from '../';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const EditExercise = ({
  classes,
  exercise,
  updateExercise,
  id,
  processing,
}) => {
  const title = 'Edit Exercise';
  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={HOME_URL} />
      <div className={classes.content}>
        {exercise ? (
          <ExerciseForm
            exercise={exercise}
            isEdit
            onSubmit={(values) => {
              values.id = id;
              updateExercise({ id, exercise: values });
            }}
            pageTitle={title}
            processing={processing}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (
  { exerciseCreator: { exercises, processing } },
  {
    match: {
      params: { id },
    },
  }
) => {
  const exerciseToEdit = exercises.find((e) => e.id === id);
  const transformItem = ({ id, title }) => ({ label: title, value: id });

  if (!exerciseToEdit) return { processing, exercise: null };

  return {
    exercise: {
      ...exerciseToEdit,
      bodyAreas: exerciseToEdit.bodyAreas.map(transformItem),
      equipments: exerciseToEdit.equipments.map(transformItem),
      frontEndVisible: exerciseToEdit.frontEndVisible?.toString() || 'false',
      modalities: exerciseToEdit.modalities.map(transformItem),
      movementPreps: exerciseToEdit.movementPreps.map(transformItem),
      muscles: exerciseToEdit.muscles.map(transformItem),
      thumbnail: {
        id: exerciseToEdit.thumbnail?.id,
        url: exerciseToEdit.thumbnail?.location,
      },
      video: {
        id: exerciseToEdit.video?.id,
        url: exerciseToEdit.video?.location,
      },
      videoLo: {
        id: exerciseToEdit.videoLo?.id,
        url: exerciseToEdit.videoLo?.location,
      },
      videoHi: {
        id: exerciseToEdit.videoHi?.id,
        url: exerciseToEdit.videoHi?.location,
      },
    },
    id,
    processing,
  };
};

export default connect(mapStateToProps, {
  updateExercise: Creators.updateExercise,
})(withStyles(styles)(EditExercise));
