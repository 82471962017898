import { createActions, createReducer } from 'reduxsauce';
import { LOCATION_CHANGE } from 'connected-react-router';

import { toFormErrorObject } from 'core';

const INITIAL_FILTER = {
  currentPage: 0,
  searchFilter: '',
  searchTerm: '',
  statusFilter: '',

};

const INITIAL_STATE = {
  teamMembers: [],
  processing: false,
  filter: INITIAL_FILTER,
};

// Action creators
const { Types, Creators } = createActions(
  {
    setFilter: ['filter'],
    deleteTeamAccountMembersSuccess: ['response'],
    deleteTeamAccountMembersFail: ['error'],
    deleteTeamAccountMembers: (ids,teamAccountId) => ({
      type: 'teamMembers/DELETE_TEAM_ACCOUNT_MEMBERS',
      payload: {
        teamMemberIds: ids,
        request: {
          url: `user/team/${teamAccountId}/member/delete/multiple`,
          method: 'post',
          data: { ids: ids },
        },
      },
    }),
    createTeamMemberAccountSuccess: ['response'],
    createTeamMemberAccountFail: ['error'],
    createTeamMemberAccount: ( invitationData,teamAccountId ) => {
      return {
        type: 'teamMembers/CREATE_TEAM_MEMBER_ACCOUNT',
        payload: {
          request: {
            url: `user/team/${teamAccountId}/member`,
            method: 'post',
            data: invitationData,
          },
        },
      }
    },     
      getTeamMemberAccountsSuccess: ['response'],
      getTeamMemberAccountsFail: ['error'],
      getTeamMemberAccounts: (teamAccountId) => {
        return {
          type: 'teamMembers/GET_TEAM_MEMBER_ACCOUNTS',
          payload: {
            request: {
              url: `user/team/${teamAccountId}/member`,
              method: 'get',
            },
          },
        }
    },
    updateTeamMemberSuccess: ['response'],
    updateTeamMemberFail: ['error'],
    updateTeamMember: ({ id, memberData , teamAccountId }) => ({
      type: 'teamMembers/UPDATE_TEAM_MEMBER',
      payload: {
        request: {
          url: `user/team/${teamAccountId}/member/update/${id}`,
          method: 'patch',
          data: memberData,
        },
      },
    }),

    updateTeamAccountInviteIdSuccess: ['response'],
    updateTeamAccountInviteIdFail: ['error'],
    updateTeamAccountInviteId: (accountId) => {
      console.log(accountId , "TEAM ACCOUNT INVITEEEE ID")
      return {
      type: 'teamMembers/UPDATE_TEAM_ACCOUNT_INVITE_ID',
      payload: {
        request: {
          url: `user/team/member/update/${accountId}`,
          method: 'patch',
        },
      },
    }
    }
    ,


    createMultipleTeamMemberAccountSuccess: ['response'],
    createMultipleTeamMemberAccountFail: ['error'],
    createMultipleTeamMemberAccount: ( invitationData,teamAccountId ) => {
      return {
        type: 'teamMembers/CREATE_MULTIPLE_TEAM_MEMBER_ACCOUNT',
        payload: {
          request: {
            url: `user/team/${teamAccountId}/member/multiple`,
            method: 'post',
            data: invitationData,
          },
        },
      }
    },   

    
  },
  { prefix: 'teamMembers/' }
);


const teamAccountMemberCreatedSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    teamMembers: [data, ...state.teamMembers]
  };
};

const getTeamMemberAccountsSuccess = (state = INITIAL_STATE, 
  { payload: { data } 
}) => {
  return {
    ...state,
    teamMembers: data?[...data]:[]
  };
};

const createMultipleTeamMemberAccountSuccess = (state = INITIAL_STATE, 
  { payload: { data } 
}) => {
  return {
    ...state,
    teamMembers: [...data,...state.teamMembers]
  };
};

const updateTeamMember = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateTeamAccountInviteId = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});


const updateteamMemberSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    teamMembers: state.teamMembers.map((u) => {
      if(u.id == data.id){
        return data;
      }
      return u;
    })
  };
};




const getUsersFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});



const deleteTeamAccountMembersSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { teamMemberIds },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    teamMembers: state.teamMembers.filter((teamMember) => !teamMemberIds.includes(teamMember.id)),
  };
};


const setFilter = (state = INITIAL_STATE, { filter }) => ({
  ...state,
  filter,
});

// const deleteTeamAccountMembersSuccess = (
//   state = INITIAL_STATE,
//   {
//     meta: {
//       previousAction: {
//         payload: { teamMemberIds },
//       },
//     },
//   }
// ) => {
//   return {
//     ...state,
//     processing: false,
//     users: state.teamMembers.filter((teamMember) => teamMember.id !== teamMemberIds),
//   };
// };


// const updateTeamAccountInviteIdSuccess = (
//   state = INITIAL_STATE,
//   { payload: user }
// ) => {
//   console.log(user);
//   return {
//     ...state,
//     processing: false,
//     users: state.users.map((u) => {
//       if( u.id == user.data.id){
//         u.teamAccountInviteId = user.data.teamAccountInviteId;  
//       } 
      
//       return u;
//     }),
//   };
// };



const Reducer = createReducer(INITIAL_STATE,
  {
  [Types.SET_FILTER]: setFilter,
  [Types.CREATE_TEAM_MEMBER_ACCOUNT_SUCCESS]: teamAccountMemberCreatedSuccess,
  [Types.GET_TEAM_MEMBER_ACCOUNTS_SUCCESS]: getTeamMemberAccountsSuccess, 
  [Types.CREATE_MULTIPLE_TEAM_MEMBER_ACCOUNT_SUCCESS]: createMultipleTeamMemberAccountSuccess,
  [Types.UPDATE_TEAM_MEMBER]: updateTeamMember,
  [Types.UPDATE_TEAM_ACCOUNT_INVITE_ID]: updateTeamAccountInviteId,
  [Types.DELETE_TEAM_ACCOUNT_MEMBERS_SUCCESS]: deleteTeamAccountMembersSuccess, 
  [Types.UPDATE_TEAM_MEMBER_SUCCESS]: updateteamMemberSuccess, 
  // [Types.UPDATE_TEAM_ACCOUNT_INVITE_ID_SUCCESS]: updateTeamAccountInviteIdSuccess,

  
  
  // [Types.DELETE_MEMBERS_SUCCES]: deleteTeamAccountMembersSuccess, 
 
});

export { Creators, Types, Reducer, INITIAL_STATE , INITIAL_FILTER};
