import { createActions, createReducer } from 'reduxsauce';
import { LOCATION_CHANGE } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { toFormErrorObject } from 'core';

// Initial state
const INITIAL_FILTER = {
  currentPage: 0,
  planFilter: '',
  searchFilter: '',
  searchTerm: '',
  statusFilter: '',
};

const INITIAL_STATE = {
  error: {},
  processing: false,
  remembeMe: false,
  users: [],
  filter: INITIAL_FILTER,
};

// Action creators
const { Types, Creators } = createActions(
  {
    setFilter: ['filter'],
    resendEmail: (id, emailType) => ({
      type: 'users/RESEND_EMAIL',
      payload: {
        request: {
          url: `users/${id}/resend-mail`,
          method: 'post',
          data: {
            emailType,
          },
        },
      },
    }),
    resendEmailSuccess: ['response'],
    resendEmailFail: ['error'],
    getUsersSuccess: ['response'],
    getUsersFail: ['error'],
    getUsers: () => ({
      type: 'users/GET_USERS',
      payload: {
        request: {
          url:
            'users?join=subscriptions&join=subscriptions.membership&join=subscriptions.membership.group',
          method: 'get',
        },
      },
    }),
    deleteUsersSuccess: ['response'],
    deleteUsersFail: ['error'],
    deleteUsers: (ids) => ({
      type: 'users/DELETE_USERS',
      payload: {
        userIds: ids,
        request: {
          url: `users/deleteByIds`,
          method: 'post',
          data: { ids: ids },
        },
      },
    }),
    deleteUserSuccess: ['response'],
    deleteUserFail: ['error'],
    deleteUser: (id) => ({
      type: 'users/DELETE_USER',
      payload: {
        userId: id,
        request: {
          url: `users/${id}`,
          method: 'delete',
        },
      },
    }),
  
    createCompanyAdminSuccess: ['response'],
    createCompanyAdminFail: ['error'],
    createCompanyAdmin: ( user ) => {
      console.log(user);
      return {
        type: 'users/CREATE_COMPANY_ADMIN',
        payload: {
          request: {
            url: `users/company/admin`,
            method: 'post',
            data: user,
          },
        },
      }
  },
  //   updateCompanyAdminSuccess: ['response'],
  //   updateCompanyAdminFail: ['error'],
  //   updateCompanyAdmin: ({ id, user }) => {
  //     console.log("ENTER UPDATE METHOD")
  //     return {
  //     type: 'users/UPDATE_COMPANY_ADMIN',
  //     payload: {
  //       request: {
  //         url: `users/${id}`,
  //         method: 'patch',
  //         data: user,
  //       },
  //     },
  //   }
  // },

    // updateUserSuccess: ['response'],
    // updateUserFail: ['error'],
    // updateUser: ({ id, user }) => ({
    //   type: 'users/UPDATE_USER',
    //   payload: {
    //     request: {
    //       url: `users/${id}`,
    //       method: 'patch',
    //       data: user,
    //     },
    //   },
    // }),


    


 },
  { prefix: 'users/' }
);

// Reducers
const getUsers = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const setFilter = (state = INITIAL_STATE, { filter }) => ({
  ...state,
  filter,
});

const getPlanName = (subscriptions) => {
  const none = '-';

  if (!subscriptions || subscriptions.length < 1) return none;
  return (
    (subscriptions[0].membership?.group?.title || '') +
      '/' +
      subscriptions[0].membership?.title || ''
  );
};

const prettifyUser = (user, i) => {
  let transactions = 0;
  let totalValue = 0;
  if (user.subscriptions?.length) {
    user.subscriptions.forEach((subscription) => {
      totalValue += parseInt(subscription.totalPayedAmount) || 0;
      transactions += parseInt(subscription.transactionsNumber) || 0;
    });
  }

  return {
    ...user,
    membershipPlan: getPlanName(
      user.subscriptions.filter(
        (s) => s.status === 'active' || s.status === 'trial'
      )
    ),
    transactions: transactions,
    value: totalValue,
    name: `${user.firstName || ''} ${user.lastName || ''}`,
  };
};

const getUsersSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    users: data.map(prettifyUser),
  };
};

const companyAdminCreatedSuccess = (
  state = INITIAL_STATE,
  { payload: user }
) => {
  const modifiedUser = prettifyUser(user)

  return {
    ...state,
    processing: false,
    users: [modifiedUser, state.users]
  };
};

const companyAdminUpdatedSuccess1 = (
  state = INITIAL_STATE,
  { payload: user }
) => {
  const modifiedUser = prettifyUser(user)

  return {
    ...state,
    processing: false,
    users: state.users.map((u) => {
      if(u.id == user.id){
        return modifiedUser;
      }
      return u;
    })
  };
};

const getUsersFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const deleteUser = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const deleteUserSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { userId },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    users: state.users.filter((user) => user.id !== userId),
  };
};

const deleteUserFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const deleteUsers = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const deleteUsersSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { userIds },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    users: state.users.filter((user) => !userIds.includes(user.id)),
  };
};

const deleteUsersFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updateUser = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateCompanyAdminSuccess = (state = INITIAL_STATE) => {
  console.log("asdasdadas222")

  toastr.success('Info', 'Company updated');
  push('/admin/dashboard/membership/team-accounts');
  console.log("asdasdadas")
  return {
  ...state,
  processing: false,
}
};

const updateUserFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  processing: false,
  error: toFormErrorObject(payload.response),
});

const resendEmail = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const resendEmailSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
  };
};

const resendEmailFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const locationChange = (state = INITIAL_STATE) => ({
  ...state,
  error: {},
});




const updateTeamAccountInviteIdSuccess = (
  state = INITIAL_STATE,
  { payload: user }
) => {
  console.log(user);
  return {
    ...state,
    processing: false,
    users: state.users.map((u) => {
      if( u.id == user.data.id){
        u.teamAccountInviteId = user.data.teamAccountInviteId;  
      } 
      
      return u;
    }),
  };
};


const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FILTER]: setFilter,
  [Types.GET_USERS]: getUsers,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAIL]: getUsersFail,
  [Types.RESEND_EMAIL]: resendEmail,
  [Types.RESEND_EMAIL_SUCCESS]: resendEmailSuccess,
  [Types.RESEND_EMAIL_FAIL]: resendEmailFail,
  [Types.DELETE_USER]: deleteUser,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAIL]: deleteUserFail,
  [Types.DELETE_USERS]: deleteUsers,
  [Types.DELETE_USERS_SUCCESS]: deleteUsersSuccess,
  // [Types.UPDATE_USER]: updateUser,
  [Types.DELETE_USERS_FAIL]: deleteUsersFail,
  // [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  // [Types.UPDATE_USER_FAIL]: updateUserFail,
  // [Types.CREATE_COMPANY_ADMIN_SUCCESS]: companyAdminCreatedSuccess,
  // [Types.UPDATE_COMPANY_ADMIN_SUCCESS]: updateCompanyAdminSuccess,
  [LOCATION_CHANGE]: locationChange,
  
});

export { Creators, Types, Reducer, INITIAL_FILTER };
