import React from 'react';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

import { Link } from 'components';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import Copy from 'components/icons/Copy';

const styles = ({ palette: { background } }) => ({
  button: {
    alignItems: 'center',
    background: background.paper,
    borderRadius: 2,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    marginLeft: 24,
    width: 45,
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0px',
  },
});

const ActionButtons = ({ baseUri, classes, id, onDelete, onDuplicate }) => {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <div className={classes.container}>
      {onDuplicate && (
        <div className={classes.button} onClick={() => onDuplicate(id)}>
          <Copy height={25} width={25} fill={primary.dark} />
        </div>
      )}
      <Link to={`${baseUri}/edit/${id}`}>
        <div className={classes.button}>
          <Edit height={24} width={24} fill={primary.dark} />
        </div>
      </Link>
      <div className={classes.button} onClick={() => onDelete(id)}>
        <Bin height={24} width={24} fill={primary.red} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ActionButtons);
