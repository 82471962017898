import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';

const INITIAL_FILTER = {
  currentPage: 0,
  searchFilter: '',
  searchTerm: '',
  statusFilter: '',
};

const INITIAL_STATE = {
  coupons: [],
  isDirty: false,
  processing: false,
  filter: INITIAL_FILTER,
};

// Action creators
const { Types, Creators } = createActions(
  {
    updateUI: ['ui'],
    setFilter: ['filter'],
    setActiveTask: ['taskIndex'],
    getCouponsFail: ['error'],
    getCouponsSuccess: ['response'],
    getCoupons: () => ({
      type: 'coupon/GET_COUPONS',
      payload: {
        request: {
          url: '/coupon-code',
          method: 'get',
        },
      },
    }),
    createCouponFail: ['error'],
    createCouponSuccess: ['response'],
    createCoupon: (coupon) => ({
      type: 'coupon/CREATE_COUPON',
      payload: {
        request: {
          url: '/coupon-code',
          method: 'post',
          data: coupon,
        },
      },
    }),
    deleteCouponSuccess: ['response'],
    deleteCouponFail: ['error'],
    deleteCoupon: (id) => ({
      type: 'coupon/DELETE_COUPON',
      payload: {
        request: {
          url: `/coupon-code/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
  },
  { prefix: 'coupon/' }
);

// Reducers
const getCoupons = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getCouponsSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    coupons: data,
    processing: false,
  };
};
const setActiveTask = (state = INITIAL_STATE, { taskIndex }) => ({
  ...state,
  activeTask: taskIndex,
});

const createCoupon = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createCouponSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    coupons: [...state.coupons, { ...data }],
  };
};

const createCouponFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const setFilter = (state = INITIAL_STATE, { filter }) => ({
  ...state,
  filter,
});

const deleteCouponSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    coupons: state.coupons.filter((e) => e.code !== id),
  };
};

const deleteCouponFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_FILTER]: setFilter,
  [Types.GET_COUPONS]: getCoupons,
  [Types.GET_COUPONS_SUCCESS]: getCouponsSuccess,
  [Types.SET_ACTIVE_TASK]: setActiveTask,
  [Types.CREATE_COUPON_SUCCESS]: createCouponSuccess,
  [Types.CREATE_COUPON_FAIL]: createCouponFail,
  [Types.CREATE_COUPON]: createCoupon,
  [Types.DELETE_COUPON_SUCCESS]: deleteCouponSuccess,
  [Types.DELETE_COUPON_FAIL]: deleteCouponFail,
});

export { Creators, Types, Reducer, INITIAL_FILTER };
