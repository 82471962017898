import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';

import { Button, Link } from 'components';
import { Creators as ModalCreators } from 'modules/modal';
import { Creators } from '../reducer';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import mediaNotUploaded from 'assets/img/media-not-uploaded.png';
import { Dialog, Fab } from '@material-ui/core';
import Question from './Question';
import Answer from './Answer';
import { VideoPlayer } from 'modules/dashboard/components';
import closeImg from 'assets/svg/close.svg';

const styles = ({ theme, breakpoints }) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  singleParagraph1: {
    paddingTop: '25px',
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
  },
  singleParagraph: {
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
  },
  button: {
    width: '240px',
    height: '64px',
    marginTop: '30px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  paper: {
    minWidth: '1128px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  popUpButtonCancel: {
    marginTop: '40px',
    width: '208px',
    height: '56px',
    textTransform: 'capitalize',
  
    [breakpoints.down('xs')]: {
      width: '100%',
      textTransform: 'uppercase',
      marginTop: '0px',

      // boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",

    },
  },
  floatingButtonContainer:{
   
    [breakpoints.down('xs')]: {
      display : "flex",
    justifyContent :"center",
    alignItems : "center",
    position : "fixed",
    bottom : 0,
    backgroundColor : "white",
    width : "100%",
    padding : "15px",

      // boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",

    },
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '72px',
    [breakpoints.down('xs')]: {
      alignItems: 'center',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  closeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  closeTitle: {
    fontSize: '24px',
    fontWeight: 900,
    color: '#020401',
    width: '424px',
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      fontSize: '20px',
      lineHeight: '30px',
      width: '100%',
    },
  },
  closeSubTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#020401',
    paddingTop: '20px',
    [breakpoints.down('xs')]: {
      lineHeight: '24px',
      paddingTop: '5px',
    },
  },
  closePaper: {
    paddingTop: '64px',
    paddingBottom: '64px',
    minWidth: '632px',
    minHeight: '0px',
    paddingBottom: '56px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  quitButton: {
    width: '208px',
    color: '#28AAE2',
    borderStyle: 'solid',
    borderColor: '#28AAE2',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  quitButtonSwitch: {
    width: '208px',
    color: '#28AAE2',
    borderStyle: 'solid',
    borderColor: '#28AAE2',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: '80px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  closeButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '32px',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  resumeButton: {
    width: '208px',
    height: '56px',
    marginLeft: '24px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      marginTop: '10px',
    },
  },
  closeButton: {
    cursor: 'pointer',
    height: 40,
    width: 40,
    padding: 12,
    borderRadius: 20,
    backgroundColor: '#0003',
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: 50,
    [breakpoints.down('xs')]: {
      top: 12,
      right: 12,
    },
  },
});

const QuestionAndAnswer = ({
  classes,
  data,
  deleteTask,
  activeTask,
  getUserAssessment,
  setActiveTask,
  showModal,
  onClose,
  open,
  question,
  addAnswer,
  videoIndex,
}) => {
  const theme = useTheme();

  useEffect(() => {
    getUserAssessment();
  }, [getUserAssessment]);

  const onDelete = (id) => {
    deleteTask(id);
  };

  const closeModal = () => {
    onClose(!onClose);
  };

  const [renderQuestion, setRenderQuestion] = useState(true);

  useEffect(() => {
    setRenderQuestion(true);
  }, [question]);

  const [showCloseMessage, setShowCloseMessage] = useState(false);

  const handleClickOpen = () => {
    setShowCloseMessage(true);
  };

  const handleClose = () => {
    setShowCloseMessage(false);
  };

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()    
  
  const [disabled, setDisabled] = useState(false);

  const hi = () =>{
    // setDisabled(true);
    setTimeout(() => {
        setDisabled(true);
    },  10000)}




    



  return (
    <>
      <div ref={myRef} >
        {renderQuestion == true ? (
          <div>
            <Question
              question={question}
              videoIndex={videoIndex}
              onClose={() => setRenderQuestion(false)}
            />
          </div>
        ) : (
          <div>
            <Answer addAnswer={addAnswer} answer={question} />
          </div>
        )}

        <div className={classes.mainContainer}>
          <div className={classes.buttonsContainer}>
            {renderQuestion == true ? null : (
              <div
                className={
                  renderQuestion == true
                    ? classes.quitButton
                    : classes.quitButtonSwitch
                }
                title='Cancel'
                type='button'
                //   onClick={onClose}
                //   onClick={Open}
                onClick={onClose}
              >
                Cancel
              </div>
            )}
            {renderQuestion == true ? (
              <div className={classes.floatingButtonContainer} >
              <Button
                className={classes.popUpButtonCancel}
                title='Enter My Results'
                // onClick={() => setRenderQuestion(false)}
                onClick={() => {
                  setRenderQuestion(false);
                  // window.scrollTo({ top: 1000, behavior: "smooth" })
                  executeScroll();
                  // hi()
                }}
              //   disabled={ setTimeout(() => {
              //     console.log("555");
              // },  10000)} 
              // disabled={10000}
              >
                Enter My Results
              </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {renderQuestion == true ? (
        <img
          alt='close button'
          className={classes.closeButton}
          onClick={onClose}
          src={closeImg}
          data-id='close-button'
        />
      ) : null}
      {/* {renderQuestion == true ? (
                <Button
                  className={classes.popUpButtonCancel}
                  title='Enter My Results'
                  onClick={() => setRenderQuestion(false)}
                >
                  Enter My Results
                </Button>
              ) : null} */}
      {/* )} */}
      {/* </Dialog> */}
    </>
  );
};

const mapDispatchToProps = {
  getUserAssessment: Creators.getUserAssessment,
};

const mapStateToProps = ({ userAssessment }) => ({
  data: userAssessment.tasks,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuestionAndAnswer));
