import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { push } from 'connected-react-router';

import TopBar from './TopBar1';
import { Link } from 'components';
import { Creators } from '../reducer';
import { Creators as ModalCreators } from 'modules/modal';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';

const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0px',
  },
  actionButton: {
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderRadius: 2,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    marginLeft: 24,
    width: 45,
  },
  cell: {
    width: 190,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    display: 'flex',
  },
  details: {
    marginLeft: 24,
    width: 600,
  },
  detailsPanel: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    '& > div:first-child': {
      paddingTop: 24,
    },
    '& > div:last-child': {
      paddingBottom: 24,
    },
  },
  header: {
    fontSize: 21,
    fontWeight: 900,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  label: {
    color: 'rgba(74, 75, 107, 0.4)',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  money: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  plans: {
    background: theme.palette.background.paper,
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
    width: 400,
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  plan: {
    borderTop: `1px solid ${theme.palette.primary.gray}`,
    padding: 16,
    cursor: 'pointer',
    '& > div': {
      marginBottom: 4,
    },
  },
  row: {
    display: 'flex',
    padding: '8px 24px',
  },
  rowOdd: {
    background: theme.palette.background.default,
  },
  selectedPlan: {
    backgroundColor: theme.palette.secondary.inactive,
  },
  text: {
    fontSize: 16,
  },
  textXL: {
    fontSize: 18,
    marginTop: 8,
    textTransform: 'capitalize',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  block: {
    display: 'block',
  },
  mb: {
    marginBottom: `${theme.spacing()}px !important`,
  },
});

const ChildPlans = ({ classes, currentPlanIndex, onPlanClick, plans }) => {
  const getIntervalName = ({ interval, intervalCount, intervalType }) => {
    if (interval === 'year') return 'yearly';
    if (interval === 'month') return 'monthly';
    if (interval === 'week') return 'weekly';
    if (interval === '3months') return 'every 3 months';
    if (interval === '6months') return 'every 6 months';
    if (interval === 'custom') {
      if (parseInt(intervalCount) === 1) {
        if (intervalType === 'day') return 'daily';
        if (intervalType === 'month') return 'monthly';
        if (intervalType === 'year') return 'yearly';
      }
      return `every ${intervalCount} ${intervalType}s`;
    }
  };

  const ChildPlan = ({ index, plan, selected }) => (
    <div
      className={cx(classes.plan, selected ? classes.selectedPlan : '')}
      onClick={() => onPlanClick(index)}
    >
      <div className={classes.label}>plan name</div>
      <div
        className={cx(classes.title, classes.mb)}
      >{`${plan.parentPlan} - ${plan.title}`}</div>
      <div className={classes.label}>terms</div>
      <div className={classes.text}>
        {(plan.trialDuration ? plan.trialDuration + '-Day trial then ' : '') +
          '$' +
          plan.price +
          '/' +
          (plan.billingType === 'once' ? 'once' : getIntervalName(plan))}
      </div>
    </div>
  );

  return (
    <div className={classes.plans}>
      {plans.map((plan, index) => (
        <ChildPlan
          index={index}
          key={plan.parentPlan + plan.title + index}
          plan={plan}
          selected={currentPlanIndex === index}
        />
      ))}
    </div>
  );
};

const Plans = ({
  activeChildPlan,
  activeParentPlan,
  activeTab,
  classes,
  data,
  deletePlan,
  deletePlanGroup,
  getPlans,
  setActiveChildPlan,
  setActiveParentPlan,
  setActiveTab,
  showModal,
}) => {
  const theme = useTheme();

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  if (data === null) return null;
  const CHILD_PLANS = data
    .reduce((acc, current) => {
      const childPlans = current.plans.map((plan) => ({
        ...plan,
        parentPlan: current.title,
      }));
      return acc.concat(childPlans);
    }, [])
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const onDelete = (id) => {
    if (activeTab === 'parent') {
      deletePlanGroup(id);
      setActiveParentPlan(activeParentPlan - 1);
    } else {
      deletePlan(id);
      setActiveChildPlan(activeChildPlan ? activeChildPlan - 1 : 0);
    }
  };

  const ParentPlans = () => {
    return (
      <div className={classes.plans}>
        {data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((plan, index) => (
            <div
              className={cx(
                classes.plan,
                classes.title,
                index === activeParentPlan ? classes.selectedPlan : ''
              )}
              onClick={() => setActiveParentPlan(index)}
              key={plan.title}
            >
              {plan.title}
            </div>
          ))}
      </div>
    );
  };

  const ActionButtons = ({ onDelete, onEdit }) => {
    const prefix = activeTab;
    const id =
      prefix === 'child'
        ? CHILD_PLANS[activeChildPlan]
          ? CHILD_PLANS[activeChildPlan].code
          : null
        : data[activeParentPlan]
        ? data[activeParentPlan].id
        : null;

    return (
      <div className={classes.actionButtons}>
        <Link to={`plan/${prefix}/${id}`}>
          <div className={classes.actionButton}>
            <Edit height={24} width={24} fill={theme.palette.primary.dark} />
          </div>
        </Link>
        <div
          className={classes.actionButton}
          onClick={() =>
            showModal(
              'deleteConfirmation',
              () => onDelete(id),
              'Are you sure you want to delete the plan?'
            )
          }
        >
          <Bin height={24} width={24} fill={theme.palette.primary.red} />
        </div>
      </div>
    );
  };

  const ChildPlanDetails = ({ plan }) => {
    if (!plan) return <div />;

    return (
      <div className={classes.detailsPanel}>
        <div className={cx(classes.row, classes.block)}>
          <div className={classes.label}>plan name</div>
          <div className={classes.header}>{plan.title}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.cell}>
            <div className={classes.label}>price</div>
            <div className={cx(classes.textXL, classes.money)}>
              ${plan.price}
            </div>
          </div>
          <div className={classes.cell}>
            <div className={classes.label}>billing type</div>
            <div className={classes.textXL}>{plan.billingType}</div>
          </div>
          <div className={classes.cell}>
            <div className={classes.label}>interval</div>
            <div className={classes.textXL}>{plan.interval}</div>
          </div>
        </div>
        <div className={cx(classes.row, classes.rowOdd)}>
          <div className={classes.cell}>
            <div className={classes.label}>trial period</div>
            <div className={classes.textXL}>
              {plan.trialDuration ? 'Yes' : 'No'}
            </div>
          </div>
          <div className={classes.cell}>
            <div className={classes.label}>trial duration</div>
            <div className={classes.textXL}>
              {plan.trialDuration
                ? plan.trialDuration +
                  ` day${plan.trialDuration > 1 ? 's' : ''}`
                : '-'}
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.cell}>
            <div className={classes.label}>parent</div>
            <div className={classes.textXL}>{plan.parentPlan}</div>
          </div>
          <div className={classes.cell}>
            <div className={classes.label}>order</div>
            <div className={classes.textXL}>{plan.order}</div>
          </div>
          <div className={classes.cell}>
            <div className={classes.label}>Ppayment Channel</div>
            <div className={classes.textXL}>{plan.paymentChannel}</div>
          </div>
        </div>
      </div>
    );
  };

  const ParentPlanDetails = ({ plan }) => {
    if (!plan) return <div />;
    return (
      <div className={classes.detailsPanel}>
        <div className={cx(classes.row, classes.block)}>
          <div className={classes.label}>plan name</div>
          <div className={classes.header}>{plan.title}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.cell}>
            <div className={classes.label}>status</div>
            <div className={cx(classes.textXL, classes.money)}>
              {plan.private ? 'private' : 'public'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <TopBar
        activeSwitchOption={activeTab}
        onLeftSwitchClick={() => setActiveTab('child')}
        onRightSwitchClick={() => setActiveTab('parent')}
      />
      <div className={classes.content}>
        {data.length > 0 ? (
          activeTab === 'child' ? (
            <ChildPlans
              classes={classes}
              currentPlanIndex={activeChildPlan}
              onPlanClick={setActiveChildPlan}
              plans={CHILD_PLANS}
            />
          ) : (
            <ParentPlans />
          )
        ) : (
          <div />
        )}
        <div className={classes.details}>
          {data.length > 0 ? (
            activeTab === 'child' ? (
              <>
                <ActionButtons onDelete={onDelete} />
                <ChildPlanDetails plan={CHILD_PLANS[activeChildPlan]} />
              </>
            ) : (
              <>
                <ActionButtons onDelete={onDelete} />
                <ParentPlanDetails plan={data[activeParentPlan]} />
              </>
            )
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  goTo: push,
  getPlans: Creators.getPlanGroups,
  deletePlanGroup: Creators.deletePlanGroup,
  deletePlan: Creators.deletePlan,
  setActiveTab: Creators.setActiveTab,
  setActiveChildPlan: (planIndex) => Creators.setActivePlan('child', planIndex),
  setActiveParentPlan: (planIndex) =>
    Creators.setActivePlan('parent', planIndex),
  showModal: ModalCreators.showModal,
};

const mapStateToProps = ({ plan }) => ({
  activeChildPlan: plan.activePlan.child,
  activeParentPlan: plan.activePlan.parent,
  activeTab: plan.activeTab,
  data: plan.plans,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Plans));
