import React, { useRef } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { push } from 'connected-react-router';

import { HOME_URL } from '../';
import { Creators } from '../reducer';
import {
  Button,
  FileUploader,
  Form,
  FormDateTimePicker,
  FormInput,
  FormRow,
  FormSelect,
} from 'components';
import PlaylistPreview from '../../components/PlaylistPreview';

import placeholderVideo from 'assets/svg/placeholder-video.svg';
import binImg from 'assets/svg/bin.svg';

const styles = ({ palette: { primary, secondary, background } }) => ({
  addButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: '100%',
  },
  addButton: {
    textTransform: 'capitalize',
    width: 200,
  },
  addedExercise: {
    background: background.paper,
    borderRadius: 4,
    paddingLeft: 48,
    overflow: 'hidden',
    padding: 12,
    position: 'relative',
    height: 100,
  },
  addedExercises: {
    background: background.default,
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: '16px 32px',
  },
  browseButton: {
    backgroundColor: primary.main,
    borderRadius: 2,
    color: primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: 0.3,
    height: 49,
    width: 90,
    textTransform: 'capitalize',
  },
  button: {
    width: 'calc(50% - 12px)',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  deleteIcon: {
    alignItems: 'center',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    position: 'absolute',
    top: 4,
    left: 4,
    width: 24,
    '& > img': {
      height: 14,
      width: 14,
    },
  },
  fileUploaders: {
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      marginRight: 15,
    },
  },
  fileNotUploaded: {
    alignItems: 'center',
    border: `2px solid #d9d9d9`,
    borderStyle: 'dotted',
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    justifyContent: 'space-evenly',
    padding: 14,
  },
  form: {
    padding: '24px 0px',
    width: 680,
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  text: {
    whiteSpace: 'pre-line',
    color: primary.generalText,
    fontSize: 14,
    lineHeight: 1.5,
  },
  textS: {
    color: secondary.grayText,
    fontSize: 14,
    lineHeight: 1.2,
  },
  title: {
    color: primary.main,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 700,
  },
});

let FileUploaders = ({
  classes,
  workoutId,
  goTo,
  playlist,
  saveDraft,
  thumbnailRef,
  thumbnailWideRef,
}) => {
  console.log(playlist[0]?.exercise?.thumbnail?.location , "SURATI")
  console.log(playlist , "PLAYLIST")
  const Playlist = ({ redirectUrl }) => (
    <>
      {playlist?.length < 1 ? (
        <div className={classes.fileNotUploaded}>
          <img
            alt='placeholder'
            className={classes.placeholderImage}
            src={placeholderVideo}
          />
          
          <div className={classes.text}>Choose videos</div>
          <div
            className={classes.playlistPreview}
            onClick={() => {
              saveDraft({ playlist });
              goTo(redirectUrl);
            }}
          >
            <Button title='browse' className={classes.browseButton} />
          </div>
        </div>
      ) : (
        
        <PlaylistPreview
          label='Edit'
          length={playlist?.length || ''}
          onClick={() => {
            saveDraft({ playlist });
            goTo(redirectUrl);
          }}
          
          thumbnailUrl={playlist[0]?.exercise?.thumbnail?.location}
        />
      )
      
      
      }


    </>
  );

  return (
    <>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={thumbnailRef.current.url}
            onFileUploaded={({ id, url }) => {
              thumbnailRef.current = { id, url };
            }}
            title='Vertical Photo'
          />
          <div style={{ width: '100%' }}>
            <div className={classes.text} style={{ marginBottom: 4 }}>
              Playlist
            </div>
            <Playlist
              redirectUrl={`${HOME_URL}/add-exercises/playlist${
                workoutId ? '/' + workoutId : ''
              }`}
            />
          </div>
        </div>
      </FormRow>
      <FormRow containerClass={classes.formRow}>
        <div style={{ padding: '0px 72px' }}>
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={thumbnailWideRef.current.url}
            onFileUploaded={({ id, url }) => {
              thumbnailWideRef.current = { id, url };
            }}
            title='Horizontal Photo'
          />
        </div>
      </FormRow>
    </>
  );
};

FileUploaders = withStyles(styles)(FileUploaders);

const WorkoutForm = ({
  bodyArea,
  classes,
  difficulty,
  draft,
  equipment,
  error,
  goTo,
  isEdit = false,
  modality,
  movementPrep,
  muscles,
  onSubmit,
  pageTitle,
  processing,
  saveDraft,
  usageArea,
  workoutType,
}) => {
  const thumbnailRef = useRef(draft?.thumbnail || { id: '', url: '' });
  const thumbnailWideRef = useRef(draft?.thumbnailWide || { id: '', url: '' });

  const canPublish = (values) => {
    return (
      !!(draft?.playlist?.length > 0) &&
      !!thumbnailRef.current.url &&
      !!values.access &&
      !!values.activeSince &&
      !!values.description &&
      !!values.difficulty &&
      !!values.title &&
      !!(values.workoutType?.length > 0)
    );
  };

  const FormBody = () => {
    const { isValid, values } = useFormikContext();

    const save = (object) =>
      saveDraft({
        ...values,
        ...object,
        thumbnail: thumbnailRef.current,
        thumbnailWide: thumbnailWideRef.current,
      });

      // console.log(values?.workoutType , "WORK OUT TYPES")

      const result = values?.workoutType?.find(({ label }) => label === "Programs");

//       console.log(result?.label , "BIJIIII")


// const test = workoutType.find(label => label.label === 'Programs');

// console.log(test , "111")

      // if(workoutType.label === "programs"){
      //   console.log("HELLO WORLD")
      // }

    return (
      <Form
        error={error}
        containerClass={classes.form}
        pageTitle={pageTitle}
        showDivider={false}
      >
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>general info</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            label='title'
            name='title'
            placeholder='Enter a title...'
            rows={1}
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            label='description'
            name='description'
            placeholder='Enter a description...'
            rows={4}
          />
        </FormRow>
        <FileUploaders
          goTo={goTo}
          playlist={draft?.playlist || []}
          saveDraft={save}
          thumbnailRef={thumbnailRef}
          thumbnailWideRef={thumbnailWideRef}
          workoutId={draft?.id || ''}
        />
        
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>tags</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.inputs}>
            <div>
              <FormSelect
                height={50}
                label='Usage area'
                name='access'
                options={usageArea}
                width='100%'
              />
            </div>
            <div>
              <FormSelect
                height={50}
                label='Difficulty'
                name='difficulty'
                options={difficulty}
                width='100%'
                avoidSorting
              />
            </div>
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              closeMenuOnSelect={false}
              height={50}
              isMulti={true}
              label='Workout type'
              name='workoutType'
              options={workoutType}
              width='100%'
            />
          </div>
        </FormRow>
        {result?.label === "Programs" ? <div>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            label='Program Title'
            name='programTitle'
            placeholder='Program Title'
            rows={1}
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            label='Program Work Out Order'
            name='programWorkOutOrder'
            placeholder='Program Work Out Order'
            rows={1}
          />
        </FormRow>
        </div> : null}


      
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              closeMenuOnSelect={false}
              height={50}
              isMulti={true}
              label='Modality'
              name='modalities'
              options={modality}
              width='100%'
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              closeMenuOnSelect={false}
              height={50}
              isMulti={true}
              label='Movement Prep'
              name='movementPreps'
              options={movementPrep}
              width='100%'
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              closeMenuOnSelect={false}
              isMulti={true}
              label='Equipment'
              name='equipments'
              width='100%'
              options={equipment}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              closeMenuOnSelect={false}
              height={50}
              isMulti={true}
              label='Area of the body'
              name='bodyAreas'
              options={bodyArea}
              width='100%'
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              closeMenuOnSelect={false}
              height={50}
              isMulti={true}
              label='Muscles'
              name='muscles'
              options={muscles}
              width='100%'
            />
          </div>
        </FormRow>
        <div className={cx(classes.text, classes.formRow)}>Added Exercises</div>
        {draft.addedExercises.length > 0 && (
          <div className={classes.addedExercises}>
            {draft.addedExercises
              .sort((a, b) => {
                if (draft.orderedExerciseIds) {
                  const aIndex = draft.orderedExerciseIds.indexOf(a.id);
                  const bIndex = draft.orderedExerciseIds.indexOf(b.id);
                  return aIndex - bIndex;
                }

                return 0;
              })
              .map((exercise) => {
                return (
                  <div className={classes.addedExercise} key={exercise.id}>
                    <div
                      className={classes.deleteIcon}
                      onClick={() => {
                        save({
                          addedExercises: draft.addedExercises.filter(
                            (e) => e.id !== exercise.id
                          ),
                        });
                      }}
                    >
                      <img src={binImg} alt='delete' />
                    </div>
                    <div className={classes.subtitle}>{exercise.title}</div>
                    <div className={classes.text}>{exercise.description}</div>
                  </div>
                );
              })}
          </div>
        )}
        <div className={classes.addButtonContainer}>
          <Button
            className={classes.addButton}
            onClick={() => {
              save();
              goTo(
                `${HOME_URL}/add-exercises/add${
                  draft?.id ? '/' + draft.id : ''
                }`
              );
            }}
            title={`${
              draft.addedExercises.length < 1 ? 'Add' : 'Edit'
            } Exercises`}
          />
        </div>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>date of publishing</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormDateTimePicker label='Time of Publishing' name='activeSince' />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button
                disabled={!isValid}
                outlined
                // processing={processing}
                processingLabel='saving...'
                title='save draft'
                type='submit'
              />
            </div>
            <div className={classes.button}>
              <Button
                id='btnPublish'
                disabled={!canPublish(values)}
                // processing={processing}
                processingLabel='saving...'
                title='publish'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const transformValues = (values) => {
    let activeSince = null;
    const isPublisButtonClicked = window.event.submitter.id === 'btnPublish';

    if (canPublish(values) && isPublisButtonClicked) {
      activeSince = Number.isInteger(values.activeSince)
        ? new Date(parseInt(values.activeSince))
        : values.activeSince;
    }



    const extractValue = (item) => item.value;

    return {
      access: values.access || usageArea[0].value,
      activeSince,
      addedExercises: draft.addedExercises.map((e) => e.id),
      bodyAreas: values.bodyAreas?.map(extractValue) || [],
      description: values.description,
      difficulty: values.difficulty || difficulty[0].value,
      equipments: values.equipments?.map(extractValue) || [],
      modalities: values.modalities?.map(extractValue) || [],
      movementPreps: values.movementPreps?.map(extractValue) || [],
      muscles: values.muscles?.map(extractValue) || [],
      playlist: draft.playlist.map((playlistItem) => ({
        exercise: playlistItem.exercise.id,
        duration: playlistItem.duration || null,
        instructions: playlistItem.instructions || null,
        order: playlistItem.order,
        type: playlistItem?.type || 'standard',
      })),
      side: 'left',
      thumbnail: thumbnailRef.current.id || null,
      thumbnailWide: thumbnailWideRef.current.id || null,
      title: values.title,
      workoutTypes: values.workoutType?.map(extractValue) || [],
      programTitle : values.programTitle,
      programWorkOutOrder : values.programWorkOutOrder
    };
  };

  if (isEdit && !draft) return null;

  const validationSchema = object().shape({
    title: string().required('Required field'),
  });
  return (
    <Formik
      initialValues={
        draft || {
          access: '',
          activeSince: Date.now(),
          bodyAreas: [],
          description: '',
          equipments: [],
          modalities: [],
          movementPreps: [],
          muscles: [],
          title: '',
          workoutType: [],
        }
      }
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default connect(
  ({
    tags: {
      bodyArea,
      difficulty,
      equipment,
      modality,
      movementPrep,
      muscles,
      usageArea,
      workoutType,
    },
  }) => ({
    bodyArea,
    difficulty,
    equipment,
    modality,
    movementPrep,
    muscles,
    usageArea,
    workoutType,
  }),
  {
    goTo: push,
    updateAddedExercises: Creators.updateAddedExercises,
    saveDraft: Creators.saveDraft,
  }
)(withStyles(styles)(WorkoutForm));
