import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';
import { PrivacyPolicy as PrivacyPolicyBody } from 'components';

import headerImg from 'assets/img/header@2x.jpg';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 535,
    background: `url(${headerImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  headerOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(18, 29, 50, 0.62)',
    height: 535,
    width: 'calc(100% - 236px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  headerContainer: {
    paddingTop: 180,
  },
  title: {
    zIndex: 1,
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
    textAlign: 'center',
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Box>
        <Box className={classes.header}>
          <div className={classes.headerOverlay} />
          <Box
            display='flex'
            alignItems='center'
            flexDirection='column'
            className={classes.headerContainer}
          >
            <Typography className={classes.title} variant='h2'>
              PRIVACY POLICY
            </Typography>
          </Box>
        </Box>
        <Box>
          <PrivacyPolicyBody showTitle={false} />
        </Box>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;
