import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { useTable, useSortBy, useRowSelect, usePagination } from 'react-table';
import ReactPaginate from 'react-paginate';

import { Button, TableCheckbox } from 'components';
import { toCSV } from 'core';
import TableMenu from './TableMenu';
import { Creators, INITIAL_FILTER } from '../reducer';
import { Creators as ModalCreators } from 'modules/modal';
import { Creators as PaymentCreators } from 'modules/admin/dashboard/membership/plan';
import TopBar from './TopBar';
import Dots from 'components/icons/Dots';

import arrowDown from 'assets/svg/arrow-down.svg';
import arrowUp from 'assets/svg/arrow-up.svg';
import moment from 'moment';


const styles = (theme) => ({
  activeText: {
    color: theme.palette.secondary.dark,
    size: 14,
  },
  bottomControls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  dots: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  exportButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    width: 230,
  },
  headerCell: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 10,
    letterSpacing: '0.06em',
    padding: 8,
    textAlign: 'start',
    textTransform: 'uppercase',
  },
  headerRow: {
    height: 48,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    '& > li': {
      color: theme.palette.primary.generalText,
      cursor: 'pointer',
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: '0.06em',
      margin: 12,
    },
    '& li:first-child': {
      color: theme.palette.primary.main,
    },
    '& li:last-child': {
      color: theme.palette.primary.main,
    },
    '& > li.disabled': {
      color: 'rgba(74, 75, 107, 0.4)',
      cursor: 'initial',
    },
    '& > li.selected': {
      color: theme.palette.primary.main,
      cursor: 'initial',
    },
  },
  role: {
    fontSize: 10,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  roleAdmin: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  sortIcon: {
    marginLeft: 6,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    width: '100%',
  },
  tableRow: {
    background: theme.palette.background.paper,
    '& td': {
      color: theme.palette.primary.generalText,
      fontSize: 14,
      padding: 8,
    },
    '& td:first-child': {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    '& td:last-child': {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  text: {
    color: theme.palette.primary.dark,
    fontSize: 12,
    letterSpacing: '0.04em',
    marginRight: 3,
    textTransform: 'uppercase',
  },
  textGray: {
    color: 'rgba(74, 75, 107, 0.4)',
  },
});

const Members = ({
  classes,
  data,
  deleteUsers,
  filter,
  getPlans,
  getUsers,
  plans,
  setFilter,
  showModal,
}) => {
  const companyUsersData = data.filter((e)=> e.isCompanyAdmin === true);
  const [members, setMembers] = useState(companyUsersData);
  const theme = useTheme();
  const {
    currentPage,
    planFilter,
    statusFilter,
    searchFilter,
    searchTerm,
  } = filter;

  const filterData = () => {
    return companyUsersData.filter((item) => {
      return (
        (searchFilter && searchTerm
          ? (item[searchFilter] || '')
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : true) &&
        (statusFilter ? item.status === statusFilter : true) &&
        (planFilter ? item.membershipPlan === planFilter : true)
      );
    });
  };

  useEffect(() => {
    if (plans === null) {
      getPlans();
    }
    getUsers();
  }, [getPlans, getUsers, plans]);

  useEffect(() => {
    setMembers(filterData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        sortType: 'basic',
      },
      {
        Header: 'user name',
        accessor: (data) => [data.name, data.role],
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <div>{`${value[0] || 'John'}`}</div>
              <div
                className={cx(
                  classes.role,
                  value[1] === 'admin' ? classes.roleAdmin : ''
                )}
              >{`${value[1]}`}</div>
            </>
          );
        },
      },
      {
        Header: 'email address',
        accessor: 'email',
        sortType: 'basic',
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          if (value === 'Active')
            return <div className={classes.activeText}>{`${value}`}</div>;
          return value;
        },
      },
      {
        Header: 'subscription',
        //accessor: 'subscription',
        Cell: ({ cell: { value } }) => {
          return <div className={classes.link}>Enabled</div>;
        },
      },
      {
        Header: 'membership plan',
        accessor: 'membershipPlan',
        Cell: ({ cell: { value } }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: 'logins',
        accessor: 'logins',
      },
      {
        Header: 'last login',
        accessor: 'lastLogin',
        Cell: ({ cell: { value } }) => {
          return <div>{moment(value).format("LLL")}</div>;
        },
        // Cell: ({ cell: { value } }) => {
        //   return <div>{value}</div>;
        // },
        sortType: 'alphanumeric',

        // moment(new Date(userWhiteLabel?.expirationDate!)).format(
				// 	"DD.MM.YYYY",
      },
      {
        Header: 'registered',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => {
          return <div>{moment(value).format("LLL")}</div>;
        },
        sortType: 'alphanumeric',
      },
      {
        Header: 'is affiliate',
        // accessor: 'createdAt',
        // Cell: ({ cell: { value } }) => {
        //   return <div>{moment(value).format("LLL")}</div>;
        // },
        sortType: 'alphanumeric',
      },
      {
        Header: 'affiliate referrer',
        // accessor: 'createdAt',
        // Cell: ({ cell: { value } }) => {
        //   return <div>{moment(value).format("LLL")}</div>;
        // },
        sortType: 'alphanumeric',
      },
      {
        Header: 'value',
        accessor: 'value',
        sortType: 'basic',
        Cell: ({ cell: { value } }) => {
          const dollars = (value / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return <div>{dollars}</div>;
        },
      },
      {
        Header: 'transactions',
        accessor: 'transactions',
        Cell: ({ cell: { value } }) => {
          return <div className={classes.link}>{value}</div>;
        },
      },
      {
        Header: () => <Dots fill={theme.palette.primary.generalText} />,
        Cell: ({ cell: { value } }) => {
          return <TableMenu userId={value} />;
        },
        accessor: 'id',
        id: 'actions',
      },
    ],
    [classes, theme]
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      autoResetPage: false,
      data: members,
      initialState: { pageSize: 10, pageIndex: currentPage },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <TableCheckbox ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  if (plans === null) return null;

  const childPlans = plans
    .reduce((acc, current) => {
      const childPlans = current.plans.map((plan) => ({
        ...plan,
        parentPlan: current.title,
      }));
      return acc.concat(childPlans);
    }, [])
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const planSelect = {
    label: 'Plan',
    onChange: (value) => setFilter({ ...filter, planFilter: value }),
    options: [
      {
        label: 'All Memberships',
        value: '',
      },
    ].concat(
      childPlans.map((plan) => {
        const planValue = plan.parentPlan + '/' + plan.title;

        return {
          label: planValue,
          value: planValue,
        };
      })
    ),
    value: planFilter,
  };

  const statusSelect = {
    label: 'Status',
    onChange: (value) => setFilter({ ...filter, statusFilter: value }),
    options: [
      {
        label: 'All Statuses',
        value: '',
      },
      {
        label: 'premium',
        value: 'premium',
      },
      {
        label: 'freemium',
        value: 'freemium',
      },
    ],
    value: statusFilter,
  };

  const searchSelect = {
    onChange: (value) => setFilter({ ...filter, searchFilter: value }),
    options: [
      {
        label: 'None',
        value: '',
      },
      {
        label: 'Role',
        value: 'role',
      },
    ].concat(
      columns
        .filter(
          (column) => typeof column.Header === 'string' && column.accessor
        )
        .map((column) => ({
          label: column.Header,
          value: typeof column.accessor === 'string' ? column.accessor : 'name',
        }))
    ),
    value: searchFilter,
  };

  const searchInput = {
    value: searchTerm,
    onChange: (value) => setFilter({ ...filter, searchTerm: value }),
  };

  return (
    <div
      style={{
        width: '100%',
        marginBottom: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <Helmet>
        <title>Team Accounts</title>
      </Helmet>
      <div style={{ position: 'absolute', width: '100%' }}>
        <TopBar
          onBulkDelete={() =>
            showModal(
              'deleteConfirmation',
              () => deleteUsers(selectedFlatRows.map((d) => d.original.id)),
              'Are you sure you want to delete the user(s)?'
            )
          }
          onClearAll={() => setFilter(INITIAL_FILTER)}
          onSearch={() => {
            setFilter({ ...filter, currentPage: 0 });
            setMembers(filterData());
            gotoPage(0);
          }}
          planSelect={planSelect}
          statusSelect={statusSelect}
          searchInput={searchInput}
          searchSelect={searchSelect}
          showDelete={selectedFlatRows.length > 0}
        />
      </div>
      <div style={{ marginTop: 80, padding: '0px 16px' }}>
        <table className={classes.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className={classes.headerRow}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className={classes.headerCell}>
                      {column.render('Header')}
                      <span className={classes.sortIcon}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={arrowDown} alt='arrow down' />
                          ) : (
                            <img src={arrowUp} alt='arrow up' />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={classes.tableRow} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <>
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={classes.bottomControls}>
          <Button
            className={classes.exportButton}
            onClick={() =>
              toCSV({
                dataJSON: members,
                fields: [
                  'id',
                  'name',
                  'firstName',
                  'lastName',
                  'role',
                  'email',
                  'status',
                  'logins',
                  `lastLogin`,
                  'value',
                  'transactions',
                  'createdAt',
                  '',
                ],
                fileName: 'members.csv',
              })
            }
            title='export members .csv'
          />


          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', marginRight: 30 }}
            >
              <span className={classes.text}>
                total {members.length} items.
              </span>
              <span className={cx(classes.text, classes.textGray)}>
                showing from 1 of {pageCount} pages
              </span>
            </div>
            <ReactPaginate
              activeClassName={classes.activePage}
              breakLabel={'...'}
              containerClassName={classes.pagination}
              marginPagesDisplayed={2}
              nextLabel={'NEXT'}
              onPageChange={({ selected }) => {
                gotoPage(selected);
                setFilter({ ...filter, currentPage: selected });
              }}
              pageClassName={classes.page}
              pageCount={pageCount}
              pageRangeDisplayed={5}
              previousLabel={'PREV'}
              subContainerClassName={'pages pagination'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ membership, plan }) => ({
  data: membership.users,
  filter: membership.filter,
  plans: plan.plans,
});

const mapDispatchToProps = {
  getUsers: Creators.getUsers,
  deleteUsers: Creators.deleteUsers,
  setFilter: Creators.setFilter,
  showModal: ModalCreators.showModal,
  getPlans: PaymentCreators.getPlanGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Members));
