import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';

import { Button, Link } from 'components';
import { Creators } from '../reducer';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import mediaNotUploaded from 'assets/img/media-not-uploaded.png';
import QuestionAndAnswer from './QuestionAndAnswer';
import Result from './Result';
import Assessment from './Assessment';
import { Line } from 'rc-progress';

const styles = ({theme , breakpoints ,height }) => ({
 
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      // paddingLeft : "15px",
      // paddingRight : "15px",
      paddingTop : "40px",
      paddingBottom : "40px",
      // height : "1265px",
    },
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft : "15px",
      paddingRight : "15px",
      paddingTop : "40px",
      paddingBottom : "40px",
    },
  },
  singleParagraph1: {
    paddingTop: '25px',
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight : "25px",
  },
  singleParagraph: {
    color: '#63646F',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight : "25px",

  },
  button: {
    width: '240px',
    height: '64px',
    marginTop: '30px',
    textTransform: "capitalize",
    fontSize : "18px",
    [breakpoints.down('xs')]: {
      marginTop: '36px',
      width :"100%",
      fontSize : "16px",
      textTransform: "uppercase",
      // boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",



     },

    //  @media only screen and (max-width: 600px) {
    //   body {
    //     background-color: lightblue;
    //   }
    // }
  },
  start:{
    [breakpoints.down('xs')]: {
     display :"flex",
     justifyContent : "center",
     marginTop : "5px"
    },
  },
  floatingButtonContainer:{
   
    [breakpoints.down('xs')]: {
      display : "flex",
    justifyContent :"center",
    alignItems : "center",
    position : "fixed",
    bottom : 0,
    backgroundColor : "white",
    width : "100%",
    padding : "15px",
    '@media (min-height: 1265px)': {
      position : "absolute",
    }
    },
   
  },
});

const Tasks = ({
  classes,
  data,
  deleteTask,
  activeTask,
  getUserAssessment,
  setActiveTask,
  showModal,
  percents,
  user,
  results 

}) => {
  const theme = useTheme();


  useEffect(() => {
    getUserAssessment();
  }, [getUserAssessment]);
  


  const onDelete = (id) => {
    deleteTask(id);
  };

 

  const [openQuestionsAndAnswers, setOpenQuestionsAndAnswers] = useState(false);

  const closeQuestionsAndAnswersPopUp = () => {
    setOpenQuestionsAndAnswers(false);
  };

  const openQuestionsAndAnswersPopUp = () => {
    setOpenQuestionsAndAnswers(true);
  };

  const assessmentResult = results || user.assessmentScore




  return (
    
    <div className={classes.mainContainer}>
      {assessmentResult === null ?<div className={classes.emptyContainer} > <div className={classes.singleParagraph}>
      The assessment is a 14-step assessment that will test how each of the major joints in your body moves.

      </div>
      <div className={classes.singleParagraph1}>
      The assessment will test how your muscles, fascia, ligaments, and other connective tissues are moving.

      </div>
      <div className={classes.singleParagraph1}>
      The assessment will help you determine what areas of your body are moving well, and which areas you should dedicate extra mobility time to. The assessment also serves as a benchmark so that you can check back and track your progress. We recommend repeating the assessment once per month.

      </div>
      <div className={classes.singleParagraph1}>
      Keep in mind that the range of motion that you are able to achieve is important, but also how you feel while moving is equally important. So take a mental note on the differences you feel when moving and while you are performing the assessment. Do you have less of a pinch, less stiffness, better ability to activate specific muscles, and smoother movement?

      </div>
      <div className={classes.singleParagraph1}>
      Also, remember that making lasting mobility changes takes time. It has taken years and even decades for you to accumulate the tightness in your muscles and joints. You will see results quickly, but also know that it will take time. The key to making lasting changes is consistency over time.

      </div>
      <div  className={classes.singleParagraph1} >
      Directions: The entire test will take you about 10 minutes to complete. Watch each video. Perform the test on yourself.

        </div>
      <div  className={classes.singleParagraph1} >
      We recommend videotaping yourself if you want to be extra accurate. You will then enter your results for each side of your body for each test. You must complete all 14 tests in order to generate a mobility score. Take the assessment as often as you would like.

        </div>
      <div className={classes.start} >
        {/* <div className={classes.floatingButtonContainer} > */}
        <Button
          onClick={openQuestionsAndAnswersPopUp}
          className={classes.button}
          title='Start Assessment'
        />
        {/* </div> */}
      </div>
    
       </div> :  <Result/> }
      
      <Assessment close={closeQuestionsAndAnswersPopUp} onClose={closeQuestionsAndAnswersPopUp}
        open={openQuestionsAndAnswers} />
    </div>
  );
};

const mapDispatchToProps = {
  deleteTask: Creators.deleteTask,
  getUserAssessment: Creators.getUserAssessment,
  setActiveTask: Creators.setActiveTask,
};

const mapStateToProps = (
  { auth: { user } , userAssessment },
  
) => {
  return {
    user,
    data: userAssessment.tasks,
    results : userAssessment.results

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Tasks));

