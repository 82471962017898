import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='2 2 16 10' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.321 0.121848L15.8145 4.98525C15.9186 5.0772 15.9781 5.20936 15.9781 5.34816V12.6998C15.9781 12.9676 15.7611 13.1846 15.4934 13.1846H4.50622C4.23849 13.1846 4.02148 12.9676 4.02148 12.6998V5.34816C4.02148 5.20936 4.08094 5.07718 4.18485 4.98525L9.6784 0.121848C9.8618 -0.0406931 10.1376 -0.0405386 10.321 0.121848ZM4.99096 12.2151H15.0086V5.56645L9.99978 1.13217L4.99096 5.56645V12.2151Z'
        fill={fill}
      />
      <path
        d='M11.6323 7.93359H8.3685C8.10077 7.93359 7.88379 8.1506 7.88379 8.41833V12.7001H8.85324V8.90304H11.1475V12.7001H12.1169H12.1171V8.41833C12.117 8.1506 11.9001 7.93359 11.6323 7.93359Z'
        fill={fill}
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='0.0214844'
        y='0'
        width='19.9566'
        height='21.1846'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
