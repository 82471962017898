import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from 'core/constants';
import { atom } from 'recoil';

let hasStartedInitializing = false;

export const initializeMixpanel = () => {
  return new Promise((resolve) => {
    if (hasStartedInitializing) {
      const interval = setInterval(() => {
        if (mixpanel.__loaded) {
          clearInterval(interval);
          resolve();
        }
      }, 300);
    }

    hasStartedInitializing = true;

    mixpanel.init(MIXPANEL_TOKEN, {
      loaded: () => {
        console.log('mixpanel loaded');
        resolve();
      },
      debug: process.env.NODE_ENV !== 'production',
    });
  });
};

export const MixpanelEvents = {
  pageViewed: 'Page Viewed',
  loggedIn: 'Logged In',
  webRegistrationCompleted: 'Web Registration Completed',
  viewedGettingStartedArticle: 'Viewed Getting Started Article',
  viewedWorkoutPreview: 'Viewed Workout Preview',
  exerciseVideoStarted: 'Exercise Video Started',
  watched90PercentOfExerciseVideo: 'Watched 90% of Exercise Video',
  workoutStarted: 'Workout Started',
  completed90PercentOfWorkout: 'Completed 90% of Workout',
  quitWorkout: 'Quit Workout',
  appliedFilter: 'Applied Filter',
  addedExerciseToFavorites: 'Added Exercise to Favorites',
  addedWorkoutToFavorites: 'Added Workout to Favorites',
  removedExerciseFromFavorites: 'Removed Exercise from Favorites',
  removedWorkoutFromFavorites: 'Removed Workout from Favorites',
  inviteLinkCopied: 'Invite Link Copied',
  inviteCodeCopied: 'Invite Code Copied',
  pressedInviteFriendsButton: 'Pressed Invite Friends Button',
  pressedInviteFriendsFacebookButton: 'Pressed Invite Friends Facebook Button',
  pressedInviteFriendsTwitterButton: 'Pressed Invite Friends Twitter Button',
  pressedInviteFriendsEmailButton: 'Pressed Invite Friends Email Button',
  viewedReferralLandingPage: 'Viewed Referral Landing Page',
  pressedRedeemNowButtonLandingPage: 'Pressed Redeem Now Button - Landing Page',
  planUpgraded: 'Plan Upgraded',
  freeTrialStarted: 'Free Trial Started',
  planDowngraded: 'Plan Downgraded',
  changedEmail: 'Changed Email',
  changedPassword: 'Changed Password',
  deletedAccount: 'Deleted Account',
};

export const MixpanelIncrementProperties = {
  totalExercisesStarted: 'totalExercisesStarted',
  totalExercisesCompleted: 'totalExercisesCompleted',
  totalWorkoutsStarted: 'totalWorkoutsStarted',
  totalWorkoutsCompleted: 'totalWorkoutsCompleted',
};

export const identify = async (id) => {
  await initializeMixpanel();
  mixpanel.identify(id);
};

export const setSuperProperty = async (properties) => {
  await initializeMixpanel();
  mixpanel.register(properties);
};

const receivedTrackEvents = {};
let trackEventsTimeout = null;
export const track = async (event, properties) => {
  if (receivedTrackEvents[event]) {
    clearTimeout(trackEventsTimeout);
    console.log('debounced', event);
  }

  receivedTrackEvents[event] = true;
  trackEventsTimeout = setTimeout(() => {
    receivedTrackEvents[event] = false;
    (async () => {
      await initializeMixpanel();
      mixpanel.track(event, properties);
    })();
  }, 300);
};

const receivedIncrementEvents = {};
export const increment = async (property, value = 1) => {
  if (receivedIncrementEvents[property]) {
    console.log('skipped', property);
    return;
  }

  receivedIncrementEvents[property] = true;
  setTimeout(() => {
    receivedIncrementEvents[property] = false;
  }, 300);

  await initializeMixpanel();

  mixpanel.people.increment(property);
};

export const mixpanelAtoms = {
  currentWorkoutPercentageCompleted: atom({
    key: 'currentWorkoutPercentageCompleted',
    default: 0,
  }),
  currentListTitle: atom({
    key: 'currentListTitle',
    default: '',
  }),
  currentFavoriteTab: atom({
    key: 'currentFavoriteTab',
    default: '',
  }),
  currentFilterSelection: atom({
    key: 'currentFilterSelection',
    default: [],
  }),
};
