import React from 'react';

export default ({ fill }) => (
  <svg
    width='10'
    height='6'
    viewBox='0 0 10 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='M1.41421 6C0.523309 6 0.077142 4.92286 0.707107 4.29289L4.29289 0.707107C4.68342 0.316583 5.31658 0.316583 5.70711 0.707107L9.29289 4.29289C9.92286 4.92286 9.47669 6 8.58579 6L1.41421 6Z'
    />
  </svg>
);
