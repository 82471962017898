import { all, call, takeEvery,put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { parseError } from 'core';
import { Types } from './reducer';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* userDeleted(action) {
  yield call(toastr.success, 'Info', 'User(s) deleted');
}

export function* userUpdated(action) {
  yield call(toastr.success, 'Info', 'User updated');
}

export function* emailSent(action) {
  yield call(toastr.success, 'Info', 'Email sent');
}

export function* companyAdminCreated(action) {
  yield call(toastr.success, 'Info', 'Company user created');
  yield put(push('/admin/dashboard/membership/team-accounts'));

}

export function* updateCompanyAdminSuccess(action) {
  yield call(toastr.success, 'Info', 'Company updated');
  yield put(push('/admin/dashboard/membership/team-accounts'));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.RESEND_EMAIL_SUCCESS, emailSent),
    takeEvery(Types.DELETE_USER_SUCCESS, userDeleted),
    takeEvery(Types.DELETE_USERS_SUCCESS, userDeleted),
    takeEvery(Types.UPDATE_USER_SUCCESS, userUpdated),
    takeEvery(Types.CREATE_COMPANY_ADMIN_SUCCESS, companyAdminCreated),
    takeEvery(Types.UPDATE_COMPANY_ADMIN_SUCCESS, updateCompanyAdminSuccess),
    // Consider pattern matching against '..._FAIL' to make this more generic.
    takeEvery(Types.GET_USERS_FAIL, requestFailed),
    takeEvery(Types.DELETE_USER_FAIL, requestFailed),
    takeEvery(Types.DELETE_USERS_FAIL, requestFailed),
  ]);
}
