import React from 'react';
import { NavBar } from '../../../index';
import { HOME_URL } from '../index';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Button, Form, FormInput, FormRow } from 'components';
import { object, string } from 'yup';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Creators } from '../reducer';

const useStyles = makeStyles(
  ({ breakpoints, palette: { background, primary, secondary } }) => ({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      position: 'relative',
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 80,
      padding: '10px 0px',
    },
    button: {
      width: 'calc(50% - 12px)',
      [breakpoints.down('sm')]: {
        margin: '10px 0px',
        width: '100%',
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20,
      width: '60%',
    },
    form: {
      padding: '24px 0px',
    },
    formRow: {
      padding: '8px 24px',
      margin: 0,
    },
    title: {
      color: primary.main,
      fontSize: 10,
      lineHeight: 1.2,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  })
);

function NotificationForm({
  processing = false,
  goTo,
  isEdit,
  id,
  movementMoment,
  update,
  create,
}) {
  const classes = useStyles();

  const validationSchema = object().shape({
    title: string().required('Required field'),
    message: string().required('Required field'),
  });

  // const createNewNotification = (values) => {
  //   console.log('new notification', values);
  // };

  // const editNotification = (values) => {
  //   console.log('edit notification with ID', id, values);
  // };

  return (
    <div className={classes.container}>
      <NavBar
        title={`${isEdit ? 'Edit ' : 'Create New'} Notification`}
        backUrl={HOME_URL}
      />
      <div className={classes.content}>
        <Formik
          initialValues={movementMoment || { title: '', message: '' }}
          onSubmit={(values) => {
            if (isEdit) {
              update({ id, moment: values });
            } else {
              create(values);
            }
          }}
          validationSchema={validationSchema}
        >
          {({ errors }) => (
            <Form
              error={errors}
              containerClass={classes.form}
              pageTitle='Create New Notification'
              showDivider={false}
            >
              <FormRow containerClass={classes.formRow}>
                <FormInput
                  error={errors}
                  label='title'
                  name='title'
                  placeholder='Add title'
                />
              </FormRow>
              <FormRow containerClass={classes.formRow}>
                <FormInput
                  error={errors}
                  label='message'
                  name='message'
                  placeholder='Add message'
                  rows={4}
                />
              </FormRow>
              <FormRow containerClass={classes.formRow}>
                <div className={classes.buttons}>
                  <div className={classes.button}>
                    <Button
                      outlined
                      processing={processing}
                      processingLabel='saving...'
                      title='cancel'
                      type='button'
                      onClick={() => goTo(HOME_URL)}
                    />
                  </div>
                  <div className={classes.button}>
                    <Button
                      id='btnPublish'
                      processing={processing}
                      processingLabel='saving...'
                      title={isEdit ? 'save' : 'publish'}
                      type='submit'
                    />
                  </div>
                </div>
              </FormRow>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default connect(
  (
    { movementMoment },
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    id,
    movementMoment: movementMoment.reminders.filter((r) => r.id === id)[0],
  }),
  {
    goTo: push,
    update: Creators.updateMovementMoment,
    create: Creators.createMovementMoment,
  }
)(NotificationForm);
