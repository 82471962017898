import { call, delay, fork, put, select, take } from 'redux-saga/effects';

import {
  sagas as authSagas,
  Creators as authCreators,
  Types as authTypes,
} from 'modules/auth';

import { sagas as membershipSagas } from 'modules/admin/dashboard/membership/members';
import { sagas as planSagas } from 'modules/admin/dashboard/membership/plan';
import { sagas as exerciseCreatorSagas } from 'modules/admin/dashboard/programming/exercise-creator';
import { sagas as workoutCreatorSagas } from 'modules/admin/dashboard/programming/workout-creator';
import { sagas as paymentSagas } from 'modules/dashboard/payment';
import { sagas as taskSagas } from 'modules/admin/dashboard/assessment';
import { sagas as couponSaga } from 'modules/admin/dashboard/membership/coupons';
import { sagas as momentSaga } from 'modules/admin/dashboard/notifications/movementMoments';
import { sagas as recommendationSaga } from 'modules/admin/dashboard/notifications/recommendations';
import { sagas as remindersSaga } from 'modules/admin/dashboard/notifications/reminders';
import { sagas as tagSaga } from 'modules/admin/dashboard/programming/tags';

import { Creators as AppCreators, Types as AppTypes } from 'modules/dashboard';
import { Creators as tagCreators } from 'modules/admin/dashboard/programming/tags';
import { Creators as exerciseCreatorCreators } from 'modules/admin/dashboard/programming/exercise-creator';
import { Creators as exerciseCreators } from 'modules/dashboard/exercises';
import { Creators as workoutCreatorCreators } from 'modules/admin/dashboard/programming/workout-creator';
import { Creators as workoutCreators } from 'modules/dashboard/workouts';
import { REFRESH_TOKEN_INTERVAL_IN_SECONDS } from './constants';
import { refreshToken as refreshTokenUtil } from './utils/auth';

function* fetchInitData() {
  const {
    auth: {
      persona,
      user: { role },
    },
  } = yield select();

  if (role === 'admin') {
    yield put(exerciseCreatorCreators.getExercises());
    yield put(workoutCreatorCreators.getWorkouts());
    yield put(tagCreators.getWorkoutTypes());
  }

  if (persona === 'user' || role === 'user') {
    yield put(exerciseCreators.getExercises());
    yield put(workoutCreators.getWorkouts());
  }

  yield put(tagCreators.getMuscles());
  yield put(tagCreators.getBodyArea());
  yield put(tagCreators.getEquipment());
  yield put(tagCreators.getModality());
  yield put(tagCreators.getMovementPrep());
}

function* refreshToken() {
  while (true) {
    try {
      yield call(refreshTokenUtil);
    } catch (exception) {
      yield put(authCreators.signOut);
    }
    yield delay(REFRESH_TOKEN_INTERVAL_IN_SECONDS * 1000);
  }
}

function* initializeApp() {
  yield put(authCreators.me());
  yield take([
    authTypes.ME_SUCCESS,
    authTypes.SIGN_IN_SUCCESS,
    authTypes.SIGN_IN_WITH_FB_SUCCESS,
    authTypes.SIGN_UP_SUCCESS,
  ]);
  yield call(fetchInitData);

  yield delay(5000);
  yield call(refreshToken);

  while (true) {
    yield take(
      authTypes.SIGN_IN_SUCCESS,
      authTypes.SIGN_IN_WITH_FB_SUCCESS,
      authTypes.SIGN_UP_SUCCESS
    );
    yield call(fetchInitData);
  }
}

function* refreshAppData() {
  while (true) {
    yield delay(120000);
    const state = yield select();

    const isAdminRoute =
      state.router.location.pathname.slice(0, 6) === '/admin';

    // Performs the check when you're not on an admin route and
    // you're logged in with the 'user' persona.
    if (
      !isAdminRoute &&
      state.auth.user.role &&
      state.auth.persona === 'user'
    ) {
      const {
        exercise: { exercises },
        workout: { workouts },
      } = state;

      const sortedExercises = exercises.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      const sortedWorkouts = workouts.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      if (sortedExercises[0]?.updatedAt && sortedWorkouts[0]?.updatedAt) {
        const requestObj = {
          exercises: {
            total: sortedExercises?.length || 0,
            updated: sortedExercises[0].updatedAt || '',
          },
          workouts: {
            total: sortedWorkouts.length || 0,
            updated: sortedWorkouts[0].updatedAt || '',
          },
        };

        yield put(AppCreators.updateCheck(requestObj));

        const action = yield take([
          AppTypes.UPDATE_CHECK_SUCCESS,
          AppTypes.UPDATE_CHECK_FAIL,
        ]);

        if (action.type === AppTypes.UPDATE_CHECK_SUCCESS) {
          const { exercises, workouts } = action.payload.data;

          if (exercises) {
            yield put(exerciseCreators.getExercises());
          }

          if (workouts) {
            yield put(workoutCreators.getWorkouts());
          }
        }
      }
    }
  }
}

export default function* root() {
  yield fork(authSagas);
  yield fork(membershipSagas);
  yield fork(planSagas);
  yield fork(paymentSagas);
  yield fork(taskSagas);
  yield fork(exerciseCreatorSagas);
  yield fork(workoutCreatorSagas);
  yield fork(couponSaga);
  yield fork(momentSaga);
  yield fork(recommendationSaga);
  yield fork(remindersSaga);
  yield fork(initializeApp);
  yield fork(refreshAppData);
  yield fork(tagSaga);
}
