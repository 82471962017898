import React from 'react';
import { withStyles } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';

const styles = ({ palette: { primary } }) => ({
  container: {},
});

const SimpleDialog = ({ classes, children, open, onClose }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      {children}
    </Dialog>
  );
};

export default withStyles(styles)(SimpleDialog);
