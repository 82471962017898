import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 12 15' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.41371 3.46831C8.36998 3.46831 9.14787 2.69043 9.14787 1.73416C9.14787 0.777886 8.36998 0 7.41371 0C6.45745 0 5.67956 0.777886 5.67956 1.73416C5.67956 2.69043 6.45745 3.46831 7.41371 3.46831ZM7.41371 1.1561C7.73243 1.1561 7.99177 1.41544 7.99177 1.73416C7.99177 2.05288 7.73243 2.31221 7.41371 2.31221C7.095 2.31221 6.83566 2.05288 6.83566 1.73416C6.83566 1.41544 7.095 1.1561 7.41371 1.1561ZM10.2018 4.65163L11.9949 6.83693L10.0409 9.39718L9.14787 8.71572V14.7981H7.99177V10.6073H7.41372C6.92745 10.6073 6.48759 10.4058 6.17237 10.0823L0.0718892 5.17357H0.0146484V4.01746H8.8611C9.38248 4.01746 9.87111 4.24857 10.2018 4.65163ZM5.67956 5.17357V8.2019L1.91612 5.17357H5.67956ZM7.99177 9.45115V6.32967H9.14787V8.66175L10.5206 6.8629L9.30796 5.38492C9.19777 5.25056 9.03486 5.17357 8.8611 5.17357H6.83566V8.8731C6.83566 9.01016 6.88376 9.13605 6.96381 9.23528L6.99576 9.26091L6.99135 9.26645C7.09692 9.3798 7.24696 9.45115 7.41371 9.45115H7.99177Z'
      fill={fill}
    />
  </svg>
);
