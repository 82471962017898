import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InputMask from 'react-input-mask';
import { push } from 'connected-react-router';

import {
  Button,
  Checkbox,
  FormRow,
  SearchInput,
  SimpleDialog,
} from 'components';
import { NavBar, TagFilter } from 'modules/admin/dashboard';
import { HOME_URL } from '../';
import { Creators } from '../reducer';

import dragIndicator from 'assets/svg/drag-indicator.svg';
import close from 'assets/svg/close-24.svg';
import isUndefined from 'lodash/isUndefined';

const styles = ({ palette: { primary, secondary, background } }) => ({
  addButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addButton: {
    textTransform: 'capitalize',
    fontSize: 16,
    height: 40,
    width: 100,
  },
  button: {
    textTransform: 'capitalize',
    fontSize: 16,
    height: 45,
    width: 160,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 40,
    width: '100%',
    '& > button:first-child': {
      marginRight: 16,
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    background: background.paper,
    borderRadius: 6,
    marginTop: 100,
    padding: 32,
    width: 664,
  },
  duration: {
    background: primary.dark,
    borderRadius: 2,
    bottom: 5,
    color: primary.contrastText,
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: `2px 5px`,
    position: 'absolute',
    right: 5,
  },
  exercise: {
    background: background.default,
    borderRadius: 2,
    display: 'flex',
    height: 80,
    marginBottom: 10,
    position: 'relative',
    '& > img:first-child': {
      alignSelf: 'center',
      display: 'flex',
      left: -20,
      position: 'absolute',
    },
    '& > img:nth-child(2)': {
      alignSelf: 'center',
      cursor: 'pointer',
      display: 'flex',
      right: -25,
      position: 'absolute',
    },
  },
  exerciseBody: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
  },
  exerciseBodySimplified: {
    alignItems: 'center',
    background: background.default,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 32,
    width: '100%',
    '& > div:first-child': {
      marginTop: -6,
    },
  },
  flex: {
    display: 'flex',
    width: '100%',
  },
  flexBetween: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: 16,
    },
  },
  input: {
    border: `1px solid ${primary.gray}`,
    height: 45,
    padding: '14px 16px',
    '&:focus': {
      outline: `1px solid ${primary.main}`,
    },
  },
  mb: {
    marginBottom: 16,
  },
  mt: {
    marginTop: 16,
  },
  mt2: {
    marginTop: 24,
  },
  mt3: {
    marginTop: 32,
  },
  mr: {
    marginRight: 8,
  },
  offeredExercises: {
    maxHeight: 400,
    overflow: 'auto',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: 10,
  },
  text: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    lineHeight: 1.2,
  },
  thumbnail: {
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    width: 120,
    '& > img': {
      height: '100%',
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
      objectFit: 'cover',
      width: 120,
    },
  },
  title: {
    color: primary.main,
    fontSize: 12,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  titleS: {
    color: secondary.grayText,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  primaryColor: {
    color: primary.main,
  },
  table: {
    '& > *:first-child': {
      width: 220,
    },
    '& > *:nth-child(2)': {
      width: 70,
    },
    '& > *:last-child': {
      width: 150,
    },
  },
});

const HH_MM_SS_REGEX = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
const PATTERN = 'h:mm:ss';

const convertSecondsToTime = (secondsFloat) => {
  if (!secondsFloat) return PATTERN;

  let seconds = Math.floor(secondsFloat);
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
};

const convertTimeToSeconds = (time) => {
  const [h, mm, ss] = time.split(':');

  return parseInt(h) * 3600 + parseInt(mm) * 60 + parseInt(ss);
};

let VideoThumbnail = ({ classes, exercise }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <div className={classes.thumbnail} onClick={() => setIsDialogOpen(true)}>
        <img src={exercise.thumbnail.location} alt='thumbnail' />
        <div className={classes.duration}>
          {convertSecondsToTime(exercise?.video?.additionalData?.duration)}
        </div>
      </div>
      <SimpleDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <div className={classes.videoPreview}>
          <video preload='metadata' controls style={{ width: 450 }}>
            <source
              src={`${exercise?.video?.streamUrl}#t=0.1`}
              type='video/mp4'
            />
          </video>
          <div style={{ padding: 16 }}>
            <div className={classes.subtitle2}>{exercise.title}</div>
            <div className={classes.text}>{exercise.description}</div>
          </div>
        </div>
      </SimpleDialog>
    </>
  );
};

VideoThumbnail = withStyles(styles)(VideoThumbnail);

let SelectedExercise = ({ classes, exercise, onDelete, onUpdate }) => {
  const mask = [/[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  const isRepBased = isUndefined(exercise.isRepBased)
    ? exercise.type === 'rep-based'
    : !!exercise.isRepBased;

  return (
    <div className={classes.exercise}>
      <img src={dragIndicator} alt='drag indicator' />
      <img onClick={() => onDelete(exercise)} src={close} alt='delete' />
      <VideoThumbnail exercise={exercise} />
      <div className={cx(classes.exerciseBody, classes.table)}>
        <div className={classes.subtitle}>{exercise.title}</div>
        <Checkbox
          checked={isRepBased}
          onCheck={() => {
            if (isRepBased) {
              onUpdate({ ...exercise, instructions: '', isRepBased: false });
            } else {
              onUpdate({ ...exercise, duration: PATTERN, isRepBased: true });
            }
          }}
        />
        {isRepBased ? (
          <input
            className={classes.input}
            onChange={({ target: { value } }) =>
              onUpdate({ ...exercise, instructions: value })
            }
            value={exercise?.instructions || ''}
          />
        ) : (
          <InputMask
            className={classes.input}
            mask={mask}
            maskPlaceholder={PATTERN}
            onBlur={() => {
              if (!exercise?.duration.match(HH_MM_SS_REGEX))
                onUpdate({ ...exercise, duration: '' });
            }}
            onChange={({ target: { value } }) =>
              onUpdate({ ...exercise, duration: value })
            }
            value={exercise?.duration || ''}
          />
        )}
      </div>
    </div>
  );
};

let SelectedExerciseSimplified = ({ classes, exercise, onDelete }) => {
  return (
    <div className={classes.exercise}>
      <img
        onClick={() => onDelete(exercise)}
        src={close}
        alt='delete'
        style={{ cursor: 'pointer', left: 'initial', right: -25 }}
      />
      <VideoThumbnail exercise={exercise} />
      <div className={cx(classes.exerciseBody, classes.table)}>
        <div className={classes.text}>{exercise.title}</div>
      </div>
    </div>
  );
};

SelectedExercise = withStyles(styles)(SelectedExercise);
SelectedExerciseSimplified = withStyles(styles)(SelectedExerciseSimplified);

let SelectedExercises = ({
  classes,
  onDelete,
  selectedExercises,
  setSelectedExercises,
  type,
}) => {
  if (selectedExercises.length < 1) return null;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    borderRadius: 3,
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedExercises,
      result.source.index,
      result.destination.index
    );

    setSelectedExercises(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <div
            {...provided.droppableProps}
            className={classes.mt3}
            ref={provided.innerRef}
          >
            <FormRow>
              <div className={cx(classes.flex)}>
                <div
                  className={cx(
                    classes.titleS,
                    classes.primaryColor,
                    classes.mr
                  )}
                >
                  edit exercises
                </div>
                <div className={classes.titleS}>{`(${
                  selectedExercises.length
                } ${
                  selectedExercises.length > 1 ? 'exercises' : 'exercise'
                } added)`}</div>
              </div>
            </FormRow>
            <div
              className={cx(
                classes.table,
                classes.flex,
                classes.mt2,
                classes.mb
              )}
            >
              <div className={classes.titleS} style={{ width: 354 }}>
                Exercise
              </div>
              {type === 'playlist' && (
                <>
                  <div className={classes.titleS}>Rep Based</div>
                  <div className={classes.titleS}>Instructions/Time</div>
                </>
              )}
            </div>
            {selectedExercises.map((item, index) => (
              <Draggable
                key={item.position}
                draggableId={item.position.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {type === 'add' ? (
                      <SelectedExerciseSimplified
                        exercise={item}
                        onDelete={onDelete}
                      />
                    ) : (
                      <SelectedExercise
                        exercise={item}
                        onDelete={onDelete}
                        onUpdate={(updatedExercise) => {
                          setSelectedExercises(
                            selectedExercises.map((exercise) => {
                              if (
                                exercise.position !== updatedExercise.position
                              ) {
                                return exercise;
                              }

                              return { ...updatedExercise };
                            })
                          );
                        }}
                      />
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SelectedExercises = withStyles(styles)(SelectedExercises);

let OfferedExercise = ({ checked, classes, exercise, onCheck }) => (
  <div className={classes.exercise}>
    <VideoThumbnail exercise={exercise} />
    <div className={classes.exerciseBodySimplified}>
      <div className={classes.text}>{exercise.title}</div>
      <Checkbox checked={checked} onCheck={onCheck} />
    </div>
  </div>
);

OfferedExercise = withStyles(styles)(OfferedExercise);

let OfferedExercises = ({
  checkedExercises,
  classes,
  offeredExercises,
  selectedExercises,
  setCheckedExercises,
  setOfferedExercises,
  setSelectedExercises,
  type,
}) => {
  if (offeredExercises?.length < 1) return null;

  const moveExercisesToSelected = () => {
    const selectedItems = offeredExercises.filter((e) =>
      checkedExercises.includes(e.id)
    );
    const notSelectedItems = offeredExercises.filter(
      (e) => !checkedExercises.includes(e.id)
    );

    setOfferedExercises(notSelectedItems);

    const allExercises = selectedItems
      .concat(selectedExercises)
      .map((exercise, index) => {
        return { ...exercise, position: index };
      });

    if (type === 'playlist') {
      setSelectedExercises(allExercises);
    } else {
      const uniqueSelectedExercises = allExercises.filter(
        (e, index) => allExercises.findIndex((e2) => e2.id === e.id) === index
      );
      setSelectedExercises(uniqueSelectedExercises);
    }
  };

  return (
    <div>
      <FormRow>
        <div className={cx(classes.flexBetween, classes.mt)}>
          <div className={classes.titleS}>exercise</div>
          <div className={classes.titleS}>select to add</div>
        </div>
      </FormRow>
      <div className={classes.offeredExercises}>
        {offeredExercises.map((exercise) => {
          const checked = checkedExercises.includes(exercise.id);
          return (
            <OfferedExercise
              checked={checked}
              exercise={exercise}
              key={exercise.id}
              onCheck={() => {
                if (checked) {
                  setCheckedExercises(
                    checkedExercises.filter((c) => c !== exercise.id)
                  );
                } else {
                  setCheckedExercises(checkedExercises.concat(exercise.id));
                }
              }}
            />
          );
        })}
      </div>
      <FormRow containerClass={classes.addButtonContainer}>
        <Button
          className={classes.addButton}
          onClick={moveExercisesToSelected}
          title='Add'
        />
      </FormRow>
    </div>
  );
};

OfferedExercises = withStyles(styles)(OfferedExercises);

let AddExercisesForm = ({
  backUrl,
  classes,
  exercises,
  draft,
  initialSelection,
  onCancel,
  onDone,
  title,
  type,
}) => {
  const INITIAL_FILTER = {
    'Usage Area': [],
    Difficulty: [],
    'Movement Prep': [],
    Equipment: [],
    'Area of the Body': [],
    Modality: [],
    Muscles: [],
    'Frontend Visible': [],
  };

  const [offeredExercises, setOfferedExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState(initialSelection);
  const [checkedExercises, setCheckedExercises] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(INITIAL_FILTER);

  const allFiltersAreEmpty = useCallback(
    () =>
      Object.keys(filter).every((key) => filter[key].length === 0) && !search,
    [filter, search]
  );

  const filterItems = useCallback(() => {
    if (allFiltersAreEmpty()) {
      setOfferedExercises([]);
      return;
    }

    const result = exercises
      .filter((item) => {
        if (filter['Movement Prep'].length > 0) {
          return item.movementPreps.some((m) =>
            filter['Movement Prep'].includes(m.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (filter['Equipment'].length > 0) {
          return item.equipments.some((eq) =>
            filter['Equipment'].includes(eq.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (filter['Area of the Body'].length > 0) {
          return item.bodyAreas.some((b) =>
            filter['Area of the Body'].includes(b.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (filter['Frontend Visible'].length > 0) {
          return filter['Frontend Visible'].includes(
            item.frontEndVisible?.toString() || ''
          );
        }
        return true;
      })
      .filter((item) => {
        if (filter['Modality'].length > 0) {
          return item.modalities.some((b) => filter['Modality'].includes(b.id));
        }
        return true;
      })
      .filter((item) => {
        if (filter['Muscles'].length > 0) {
          return item.muscles.some((b) => filter['Muscles'].includes(b.id));
        }
        return true;
      })
      .filter((item) => {
        if (filter['Usage Area'].length > 0) {
          return filter['Usage Area'].includes(item.access);
        }
        return true;
      })
      .filter((item) => {
        if (filter['Difficulty'].length > 0) {
          return filter['Difficulty'].includes(item.difficulty);
        }
        return true;
      })
      .filter((item) => {
        if (search) {
          return item?.title?.toLowerCase().includes(search.toLowerCase());
        }
        return true;
      });

    setOfferedExercises(result);
  }, [allFiltersAreEmpty, filter, exercises, search]);

  useEffect(() => {
    filterItems();
  }, [filter, filterItems, search, selectedExercises]);

  const onDelete = (exercise) => {
    setSelectedExercises(
      selectedExercises.filter((s) => s.position !== exercise.position)
    );
  };

  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={backUrl} />
      <div className={classes.content}>
        <FormRow>
          <div className={classes.title}>select exercises</div>
        </FormRow>
        <FormRow>
          <div className={classes.filter}>
            <TagFilter
              initialSelections={INITIAL_FILTER}
              onSelectionsChange={(value) => setFilter(value)}
              placeholder='Filter by'
              selections={filter}
            />
            <SearchInput onClick={(value) => setSearch(value)} />
          </div>
        </FormRow>
        {offeredExercises.length === 0 ? (
          allFiltersAreEmpty() ? (
            <div>Use filter or search to get exercises.</div>
          ) : (
            <div>You found nothing.</div>
          )
        ) : null}
        <OfferedExercises
          checkedExercises={checkedExercises}
          offeredExercises={offeredExercises}
          selectedExercises={selectedExercises}
          setCheckedExercises={setCheckedExercises}
          setOfferedExercises={setOfferedExercises}
          setSelectedExercises={setSelectedExercises}
          type={type}
        />
        <SelectedExercises
          selectedExercises={selectedExercises}
          setSelectedExercises={setSelectedExercises}
          onDelete={onDelete}
          type={type}
        />
        <FormRow containerClass={classes.buttons}>
          <Button
            className={classes.button}
            onClick={onCancel}
            outlined
            title='Cancel'
          />
          <Button
            className={classes.button}
            onClick={() => {
              console.log(selectedExercises);
              onDone(draft, selectedExercises);
            }}
            title='Done'
          />
        </FormRow>
      </div>
    </div>
  );
};

AddExercisesForm = withStyles(styles)(AddExercisesForm);

const AddExercises = ({
  backUrl,
  draft,
  exercises,
  initialSelection,
  onCancel,
  onDone,
  title,
  type,
}) => {
  if (exercises?.length < 1) return null;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AddExercisesForm
        backUrl={backUrl}
        onCancel={onCancel}
        draft={draft}
        exercises={exercises}
        initialSelection={initialSelection}
        onDone={onDone}
        title={title}
        type={type}
      />
    </>
  );
};

const mapStateToProps = (
  { exerciseCreator: { exercises }, workoutCreator: { draft } },
  {
    match: {
      params: { type, workoutId },
    },
  }
) => {
  const { addedExercises, playlist, orderedExerciseIds } = draft;
  let initialSelection = [];
  if (type === 'playlist') {
    initialSelection = playlist
      .map((item, position) => ({
        ...item,
        ...item.exercise,
        duration: convertSecondsToTime(item.duration),
        position,
      }))
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  } else {
    if (orderedExerciseIds) {
      initialSelection = orderedExerciseIds.map((id, index) => {
        const exercise = addedExercises.find((e) => e.id === id);
        return { ...exercise, position: index };
      });
    } else {
      initialSelection = addedExercises.map((e, position) => {
        return { ...e, position };
      });
    }
  }

  return {
    backUrl: workoutId ? `${HOME_URL}/edit/${workoutId}` : `${HOME_URL}/create`,
    exercises: exercises.filter((e) => e.status === 'published'),
    draft,
    initialSelection,
    title:
      type === 'playlist'
        ? `Edit playlist ${draft.title ? '(' + draft.title + ')' : ''}`
        : `Add exercises ${draft.title ? '(' + draft.title + ')' : ''}`,
    type,
  };
};

const toPlaylist = (exercises) =>
  exercises.map((exercise, order) => {
    if (exercise.isRepBased || !!exercise.instructions) {
      return {
        exercise: { ...exercise },
        instructions: exercise?.instructions,
        type: 'rep-based',
        order,
      };
    }

    return {
      exercise: { ...exercise },
      type: exercise?.duration?.match(HH_MM_SS_REGEX)
        ? 'time-based'
        : 'standard',
      duration: exercise?.duration?.match(HH_MM_SS_REGEX)
        ? convertTimeToSeconds(exercise?.duration)
        : null,
      order,
    };
  });

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { workoutId, type },
    },
  }
) => {
  const goBack = () => {
    if (workoutId) {
      dispatch(push(`${HOME_URL}/edit/${workoutId}`));
    } else {
      dispatch(push(`${HOME_URL}/create`));
    }
  };

  return {
    onCancel: goBack,
    onDone:
      type === 'playlist'
        ? (draft, exercises) => {
            dispatch(
              Creators.saveDraft({
                ...draft,
                playlist: toPlaylist(exercises),
              })
            );
            goBack();
          }
        : (draft, exercises) => {
            const uniqueExercises = exercises.filter(
              (e, index) =>
                index === exercises.findIndex((e2) => e2.id === e.id)
            );
            dispatch(
              Creators.saveDraft({
                ...draft,
                addedExercises: uniqueExercises,
                orderedExerciseIds: uniqueExercises.map((e) => e.id),
              })
            );
            goBack();
          },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExercises);
