import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { parseError } from 'core';
import { Types } from './reducer';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* planSaved(action) {
  yield call(toastr.success, '', 'Plan saved successfully');
  yield put(push('/admin/dashboard/membership/plan'));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_PLAN_GROUP_SUCCESS, planSaved),
    takeEvery(Types.CREATE_PLAN_SUCCESS, planSaved),
    takeEvery(Types.UPDATE_PLAN_GROUP_SUCCESS, planSaved),
    takeEvery(Types.UPDATE_PLAN_SUCCESS, planSaved),
    takeEvery(Types.CREATE_PLAN_GROUP_FAIL, requestFailed),
    takeEvery(Types.UPDATE_PLAN_GROUP_FAIL, requestFailed),
    takeEvery(Types.DELETE_PLAN_GROUP_FAIL, requestFailed),
  ]);
}
