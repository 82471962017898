import React from 'react';
import { withStyles } from '@material-ui/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import FormError from './FormError';

const styles = (theme) => ({
  label: {
    fontSize: 14,
    color: theme.palette.primary.dark,
  },
  root: {
    alignItems: 'flex-start',
    marginLeft: -4,
    padding: '0px',
    '& > span': {
      padding: 0,
      marginRight: 8,
    },
  },
});

export const Checkbox = ({ classes, className, name, label }) => {
  const [field, meta] = useField(name);
  const hasError = meta.error && meta.touched;
  const errorMessage = meta.error;

  return (
    <>
      <FormControlLabel
        classes={{ root: classes.root, label: classes.label }}
        control={
          <MUICheckbox
            checked={field.value}
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
            name={name}
            onChange={field.onChange(name)}
          />
        }
        label={label}
      />
      {hasError && <FormError>{errorMessage}</FormError>}
    </>
  );
};

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withStyles(styles)(Checkbox);
