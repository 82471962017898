export const sortByTitle = (a, b) => {
  return sortByField('title')(a, b);
};

export const sortByLabel = (a, b) => {
  return sortByField('label')(a, b);
};

export const sortByActiveSince = (a, b) => {
  return sortByField('activeSince', -1)(a, b);
};

export const sortByField = (field, direction = 1) => {
  return (a, b) => {
    const fieldA = a[field]?.toLowerCase() || '';
    const fieldB = b[field]?.toLowerCase() || '';

    return (fieldA?.localeCompare(fieldB) || 0) * direction;
  };
};
