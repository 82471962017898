import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  modalType: '',
  isOpen: false,
  callback: () => {},
  text: '',
};

const { Types, Creators } = createActions(
  {
    showModal: ['modalType', 'callback', 'text'],
    hideModal: null,
  },
  { prefix: 'modal/' }
);

const showModal = (state = INITIAL_STATE, action) => ({
  ...state,
  modalType: action.modalType,
  callback: action.callback,
  text: action.text,
  isOpen: true,
});

const hideModal = (state = INITIAL_STATE, action) => ({
  ...state,
  isOpen: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.SHOW_MODAL]: showModal,
  [Types.HIDE_MODAL]: hideModal,
});

export { Creators, Types, Reducer };
