import axios from 'axios';
import jwtDecode from 'jwt-decode';

import {
  BASE_URL,
  TOKEN,
  TOKEN_EXPIRY_DATE,
  REFRESH_TOKEN,
  REMEMBER_ME_STATUS,
} from '../constants';
import * as localStorage from './localStorage';

export const hasExpired = (exp) => {
  return Date.now() >= exp * 1000;
};

export const setTokenData = (authToken, rememberMe) => {
  const token = authToken.accessToken;
  const refreshToken = authToken.refreshToken;

  if (!token || !refreshToken) return;

  const decodedToken = jwtDecode(token);

  localStorage.setItem(TOKEN, token);
  localStorage.setItem(TOKEN_EXPIRY_DATE, decodedToken.exp);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
  localStorage.setItem(REMEMBER_ME_STATUS, rememberMe);
};

export const removeTokenData = () => {
  [TOKEN, TOKEN_EXPIRY_DATE, REFRESH_TOKEN].map((key) =>
    localStorage.removeItem(key)
  );
};

export const verifyToken = async () => {
  const tokenExpiryDate = localStorage.getItem(TOKEN_EXPIRY_DATE);

  if (!tokenExpiryDate) return [false, null];

  const tokenActive = !hasExpired(parseInt(tokenExpiryDate));

  let isAuthenticated,
    updatedToken = null;

  if (tokenActive) {
    isAuthenticated = true;
  } else {
    try {
      const response = await refreshToken();
      isAuthenticated = response.isAuthenticated;
      updatedToken = response.authToken;
    } catch (error) {
      isAuthenticated = false;
    }
  }

  if (isAuthenticated) return [true, updatedToken];
  return [false, null];
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  let rememberMe = localStorage.getItem(REMEMBER_ME_STATUS) || false;

  if (!refreshToken) {
    return { isAuthenticated: false, authToken: null };
  }
  if (rememberMe === 'undefined') rememberMe = false;

  const refreshResponse = await axios.post(`${BASE_URL}/auth/refresh-access`, {
    refreshToken,
    rememberMe,
  });

  if (!refreshResponse) {
    return { isAuthenticated: false, authToken: null };
  }

  setTokenData(refreshResponse.data.authToken);
  return { isAuthenticated: true, authToken: refreshResponse.data.authToken };
};
