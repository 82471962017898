import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';
import { MixpanelEvents, track } from 'core/utils/mixpanel';
import { getExerciseTags } from 'core/utils/exercise';

const INITIAL_STATE = {
  exercises: [],
  processing: false,
  processedItemId: -1,
};

// Action creators
const { Types, Creators } = createActions(
  {
    getExercisesSuccess: ['response'],
    getExercisesFail: ['error'],
    getExercises: () => ({
      type: 'exercises/GET_EXERCISES',
      payload: {
        request: {
          url: '/exercises',
          method: 'get',
        },
      },
    }),
    addFavoriteSuccess: ['response'],
    addFavoriteFail: ['error'],
    addFavorite: ({ id }) => ({
      type: 'exercises/ADD_FAVORITE',
      payload: {
        request: {
          url: `/exercises/favorites/${id}`,
          method: 'post',
        },
        id,
      },
    }),
    deleteFavoriteSuccess: ['response'],
    deleteFavoriteFail: ['error'],
    deleteFavorite: ({ id }) => ({
      type: 'exercises/DELETE_FAVORITE',
      payload: {
        request: {
          url: `/exercises/favorites/${id}`,
          method: 'delete',
        },
        id,
      },
    }),
  },
  { prefix: 'exercises/' }
);

// Reducers
const getExercises = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getExercisesSuccess = (
  state = INITIAL_STATE,
  {
    payload: {
      data: { data },
    },
  }
) => {
  return {
    ...state,
    exercises: data,
    processing: false,
  };
};

const getExercisesFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const addFavorite = (state = INITIAL_STATE, { payload: { id } }) => ({
  ...state,
  processedItemId: id,
});

const addFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  const _exercise = state.exercises.find((exercise) => exercise.id === id);

  track(MixpanelEvents.addedExerciseToFavorites, {
    exerciseName: _exercise.title,
    exerciseTags: getExerciseTags(_exercise),
    exerciseLength: _exercise.video?.duration,
  });

  return {
    ...state,
    processedItemId: -1,
    exercises: state.exercises.map((exercise) => {
      if (exercise.id === id) {
        return { ...exercise, isFavorite: true };
      }
      return exercise;
    }),
  };
};

const addFavoriteFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processedItemId: -1,
});

const deleteFavorite = (state = INITIAL_STATE, { payload: { id } }) => ({
  ...state,
  processedItemId: id,
});

const deleteFavoriteSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  const _exercise = state.exercises.find((exercise) => exercise.id === id);
  track(MixpanelEvents.removedExerciseFromFavorites, {
    exerciseName: _exercise.title,
    exerciseTags: getExerciseTags(_exercise),
    exerciseLength: _exercise.video?.duration,
  });

  return {
    ...state,
    processedItemId: -1,
    exercises: state.exercises.map((exercise) => {
      if (exercise.id === id) {
        return { ...exercise, isFavorite: false };
      }
      return exercise;
    }),
  };
};

const deleteFavoriteFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processedItemId: -1,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_EXERCISES]: getExercises,
  [Types.GET_EXERCISES_SUCCESS]: getExercisesSuccess,
  [Types.GET_EXERCISES_FAIL]: getExercisesFail,
  [Types.ADD_FAVORITE]: addFavorite,
  [Types.ADD_FAVORITE_SUCCESS]: addFavoriteSuccess,
  [Types.ADD_FAVORITE_FAIL]: addFavoriteFail,
  [Types.DELETE_FAVORITE]: deleteFavorite,
  [Types.DELETE_FAVORITE_SUCCESS]: deleteFavoriteSuccess,
  [Types.DELETE_FAVORITE_FAIL]: deleteFavoriteFail,
});

export { Creators, Types, Reducer };
