import React from 'react';
import { useTheme, withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

import playImg from 'assets/svg/play.svg';
import { Box, useMediaQuery } from '@material-ui/core';

const styles = ({ breakpoints, palette: { background, primary } }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    background: background.paper,
    padding: '2rem',
    flex: 1,
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  listItem: {
    display: 'flex',
    height: 178,
    maxWidth: 1120,
    margin: '0 auto',
    padding: 18,
    paddingLeft: 0,
    borderTop: `1px solid ${primary.gray}`,
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingRight: 0,
      height: 'auto',
    },
  },
  listImage: {
    height: 160,
    width: 160,
    '& > img': {
      height: 160,
      objectFit: 'cover',
      width: 160,
    },
    [breakpoints.down('xs')]: {
      height: 78,
      width: 78,
      '& > img': {
        height: 78,
        objectFit: 'cover',
        width: 78,
      },
    },
  },
  listItemContent: {
    alignItems: 'center',
    color: primary.contrastText,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 18,
  },
  listItemTitle: {
    color: primary.black,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: '48px',
    textDecoration: 'none',
    [breakpoints.down('xs')]: {
      lineHeight: 1.44,
      letterSpacing: '0.25px',
      fontSize: 18,
      padding: '0 10px',
    },
  },
  pageTitle: {
    color: primary.contrastText,
    fontSize: 24,
    fontWeight: '900',
    textShadow: '0px 2px 4px rgba(0,0,0,0.5)',
    padding: 40,
    [breakpoints.down('xs')]: {
      paddingLeft: 15,
    },
  },
  text: {
    color: primary.generalText,
    fontSize: 16,
    lineHeight: '24px',
    paddingRight: 32,
    [breakpoints.down('xs')]: {
      lineHeight: 1.5,
      letterSpacing: 'normal',
      paddingTop: '10px',
    },
  },
});

const List = ({ baseUrl, classes, items, title }) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));

  const ListItem = ({ item: { description, id, image, title, video } }) => (
    <NavLink to={`${baseUrl}/${id}`} exact style={{ textDecoration: 'none ' }}>
      <div className={classes.listItem}>
        {isSmallScreen && (
          <>
            <Box display='flex'>
              <div className={classes.listImage}>
                <img src={image} alt='thumbnail' />
              </div>
              <div className={classes.listItemTitle}>{title}</div>
              {video && (
                <Box alignSelf='flex-start'>
                  <img src={playImg} alt='play button' />
                </Box>
              )}
            </Box>
            <Box>
              <div className={classes.text}>{description}</div>
            </Box>
          </>
        )}
        {!isSmallScreen && (
          <>
            <div className={classes.listImage}>
              <img src={image} alt='thumbnail' />
            </div>
            <div className={classes.listItemContent}>
              <div>
                <div className={classes.listItemTitle}>{title}</div>
                <div className={classes.text}>{description}</div>
              </div>
              {video && <img src={playImg} alt='play button' />}
            </div>
          </>
        )}
      </div>
    </NavLink>
  );

  return (
    <div className={classes.container}>
      <div className={classes.pageTitle}>{title}</div>
      <div className={classes.list}>
        {items.map((item) => (
          <ListItem item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(List);
