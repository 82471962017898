import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Box, useMediaQuery } from '@material-ui/core';

import contactUsImg from 'assets/img/contact-us.png';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  title: {
    zIndex: 1,
    color: primary.black,
    textTransform: 'uppercase',
    fontSize: 38,
    fontWeight: 900,
    letterSpacing: 0.56,
    lineHeight: 1.5,
    [breakpoints.down('xs')]: {
      fontSize: 26,
      lineHeight: 1.38,
      letterSpacing: 0.38,
    },
    textAlign: 'center',
  },
  text: {
    color: primary.generalText,
    fontSize: 18,
    letterSpacing: 'normal',
    lineHeight: 1.5,
    marginTop: 20,
    [breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  link: {
    color: primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingLeft: 3,
    paddingRight: 1,
    lineHeight: 1.3,
    marginTop: 0,
    textAlign: 'center',
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));

  const Contact = () => (
    <a
      className={classes.link}
      href='mailto:info@movementvault.com'
      target='_blank'
      rel='noopener noreferrer'
    >
      info@movementvault.com
    </a>
  );

  return (
    <div>
      <Helmet>
        <title>Contact us</title>
      </Helmet>
      <Box>
        <Box
          display='flex'
          pt={5}
          px={{ sm: 0, xs: '15px' }}
          justifyContent='center'
          flexDirection={{
            xs: 'column',
            sm: 'row',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            maxWidth={700}
          >
            <div className={classes.title}>contact us</div>
            <Box pr={8}>
              <div className={classes.text}>
                Do you have a question about Movement Vault in general? Or a
                question about how we can help you alleviate your pain, decrease
                your injuries, and help you improve your flexibility and
                mobility?
              </div>
              <div className={classes.text}>
                Chances are you can find the answer in our
                <span className={classes.link}>FAQ section.</span>
              </div>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            mr={{ sm: 5, xs: 4 }}
            ml={{ sm: 4, xs: 0 }}
            pt={{ sm: 0, xs: 5 }}
            maxWidth={552}
          >
            <img src={contactUsImg} alt='exercise' width='100%' />
          </Box>
        </Box>
        <Box
          display='flex'
          alignSelf='center'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          width='100%'
          mt={6}
        >
          <Box
            pb={{ sm: 0, xs: 5 }}
            width={{ sm: 700, xs: 'auto' }}
            textAlign='center'
          >
            <div className={classes.title}>helpful emails</div>
            <div className={classes.text} style={{ marginTop: 8 }}>
              Movement Vault For Work:
              {isSmallScreen ? (
                <div className={classes.link}>Contact Form</div>
              ) : (
                <span className={classes.link}>Contact Form</span>
              )}
            </div>
            <div className={classes.text} style={{ marginTop: 8 }}>
              Partnerships, Press, General Questions:
              {isSmallScreen ? (
                <div className={classes.link}>
                  <Contact />
                </div>
              ) : (
                <span className={classes.link}>
                  <Contact />
                </span>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ContactUs;
