import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  container: {
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: `${theme.spacing(1.5)}px 0px`,
    },
  },
});

const FormRow = ({ classes, children, containerClass }) => (
  <div className={cx(classes.container, containerClass)}>{children}</div>
);

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  containerClas: PropTypes.string,
};

export default withStyles(styles)(FormRow);
