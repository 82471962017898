import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';

import checkbox from 'assets/svg/checkbox.svg';
import checkboxChecked from 'assets/svg/checkbox-checked.svg';

const styles = () => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  label: {
    fontSize: 14,
    marginLeft: 8,
  },
});

const Checkbox = ({
  containerClass,
  checked = false,
  classes,
  label,
  labelClass,
  onCheck,
}) => (
  <div className={cx(classes.container, containerClass)} onClick={onCheck}>
    <img src={checked ? checkboxChecked : checkbox} alt='checkbox' />
    {label && <div className={cx(classes.label, labelClass)}>{label}</div>}
  </div>
);

export default withStyles(styles)(Checkbox);
