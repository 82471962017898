import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { withStyles } from '@material-ui/styles';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { Button } from 'components';
import cards from 'assets/svg/cards.svg';
import debounce from 'lodash/debounce';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  console.error("Couldn't find Stripe publishable key");
}

// TODO: Cleanup and better error/success handling needed.
const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '12px 0px',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  container: {
    width: '100%',
  },
  content: {
    borderRadius: 2,
    '& > input': {
      border: `1px solid ${theme.palette.primary.gray}`,
      boxSizing: 'border-box',
      padding: theme.spacing(2),
      width: '100%',
      fontSize: 16,
    },
    '& > input:focus': {
      outline: `2px solid ${theme.palette.primary.main}`,
      border: 'none',
    },
  },
  error: {
    padding: '10px 0px',
  },
  form: {
    background: theme.palette.background.paper,
    boxShadow: '0 8px 16px 2px rgba(15, 18, 26, 0.08)',
    borderRadius: theme.radius,
    padding: `64px 92px`,
    width: 600,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      padding: 16,
      width: '100%',
      minHeight: 550,
    },
  },
  input: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  inputField: {
    width: '100%',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  label: {
    fontSize: 14,
    marginBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
  labelError: {
    color: theme.palette.messages.error,
  },
  link: {
    '& > a': {
      textDecoration: 'none',
    },
  },
  stripeInput: {
    border: `1px solid ${theme.palette.primary.gray}`,
    padding: theme.spacing(2),
    height: 50,
    '&:focus': {
      outline: `2px solid ${theme.palette.primary.main}`,
      border: 'none',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      marginRight: 60,
    },
  },
});

const InputField = ({
  classes,
  label,
  name,
  onChange,
  placeholder,
  required = false,
  type,
  value,
  autoFocus,
}) => (
  <div className={classes.inputField}>
    <div className={classes.label}>{label}</div>
    <div className={classes.content}>
      <input
        onChange={(e) => onChange(e.target.value)}
        id={name}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
        autoFocus={autoFocus}
      />
    </div>
  </div>
);

const PaymentMethodForm = ({ classes, onCancelClick, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [fullName, setFullName] = useState('');
  const [errorToDisplay, setErrorToDisplay] = useState('');

  useEffect(() => {
    const handleScrollFocussedIntoView = debounce(
      () => {
        if (document.activeElement.tagName) {
          setTimeout(() => {
            requestAnimationFrame(() => {
              document.activeElement.scrollIntoView({
                block: 'center',
                inline: 'center',
                behavior: 'auto',
              });
            });
          }, 0);
        }
      },
      300,
      { leading: false, trailing: true }
    );

    handleScrollFocussedIntoView();

    window.addEventListener('resize', handleScrollFocussedIntoView);
    window.addEventListener('scroll', handleScrollFocussedIntoView);

    return () => {
      window.removeEventListener('resize', handleScrollFocussedIntoView);
      window.removeEventListener('scroll', handleScrollFocussedIntoView);
    };
  }, []);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: fullName,
      },
    });

    if (error) {
      setErrorToDisplay(error && error.message);
      setSubscribing(false);
      return;
    }
    onSuccess(paymentMethod.id);
    setSubscribing(false);
    //const paymentMethodId = paymentMethod.id;

    // Create the subscription
  };

  return (
    <div id='payment-form' className={classes.form}>
      <div>
        <div className={classes.title}>Update Payment Method</div>
        <div className={classes.label} style={{ marginBottom: 16 }}>
          No commitment. Cancel anytime.
        </div>
        <img src={cards} alt='card brands' style={{ marginBottom: 16 }} />
        <div>
          <form id='payment-form' onSubmit={handleSubmit}>
            <div style={{ marginBottom: 16 }}>
              <InputField
                classes={classes}
                id='name'
                label='Card Name'
                onChange={(value) => setFullName(value)}
                type='text'
                placeholder='Full Name'
                value={fullName}
                autoFocus
                required
              />
            </div>
            <div id='card-element'>
              <div style={{ marginBottom: 16 }}>
                <div className={classes.label}>Card Number</div>
                <CardNumberElement className={classes.stripeInput} required />
              </div>
              <div className={classes.inputRow} style={{ marginBottom: 16 }}>
                <div className={classes.input}>
                  <div className={classes.label}>Expiry Date</div>
                  <CardExpiryElement className={classes.stripeInput} required />
                </div>
                <div className={classes.input}>
                  <div className={classes.label}>CVC</div>
                  <CardCvcElement className={classes.stripeInput} required />
                </div>
              </div>
            </div>
            <div className={classes.buttons}>
              <div className={cx(classes.button, classes.link)}>
                <Button title='Cancel' outlined onClick={onCancelClick} />
              </div>
              <div className={classes.button}>
                <Button
                  processing={subscribing}
                  processingLabel='Saving Card...'
                  title='Save Card'
                  type='submit'
                />
              </div>
            </div>
            {errorToDisplay && (
              <div className={cx(classes.error, classes.labelError)}>
                {errorToDisplay}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const PaymentMethod = ({ ...rest }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodForm {...rest} />
    </Elements>
  );
};

// const mapStateToProps = () => {};

// const mapDispatchToProps = {};

export default connect()(withStyles(styles)(PaymentMethod));
