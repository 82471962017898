import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object } from 'yup';

import {
  Button,
  FileUploader,
  Form,
  FormCheckbox,
  FormInput,
  FormRow,
  FormSelect,
} from 'components';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  fileUploaders: {
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      marginRight: 15,
    },
  },
  form: {
    padding: '24px 0px',
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  '& video': {
    height: 200,
    objectFit: 'cover',
    width: '100%',
  },
});

let FileUploaders = React.memo(
  ({ classes, videoRef, videoHiRef, videoLoRef }) => (
    <>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoRef.current = { id, url };
            }}
            title='Video 720px'
          />
        </div>
      </FormRow>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoLoRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoLoRef.current = { id, url };
            }}
            title='Video 1080p'
          />
          <FileUploader
            acceptPattern='video/*'
            fileType='video'
            initialFileUrl={videoHiRef.current.url}
            onFileUploaded={({ id, url }) => {
              videoHiRef.current = { id, url };
            }}
            title='Video 480p'
          />
        </div>
      </FormRow>
    </>
  )
);

let ResultUploads = React.memo(
  ({ classes, resultImage1, resultImage2, resultImage3, resultImage4 }) => (
    <>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={resultImage1.current.url}
            onFileUploaded={({ id, url }) => {
              resultImage1.current = { id, url };
            }}
          />
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={resultImage2.current.url}
            onFileUploaded={({ id, url }) => {
              resultImage2.current = { id, url };
            }}
          />
        </div>
      </FormRow>
      <FormRow containerClass={classes.formRow}>
        <div className={classes.fileUploaders}>
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={resultImage3.current.url}
            onFileUploaded={({ id, url }) => {
              resultImage3.current = { id, url };
            }}
          />
          <FileUploader
            acceptPattern='.png,.jpg,.jpeg'
            fileType='image'
            initialFileUrl={resultImage4.current.url}
            onFileUploaded={({ id, url }) => {
              resultImage4.current = { id, url };
            }}
          />
        </div>
      </FormRow>
    </>
  )
);

FileUploaders = withStyles(styles)(FileUploaders);
ResultUploads = withStyles(styles)(ResultUploads);

const TaskForm = ({
  bodyArea,
  classes,
  task,
  error,
  isEdit = false,
  onSubmit,
  pageTitle,
  processing,
}) => {
  const resultImage1 = useRef(task?.image1 || { id: '', url: '' });
  const resultImage2 = useRef(task?.image2 || { id: '', url: '' });
  const resultImage3 = useRef(task?.image3 || { id: '', url: '' });
  const resultImage4 = useRef(task?.image4 || { id: '', url: '' });
  const videoRef = useRef(task?.video || { id: '', url: '' });
  const videoHiRef = useRef(task?.videoHi || { id: '', url: '' });
  const videoLoRef = useRef(task?.videoLo || { id: '', url: '' });
  const FormBody = () => {
    const { isValid, values } = useFormikContext();

    return (
      <Form
        error={error}
        containerClass={classes.form}
        pageTitle={pageTitle}
        showDivider={false}
      >
        <FormRow containerClass={classes.formRow}>
          <div className={classes.title}>general info</div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label='title'
            name='title'
            placeholder='Enter a title...'
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label={`Do's`}
            name='dos'
            rows='5'
            placeholder='Enter the do list (seperated by ;)'
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label={`Don'ts`}
            name='donts'
            rows='5'
            placeholder='Enter the dont list (seperated by ;)'
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label={`Goal`}
            name='goal'
            placeholder='Enter the goal'
          />
        </FormRow>
        <FileUploaders
          videoRef={videoRef}
          videoHiRef={videoHiRef}
          videoLoRef={videoLoRef}
        />
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormSelect
              height={50}
              label='Area of the body'
              name='bodyArea'
              width='100%'
              options={bodyArea}
            />
          </div>
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormCheckbox label='Double Sided' name='doubleSided' />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <FormInput
            error={error}
            label={`First Side Text`}
            name='firstText'
            placeholder='Enter the text for the first side'
          />
        </FormRow>
        {values.doubleSided && (
          <FormRow containerClass={classes.formRow}>
            <FormInput
              error={error}
              label={`Second Side Text`}
              name='secondText'
              placeholder='Enter the text for the second side'
            />
          </FormRow>
        )}
        <ResultUploads
          resultImage1={resultImage1}
          resultImage2={resultImage2}
          resultImage3={resultImage3}
          resultImage4={resultImage4}
        />
        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button
                id='btnSave'
                disabled={!isValid}
                processing={processing}
                processingLabel='saving...'
                title='save'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };
  const transformValues = (values) => {
    return {
      dos: values.dos,
      donts: values.donts,
      goal: values.goal,
      firstText: values.firstText,
      secondText: values.secondText,
      doubleSided: values.doubleSided,
      bodyArea: values.bodyArea,
      title: values.title,
      image1: resultImage1.current.id || null,
      image2: resultImage2.current.id || null,
      image3: resultImage3.current.id || null,
      image4: resultImage4.current.id || null,
      video: videoRef.current.id || null,
      videoLo: videoLoRef.current.id || null,
      videoHi: videoHiRef.current.id || null,
    };
  };

  if (isEdit && !task) return null;

  const validationSchema = object().shape({
    // access: string().required('Required field'),
    // difficulty: string().required('Required field'),
    // frontEndVisible: string().required('Required field'),
  });

  return (
    <Formik
      initialValues={
        task || {
          bodyArea: [],
        }
      }
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default connect(({ tags: { bodyArea } }) => ({
  bodyArea,
}))(withStyles(styles)(TaskForm));
