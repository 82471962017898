import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    color: theme.palette.primary.dark,
    display: 'inline',
    fontSize: 14,
  },
});

export const Text = ({ children, classes, className }) => (
  <div className={cx(classes.root, className)}>{children}</div>
);

Text.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Text);
