import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 12 12' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.96262 6.32721C8.81131 6.62009 9.58914 7.10385 10.2426 7.75735C11.3759 8.89059 12 10.3974 12 12H11.0625C11.0625 10.5497 10.4493 9.24005 9.46875 8.31619V11.9766H8.53125V9.82251C7.89789 10.4987 6.99747 10.9219 6 10.9219C5.00253 10.9219 4.10211 10.4987 3.46875 9.82251V11.9766H2.53125V8.31619C1.55072 9.24005 0.9375 10.5497 0.9375 12H0C0 10.3974 0.624115 8.89059 1.75735 7.75735C2.41086 7.10385 3.18869 6.62009 4.03738 6.3273C3.12845 5.70117 2.53125 4.65344 2.53125 3.46875C2.53125 1.55612 4.08737 0 6 0C7.28256 0 8.40463 0.699738 9.00485 1.7374C9.18018 1.69583 9.38901 1.61526 9.54803 1.45871C9.73123 1.27835 9.82031 1.02356 9.82031 0.679688H10.7578C10.7578 1.25574 10.5862 1.72723 10.248 2.0831C10.3922 2.16119 10.5349 2.26099 10.6664 2.38861C11.0381 2.74988 11.2266 3.2395 11.2266 3.84375H10.2891C10.2891 3.49988 10.2 3.24509 10.0168 3.06473C9.83405 2.88483 9.58575 2.80518 9.39798 2.76993C9.4444 2.99579 9.46875 3.22943 9.46875 3.46875C9.46875 4.65344 8.87155 5.70117 7.96262 6.32721ZM7.88498 1.78125C7.42117 1.2637 6.74799 0.9375 6 0.9375C5.25201 0.9375 4.57883 1.2637 4.11502 1.78125H7.88498ZM3.46875 3.46875C3.46875 3.20773 3.50848 2.95587 3.58218 2.71875H8.41782C8.49152 2.95587 8.53125 3.20773 8.53125 3.46875C8.53125 4.86447 7.39572 6 6 6C4.60428 6 3.46875 4.86447 3.46875 3.46875ZM3.47397 7.61398C3.55728 8.935 4.65829 9.98438 6 9.98438C7.34171 9.98438 8.44272 8.935 8.52603 7.61398C7.78207 7.18387 6.91928 6.9375 6 6.9375C5.08072 6.9375 4.21793 7.18387 3.47397 7.61398Z'
      fill={fill}
    />
  </svg>
);
