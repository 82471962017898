import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Creators } from '../reducer';
import { connect } from 'react-redux';

const styles = ({ breakpoints, palette: { primary } }) => ({
  mainContainer: {
    paddingTop: '112px',
    paddingBottom: '31px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '152px',
    paddingRight: '152px',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '36px',
      paddingBottom: '40px',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 900,
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  chooseResult: {
    fontWeight: 600,
    fontSize: '18px',
    paddingTop: '12px',
    color: '#585963',
  },
  answersContainer: {
    width: '100%',
  },
  line: {
    // borderBottom: "solid",
    // color : "#28AAE2",
    // width  : "44px",
    // height : "4px",
    // borderRadius : "10px",
    // borderWidth : "4px"
    border: '1px solid #DFE2E6',
    // borderRadius: '5px',
    width: '100%',
    marginTop: '24px',
  },
  picRight: {
    width: '392px',
    // width: "552px",
    height: '296px',
    // transform: "scaleX(-1)",
    // backgroundColor : "red",
    // color : "#FFFFFF",
    // fontSize : "70px",
    // fontWeight : 700,
    // display : "flex",
    // justifyContent :  "center",
    // alignText : "center",
    // alignItems : "center",
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '185px',
    },
  },
  picLeft: {
    width: '392px',
    // width: "552px",
    height: '296px',
    // transform: 'scaleX(-1)',
    // backgroundColor : "red",
    // color : "#FFFFFF",
    // fontSize : "70px",
    // fontWeight : 700,
    // display : "flex",
    // justifyContent :  "center",
    // alignText : "center",
    // alignItems : "center",
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '185px',
    },
  },
  picsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '40px',
    gap: '24px',
    [breakpoints.down('xs')]: {
      paddingTop: '20px',
    },
  },
});

const Answer = ({ classes, answer, addAnswer, postResults, user }) => {
  console.log(user, 'USERCHIKA');

  const [firstAnswer, setFirstAnswer] = useState();
  useEffect(() => {
    setFirstAnswer(undefined);
  }, [answer, setFirstAnswer]);

  const answerCallback = (ans) => {
    if (answer?.doubleSided) {
      // 2 kitxviania?
      if (!firstAnswer) {
        setFirstAnswer(ans);
      } else {
        addAnswer({
          task: answer?.id,
          values: [firstAnswer, ans],
        });
      }
    } else {
      addAnswer({
        task: answer?.id,
        values: [ans],
      });
    }

    console.log(answer, 'HLALALALA');
  };

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  console.log(firstAnswer, 'FIRST ANSWER');

  return (
    <div ref={myRef} className={classes.mainContainer}>
      <div className={classes.title}>
        {' '}
        {answer?.doubleSided == true ? (
          <>
            {' '}
            {!firstAnswer ? (
              <>RIGHT {answer?.bodyArea?.title}</>
            ) : (
              <>LEFT {answer?.bodyArea?.title}</>
            )}{' '}
          </>
        ) : (
          answer?.bodyArea?.title
        )}{' '}
      </div>
      {/* <div onClick={()=>postResults(firstAnswer)} className={classes.title}>{answer?.title}</div> */}
      <div className={classes.chooseResult}>Choose result</div>
      <div className={classes.answersContainer}>
        <div className={classes.line} />
        {/* <div className={classes.picsContainer}>
          <img
            src={answer?.image1?.location}
            className={classes.pic}
            // onClick={() => answerCallback(1)}
            onClick={()=>{
              answerCallback(1);
              executeScroll()
            }}
          />

          <img
            src={answer?.image2?.location}
            className={classes.pic}
            // onClick={() => answerCallback(2)}
            onClick={()=>{
              answerCallback(2);
              executeScroll()
            }}
          />
          <img
            src={answer?.image3?.location}
            className={classes.pic}
            // onClick={() => answerCallback(3)}
            onClick={()=>{
              answerCallback(3);
              executeScroll()
            }}
          />
          <img
            src={answer?.image4?.location}
            className={classes.pic}
            // onClick={() => answerCallback(4)}
            onClick={()=>{
              answerCallback(4);
              executeScroll()
            }}
          />
        </div> */}

        {answer?.doubleSided == true ? (
          <>
            
            {!firstAnswer ? (
              <div className={classes.picsContainer}>
                <img
                  src={answer?.image1?.location}
                  className={classes.picRight}
                  // onClick={() => answerCallback(1)}
                  onClick={() => {
                    answerCallback(1);
                    executeScroll();
                  }}
                />

                <img
                  src={answer?.image2?.location}
                  className={classes.picRight}
                  // onClick={() => answerCallback(2)}
                  onClick={() => {
                    answerCallback(2);
                    executeScroll();
                  }}
                />
                <img
                  src={answer?.image3?.location}
                  className={classes.picRight}
                  // onClick={() => answerCallback(3)}
                  onClick={() => {
                    answerCallback(3);
                    executeScroll();
                  }}
                />
                <img
                  src={answer?.image4?.location}
                  className={classes.picRight}
                  // onClick={() => answerCallback(4)}
                  onClick={() => {
                    answerCallback(4);
                    executeScroll();
                  }}
                />
              </div>
            ) : (
              <div className={classes.picsContainer}>
                <img
                  src={answer?.image1?.location}
                  className={classes.picLeft}
                  // onClick={() => answerCallback(1)}
                  onClick={() => {
                    answerCallback(1);
                    executeScroll();
                  }}
                />

                <img
                  src={answer?.image2?.location}
                  className={classes.picLeft}
                  // onClick={() => answerCallback(2)}
                  onClick={() => {
                    answerCallback(2);
                    executeScroll();
                  }}
                />
                <img
                  src={answer?.image3?.location}
                  className={classes.picLeft}
                  // onClick={() => answerCallback(3)}
                  onClick={() => {
                    answerCallback(3);
                    executeScroll();
                  }}
                />
                <img
                  src={answer?.image4?.location}
                  className={classes.picLeft}
                  // onClick={() => answerCallback(4)}
                  onClick={() => {
                    answerCallback(4);
                    executeScroll();
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className={classes.picsContainer}>
            <img
              src={answer?.image1?.location}
              className={classes.picRight}
              // onClick={() => answerCallback(1)}
              onClick={() => {
                answerCallback(1);
                executeScroll();
              }}
            />

            <img
              src={answer?.image2?.location}
              className={classes.picRight}
              // onClick={() => answerCallback(2)}
              onClick={() => {
                answerCallback(2);
                executeScroll();
              }}
            />
            <img
              src={answer?.image3?.location}
              className={classes.picRight}
              // onClick={() => answerCallback(3)}
              onClick={() => {
                answerCallback(3);
                executeScroll();
              }}
            />
            <img
              src={answer?.image4?.location}
              className={classes.picRight}
              // onClick={() => answerCallback(4)}
              onClick={() => {
                answerCallback(4);
                executeScroll();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {
  postResults: Creators.postResults,
})(withStyles(styles)(Answer));
