import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';
import moment from 'moment';

const INITIAL_STATE = {
  recommendations: [],
  isDirty: false,
  processing: false,
};

const { Types, Creators } = createActions(
  {
    createRecommendationSuccess: ['response'],
    createRecommendationFail: ['error'],
    createRecommendation: (recommendation) => ({
      type: 'recommendation/CREATE_RECOMMENDATION',
      payload: {
        request: {
          url: '/notifications/recommendation',
          method: 'post',
          data: {
            ...recommendation,
            postingDate: moment(recommendation.postingDate).toISOString(),
          },
        },
      },
    }),
    updateRecommendationSuccess: ['response'],
    updateRecommendationFail: ['error'],
    updateRecommendation: ({ id, recommendation }) => ({
      type: 'recommendation/UPDATE_RECOMMENDATION',
      payload: {
        id: id,
        request: {
          url: `/notifications/recommendation/${id}`,
          method: 'patch',
          data: {
            ...recommendation,
            postingDate: moment(recommendation.postingDate).toISOString(),
          },
        },
      },
    }),
    getRecommendationsSuccess: ['response'],
    getRecommendations: () => ({
      type: 'recommendation/GET_RECOMMENDATIONS',
      payload: {
        request: {
          url: '/notifications/recommendation',
          method: 'get',
        },
      },
    }),
    deleteRecommendationSuccess: ['response'],
    deleteRecommendationFail: ['error'],
    deleteRecommendation: (id) => ({
      type: 'recommendation/DELETE_RECOMMENDATION',
      payload: {
        request: {
          url: `/notifications/recommendation/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
  },
  { prefix: 'recommendation/' }
);

const getRecommendations = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createRecommendation = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createRecommendationSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    recommendations: [...state.recommendations, { ...data }],
  };
};

const createRecommendationFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updateRecommendation = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateRecommendationSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: { data },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    recommendations: state.recommendations.map((recommendation) => {
      if (recommendation.id === id) {
        recommendation = {
          ...data,
          postingDate: moment(data.postingDate).unix(),
        };
      }
      return recommendation;
    }),
  };
};

const updateRecommendationFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const getRecommendationsSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  console.log('in reducer');
  console.log(data);

  return {
    ...state,
    recommendations: data,
    processing: false,
  };
};

const deleteRecommendationSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    recommendations: state.recommendations.filter((e) => e.id !== id),
  };
};

const deleteRecommendationFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_RECOMMENDATION]: createRecommendation,
  [Types.CREATE_RECOMMENDATION_SUCCESS]: createRecommendationSuccess,
  [Types.CREATE_RECOMMENDATION_FAIL]: createRecommendationFail,
  [Types.UPDATE_RECOMMENDATION]: updateRecommendation,
  [Types.UPDATE_RECOMMENDATION_SUCCESS]: updateRecommendationSuccess,
  [Types.UPDATE_RECOMMENDATION_FAIL]: updateRecommendationFail,
  [Types.GET_RECOMMENDATIONS]: getRecommendations,
  [Types.GET_RECOMMENDATIONS_SUCCESS]: getRecommendationsSuccess,
  [Types.DELETE_RECOMMENDATION_SUCCESS]: deleteRecommendationSuccess,
  [Types.DELETE_RECOMMENDATION_FAIL]: deleteRecommendationFail,
});

export { Creators, Types, Reducer };
