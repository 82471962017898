import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';

import search from 'assets/svg/search.svg';

const styles = ({ palette: { primary, secondary } }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  input: {
    border: `1px solid ${secondary.gray}`,
    padding: 16,
    height: 45,
    fontSize: 14,
    width: '100%',
    '&:focus': {
      outline: `1px solid ${primary.main}`,
    },
    '&::placeholder': {
      color: secondary.grayText,
    },
  },
  label: {
    fontSize: 14,
    marginLeft: 8,
  },
  searchButton: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 38,
    justifyContent: 'center',
    marginLeft: 3,
    position: 'absolute',
    right: 8,
    width: 38,
    '& > img': {
      height: 24,
      width: 24,
    },
  },
});

const SearchInput = ({ classes, onClick, placeholder = 'Search' }) => {
  const [value, setValue] = useState('');

  return (
    <div className={classes.container} onClick={() => onClick(value)}>
      <input
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') onClick(value);
        }}
        className={classes.input}
        value={value}
      />
      <div className={classes.searchButton}>
        <img src={search} alt='search icon' />
      </div>
    </div>
  );
};

export default withStyles(styles)(SearchInput);
