import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { isEqual } from 'lodash';

import { NavBar } from 'modules/admin/dashboard';
import { Creators, INITIAL_DRAFT } from '../reducer';
import WorkoutForm from './WorkoutForm';
import { HOME_URL } from '../';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const EditWorkout = ({
  classes,
  draft,
  fetchingWorkout,
  getWorkout,
  id,
  processing,
  updateWorkout,
}) => {
  if (!fetchingWorkout && id && !draft?.playlist) {
    getWorkout(id);
    return null;
  }

  if (fetchingWorkout) return null;

  return (
    <div className={classes.container}>
      <NavBar title='edit workout' backUrl={HOME_URL} />
      <div className={classes.content}>
        {draft ? (
          <WorkoutForm
            draft={draft}
            isEdit
            onSubmit={(values) => {
              values.id = id;
              updateWorkout({ workout: values });
            }}
            processing={processing}
          />
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (
  { workoutCreator: { draft, fetchingWorkout, processing, workouts } },
  {
    match: {
      params: { id },
    },
  }
) => {
  if (!isEqual(draft, INITIAL_DRAFT)) {
    return {
      draft,
      id,
      processing,
    };
  }

  const workoutToEdit = workouts.find((w) => w.id === id);
  if (!workoutToEdit) return { processing, workout: null };

  const transformItem = ({ id, title }) => ({ label: title, value: id });

  const result = {
    draft: {
      ...workoutToEdit,
      bodyAreas: workoutToEdit.bodyAreas?.map(transformItem) || [],
      equipments: workoutToEdit.equipments?.map(transformItem) || [],
      modalities: workoutToEdit.modalities?.map(transformItem) || [],
      movementPreps: workoutToEdit.movementPreps?.map(transformItem) || [],
      muscles: workoutToEdit.muscles?.map(transformItem) || [],
      workoutType: workoutToEdit.workoutTypes?.map(transformItem) || [],
      thumbnail: {
        id: workoutToEdit.thumbnail?.id,
        url: workoutToEdit.thumbnail?.location,
      },
      thumbnailWide: {
        id: workoutToEdit.thumbnailWide?.id,
        url: workoutToEdit.thumbnailWide?.location,
      },
    },
    fetchingWorkout,
    id,
    processing,
  };

  return result;
};

export default connect(mapStateToProps, {
  getWorkout: Creators.getWorkout,
  updateWorkout: Creators.updateWorkout,
})(withStyles(styles)(EditWorkout));
