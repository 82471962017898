import React from 'react';

export default ({ fill }) => (
  <svg
    width='20'
    height='14'
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='M19.2188 3.15845H16.927V1.64844C16.927 1.21692 16.5773 0.867188 16.1458 0.867188H13.0728C12.6414 0.867188 12.2916 1.21692 12.2916 1.64844V6.23141H7.70828V1.64859C7.70828 1.21707 7.35855 0.86734 6.92703 0.86734H3.85422C3.4227 0.86734 3.07297 1.21707 3.07297 1.64859V3.15845H0.78125C0.349731 3.15845 0 3.50818 0 3.9397V10.0855C0 10.517 0.349731 10.8667 0.78125 10.8667H3.07297V12.3723C3.07297 12.8038 3.4227 13.1536 3.85422 13.1536H6.92719C7.35855 13.1536 7.70844 12.8038 7.70844 12.3723V7.79376H12.2917V12.3766C12.2917 12.8081 12.6414 13.1578 13.073 13.1578H16.1459C16.5773 13.1578 16.9272 12.8081 16.9272 12.3766V10.8667H19.2188C19.6503 10.8667 20 10.517 20 10.0855V3.9397C20 3.50818 19.6503 3.15845 19.2188 3.15845ZM1.5625 9.30423V4.72095H3.07297V9.30423H1.5625ZM6.14578 11.5911H4.63531V2.42984H6.14578V11.5911ZM15.3645 11.5953H13.8541V2.42969H15.3645V11.5953ZM18.4375 9.30423H16.927V4.72095H18.4375V9.30423Z'
    />
  </svg>
);
