import { createActions, createReducer } from 'reduxsauce';
import { LOCATION_CHANGE } from 'connected-react-router';
import { toFormErrorObject } from 'core';

const INITIAL_STATE = {
  activeTab: 'child',
  activePlan: {
    child: 0,
    parent: 0,
  },
  error: {},
  processing: false,
  remembeMe: false,
  plans: null,
};

// Action creators
const { Types, Creators } = createActions(
  {
    setActivePlan: ['tab', 'planIndex'],
    setActiveTab: ['activeTab'],
    getPlanGroupsSuccess: ['response'],
    getPlanGroupsFail: ['error'],
    getPlanGroups: () => ({
      type: 'plans/GET_PLAN_GROUPS',
      payload: {
        request: {
          url:
            '/membership-plan-group?join=plans&filter=plans.deletedAt||$isnull',
          method: 'get',
        },
      },
    }),
    createPlanGroupSuccess: ['response'],
    createPlanGroupFail: ['error'],
    createPlanGroup: (plan) => ({
      type: 'plans/CREATE_PLAN_GROUP',
      payload: {
        request: {
          url: '/membership-plan-group',
          method: 'post',
          data: plan,
        },
      },
    }),
    createPlanSuccess: ['response'],
    createPlanFail: ['error'],
    createPlan: (plan) => ({
      type: 'plans/CREATE_PLAN',
      payload: {
        request: {
          url: '/membership-plan',
          method: 'post',
          data: { ...plan, price: Math.round(plan.price * 100) },
        },
      },
    }),
    deletePlanGroupSuccess: ['response'],
    deletePlanGroupFail: ['error'],
    deletePlanGroup: (id) => ({
      type: 'plans/DELETE_PLAN_GROUP',
      payload: {
        id: id,
        request: {
          url: `/membership-plan-group/${id}`,
          method: 'delete',
        },
      },
    }),
    deletePlanSuccess: ['response'],
    deletePlanFail: ['error'],
    deletePlan: (id) => ({
      type: 'plans/DELETE_PLAN',
      payload: {
        id: id,
        request: {
          url: `/membership-plan/${id}`,
          method: 'delete',
        },
      },
    }),
    updatePlanGroupSuccess: ['response'],
    updatePlanGroupFail: ['error'],
    updatePlanGroup: ({ id, planGroup }) => ({
      type: 'plans/UPDATE_PLAN_GROUP',
      payload: {
        request: {
          url: `membership-plan-group/${id}`,
          method: 'patch',
          data: planGroup,
        },
      },
    }),
    updatePlanSuccess: ['response'],
    updatePlanFail: ['error'],
    updatePlan: ({ id, plan }) => ({
      type: 'plans/UPDATE_PLAN',
      payload: {
        request: {
          url: `membership-plan/${id}`,
          method: 'patch',
          data: { ...plan, price: Math.round(plan.price * 100) },
        },
      },
    }),
  },
  { prefix: 'plans/' }
);

// Reducers
const setActivePlan = (state = INITIAL_STATE, { tab, planIndex }) => ({
  ...state,
  activePlan: {
    ...state.activePlan,
    [tab]: planIndex,
  },
});

const setActiveTab = (state = INITIAL_STATE, { activeTab }) => ({
  ...state,
  activeTab,
});

const getPlanGroups = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getPlanGroupsSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    plans: data.map((plan) => {
      plan.plans = plan.plans.map((childPlan) => {
        childPlan.price = childPlan.price / 100;
        return childPlan;
      });
      return plan;
    }),
  };
};

const getPlanGroupsFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const createPlanGroup = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createPlanGroupSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    plans: [...state.plans, { ...data, plans: [] }],
  };
};

const createPlanGroupFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const createPlan = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createPlanSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    //  plans: [...state.plans, data],
  };
};

const createPlanFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updatePlan = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updatePlanSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    //  plans: [...state.plans, data],
  };
};

const updatePlanFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
});

const deletePlanGroup = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const deletePlanGroupSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    plans: state.plans.filter((plan) => plan.id !== id),
  };
};

const deletePlanGroupFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const deletePlan = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const deletePlanSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    plans: state.plans.map((plan) => {
      plan.plans = plan.plans.filter((childPlan) => childPlan.code !== id);
      return plan;
    }),
  };
};

const deletePlanFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updatePlanGroup = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updatePlanGroupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const updatePlanGroupFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  processing: false,
  error: toFormErrorObject(payload.response),
});

const locationChange = (state = INITIAL_STATE) => ({
  ...state,
  error: {},
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_PLAN]: setActivePlan,
  [Types.SET_ACTIVE_TAB]: setActiveTab,
  [Types.GET_PLAN_GROUPS]: getPlanGroups,
  [Types.GET_PLAN_GROUPS_SUCCESS]: getPlanGroupsSuccess,
  [Types.GET_PLAN_GROUPS_FAIL]: getPlanGroupsFail,
  [Types.CREATE_PLAN_GROUP]: createPlanGroup,
  [Types.CREATE_PLAN_GROUP_SUCCESS]: createPlanGroupSuccess,
  [Types.CREATE_PLAN_GROUP_FAIL]: createPlanGroupFail,
  [Types.DELETE_PLAN_GROUP]: deletePlanGroup,
  [Types.DELETE_PLAN_GROUP_SUCCESS]: deletePlanGroupSuccess,
  [Types.DELETE_PLAN_GROUP_FAIL]: deletePlanGroupFail,
  [Types.UPDATE_PLAN_GROUP]: updatePlanGroup,
  [Types.UPDATE_PLAN_GROUP_SUCCESS]: updatePlanGroupSuccess,
  [Types.UPDATE_PLAN_GROUP_FAIL]: updatePlanGroupFail,
  [Types.CREATE_PLAN]: createPlan,
  [Types.CREATE_PLAN_SUCCESS]: createPlanSuccess,
  [Types.CREATE_PLAN_FAIL]: createPlanFail,
  [Types.DELETE_PLAN]: deletePlan,
  [Types.DELETE_PLAN_SUCCESS]: deletePlanSuccess,
  [Types.DELETE_PLAN_FAIL]: deletePlanFail,
  [Types.UPDATE_PLAN]: updatePlan,
  [Types.UPDATE_PLAN_SUCCESS]: updatePlanSuccess,
  [Types.UPDATE_PLAN_FAIL]: updatePlanFail,
  [LOCATION_CHANGE]: locationChange,
});

export { Creators, Types, Reducer };
