import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { goBack } from 'connected-react-router';

import { Creators } from '../reducer';
import ChildPlanForm from './ChildPlanForm';
import TopBar from './TopBar2';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const CreateChildPlan = ({
  classes,
  createPlan,
  error,
  navigateBack,
  plans,
}) => {
  return (
    <div className={classes.container}>
      <TopBar onBack={navigateBack} title='create new child plan' />
      <div className={classes.content}>
        <ChildPlanForm
          buttonText={'Create'}
          error={error}
          parentPlans={plans}
          onSubmit={createPlan}
          onCancel={navigateBack}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ plan: { error, processing, plans } }) => ({
  error,
  processing,
  plans,
});

const mapDispatchToProps = {
  navigateBack: goBack,
  createPlan: Creators.createPlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateChildPlan));
