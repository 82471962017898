import React from 'react';
import { connect } from 'react-redux';

import { Creators } from './reducer';
import { Modal as ModalComponent } from 'components';
import DeleteConfirmation from 'components/DeleteConfirmation';
import DuplicateConfirmation from 'components/DuplicateConfirmation';
import CloseQuestionAndAnswer from 'components/CloseQuestionAndAnswer';

const Modal = ({ hideModal, isOpen, modalType, callback, text }) => {
  const getModal = () => {
    if (modalType === 'deleteConfirmation') {
      return (
        <DeleteConfirmation
          hideModal={hideModal}
          callback={callback}
          text={text}
        />
      );
    }

    if (modalType === 'duplicateConfirmation') {
      return (
        <DuplicateConfirmation
          hideModal={hideModal}
          callback={callback}
          text={text}
        />
      );
    }
    if (modalType === 'closeQuestionAndAnswer') {
      return (
        <CloseQuestionAndAnswer
          hideModal={hideModal}
          callback={callback}
          text={text}
        />
      );
    }
  };

  return (
    <ModalComponent isOpen={isOpen} hideModal={hideModal}>
      {getModal()}
    </ModalComponent>
  );
};

const mapDispatchToProps = { hideModal: Creators.hideModal };

const mapStateToProps = ({ modal }) => ({
  isOpen: modal.isOpen,
  modalType: modal.modalType,
  callback: modal.callback,
  text: modal.text,
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
