import React from 'react';
import List from './List';
import { Helmet } from 'react-helmet';

export default () => (
  <>
    <Helmet>
      <title>Daily Classes</title>
    </Helmet>
    <List
      isDailyClassList
      title='Daily Classes'
      workoutKey='daily-class'
      workoutType='dailyClass'
      orderByField='activeSince'
      orderByDirection='desc'
    />
  </>
);
