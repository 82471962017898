import React from 'react';

export default ({ fill, height = 14, width = 14 }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.15156 2.75725C1.99082 2.75725 1.83666 2.8211 1.72301 2.93476C1.60935 3.04842 1.5455 3.20257 1.5455 3.36331V11.8482C1.5455 12.0089 1.60935 12.1631 1.72301 12.2767C1.83666 12.3904 1.99082 12.4542 2.15156 12.4542H10.6364C10.7971 12.4542 10.9513 12.3904 11.065 12.2767C11.1786 12.1631 11.2425 12.0089 11.2425 11.8482V8.6118C11.2425 8.27708 11.5138 8.00574 11.8485 8.00574C12.1832 8.00574 12.4546 8.27708 12.4546 8.6118V11.8482C12.4546 12.3304 12.263 12.7928 11.9221 13.1338C11.5811 13.4748 11.1186 13.6663 10.6364 13.6663H2.15156C1.66934 13.6663 1.20688 13.4748 0.865907 13.1338C0.524932 12.7928 0.333374 12.3304 0.333374 11.8482V3.36331C0.333374 2.8811 0.524932 2.41864 0.865907 2.07766C1.20688 1.73669 1.66934 1.54513 2.15156 1.54513H5.38792C5.72264 1.54513 5.99398 1.81647 5.99398 2.15119C5.99398 2.48591 5.72264 2.75725 5.38792 2.75725H2.15156Z'
      fill={fill}
    />
    <path
      d='M10.2079 0.510519C10.4445 0.273837 10.8283 0.273837 11.065 0.510519L13.4892 2.93476C13.7259 3.17144 13.7259 3.55518 13.4892 3.79186L7.42859 9.85247C7.31493 9.96612 7.16078 10.03 7.00004 10.03H4.5758C4.24108 10.03 3.96974 9.75864 3.96974 9.42392V6.99967C3.96974 6.83894 4.03359 6.68478 4.14725 6.57113L10.2079 0.510519ZM5.18186 7.25071V8.81786H6.749L12.2035 3.36331L10.6364 1.79617L5.18186 7.25071Z'
      fill={fill}
    />
  </svg>
);
