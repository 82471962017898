import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button, Dialog } from 'components';

import binImg from 'assets/svg/bin.svg';
import starImg from 'assets/svg/star.svg';
import starFullImg from 'assets/svg/star-full.svg';

const styles = ({ breakpoints, palette: { primary } }) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  container: {
    cursor: 'pointer',
  },
  dialogText: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    textTransform: 'uppercase',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 632,
    justifyContent: 'space-between',
    padding: 64,
    [breakpoints.down('xs')]: {
      padding: 16,
    },
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
    [breakpoints.down('xs')]: {
      marginTop: '5px',
      width: '100%',
    },
  },
});

const Favorite = ({
  classes,
  isDeleteMode = false,
  isFavorite,
  itemTitle = '',
  onAddFavorite,
  onDeleteFavorite,
  processing,
  size = 24,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const iconStyle = { height: size, width: size };

  return (
    <div className={classes.container}>
      {processing && <CircularProgress size={size} />}
      {!processing &&
        (isDeleteMode ? (
          <img
            alt='trash can'
            onClick={(e) => {
              e.stopPropagation();
              setIsDialogOpen(true);
            }}
            src={binImg}
            style={iconStyle}
          />
        ) : isFavorite ? (
          <div>
            <img
              alt={'Delete favorite'}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteFavorite();
                console.log("DELETED FROM FAV")

              }}
              src={starFullImg}
              style={iconStyle}
            />
          </div>
        ) : (
          <div>
            <img
              alt={'Add Favorite'}
              onClick={(e) => {
                e.stopPropagation();
                onAddFavorite();
                console.log("ADDED TO FAV")
              }}
              src={starImg}
              style={iconStyle}
            />
          </div>
        ))}
        
      <Dialog open={isDialogOpen}>
        <div className={classes.dialog}>
          <div className={classes.dialogText}>
            {`Are you sure you want to remove "${itemTitle}" from favorites?`}
          </div>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button
                title='cancel'
                outlined
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDialogOpen(false);
                }}
              />
            </div>
            <div className={classes.input}>
              <Button
                title='remove'
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteFavorite();
                  setIsDialogOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(Favorite);
