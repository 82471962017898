import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cs from 'classnames';

import tightMuscles from 'assets/img/tight-muscles@2x.jpg';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: primary.hunterGreen,
    [breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  dividerText: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.56,
    color: primary.main,
    [breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  text: {
    fontSize: 18,
    lineHeight: 1.56,
    color: primary,
    maxWidth: 470,
    [breakpoints.down('xs')]: {
      maxWidth: 'none',
      fontSize: 16,
    },
  },
  textCursive: {
    fontStyle: 'italic',
  },
  divider: {
    width: '115%',
    'border-bottom': `1px solid ${primary.main}`,
    marginLeft: '-25%',
  },
  image: {
    width: '100%',
    maxWidth: 704,
    maxHeight: 485,
    height: 'auto',
  },
}));

const TightMusclesBlock = () => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        pr={{ xs: '15px', sm: 5 }}
        pl={{
          xs: '15px',
          sm: '10%',
        }}
        width='100%'
      >
        <Typography className={classes.dividerText} variant='h4'>
          PREVENT INJURIES
        </Typography>
        <div className={classes.divider} />
        <Typography className={classes.title} variant='h4'>
          TIGHT MUSCLES
        </Typography>
        <Typography className={classes.text} variant='body1'>
          It all boils down to the fact that most people are not moving enough
          and sitting way too much. This leads to tight muscles, and tight
          joints. These tight muscles and joints then lead to compensations in
          other areas of the body, which can eventually lead to pain and injury.
          <br />
          <br />
          He also noticed that people’s performance in their day-to-day life and
          in the gym was suffering due to pain and these tight areas of their
          body.
          <br />
          <br />
        </Typography>
        <Typography
          className={cs(classes.text, classes.textCursive)}
          variant='body1'
        >
          “When you are dealing with pain everyday and/or tight areas of your
          body, your life isn’t as good as it could be. You are simply not
          living up to your potential.”
        </Typography>
      </Box>
      <Box
        display='flex'
        justifyContent={{
          xs: 'center',
          sm: 'flex-end',
        }}
        alignItems='center'
        width='100%'
        pt={{
          xs: 5,
          sm: 0,
        }}
        px={{
          xs: 5,
          sm: 0,
        }}
      >
        <img src={tightMuscles} alt='our-mission' className={classes.image} />
      </Box>
    </Box>
  );
};

export default TightMusclesBlock;
