import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    padding: '0px 16px',
  },
});

const SearchBar = ({ children, classes }) => (
  <div className={classes.container}>{children}</div>
);

export default withStyles(styles)(SearchBar);
