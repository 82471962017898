import React from 'react';
import { connect } from 'react-redux';

import Preview from './Preview';

const RecoveryPreview = ({ id }) => {
  return <Preview workoutType='recovery' title='Recovery' workoutId={id} />;
};

const mapStateToProps = (
  _,
  {
    match: {
      params: { id },
    },
  }
) => ({
  id,
});

export default connect(mapStateToProps)(RecoveryPreview);
