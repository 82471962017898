import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import cs from 'classnames';

import heroImg from 'assets/img/choose-plan.png';
import avatarFrameImg from 'assets/img/avatar-frame.png';
import placeholderAvatar from 'assets/svg/avatar.svg';
import inviteImg from 'assets/img/image-invite-friends.png';

import ReadOnlyField from './ReadOnlyField';
import chevronLeftImg from '../../../../assets/svg/chevron-left.svg';
import { Box, Popover } from '@material-ui/core';
import arrowRight from '../../../../assets/svg/arrow-right.svg';
import { Button } from '../../../../components';
import InviteModal from './InviteModal';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import copy from '../../../../assets/svg/copy.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InputAdornment from '@material-ui/core/InputAdornment';
import { WEBSITE_URL } from 'core/constants';
import { MixpanelEvents, track } from 'core/utils/mixpanel';

const styles = ({ palette: { primary }, breakpoints }) => ({
  container: {
    width: '100%',
    height: '100%',
  },
  header: {
    alignItems: 'center',
    background: `url(${heroImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 316,
    display: 'flex',
    paddingLeft: 48,
    width: '100%',
    [breakpoints.down('sm')]: {
      height: 'auto',
      paddingLeft: 0,
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 0',
    },
  },
  headerImage: {
    position: 'relative',
    height: 160,
    width: 160,
    '& > img': {
      position: 'absolute',
    },
    '& > img:first-child': {
      height: 160,
      width: 160,
    },
    '& > img:last-child': {
      top: 12,
      left: 12,
      height: 136,
      objectFit: 'cover',
      width: 136,
    },
  },
  headerContent: {
    color: primary.contrastText,
    paddingLeft: 32,
    fontSize: 20,
    letterSpacing: 'normal',
    '& > div:first-child': {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 2,
    },
    [breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 32,
    fontWeight: 900,
    lineHeight: 1.59,
    letterSpacing: 0.47,
    color: primary.hunterGreen,
    [breakpoints.down('xs')]: {
      fontSize: 24,
      lineHeight: 1.42,
      letterSpacing: 0.35,
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 1.75,
    color: primary.generalText,
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  howItWorksLink: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 0.24,
    color: primary.main,
  },
  howItWorksText: {
    fontSize: 16,
    lineHeight: 1.63,
    color: primary.generalText,
  },
  button: {
    width: 208,
    height: 64,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  action: {
    color: `${primary.main}`,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingLeft: '5px',
  },
});

const GeneralInviteContent = ({
  classes,
  onHowItWorksClick,
  user,
  onInviteClick,
}) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const codeCopyRef = useRef(null);
  const linkCopyRef = useRef(null);
  const [copyPopoverAnchor, setCopyPopoverAnchor] = useState(null);
  const [popoverTimeout, setPopoverTimeout] = useState(null);
  const { affiliateCode } = user;
  const url = `${WEBSITE_URL}invite/${affiliateCode}`;

  const handleCopyClick = (copyElement) => {
    setIsCopiedToClipboard(true);
    setCopyPopoverAnchor(copyElement);
    const timeout = setTimeout(() => setIsCopiedToClipboard(false), 750);
    setPopoverTimeout(timeout);
  };

  const handleOnClickCopyCode = () => {
    handleCopyClick(codeCopyRef?.current);
    track(MixpanelEvents.inviteCodeCopied);
  };

  const handleOnClickCopyLink = () => {
    handleCopyClick(linkCopyRef?.current);
    track(MixpanelEvents.inviteLinkCopied);
  };

  return (
    <Box py={5} px={{ xs: '15px', sm: 5 }}>
      <NavLink
        exact
        to='/dashboard/home'
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <Box display='flex'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='flex-start'
            pt='10px'
            mr={1}
          >
            <img height={32} width={32} src={chevronLeftImg} alt='arrow left' />
          </Box>
          <Box className={classes.title}>
            Earn <span style={{ color: '#28aae2' }}>$5</span> For Inviting
            Friends
          </Box>
        </Box>
      </NavLink>
      <Box py={2}>
        <div className={classes.subtitle}>
          You can earn a $5 Movement Vault credit for every friend you refer.
          Your friends will also receive a $5 credit.
        </div>
      </Box>
      <Box py={2}>
        <span
          className={cs(classes.howItWorksLink, classes.cursorPointer)}
          onClick={onHowItWorksClick}
        >
          How invites work
        </span>
        <img
          src={arrowRight}
          alt='arrow right'
          height={10}
          width={10}
          onClick={onHowItWorksClick}
        />
      </Box>
      {/* <Box>
        <ReadOnlyField
          id='standard-read-only-input'
          label='Your Invite Code'
          defaultValue={affiliateCode}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <CopyToClipboard
                  text={affiliateCode}
                  onCopy={handleOnClickCopyCode}
                >
                  {isSmallScreen ? (
                    <img
                      ref={codeCopyRef}
                      height={19}
                      width={16}
                      src={copy}
                      alt='copy'
                    />
                  ) : (
                    <div ref={codeCopyRef} className={classes.action}>
                      Copy code
                    </div>
                  )}
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          isSmallScreen={isSmallScreen}
        />
      </Box> */}
      <Box>
        <ReadOnlyField
          id='standard-read-only-input'
          label='Your Invite Link'
          defaultValue={url}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <CopyToClipboard text={url} onCopy={handleOnClickCopyLink}>
                  {isSmallScreen ? (
                    <img
                      ref={linkCopyRef}
                      height={19}
                      width={16}
                      src={copy}
                      alt='copy'
                    />
                  ) : (
                    <div ref={linkCopyRef} className={classes.action}>
                      Copy link
                    </div>
                  )}
                </CopyToClipboard>
              </InputAdornment>
            ),
          }}
          variant='outlined'
          isSmallScreen={isSmallScreen}
        />
      </Box>
      <Box py={4}>
        <Button
          className={classes.button}
          title='invite friends'
          type='button'
          onClick={onInviteClick}
        />
      </Box>
      <Popover
        open={isCopiedToClipboard}
        anchorEl={copyPopoverAnchor}
        onClose={() => {
          if (popoverTimeout) {
            clearTimeout(popoverTimeout);
            setPopoverTimeout(null);
          }
          setIsCopiedToClipboard(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={1.25}>Copied!</Box>
      </Popover>
    </Box>
  );
};

const HowInviteWorksContent = ({ classes, onTitleClick, onInviteClick }) => {
  return (
    <Box p={5}>
      <Box display='flex' style={{ color: 'inherit' }} onClick={onTitleClick}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='flex-start'
          pt='10px'
          mr={1}
        >
          <img
            height={32}
            width={32}
            src={chevronLeftImg}
            alt='arrow left'
            className={classes.cursorPointer}
          />
        </Box>
        <Box className={cs(classes.title, classes.cursorPointer)}>
          How invites work
        </Box>
      </Box>
      <Box py={4} className={classes.howItWorksText}>
        Everytime a new Movement Vault user signs up with your invite code or
        invite link, they will receive a $5 credit that can be used when they
        sign up.
        <br />
        <br />
        After they sign up for Movement Vault premium, you will also receive a
        $5 credit that can be used toward your Movement Vault subscription.
        <br />
        <br />
        There is no limit to the amount of people you can invite. These credits
        add up quickly! Before you know it, you will have a year’s worth of free
        Movement Vault. You can share via all social media platforms, text, and
        email.
        <br />
        <br />
        To track and redeem your credits you must use our website.
      </Box>
      <Box py={4}>
        <Button
          className={classes.button}
          title='invite friends'
          type='button'
          onClick={onInviteClick}
        />
      </Box>
    </Box>
  );
};

const Invite = ({ classes, user }) => {
  const [isGeneralInviteContent, setIsGeneralInviteContent] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnClickInviteFriend = () => {
    setIsModalOpen(true);
    track(MixpanelEvents.pressedInviteFriendsButton);
  };

  const { avatarUrl, firstName, lastName, bio, affiliateCode } = user;
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Invite Friends</title>
      </Helmet>
      <Box display='flex' flexDirection='column' height='100%'>
        <Box className={classes.header}>
          <div className={classes.headerImage}>
            <img src={avatarFrameImg} alt='avatar frame' />
            <img src={avatarUrl || placeholderAvatar} alt='avatar' />
          </div>
          <div className={classes.headerContent}>
            <div>{`${firstName} ${lastName}`}</div>
            <div>{bio}</div>
          </div>
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          height='100%'
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box height='100%'>
            {isGeneralInviteContent && (
              <GeneralInviteContent
                user={user}
                classes={classes}
                onInviteClick={handleOnClickInviteFriend}
                onHowItWorksClick={() => setIsGeneralInviteContent(false)}
              />
            )}
            {!isGeneralInviteContent && (
              <HowInviteWorksContent
                classes={classes}
                onInviteClick={handleOnClickInviteFriend}
                onTitleClick={() => setIsGeneralInviteContent(true)}
              />
            )}
          </Box>
          <Box minWidth='50%' display='flex'>
            <img src={inviteImg} alt='invite' className={classes.image} />
          </Box>
        </Box>
      </Box>
      <InviteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        affiliateCode={affiliateCode}
        firstName={firstName}
        lastName={lastName}
      />
    </div>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Invite));
