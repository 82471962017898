import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';

const INITIAL_STATE = {
  reminders: [],
  isDirty: false,
  processing: false,
};

const { Types, Creators } = createActions(
  {
    createMovementMomentSuccess: ['response'],
    createMovementMomentFail: ['error'],
    createMovementMoment: (MovementMoment) => {
      const data = {
        ...MovementMoment,
        key: MovementMoment.title.trim().toLowerCase().replaceAll(' ', '-'),
      };

      return {
        type: 'movementMoment/CREATE_MOVEMENT_MOMENT',
        payload: {
          request: {
            url: '/notifications/movement-moment',
            method: 'post',
            data,
          },
        },
      };
    },
    updateMovementMomentSuccess: ['response'],
    updateMovementMomentFail: ['error'],
    updateMovementMoment: ({ id, moment }) => ({
      type: 'movementMoment/UPDATE_MOVEMENT_MOMENT',
      payload: {
        id: id,
        request: {
          url: `/notifications/movement-moment/${id}`,
          method: 'patch',
          data: moment,
        },
      },
    }),
    getMovementMomentsSuccess: ['response'],
    getMovementMoments: () => ({
      type: 'movementMoment/GET_MOVEMENT_MOMENTS',
      payload: {
        request: {
          url: '/notifications/movement-moment',
          method: 'get',
        },
      },
    }),
    deleteMovementMomentSuccess: ['response'],
    deleteMovementMomentFail: ['error'],
    deleteMovementMoment: (id) => ({
      type: 'movementMoment/DELETE_MOVEMENT_MOMENT',
      payload: {
        request: {
          url: `/notifications/movement-moment/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
  },
  { prefix: 'movementMoment/' }
);

const getMovementMoments = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createMovementMoment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createMovementMomentSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    reminders: [...state.reminders, { ...data }],
  };
};

const createMovementMomentFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updateMovementMoment = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateMovementMomentSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: { data },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    reminders: state.reminders.map((reminder) => {
      if (reminder.id === id) {
        reminder = data;
      }
      return reminder;
    }),
  };
};

const updateMovementMomentFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const getMovementMomentsSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  console.log('in reducer');
  console.log(data);

  return {
    ...state,
    reminders: data,
    processing: false,
  };
};

const deleteMovementMomentSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    reminders: state.reminders.filter((e) => e.id !== id),
  };
};

const deleteMovementMomentFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_MOVEMENT_MOMENT]: createMovementMoment,
  [Types.CREATE_MOVEMENT_MOMENT_SUCCESS]: createMovementMomentSuccess,
  [Types.CREATE_MOVEMENT_MOMENT_FAIL]: createMovementMomentFail,
  [Types.UPDATE_MOVEMENT_MOMENT]: updateMovementMoment,
  [Types.UPDATE_MOVEMENT_MOMENT_SUCCESS]: updateMovementMomentSuccess,
  [Types.UPDATE_MOVEMENT_MOMENT_FAIL]: updateMovementMomentFail,
  [Types.GET_MOVEMENT_MOMENTS]: getMovementMoments,
  [Types.GET_MOVEMENT_MOMENTS_SUCCESS]: getMovementMomentsSuccess,
  [Types.DELETE_MOVEMENT_MOMENT_SUCCESS]: deleteMovementMomentSuccess,
  [Types.DELETE_MOVEMENT_MOMENT_FAIL]: deleteMovementMomentFail,
});

export { Creators, Types, Reducer };
