import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

function Loader({ height = '100%' }) {
  return (
    <Box
      p={4}
      display='flex'
      alignItems='center'
      justifyContent='center'
      height={height}
    >
      <CircularProgress />
    </Box>
  );
}

export default Loader;
