import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { Creators } from 'modules/auth';
import { BASE_URL, PUBLIC_ENDPOINTS, TOKEN } from './constants';
import { createRootReducer } from './reducer';
import rootSaga from './saga';
import { verifyToken } from './utils/auth';
import * as localStorage from './utils/localStorage';

const middleware = [];
const enhancers = [];

const middlewareConfig = {
  onError: ({ action, dispatch, error }) => {
    dispatch({
      type: action.type + '_FAIL',
      payload: { response: error?.response?.data },
    });
  },
  interceptors: {
    request: [
      async function ({ dispatch }, req) {
        if (!PUBLIC_ENDPOINTS.includes(req.url)) {
          const token = localStorage.getItem(TOKEN);

          if (token) {
            const [tokenVerified, updatedToken] = await verifyToken();

            if (tokenVerified) {
              req.headers.Authorization = `Bearer ${updatedToken || token}`;
            } else {
              dispatch(Creators.signOut());
            }
          }
        }
        return req;
      },
    ],
  },
};

const client = axios.create({
  baseURL: BASE_URL,
  responseType: 'json',
});

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

middleware.push(axiosMiddleware(client, middlewareConfig));
middleware.push(sagaMiddleware);
middleware.push(routerMiddleware(history));
enhancers.push(applyMiddleware(...middleware));

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(...enhancers)
);

sagaMiddleware.run(rootSaga);
