import React from 'react';
import { connect } from 'react-redux';

import Preview from './Preview';

const WorRoutinePreview = ({ id }) => {
  return (
    <Preview workoutType='workRoutine' title='Work Routine' workoutId={id} />
  );
};

const mapStateToProps = (
  _,
  {
    match: {
      params: { id },
    },
  }
) => ({
  id,
});

export default connect(mapStateToProps)(WorRoutinePreview);
