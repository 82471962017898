import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { history, theme } from 'core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';
import { withStyles } from '@material-ui/styles';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { store, Routes } from 'core';
import { Modal } from 'modules/modal';
import { initializeMixpanel } from 'core/utils/mixpanel';
import { RecoilRoot } from 'recoil';

initializeMixpanel();

const styles = () => ({
  toastr: {
    opacity: 1,
    '& *': {
      opacity: '1 !important',
    },
  },
});

function App({ classes }) {
  
  return (
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes />
            <Modal />
            <ReduxToastr
              className={classes.toastr}
              closeOnToastrClick
              getState={(state) => state.toastr}
              newestOnTop={false}
              position='top-left'
              preventDuplicates
              progressBar
              timeOut={4000}
              transitionIn='fadeIn'
              transitionOut='fadeOut'
            />
          </ConnectedRouter>
        </Provider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default withStyles(styles)(App);
