import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

import { Button, Checkbox, Link, SearchInput, Select } from 'components';
import {
  SearchBar as SearchBarLayout,
  TagFilter,
} from 'modules/admin/dashboard';

import bin from 'assets/svg/bin.svg';

const styles = ({ palette: { secondary } }) => ({
  aligned: {
    alignItems: 'center',
    display: 'flex',
  },
  button: {
    textTransform: 'capitalize',
    width: 210,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  deleteButton: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    marginLeft: 8,
    '& > img': {
      width: 19,
      height: 20,
    },
    '& > span': {
      color: secondary.red,
      fontSize: 14,
      paddingLeft: 16,
    },
  },
  label: {
    color: secondary.grayText,
    fontSize: 14,
    marginRight: 8,
  },
  ml: {
    marginLeft: 16,
  },
});

const SearchBar = ({
  buttonTitle,
  baseUri,
  classes,
  filter,
  onMonthSelected,
  onBulkDelete,
  onSelectAll,
  onSearchChanged,
  onTagFilterChanged,
  showDelete = false,
  selectAllChecked,
}) => {
  const {
    palette: { primary },
  } = useTheme();

  const INITIAL_TAG_FILTER_SELECTIONS = {
    Status: [],
    'Usage Area': [],
    Difficulty: [],
    'Frontend Visible': [],
    'Movement Prep': [],
    Equipment: [],
    'Area of the Body': [],
    Modality: [],
    Muscles: [],
    'Workout Type': [],
  };

  const getMonthOptions = () => {
    const MONTHS = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let currentYear;
    let currentMonth;
    let monthOptions = [];

    // If December start from the next year January
    if (new Date().getMonth() === 11) {
      currentYear = new Date().getFullYear() + 1;
      currentMonth = 0;
    } else {
      currentYear = new Date().getFullYear();
      currentMonth = new Date().getMonth() + 1;
    }

    for (let i = 0; i < 12; i++) {
      monthOptions.push({
        label: `${MONTHS[currentMonth]} ${currentYear}`,
        value: `${currentMonth}-${currentYear}`,
      });

      currentMonth--;

      if (currentMonth === -1) {
        currentMonth = 11;
        currentYear--;
      }
    }

    return monthOptions;
  };

  return (
    <SearchBarLayout>
      <div className={classes.container}>
        <div className={classes.aligned}>
          <Checkbox
            checked={selectAllChecked}
            label='Select All'
            labelClass={classes.label}
            onCheck={() => onSelectAll(!selectAllChecked)}
          />
          {showDelete && (
            <div onClick={onBulkDelete} className={classes.deleteButton}>
              <img src={bin} alt='bin' />
              <span>Delete</span>
            </div>
          )}
        </div>
        <div className={classes.aligned}>
          <div className={cx(classes.aligned, classes.ml)}>
            <label className={classes.label}>Filter by:</label>
            <Select
              onChange={(e) => onMonthSelected(e.value)}
              options={[
                { label: 'All Months', value: '' },
                ...getMonthOptions(),
              ]}
              placeholder='Month'
              placeholderColor={primary.dark}
              width={120}
              value={filter.month}
            />
          </div>
          <div className={cx(classes.aligned, classes.ml)}>
            <label className={classes.label}>Tag Filter:</label>
            <TagFilter
              initialSelections={INITIAL_TAG_FILTER_SELECTIONS}
              onSelectionsChange={onTagFilterChanged}
              selections={filter.tags}
            />
          </div>
          <div className={classes.ml}>
            <SearchInput onClick={onSearchChanged} value={filter.search} />
          </div>
          <div className={classes.ml}>
            <Link style={{ textDecoration: 'none' }} to={`${baseUri}/create`}>
              <Button className={classes.button} title={buttonTitle} />
            </Link>
          </div>
        </div>
      </div>
    </SearchBarLayout>
  );
};

export default withStyles(styles)(SearchBar);
