import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { goBack } from 'connected-react-router';

import ChildPlanForm from './ChildPlanForm';
import { Creators } from '../reducer';
import TopBar from './TopBar2';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const EditChildPlan = ({
  childPlan,
  classes,
  error,
  getPlans,
  id,
  navigateBack,
  plans,
  updatePlan,
}) => {
  React.useEffect(() => {
    if (plans === null) {
      getPlans();
    }
  }, [getPlans, plans]);

  if (plans.length < 1) return null;

  const onSubmit = (values) => updatePlan({ id: id, plan: values });
  return (
    <div className={classes.container}>
      <TopBar onBack={navigateBack} title='edit child plan' />
      <div className={classes.content}>
        <ChildPlanForm
          buttonText={'Save'}
          error={error}
          onCancel={navigateBack}
          onSubmit={onSubmit}
          parentPlans={plans}
          plan={childPlan}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (
  { plan: { error, plans, processing } },
  {
    match: {
      params: { id },
    },
  }
) => ({
  error,
  id,
  processing,
  plans,
  childPlan: plans.reduce((acc, parentPlan) => {
    const result = parentPlan.plans.find((plan) => plan.code === id);
    if (result) {
      acc.push({
        ...result,
        parentPlan: parentPlan.id,
        trialPeriod: result.trial,
      });
    }
    return acc;
  }, [])[0],
});

const mapDispatchToProps = {
  navigateBack: goBack,
  updatePlan: Creators.updatePlan,
  getPlans: Creators.getPlanGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditChildPlan));
