import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = ({ breakpoints, palette: { primary } }) => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    paddingBottom: 64,
    [breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  description: {
    whiteSpace: 'pre-line',
    fontSize: 24,
    lineHeight: 1.5,
    color: primary.generalText,
    [breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  title: {
    color: primary.black,
    fontSize: 32,
    lineHeight: 2,
    fontWeight: '900',
    [breakpoints.down('xs')]: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 1.42,
      letterSpacing: '0.35px',
    },
  },
  text: {
    whiteSpace: 'pre-line',
    color: primary.black,
    fontSize: 18,
    lineHeight: 1.5,
    marginTop: 20,
  },
  video: {
    padding: '32px 0px',
  },
});

const Details = ({ classes, item: { description, text, title, video } }) => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      {description && <div className={classes.description}>{description}</div>}
      {video && (
        <div className={classes.video}>
          <video preload='metadata' controls style={{ maxWidth: '100%' }}>
            <source src={`${video}#t=0.1`} type='video/mp4' />
          </video>
        </div>
      )}
      {text && <div className={classes.text}>{text}</div>}
    </div>
  );
};

export default withStyles(styles)(Details);
