import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Helmet } from 'react-helmet';

const styles = (theme) => ({
  container: {
    borderRadius: 6,
    color: theme.palette.primary.generalText,
    background: theme.palette.background.paper,
    fontSize: 16,
    maxWidth: 800,
    lineHeight: 1.7,
    padding: 40,
    width: '75vw',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0',
      width: 'auto',
    },
  },
  layoutContent: {
    display: 'block',
  },
  label: {
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  link: {
    color: theme.palette.primary.generalText,
    fontWeight: 'bold',
    lineBreak: 'anywhere',
    paddingLeft: 3,
    paddingRight: 1,
  },
  list: {
    '& li': {
      marginBottom: 16,
    },
  },
  paragraph: {
    fontSize: 16,
    lineHeight: 1.7,
    marginBottom: 16,
    marginTop: 16,
  },
  pl3: {
    paddingLeft: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.5,
    marginBottom: 16,
    marginTop: 32,
    textTransform: 'uppercase',
  },
  subtitleSmall: {
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.8,
    marginBottom: 16,
    marginTop: 32,
    textTransform: 'uppercase',
  },
  title: {
    color: theme.palette.primary.dark,
    fontSize: 36,
    fontWeight: 900,
    marginBottom: 36,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

export const ToS = ({ classes, showTitle = true }) => {
  const Contact = () => (
    <a
      className={classes.link}
      href='mailto:support@movementvault.com'
      target='_blank'
      rel='noopener noreferrer'
    >
      support@movementvault.com
    </a>
  );

  const Link = ({ to }) => (
    <a
      className={classes.link}
      href={to}
      target='_blank'
      rel='noopener noreferrer'
    >
      {to}
    </a>
  );

  return (
    <Box display='flex' justifyContent='center' pl='5vw' pr='5vw'>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <div className={classes.container}>
        {showTitle && <div className={classes.title}>Terms and Conditions</div>}
        <div className={classes.subtitle}>1. GENERAL INFORMATION</div>
        <div className={classes.subtitleSmall}>1.1 MOVEMENT VAULT PRODUCTS</div>
        <div className={classes.paragraph}>
          These Terms & Conditions (these “Terms”) contain the terms and
          conditions on which we supply content, products or services listed on
          <Link to='www.movementvault.com' /> (the “Website”), through our
          applications (the “Apps”) or via other delivery methods to you (the
          Website and such content, products, services and the Apps are
          collectively referred to herein as the “Product” or “Products”, which
          may be updated from time-to-time at the sole discretion of Movement
          Vault). Please read these terms and conditions, carefully before
          ordering any Products from the Website or third party App stores (e.g.
          the Apple App Store, the Android Play Store, Amazon, etc.). The terms
          “Movement Vault,” “us” or “we” refers to Movement Vault, Inc. and its
          wholly owned UK based subsidiary, Movement Vault Meditation Limited.
          The term “Device” refers to the device which is used to access the
          Products including but not limited to computers, smart phones and
          tablets. The term “you” refers to the user of the Products. When you
          order (“Order”) any Products, or otherwise use or access the Products,
          you agree to be bound by these Terms and all applicable laws, rules
          and regulations. You may also be asked to click “I accept” at the
          appropriate place prior to your purchase of access to the Products. At
          such time, if you do not click “I accept”, you may not be able to
          complete such purchase or gain such access. By using the Products, you
          indicate that you accept these Terms and that you agree to abide by
          them. If you do not agree to these Terms, please refrain from using
          the Products.
        </div>
        <div className={classes.paragraph}>
          Our contact email address is <Contact /> All correspondence to
          Movement Vault including any queries you may have regarding your use
          of the Products or these Terms should be sent to this contact email
          address.
        </div>
        <div className={classes.subtitleSmall}>
          1.2 ARBITRATION NOTICE AND CLASS ACTION WAIVER
        </div>
        <div className={classes.paragraph}>
          PLEASE NOTE THAT THESE TERMS CONTAIN AN ARBITRATION CLAUSE. EXCEPT FOR
          CERTAIN TYPES OF DISPUTES MENTIONED IN THE ARBITRATION CLAUSE, YOU AND
          MOVEMENT VAULT AGREE THAT DISPUTES RELATING TO THESE TERMS OR YOUR USE
          OF THE PRODUCTS WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, AND
          YOU AND MOVEMENT VAULT WAIVE ANY RIGHT TO PARTICIPATE IN A
          CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </div>
        <div className={classes.subtitleSmall}>1.3 BASIS OF LICENSE</div>
        <div className={classes.paragraph}>
          (a) These Terms and the Order set out the whole agreement between you
          and us for the supply of the Products. In order to participate in
          certain Products, you may be required to agree to additional terms and
          conditions; those additional terms are hereby incorporated into these
          Terms. Where such terms are inconsistent with these Terms, the
          additional terms shall control.
        </div>
        <div className={classes.paragraph}>
          (b) Please check that the details in these Terms and on the Order are
          complete and accurate before you use or commit yourself to purchase
          the Products. If you think that there is a mistake, please make sure
          that you ask us to confirm any changes in writing, as we only accept
          responsibility for statements and representations made in writing by
          an officer of Movement Vault.
        </div>
        <div className={classes.paragraph}>
          (c) AS PART OF YOUR USE OF THE PRODUCTS, YOU AFFIRMATIVELY CONSENT TO
          THE PROCESSING AND STORAGE OF YOUR PERSONAL INFORMATION IN THE UNITED
          STATES AND THE UNITED KINGDOM, INCLUDING THE PROCESSING AND STORING OF
          YOUR PERSONAL INFORMATION IN THE UNITED STATES AND THE UNITED KINGDOM
          FOR THE PURPOSES OF PROCESSING PAYMENTS AND TRACKING INDIVIDUAL USE OF
          THE PRODUCTS. BY USING THE PRODUCTS, YOU ACKNOWLEDGE THAT YOU
          UNDERSTAND AND AGREE THAT THE UNITED STATES AND THE UNITED KINGDOM MAY
          NOT HAVE THE SAME LEVEL OF PROTECTIONS FOR YOUR PERSONAL INFORMATION
          THAT EXIST IN YOUR COUNTRY OF RESIDENCE, AND YOU NONETHELESS CONSENT
          TO THE PROCESSING AND STORAGE OF YOUR PERSONAL INFORMATION IN THE
          UNITED STATES AND THE UNITED KINGDOM. WE WILL TAKE MEASURES AS
          REQUIRED TO COMPLY WITH APPLICABLE LAW REGARDING THE TRANSFER, STORAGE
          AND USE OF CERTAIN PERSONAL INFORMATION.
        </div>
        <div className={classes.subtitleSmall}>1.4 CHANGES TO TERMS</div>
        <div className={classes.paragraph}>
          Movement Vault reserves the right to change or update these Terms, or
          any other of our policies or practices, at any time, and will notify
          users by posting such changed or updated Terms on this page. Any
          changes or updates will be effective immediately upon posting to
          <Link to='www.movementvault.com' /> Your continued use of the Products
          constitutes your agreement to abide by the Terms as changed. Under
          certain circumstances we may also elect to notify you of changes or
          updates to our Terms by additional means, such as pop-up or push
          notifications within the Products or email.
        </div>
        <div className={classes.subtitle}>2. MEMBERSHIPS AND SUBSCRIPTIONS</div>
        <div className={classes.subtitleSmall}>2.1 BECOMING A MEMBER</div>
        <div className={classes.paragraph}>
          (a) You may sign up as a registered user of the Products free of
          charge (a “Member”). To become a Member you need to go to the relevant
          section of the Products, then submit your email address to us, and
          create a password to be used in conjunction with that email address.
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your Device.
        </div>
        <div className={classes.paragraph}>
          (b) In the course of your use of the Products, you may be asked to
          provide certain personalized information to us (such information is
          referred to hereinafter as “User Information”). This User Information
          may include information from your Facebook and similar social
          networking profiles. Our information collection and use policies with
          respect to the privacy of such User Information are set forth in the
          Movement Vault Privacy Policy. You acknowledge and agree that you are
          solely responsible for the accuracy and content of User Information,
          and you agree to keep it up to date.
        </div>
        <div className={classes.paragraph}>
          (c) By placing an Order through the Products, you warrant that: (i)
          You are legally capable of entering into binding contracts; (ii) All
          registration information you submit is truthful and accurate; (iii)
          You will maintain the accuracy of such information; and (iv) Your use
          of the Products does not violate any applicable law or regulation.
        </div>
        <div className={classes.subtitleSmall}>2.2 ONCE A MEMBER</div>
        <div className={classes.paragraph}>
          You are responsible for maintaining the confidentiality of your
          account, password and other User Information and for restricting
          access to your Device to further help protect such information. You
          are responsible for updating your User Information.
        </div>
        <div className={classes.subtitleSmall}>
          2.3 USE OF MOVEMENT VAULT BY MINORS
        </div>
        <div className={classes.paragraph}>
          You must be 18 years of age, or the age of majority in your province,
          territory or country, to sign up as a registered user of the Products.
          Individuals under the age of 18, or the applicable age of majority,
          may utilize the Products only with the involvement and consent of a
          parent or legal guardian, under such person's account and otherwise
          subject to these Terms.
        </div>
        <div className={classes.subtitleSmall}>2.4 MEMBERSHIP</div>
        <div className={classes.paragraph}>
          As a Movement Vault Member you will receive access to certain
          sections, features and functions of the Products that are not
          available to non-members. By agreeing to become a Member you opt-in to
          receiving occasional special offer, marketing, survey and Product
          based communication emails. You can easily unsubscribe from Movement
          Vault commercial emails by following the opt-out instruction in these
          emails. Movement Vault memberships and subscriptions are not
          transferable and therefore cannot be sold or exchanged or transferred
          in any way whatsoever.
        </div>
        <div className={classes.subtitleSmall}>2.5 SUBSCRIPTIONS</div>
        <div className={classes.paragraph}>
          (a) Movement Vault account holders may access the Products in two
          ways:
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          (i) “Basics” Free Trial: a free-of-charge program, which gives
          unlimited access to ten days of our “Foundation Course.”
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          (ii) Paid Subscription: a subscription fee-based program, which gives
          access to all content including and beyond the “Basics” Free Trial.
          You will only have access to the Subscription Program while your
          subscription is active and subsisting. You may have access to a free
          trial period of the Subscription Program in accordance with certain
          promotional offers. All subscription services provide access through
          the Products. You can become a subscriber to the Subscription Program
          by purchasing a subscription to the Products from the Website, within
          the Apps, where allowed by the App marketplace partners, or through a
          bundle with one or more of our bundle subscription partners. Please
          note that if you purchase a subscription through the Apple iTunes
          Store or our iPhone application, the sale is final, and we will not
          provide a refund. Your purchase will be subject to Apple’s applicable
          payment policy, which also may not provide for refunds. If you
          purchase a subscription through the Google Play store, the sale is
          final and we will not provide a refund. Your purchase will be subject
          to Google’s applicable payment policy, which also may not provide for
          refunds. If you purchase through one or more of our bundle
          subscription partners, the purchase may be further subject to the
          Terms and Conditions of such partners, and payment and management of
          the bundle subscription may be administered by them.
        </div>
        <div className={classes.paragraph}>
          (b) Movement Vault offers monthly, biannual and yearly subscription
          options. For the purposes of our monthly and yearly subscriptions, a
          month constitutes 30 calendar days, biyearly 180 calendar days and a
          year constitutes 365 calendar days.
        </div>
        <div className={classes.paragraph}>
          (c) Our “Monthly” subscription is paid in monthly installments. For
          each month that your monthly subscription is active, you acknowledge
          and agree that Movement Vault is authorized to charge the same credit
          card as was used for the initial subscription fee or other payment
          method as set forth in section 2.5(h) (the “Payment Method”) in the
          amount of the current monthly subscription fee as of the time of
          renewal. The monthly renewal subscription fees will continue to be
          billed to the Payment Method you provided, automatically until
          cancelled. You must cancel your subscription before it renews each
          month in order to avoid billing of the next month’s subscription fee
          to the Payment Method you provided. Refunds cannot be claimed for any
          partial-month subscription period.
        </div>
        <div className={classes.paragraph}>
          (d) Our “Yearly” subscription is paid for by an upfront one-off
          payment with automatic annual renewals. You acknowledge and agree that
          Movement Vault is authorized to charge the Payment Method used for (i
          ) the initial annual subscription fee at the rate secured at the time
          of purchase, and (ii) the renewal subscription fee(s) at the
          non-discounted rate in effect at the time of any such renewal. You
          must cancel your subscription before it renews in order to avoid
          billing of the renewal subscription fee to the Payment Method you
          provided. Refunds cannot be claimed for any partial subscription
          period.
        </div>
        <div className={classes.paragraph}>(f) Subscription</div>
        <div className={classes.paragraph}>
          (g) You agree to promptly notify Movement Vault of any changes to the
          Payment Method you provided while any subscriptions remain
          outstanding. You are responsible for all applicable fees and charges
          incurred, including applicable taxes, and all subscriptions purchased
          by you.
        </div>
        <div className={classes.paragraph}>
          (h) In the course of your use of the Products, Movement Vault and its
          third party payment service provider may receive and implement updated
          credit card information from your credit card issuer in order to
          prevent your subscription from being interrupted by an outdated or
          invalid card. This disbursement of the updated credit card information
          is provided to Movement Vault and Movement Vault’s third party payment
          service provider at the sole election of your credit card issuer. Your
          credit card issuer may give you the right to opt-out of the update
          service. Should you desire to do so, please contact your credit card
          issuer.
        </div>
        <div className={classes.paragraph}>
          (i) Our obligation to provide the Products only comes into being when
          we take receipt of your Order, and we confirm your purchase to you by
          email. We shall confirm your Order and send you an email to confirm
          your access to the subscription purchased. Please quote the Order
          number in all subsequent correspondence with us. Prices in US Dollars
          and Euros include local taxes. All prices in Pound Sterling include
          VAT unless otherwise stated. You agree not to hold us responsible for
          banking charges incurred due to payments on your account. If payment
          is not received by us from the Payment Method you provided, you agree
          to pay all amounts due upon demand by us. You agree that you are not
          permitted to resell any Products purchased through Movement Vault for
          commercial purposes.
        </div>
        <div className={classes.subtitleSmall}>2.6 DEVICE REQUIREMENTS</div>
        <div className={classes.paragraph}>
          To enjoy Movement Vault via your smartphone or other Device, your
          Device must satisfy certain system requirements. These requirements
          can be found on the Website and the Google, Apple and Amazon App
          marketplaces.
        </div>
        <div className={classes.subtitleSmall}>2.7 GIFTING</div>
        <div className={classes.paragraph}>
          “Gift Subscriptions” are pre-paid memberships to the Products. A
          person who purchases the gift is referred to in these terms as the
          “Giftor”. A person who receives and redeems a Gift Subscription to the
          Products is referred to in these terms as the “Recipient”. Gift
          subscriptions are paid for as a one-off upfront payment. Once bought,
          the Giftor will receive an Order confirmation and receipt. The
          Movement Vault gift subscription will be sent to the Recipient on the
          Giftor’s specified date. Gifting codes can only be used once in the
          country for which they were purchased and cannot be redeemed for cash,
          resold or combined with any other offers, including free trial. Please
          note that gifting codes cannot be redeemed if the Recipient has
          already purchased a subscription through the Apple iTunes Store or our
          iPhone application, or the Google Play Store or our Android
          application. We will automatically bill the Payment Method you
          provided for any purchased Gift Subscriptions at the time of purchase,
          not delivery. There are no refunds or other credits for Gift
          Subscription that are not redeemed. Movement Vault will notify the
          Recipient prior to the end of the Gift Subscription that the gift
          period is about to expire. Movement Vault is not responsible if a Gift
          Subscription is lost, stolen or used without permission.
        </div>
        <div className={classes.subtitleSmall}>
          2.8 CORPORATE AND OTHER CONSUMER COMMUNITIES
        </div>
        <div className={classes.paragraph}>
          While Movement Vault is a consumer products company, there is
          increasing interest by large consumer communities (corporations,
          universities, hospitals, etc.) (“Communities”) to introduce the
          Products to their employees and members. In some cases, these
          Communities may supplement these Terms with their own terms and
          conditions. In such event, these Community terms and conditions shall
          also apply to your use of the Products. In the event of any conflict
          with such additional terms and these Terms, these Terms shall prevail.
        </div>
        <div className={classes.subtitleSmall}>
          2.9 CHANGING FEES AND CHARGES
        </div>
        <div className={classes.paragraph}>
          We reserve the right to change our subscription plans or adjust
          pricing for our service or any components thereof in any manner and at
          any time as we may determine in our sole and absolute discretion.
          Except as otherwise expressly provided for in these Terms, any price
          changes or changes to your subscription plan will take effect
          following notice to you.
        </div>
        <div className={classes.subtitle}>3. CANCELLATION OF SERVICES</div>
        <div className={classes.subtitleSmall}>3.1 CANCELLATION BY YOU</div>
        <div className={classes.paragraph}>
          (a) You may cancel a Monthly subscription at any time. Cancellation is
          effective at the end of the applicable monthly period. Please make any
          such cancellation by visiting here or emailing <Contact />.
        </div>
        <div className={classes.paragraph}>
          (b) You may cancel our biyearly and yearly subscription plans within
          the 30-day money back guarantee offer, which entitles you to cancel
          your subscription and have the full cost refunded to you up to 30
          calendar days from your first date of payment, by emailing
          <Contact />. You are entitled to one refund only. After your refund,
          any future subscriptions will no longer qualify for the 30-day money
          back guarantee. No such refunds will apply to subsequent renewals of
          the Yearly subscription or subscriptions purchased through the Apple
          iTunes Store or our iPhone application, or the Google Play Store or
          our Android application.
        </div>
        <div className={classes.paragraph}>
          (c) Please note that if you purchase a subscription through the Apple
          iTunes Store or our iPhone application, you may cancel your
          subscription by cancelling automatic renewal of paid In App
          Subscriptions by selecting Manage App Subscriptions in your iTunes
          Account settings and selecting the subscription you want to modify. If
          you purchase a subscription through the Google Play store you may
          cancel automatic renewals in account settings under Subscriptions in
          the Google Play app, or according to the current process outlined by
          Google Play.
        </div>
        <div className={classes.subtitleSmall}>3.2 CANCELLATION BY US</div>
        <div className={classes.paragraph}>
          We may suspend or terminate your use of the Products as a result of
          your fraud or breach of any obligation under these Terms. Such
          termination or suspension may be immediate and without notice. A
          breach of these Terms, includes without limitation, the unauthorized
          copying or download of our audio or video content from the Products.
        </div>
        <div className={classes.subtitleSmall}>
          3.3 PROMOTION AND DISCOUNT CODES
        </div>
        <div className={classes.paragraph}>
          Any promotion code or offer (including the Special Discount Pricing
          Options) provided by us may not be used in conjunction with any other
          promotion code or offer, past or present. Introductory offers are only
          available to new users of the Products, except where expressly stated
          otherwise. Previous users or trial users of the Products do not
          qualify as new users. No promotion code or discount will apply to
          corporate or other Community subscriptions.{' '}
          <b>
            Unless otherwise set forth in the terms of any promotion, all
            pricing promotions or discounts will apply to the initial period of
            the subscription, and any renewals will be charged at the rate in
            effect at the time of renewal for the type of subscription
            purchased.
          </b>
        </div>
        <div className={classes.paragraph}>
          The Products may, from time to time, contain links to and from the
          Products of our partner networks, advertisers and affiliates. If you
          follow a link to any of these external websites, please note that
          these websites have their own privacy policies and that we do not
          accept any responsibility or liability for these websites or their
          policies. Please check these policies before you submit any personal
          information to these external websites.
        </div>
        <div className={classes.subtitle}>
          4. Prohibited Use Of The Products
        </div>
        <div className={classes.subtitleSmall}>4.1</div>
        <div className={classes.paragraph}>
          You agree not to upload, post, email or otherwise send or transmit or
          introduce any material that contains software viruses or any other
          computer code, files or programs designed to interrupt, harm, damage,
          destroy or limit the functionality of any computer software or
          hardware or equipment linked directly or indirectly with the Products
          or the Products themselves. You agree not to interfere with the
          servers or networks underlying or connected to the Products or to
          violate any of the procedures, policies or regulations of networks
          connected to the Products. You may not access the Products in an
          unauthorized manner.
        </div>
        <div className={classes.subtitleSmall}>4.2</div>
        <div className={classes.paragraph}>
          You agree not to impersonate any other person while using the
          Products, conduct yourself in an offensive manner while using the
          Products, or use the Products for any illegal, immoral or harmful
          purpose.
        </div>
        <div className={classes.subtitleSmall}>4.3</div>
        <div className={classes.paragraph}>
          By breaching the provisions of this section 4, you may commit a
          criminal offense under applicable laws. We may report any such breach
          to the relevant law enforcement authorities and we may cooperate with
          those authorities by disclosing your identity to them. In the event of
          such a breach, your right to use the Products will cease immediately.
        </div>
        <div className={classes.subtitleSmall}>4.4</div>
        <div className={classes.paragraph}>
          You agree not to use the Products for any purposes related to
          scientific research, analysis or evaluation of the Products without
          the express written consent of Movement Vault.
        </div>
        <div className={classes.subtitle}>
          5. MATERIALS OFFERED THROUGH THE PRODUCTS
        </div>
        <div className={classes.subtitleSmall}>5.1 COPYRIGHT</div>
        <div className={classes.paragraph}>
          (a) All materials (including software and content whether downloaded
          or not) contained in the Products are owned by Movement Vault (or our
          affiliates and/or third party licensors, where applicable), unless
          indicated otherwise. You agree and acknowledge that the materials are
          valuable property and that other than any specific and limited license
          for use of such materials, you shall not acquire any ownership rights
          in or to such materials. The materials may not be used except as
          provided for in these Terms, and any other relevant terms and
          conditions provided to you without our prior written permission.
        </div>
        <div className={classes.paragraph}>
          (b) You acknowledge and agree that certain materials on or in the
          Products are the property of third party licensors and, without
          prejudice to any and all other rights and remedies available, each
          such licensor has the right to directly enforce relevant provisions of
          section 12 against you.
        </div>
        <div className={classes.paragraph}>
          (c) Audio or video content from Movement Vault not explicitly
          indicated as downloadable may not be downloaded or copied from the
          Products or any Device.
        </div>
        <div className={classes.paragraph}>
          (d) The Products are not intended for your commercial use. Commercial
          advertisements, affiliate links, and other forms of solicitation may
          be removed by us without notice and may result in termination of
          privileges. You must not use any part of the materials used in or on
          the Products for commercial purposes without obtaining a written
          license to do so from us. Material from the Products may not be copied
          or distributed, or republished, or transmitted in any way, without our
          prior written consent. Any unauthorized use or violation of these
          Terms immediately and automatically terminates your right to use the
          Products and may subject you to legal liability. You agree not to use
          the Products for illegal purposes (including, without limitation,
          unlawful, harassing, libelous, invasion of another’s privacy, abusive,
          threatening or obscene purposes) and you agree that you will comply
          with all laws, rules and regulations related to your use of the
          Products. Appropriate legal action may be taken for any illegal or
          unauthorized use of the Products.
        </div>
        <div className={classes.paragraph}>
          (e) A limited amount of content may be marked and authorized for the
          user to share in their personal social channels (Facebook, Twitter,
          etc.). With respect to content made available by Movement Vault
          through the Products that is specifically identified as available for
          distribution by you (“Distribution Content”) as part of your blog or
          other online commentary, analysis or review (“User Commentary”),
          Movement Vault grants you a limited right to download, reproduce and
          distribute Distribution Content over the internet as part of your User
          Commentary. You may also modify such Distribution Content but only as
          required to technically enable the display and distribution of such
          content through your computer systems and over the Internet (e.g. a
          change in video format or file size) provided such modification does
          not materially alter the substance or quality of such content. Your
          display and distribution of Distribution Content may also be subject
          to other terms and conditions that are set forth in the description of
          such content in the Products, such as display and distribution of
          Distribution Content only within specified usage dates. You agree not
          to publish the Distribution Content with other content that is known
          by you to be false, inaccurate, or misleading or that is, or that
          encourages activity or conduct that is, unlawful, harmful,
          threatening, abusive, harassing, tortious, defamatory, vulgar,
          obscene, pornographic, libelous, invasive of another’s privacy,
          hateful, or racially, ethnically or otherwise objectionable.
          Distribution Content may contain trackers that enable us to collect
          information with respect to the distribution and consumption of such
          content.
        </div>
        <div className={classes.paragraph}>
          (f) You may not otherwise download, display, copy, reproduce,
          distribute, modify, perform, transfer, create derivative works from,
          sell or otherwise exploit any content, code, data or materials in the
          Products. If you make other use of the Products, or the content, code,
          data or materials thereon, except as otherwise provided above, you may
          violate copyright and other laws of the United States, other
          countries, as well as applicable state laws and may be subject to
          liability for such unauthorized use. Movement Vault will enforce its
          intellectual property rights to the fullest extent of the law,
          including the seeking of criminal prosecution.
        </div>
        <div className={classes.subtitle}>6. AVAILABILITY OF PRODUCTS</div>
        <div className={classes.subtitleSmall}>6.1</div>
        <div className={classes.paragraph}>
          Although we aim to offer you the best service possible, we make no
          promise that the Products will meet your requirements and we cannot
          guarantee that the Products will be fault free. If a fault occurs in
          the Products, please report it to us at support@movementvault.com and
          we will review your complaint and, where we determine it is
          appropriate to do so, correct the fault. If the need arises, we may
          suspend access to the Products while we address the fault. We will not
          be liable to you if the Products are unavailable for a commercially
          reasonable period of time.
        </div>
        <div className={classes.subtitleSmall}>6.2</div>
        <div className={classes.paragraph}>
          Your access to the Products may be occasionally restricted to allow
          for repairs, maintenance or the introduction of new facilities or
          Products. We will restore the Products as soon as we reasonably can.
          In the event that the Products are unavailable, our usual Order and
          cancellation deadlines apply; please notify us of changes to your
          Order by emailing <Contact />.
        </div>
        <div className={classes.subtitle}>7. USER MATERIAL</div>
        <div className={classes.subtitleSmall}>7.1</div>
        <div className={classes.paragraph}>
          The Products may let you submit material to us: for example, you may
          be able to upload a photo to your profile, post subjects and comments
          in the community and comment on various matters in various parts of
          the Products. You may be able to upload video, images or sounds. In
          these Terms, we use the term “User Material” to refer to any publicly
          available material of any kind that you submit to us, including text,
          files, images, photos, video, sounds and musical or literary works.
          User Material does not include the account information, Product
          purchase, or Product use information which you provide in registering
          for and using Products.
        </div>
        <div className={classes.subtitleSmall}>7.2</div>
        <div className={classes.paragraph}>
          This section 7 sets out the rights and obligations that each of us
          have in connection with User Material. If you review or submit User
          Material, you are agreeing to do so in accordance with these Terms. If
          you do not want to review or submit User Material in accordance with
          these Terms, then you should not do so.
        </div>
        <div className={classes.subtitleSmall}>7.3</div>
        <div className={classes.paragraph}>
          We do not systematically review User Material submitted by you or
          other users. We are not responsible for the content of User Material
          provided by you or any other user. We do not necessarily endorse any
          opinion contained in such material. We make no warranties or
          representations, express or implied, about User Material, including as
          to its legality or accuracy.
        </div>
        <div className={classes.subtitleSmall}>7.4</div>
        <div className={classes.paragraph}>
          We reserve the right, in our sole discretion, to refuse to post or to
          remove or edit any of your User Material, or to restrict, suspend, or
          terminate your access to all or any part of the Products, particularly
          where User Material breaches this section 7, and we may do this with
          or without giving you any prior notice.
        </div>
        <div className={classes.subtitleSmall}>7.5</div>
        <div className={classes.paragraph}>
          We may link User Material or parts of User Material to other material,
          including material submitted by other users or created by Movement
          Vault or other third parties. We may use User Material for our
          internal business purposes, for example, to examine trends or
          categories or to promote, market or advertise Movement Vault. You
          acknowledge that we may indirectly commercially benefit from use of
          your User Material.
        </div>
        <div className={classes.subtitleSmall}>7.6</div>
        <div className={classes.paragraph}>
          Each time you submit User Material to us, you represent and warrant to
          us as follows:
        </div>
        <div className={classes.paragraph}>
          (a) You own your User Material or have the right to submit it, and in
          submitting it you will not be infringing any rights of any third
          party, including intellectual property rights (such as copyright or
          trade mark), privacy or publicity rights, rights of confidentiality or
          rights under contract.
        </div>
        <div className={classes.paragraph}>
          (b) Your User Material is not illegal, obscene, defamatory,
          threatening, pornographic, harassing, hateful, racially or ethnically
          offensive, and does not encourage conduct that would be considered a
          criminal offense, and does not give rise to civil liability, violate
          any law, or is otherwise deemed inappropriate.
        </div>
        <div className={classes.paragraph}>
          (c) Your User Material does not advertise any product or service or
          solicit any business.
        </div>
        <div className={classes.paragraph}>
          (d) Your User Material does not identify any individual (including by
          way or name, address or a still picture or video) under the age of 18
          and if User Material identifies any individual over the age of 18, you
          have that person’s consent to being identified in exactly that way in
          your User Material; and in submitting your User Material you are not
          impersonating any other person.
        </div>
        <div className={classes.paragraph}>
          (e) You will not collect email addresses of users for the purpose of
          sending unsolicited email.
        </div>
        <div className={classes.paragraph}>
          (f) You will not engage in criminal or tortious activity, including
          fraud, spamming, spimming, sending of viruses or other harmful files,
          copyright infringement, patent infringement, or theft of trade secrets
          or attempt to impersonate another user or person.
        </div>
        <div className={classes.paragraph}>
          (g) You will not engage in any automated use of the system, such as
          using scripts to alter our content.
        </div>
        <div className={classes.paragraph}>
          (h) You will not, without authorization, access, tamper with, or use
          non-public areas of the Products, Movement Vault’s computer systems,
          or the technical delivery systems of Movement Vault’s providers.
        </div>
        <div className={classes.paragraph}>
          (i) Except as necessary to maintain your own computer security by use
          of commercial-off-the-shelf anti-virus or anti-malware products, you
          will not attempt to probe, scan, or test the vulnerability of the
          Products or any other Movement Vault system or network or breach any
          security or authentication measures.
        </div>
        <div className={classes.subtitleSmall}>7.7</div>
        <div className={classes.paragraph}>
          We are entitled to identify you to third parties who claim that their
          rights have been infringed by User Material you have submitted.
        </div>
        <div className={classes.subtitleSmall}>7.8</div>
        <div className={classes.paragraph}>
          User Material is not considered to be confidential. You agree not to
          submit any content as User Material in which you have any expectation
          of privacy. We do not claim any ownership rights in User Material.
          However, by submitting User Material you hereby grant Movement Vault
          an irrevocable, perpetual, non-exclusive, royalty free, worldwide
          license to use, telecast, copy, perform, display, edit, distribute and
          otherwise exploit the User Material you post on the Products, or any
          portion thereof, and any ideas, concepts, or know how contained
          therein, with or without attribution, and without the requirement of
          any permission from or payment to you or to any other person or
          entity, in any manner (including, without limitation, for commercial,
          publicity, trade, promotional, or advertising purposes) and in any and
          all media now known or hereafter devised, and to prepare derivative
          works of, or incorporate into other works, such User Material, and to
          grant and authorize sublicenses of the foregoing without any payment
          of money or any other form of consideration to you or to any third
          party. Movement Vault may include your User Material in Movement
          Vault’s Distribution Content that is made available to others through
          the Products. Be aware that Movement Vault has no control over User
          Material once it leaves the Products, and it is possible that others
          may duplicate material found on the Products, including, but not
          limited to, on other sites on the Internet. You represent and warrant
          that you own or otherwise control the rights to your User Material.
          You agree to indemnify Movement Vault and its affiliates for all
          claims arising from or in connection with any claims to any rights in
          your User Material or any damages arising from your User Material.
        </div>
        <div className={classes.subtitleSmall}>7.9</div>
        <div className={classes.paragraph}>
          Any inquiries, feedback, suggestions, ideas, other information which
          is not part of your use of the Products or User Material that you
          provide to us (collectively, “Submissions”) will be treated as
          non-proprietary and non-confidential. By transmitting, uploading,
          posting, e-mailing, or otherwise submitting Submissions to the
          Products, you grant, and you represent and warrant that you have the
          right to grant, to Movement Vault an irrevocable, perpetual,
          non-exclusive, royalty free, worldwide license to use, telecast, copy,
          perform, display, edit, distribute and otherwise exploit the
          Submissions, or any portion thereof and any ideas, concepts, or know
          how contained therein, with or without attribution, and without the
          requirement of any permission from or payment to you or to any other
          person or entity, in any manner (including, without limitation, for
          commercial, publicity, trade, promotional, or advertising purposes)
          and in any and all media now known or hereafter devised, and to
          prepare derivative works of, or incorporate into other works, such
          Submissions, and to grant and authorize sublicenses of the foregoing
          without any payment of money or any other form of consideration to you
          or to any third party. You also acknowledge that your Submissions will
          not be returned to you and that Movement Vault has no obligation to
          acknowledge receipt of or respond to any Submissions. If you make a
          Submission, you represent and warrant that you own or otherwise
          control the rights to your Submission. You agree to indemnify Movement
          Vault and its affiliates for all claims arising from or in connection
          with any claims to any rights in any Submission or any damages arising
          from any Submission.
        </div>
        <div className={classes.subtitle}>8. LINKS TO WEBSITES/HOME PAGE</div>
        <div className={classes.subtitleSmall}>8.1</div>
        <div className={classes.paragraph}>
          We may provide links to other websites or services for you to access.
          You acknowledge that any access is at your sole discretion and for
          your information only. We do not review or endorse any of those
          websites or services. We are not responsible in any way for:
        </div>
        <div className={classes.paragraph}>(a) the availability of, </div>
        <div className={classes.paragraph}>(b) the privacy practices of, </div>
        <div className={classes.paragraph}>
          (c) the content, advertising, products, goods or other materials or
          resources on or available from, or{' '}
        </div>
        <div className={classes.paragraph}>
          (d) the use to which others make of these other websites or services.
          We are also not responsible for any damage, loss or offense caused or
          alleged to be caused by, or in connection with, the use of or reliance
          on such websites or services.
        </div>
        <div className={classes.subtitleSmall}>8.2</div>
        <div className={classes.paragraph}>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take advantage of
          it, but you must not establish a link in such a way as to suggest any
          form of association, approval or endorsement on our part where none
          exists. You must not establish a link from any website that is not
          owned by you. The Products must not be framed on any other website,
          nor may you create a link to any part of the Products unless you have
          written permission to do so from Movement Vault. We reserve the right
          to withdraw linking permission with written notice. The website from
          which you are linking must comply in all respects with the content
          standards set out in our acceptable use policy. If you wish to make
          any use of material on or in the Products other than that set out
          above, please address your request to <Contact />.
        </div>
        <div className={classes.subtitle}>9. PRODUCTS DISCLAIMER</div>
        <div className={classes.paragraph}>
          The information contained in the Products is for general information
          purposes only. While we endeavor to keep the information up-to-date
          and correct, we make no representations or warranties of any kind,
          express or implied, about the completeness, accuracy, reliability,
          suitability or availability with respect to the Products or the
          information contained on the Products for any purpose. Any reliance
          you place on such information is therefore strictly at your own risk.
        </div>
        <div className={classes.subtitle}>10. MEDICAL DISCLAIMER</div>
        <div className={classes.subtitleSmall}>10.1</div>
        <div className={classes.paragraph}>
          Movement Vault is a provider of online and mobile exercise and
          movement content in the health & wellness space. We are not a health
          care or medical device provider, nor should our Products be considered
          medical advice. Only your physician or other health care provider can
          do that. While there is third party evidence from research that
          exercise and movement can assist in the prevention and recovery
          process for a wide array of conditions as well as in improving some
          performance, Movement Vault makes no claims, representations or
          guarantees that the Products provide a therapeutic benefit.
        </div>
        <div className={classes.subtitleSmall}>10.2</div>
        <div className={classes.paragraph}>
          Any health information and links on the Products, whether provided by
          Movement Vault or by contract from outside providers, is provided
          simply for your convenience.
        </div>
        <div className={classes.subtitleSmall}>10.3</div>
        <div className={classes.paragraph}>
          Any advice or other materials in the Products are intended for general
          information purposes only. They are not intended to be relied upon and
          are not a substitute for professional medical advice based on your
          individual condition and circumstances. The advice and other materials
          we make available are intended to support the relationship between you
          and your healthcare providers and not replace it. We are not liable or
          responsible for any consequences of your having read or been told
          about such advice or other materials as you assume full responsibility
          for your decisions and actions. In particular, to the fullest extent
          permitted by law, we make no representation or warranties about the
          accuracy, completeness, or suitability for any purpose of the advice,
          other materials and information published as part of the Products.
        </div>
        <div className={classes.subtitleSmall}>10.4</div>
        <div className={classes.paragraph}>
          There have been rare reports where people with certain psychiatric
          problems like anxiety and depression have experienced worsening
          conditions in conjunction with intensive meditation practice. People
          with existing mental health conditions should speak with their health
          care providers before starting a meditation practice.
        </div>
        <div className={classes.subtitle}>11. END USER LICENSE</div>
        <div className={classes.subtitleSmall}>11.1</div>
        <div className={classes.paragraph}>
          Subject to the terms of this license agreement (“License Agreement”),
          as set out in this section 11, and these other Terms, and your payment
          of applicable subscription fees, Movement Vault grants you a limited,
          non-exclusive, revocable license to stream, download and make personal
          non-commercial use of the Products.
        </div>
        <div className={classes.subtitleSmall}>11.2</div>
        <div className={classes.paragraph}>
          The Products contain or embody copyrighted material, proprietary
          material or other intellectual property of Movement Vault or its
          licensors. All right, title and ownership in the Products remain with
          Movement Vault or its licensors, as applicable. The rights to download
          and use the Products are licensed to you and are not being sold to
          you, and you have no rights in them other than to use them in
          accordance with this License Agreement and our other Terms.
        </div>
        <div className={classes.subtitleSmall}>11.3</div>
        <div className={classes.paragraph}>
          You agree that you will not and you will not assist or permit any
          third party to:
        </div>
        <div className={classes.paragraph}>
          (a) Copy, store, reproduce, transmit, modify, alter, reverse-engineer,
          emulate, de-compile, or disassemble the Products in any way, or create
          derivative works of the Products;
        </div>
        <div className={classes.paragraph}>
          (b) Use the Products or any part of them to create any tool or
          software product that can be used to create software applications of
          any nature whatsoever;
        </div>
        <div className={classes.paragraph}>
          (c) Rent, lease, loan, make available to the public, sell or
          distribute the Products in whole or in part;
        </div>
        <div className={classes.paragraph}>
          (d) Tamper with the Products or circumvent any technology used by
          Movement Vault or its licensors to protect any content accessible
          through the Products;
        </div>
        <div className={classes.paragraph}>
          (e) Circumvent any territorial restrictions applied to the Products;
          or
        </div>
        <div className={classes.paragraph}>
          (f) Use the Products in a way that violates this License Agreement or
          the other Terms.
        </div>
        <div className={classes.subtitleSmall}>11.4</div>
        <div className={classes.paragraph}>
          You may not make the Products available to the public. The Products
          made available (in whole or in part) are owned by Movement Vault or
          its licensors and your use of them must be in accordance with these
          Terms.
        </div>
        <div className={classes.subtitle}>
          12. DIGITAL MILLENIUM COPYRIGHT ACT (“DMCA”) NOTICE
        </div>
        <div className={classes.subtitleSmall}>12.1</div>
        <div className={classes.paragraph}>
          We are committed to complying with copyright and related laws, and we
          require all users of the Products to comply with these laws.
          Accordingly, you may not store any material or content on, or
          disseminate any material or content over, the Products in any manner
          that constitutes an infringement of third party intellectual property
          rights, including rights granted by copyright law. Owners of
          copyrighted works in the United States who believe that their rights
          under copyright law have been infringed may take advantage of certain
          provisions of the US Digital Millennium Copyright Act of 1998 (the
          “DMCA”) to report alleged infringements. You may not post, modify,
          distribute, or reproduce in any way any copyrighted material,
          trademarks, or other proprietary information belonging to others
          without obtaining the prior written consent of the owner of such
          proprietary rights. It is our policy to terminate privileges of any
          user who repeatedly infringes the copyright rights of others upon
          receipt of proper notification to us by the copyright owner or the
          copyright owner’s legal agent.
        </div>
        <div className={classes.subtitleSmall}>12.2</div>
        <div className={classes.paragraph}>
          If you feel that a posted message is objectionable or infringing, we
          encourage you to contact us immediately. Upon our receipt of a proper
          notice of claimed infringement under the DMCA, we will respond
          expeditiously to remove, or disable access to, the material claimed to
          be infringing and will follow the procedures specified in the DMCA to
          resolve the claim between the notifying party and the alleged
          infringer who provided the content in issue. Our designated agent
          (i.e., the proper party) to whom you should address such notice is
          listed below.
        </div>
        <div className={classes.subtitleSmall}>12.3</div>
        <div className={classes.paragraph}>
          If you believe that your work has been copied and posted on the
          Products in a way that constitutes copyright infringement, please
          provide our designated agent with the following information:
        </div>
        <div className={classes.paragraph}>
          (a) An electronic or physical signature of the person authorized to
          act on behalf of the owner of the copyright or other intellectual
          property interest;
        </div>
        <div className={classes.paragraph}>
          (b) A description of the copyrighted work or other intellectual
          property that you claim has been infringed;
        </div>
        <div className={classes.paragraph}>
          (c) A description of where the material that you claim is infringing
          is located on the Products;
        </div>
        <div className={classes.paragraph}>
          (d) Your address, telephone number, and email address;
        </div>
        <div className={classes.paragraph}>
          (e) A statement by you that you have a good faith belief that the
          disputed use is not authorized by the copyright or intellectual
          property owner, its agent, or the law; and
        </div>
        <div className={classes.paragraph}>
          (f) A statement by you, made under penalty of perjury, that the
          information contained in your report is accurate and that you are the
          copyright or intellectual property owner or authorized to act on the
          copyright or intellectual property owner’s behalf.
        </div>
        <div className={classes.paragraph}>
          (g) Our designated agent for notice of claims of copyright
          infringement can be reached as follows:
        </div>
        <div className={classes.paragraph}>
          By E-Mail: <Contact /> Subject line: DMCA
        </div>
        <div className={classes.subtitle}>13. GENERAL TERMS AND CONDITIONS</div>
        <div className={classes.subtitleSmall}>13.1 ASSIGNMENT BY US</div>
        <div className={classes.paragraph}>
          Movement Vault may transfer its rights and obligations under these
          Terms to any company, firm or person at any time if it does not
          materially affect your rights under it. You may not transfer your
          rights or obligations under these Terms to anyone else. These Terms
          are personal to you and no third party is entitled to benefit under
          these Terms except as set out here.
        </div>
        <div className={classes.subtitleSmall}>13.2 INDEMNITY BY YOU</div>
        <div className={classes.paragraph}>
          You agree to defend, indemnify and hold Movement Vault and its
          directors, officers, members, investors, managers, employees and
          agents harmless from any and all claims, liabilities, costs and
          expenses, including reasonable attorneys’ fees, arising in any way
          from your use of the Products, your placement or transmission of any
          message, content, information, software, or other submissions through
          the Products, or your breach or violation of the law or of these
          Terms. Movement Vault reserves the right, at its own expense, to
          assume the exclusive defense and control of any matter otherwise
          subject to indemnification by you, and in such case, you agree to
          cooperate with Movement Vault defense of such claim.
        </div>
        <div className={classes.subtitleSmall}>
          13.3 WARRANTIES AND LIMITATIONS
        </div>
        <div className={classes.paragraph}>
          (a) We warrant to you that any Product purchased from us will, on
          delivery, conform in all material respects with its description and be
          of reasonably satisfactory quality.
        </div>
        <div className={classes.paragraph}>
          (b) We warrant that we will use reasonable skill and care in making
          the Products available to you during your subscription.
        </div>
        <div className={classes.paragraph}>
          (c) Nothing in this sections 13.3 or otherwise in these Terms shall
          exclude or in any way limit Movement Vault’s liability for: fraud;
          death or personal injury caused by negligence; or liability to the
          extent the same may not be excluded or limited as a matter of law.
        </div>
        <div className={classes.paragraph}>
          (d) The Products and their content are otherwise provided on an “as
          is” basis and we make no representations or warranties of any kind
          with respect to them, including as to the accuracy, completeness or
          currency of the Products or their content. We assume no liability or
          responsibility for any errors or omissions in the content of the
          Products, or any failures, delays, or interruptions in the provision
          of the Products. We disclaim and exclude any express or implied
          warranties or representations, including any warranties as to
          merchantability or fitness for a particular purpose of the Products to
          the broadest extent permitted by law. We make no warranties or
          representations, express or implied, as to the timeliness, accuracy,
          quality, completeness or existence of the content and information
          posted on the Products. We make no warranties or representations,
          express or implied, for technical accessibility, fitness or
          flawlessness of the Products. We make no warranties or representations
          that your use of content and information posted on the Products will
          not infringe rights of third parties.
        </div>
        <div className={classes.paragraph}>
          (e) All conditions, warranties and other terms which might otherwise
          be implied by statute, common law or the law of equity are, to the
          extent permitted by law, excluded
        </div>
        <div className={classes.subtitleSmall}>13.4 NO WAIVER</div>
        <div className={classes.paragraph}>
          If we delay exercising or fail to exercise or enforce any right
          available to us under these Terms, such delay or failure does not
          constitute a waiver of that right or any other rights under these
          Terms.
        </div>
        <div className={classes.subtitleSmall}>13.5 FORCE MAJEURE</div>
        <div className={classes.paragraph}>
          We will not be liable to you for any lack of performance, or the
          unavailability or failure, of the Products, or for any failure or
          delay by us to comply with these Terms, where such lack,
          unavailability or failure arises from any cause beyond our reasonable
          control.
        </div>
        <div className={classes.subtitleSmall}>13.6 INTERPRETATION</div>
        <div className={classes.paragraph}>
          In these Terms, unless the context requires otherwise:
        </div>
        <div className={classes.paragraph}>
          i) any phrase introduced by the words “including”, “include”, “in
          particular”, “for example” or any similar expression shall be
          construed as illustrative only and shall not be construed as limiting
          the generality of any preceding words; and
        </div>
        <div className={classes.paragraph}>
          ii) references to the singular include the plural and to the masculine
          include the feminine, and in each case vice versa.
        </div>
        <div className={classes.subtitleSmall}>
          13.7 ELECTRONIC COMMUNICATIONS
        </div>
        <div className={classes.paragraph}>
          (a) Applicable laws require that some of the information or
          communications we send to you should be in writing. When using the
          Products, you agree to transact with us electronically, and that
          communication with us will be mainly electronic. We will contact you
          by e-mail or provide you with information by posting notices on the
          Products. You agree to this electronic means of communication and you
          acknowledge that all contracts, notices, information and other
          communications that we provide to you electronically comply with any
          legal requirement that such communications be in writing.
        </div>
        <div className={classes.paragraph}>
          (b) In order to retain a copy, please select “Print,” and select the
          appropriate printer. If you do not have a printer, you can copy the
          text and the underlying agreement(s) and paste them into a new
          document in a word processor or a text editor on your computer and
          save the text.
        </div>
        <div className={classes.paragraph}>
          (c) You have the right to receive a paper copy of the communications.
          To receive a paper copy, please request it by emailing us at
          <Contact />
        </div>
        <div className={classes.paragraph}>
          (d) We may charge you a reasonable service charge to mail you a paper
          copy of any communication. We will either include such service charge
          on our fee schedule or we will first inform you of the charge and
          provide you with the choice as to whether you still want us to send
          you a paper copy. Please be sure to state that you are requesting a
          copy of the particular communication.
        </div>
        <div className={classes.paragraph}>
          (e) To receive and view an electronic copy of the communications you
          must have the following equipment and software:
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          {' '}
          (i) A personal computer or other device which is capable of accessing
          the Internet. Your access to this page verifies that your
          system/device meets these requirements.
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          {' '}
          (ii) an Internet web browser which is capable of supporting 128-bit
          SSL encrypted communications, JavaScript, and cookies. Your system or
          device must have 128-bit SSL encryption software. Your access to this
          page verifies that your browser and encryption software/device meet
          these requirements.
        </div>
        <div className={classes.paragraph}>
          (f) To retain a copy, you must either have a printer connected to your
          personal computer or other device or, alternatively, the ability to
          save a copy through use of printing service or software such as Adobe
          Acrobat®. If you have a word processor or text editor program on your
          computer, then you can also copy the text and paste the text into a
          new document in the word processor or text editor and save the text.
        </div>
        <div className={classes.paragraph}>
          (g) You can also contact us via email at support@movementvault.com to
          withdraw your consent to receive any future communications
          electronically, including if the system requirements described above
          change and you no longer possess the required system. If you withdraw
          your consent, we may terminate your use of the Products.
        </div>
        <div className={classes.paragraph}>
          (h) We reserve the right, in our sole discretion, to discontinue the
          provision of your electronic communications, or to terminate or change
          the terms and conditions on which we provide electronic
          communications. We will provide you with notice of any such
          termination or change as required by law.
        </div>
        <div className={classes.subtitleSmall}>13.8 NOTICES</div>
        <div className={classes.paragraph}>
          Unless otherwise specifically indicated, all notices given by you to
          us must be given to Movement Vault at <Contact />. We may give notice
          to you at the e-mail address you provide to us when you register, or
          in any of the ways specified in section 13.7 above. Notice will be
          deemed received and properly served immediately when posted on the
          Products or when an e-mail or other electronic communication is sent.
          In proving the service of any notice via email, it will be sufficient
          to prove that such e-mail was sent to the specified e-mail address of
          the addressee.
        </div>
        <div className={classes.subtitleSmall}>13.9 NOTICES</div>
        <div className={classes.paragraph}>
          These Terms and any document expressly referred to in them constitute
          the whole agreement between us and supersede all previous discussions,
          correspondence, negotiations, previous arrangement, understanding or
          agreement between us relating to their subject matter. We each
          acknowledge that neither of us relies on, or will have any remedies in
          respect of, any representation or warranty (whether made innocently or
          negligently) that is not set out in these Terms or the documents
          referred to in them. Each of us agrees that our only liability in
          respect of those representations and warranties that are set out in
          this agreement (whether made innocently or negligently) will be for
          breach of contract. Nothing in this section limits or excludes any
          liability for fraud.
        </div>
        <div className={classes.subtitleSmall}>13.10 NOTICES</div>
        <div className={classes.paragraph}>
          A person who is not party to these Terms will not, subject to section
          12 (DMCA), have any rights under or in connection with these Terms.
        </div>
        <div className={classes.subtitleSmall}>13.11 OUR LIABILITY</div>
        <div className={classes.paragraph}>
          (a) We will use reasonable endeavors to remedy faults in the Products.
          If we fail to comply with these Terms, we will be liable to you only
          for the purchase price of the Products in question. In addition, we
          will not be liable for:
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          {' '}
          (i) Faulty operation of computers during the registration process or
          during completion of a subscription or during the transmission of any
          data and/or for incorrect or overly slow transmission of data by the
          internet provider and/or any damage that occurs due to information
          submitted by you not being received by us or not being received
          promptly or not being considered, as a consequence of technical faults
          with our software or hardware (whether or not they are within or
          outside of our control).
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          {' '}
          (ii) Any loss or damage due to viruses or other malicious software
          that may infect your Device, computer equipment, software, data or
          other property caused by you accessing, using or downloading from the
          Products, or from transmissions via emails or attachments received
          from us.
        </div>
        <div className={cx(classes.paragraph, classes.pl3)}>
          {' '}
          (iii) Any use of websites linked to the Products but operated by third
          parties.
        </div>
        <div className={classes.paragraph}>
          (b) To the extent permitted by law, Movement Vault and its affiliates,
          suppliers, clients, or licensors (collectively, the “Protected
          Entities”) shall not be liable for any consequential, exemplary or
          punitive damages arising from, or directly or indirectly related to,
          the use of, or the inability to use, the Products or the content,
          materials and functions related thereto, your provision of information
          via the Products, or lost business or lost sales, or any errors,
          viruses or bugs contained in the Products, even if such Protected
          Entity has been advised of the possibility of such damages. In no
          event shall the Protected Entities be liable for or in connection with
          any content posted, transmitted, exchanged or received by or on behalf
          of any user or other person on or through the Products. In no event
          shall the total aggregate liability of the Protected Entities to you
          for all damages, losses, and causes of action (whether in contract or
          tort, including, but not limited to, negligence or otherwise) arising
          from these terms of use or your use of the Products exceed, in the
          aggregate, the amount, if any, paid by you to Movement Vault for your
          use of the Products.
        </div>
        <div className={classes.subtitleSmall}>13.12 ARBITRATION</div>
        <div className={classes.paragraph}>
          <b>
            PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY, AS THEY REQUIRE YOU
            TO ARBITRATE DISPUTES WITH MOVEMENT VAULT, AND LIMIT THE MANNER IN
            WHICH YOU CAN SEEK RELIEF FROM MOVEMENT VAULT.
          </b>
        </div>
        <div className={classes.paragraph}>
          <b>(a) Applicability of Arbitration Agreement.</b> <br /> All disputes
          arising out of, relating to, or in connection with these Terms or your
          use of the Products that cannot be resolved informally or in small
          claims court will be resolved through binding arbitration on an
          individual basis, except that you and Movement Vault are not required
          to arbitrate any dispute in which either party seeks equitable relief
          for the alleged unlawful use of copyrights, trademarks, trade names,
          logos, trade secrets, or patents.
        </div>
        <div className={classes.paragraph}>
          <b>(b) Arbitration Rules.</b>
          <br /> The Federal Arbitration Act governs the interpretation and
          enforcement of this dispute-resolution provision. Arbitration will be
          initiated through the American Arbitration Association ("AAA"). If the
          AAA is not available to arbitrate, the parties will select an
          alternative arbitral forum. The rules of the arbitral forum will
          govern all aspects of this arbitration, except to the extent those
          rules conflict with these Terms. The AAA Consumer Arbitration Rules
          (“AAA Rules”) governing the arbitration are available online at
          www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration
          will be conducted by a single neutral arbitrator. If the claim is for
          $10,000 or less, the party initiating the arbitration may choose
          whether the arbitration will be conducted (1) solely on the basis of
          documents submitted to the arbitrator; (2) through a non-appearance
          based telephonic hearing; or (3) by an in-person hearing as
          established by the AAA Rules in the county of your billing address. In
          the case of an in-person hearing, the proceedings will be conducted at
          a location which is reasonably convenient for both parties with due
          consideration of the ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, the
          determination will be made by the arbitration institution.
        </div>
        <div className={classes.paragraph}>
          Your arbitration fees and your share of arbitrator compensation will
          be limited to those fees set forth in the AAA Rules with the remainder
          paid by Movement Vault. If the arbitrator finds that either the
          substance of your claim or the relief sought in the arbitration is
          frivolous or brought for an improper purpose (as measured by the
          standards set forth in Federal Rule of Civil Procedure 11(b)), then
          the payment of all fees will be governed by the AAA Rules. In such
          case, you agree to reimburse Movement Vault for all monies previously
          disbursed by it that are otherwise your obligation to pay under the
          AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the arbitrator shall issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which
          the decision and award, if any, are based. The arbitrator may make
          rulings and resolve disputes as to the payment and reimbursement of
          fees or expenses at any time during the proceeding and upon request
          from either party made within 14 days of the arbitrator’s ruling on
          the merits.
        </div>
        <div className={classes.paragraph}>
          <b>(c) Authority of Arbitrator.</b> <br /> The arbitrator will decide
          the jurisdiction of the arbitrator and the rights and liabilities, if
          any, of you and Movement Vault. The dispute will not be consolidated
          with any other matters or joined with any other cases or parties. The
          arbitrator will have the authority to grant motions dispositive of all
          or part of any claim or dispute. The arbitrator will have the
          authority to award all remedies available under applicable law, the
          arbitral forum's rules, and the Terms. The arbitrator has the same
          authority to award relief on an individual basis that a judge in a
          court of law would have. The award of the arbitrator is final and
          binding upon you and Movement Vault.
        </div>
        <div className={classes.paragraph}>
          <b>(d) Jury Trial Waiver.</b> <br />
          You and Movement Vault waive any constitutional and statutory rights
          to go to court and have a trial in front of a judge or a jury. Rather,
          you and Movement Vault elect to have claims and disputes resolved by
          arbitration. In any litigation between you and Movement Vault over
          whether to vacate or enforce an arbitration award, you and Movement
          Vault waive all rights to a jury trial, and elect instead to have the
          dispute be resolved by a judge.
        </div>
        <div className={classes.paragraph}>
          <b>(e) Class Action Waiver.</b> <br /> WHERE PERMITTED UNDER THE
          APPLICABLE LAW, YOU AND MOVEMENT VAULT AGREE THAT EACH MAY BRING
          CLAIMS AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY AND
          NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          CONSOLIDATED ACTION. If, however, this waiver of class or consolidated
          actions is deemed invalid or unenforceable, neither you nor Movement
          Vault are entitled to arbitration; instead all claims and disputes
          will be resolved in a court as set forth in section 13.13 below.
        </div>
        <div className={classes.paragraph}>
          <b>(f) Opt-out.</b> <br /> YOU MAY OPT-OUT OF THIS ARBITRATION
          AGREEMENT. If you do so, neither you nor Movement Vault can force the
          other to arbitrate. To opt-out, you must notify Movement Vault in
          writing no later than 30 days after first becoming subject to this
          arbitration agreement. Your notice must include your name and address,
          and the email address you used to set up your Movement Vault account
          (if you have one), and an unequivocal statement that you want to
          opt-out of this arbitration agreement. You must send your opt-out
          notice to our email address: Movement Vault, Inc.,
          <Contact />.
        </div>
        <div className={classes.paragraph}>
          <b>(g) Small Claims Court.</b> <br /> Notwithstanding the foregoing,
          either you or Movement Vault may bring an individual action in small
          claims court.
        </div>
        <div className={classes.paragraph}>
          <b>(h) Arbitration Agreement Survival.</b> <br /> This arbitration
          agreement will survive the termination of your relationship with
          Movement Vault.
        </div>
        <div className={classes.subtitleSmall}>13.13 EXCLUSIVE VENUE</div>
        <div className={classes.paragraph}>
          To the extent the parties are permitted under these Terms to initiate
          litigation in a court, both you and Movement Vault agree that all
          claims and disputes arising out of or relating to the Terms or the use
          of the Products will be litigated exclusively in the United States
          District Court for the Central District of California. If, however,
          that court would lack original jurisdiction over the litigation, then
          all claims and disputes arising out of or relating to the Terms or the
          use of the Products will be litigated exclusively in the Superior
          Court of California, County of Los Angeles. You and Movement Vault
          consent to the personal jurisdiction of both courts.
        </div>
        <div className={classes.subtitleSmall}>13.14 CHOICE OF LAW</div>
        <div className={classes.paragraph}>
          Except to the extent they are preempted by U.S. federal law, the laws
          of California, other than its conflict-of-laws principles, govern
          these Terms and any disputes arising out of or relating to these Terms
          or their subject matter, including tort claims.
        </div>
        <div className={classes.subtitleSmall}>13.15 SEVERABILITY</div>
        <div className={classes.paragraph}>
          If any provision of these Terms is found unenforceable, then that
          provision will be severed from these Terms and not affect the validity
          and enforceability of any remaining provisions. <br /> These Terms are
          effective and were last updated on July 29, 2019.
        </div>
        <div className={classes.subtitle}>
          14. Movement Vault Referral Program Terms
        </div>
        <div className={classes.paragraph}>
          Thank you for helping to grow the Movement Vault community. From time
          to time, Movement Vault may invite you to participate in one of its
          referral programs to encourage people to use a Movement Vault app
          and/or website. Participating in a referral program (either by
          referring your friends or family or signing up with a referral code)
          can be a great way for riders Movement Vault app and/or website users
          (collectively "Movement Vault users") to receive Movement Vault
          credits or earn extra money and/or credits (each a "Referral Reward").
          <br />
          Movement Vault Referral Program rules (the "Rules") apply to referral
          programs that are administered solely by Movement Vault. By accepting
          and sharing your referral code or by signing up with a referral code,
          you are agreeing to be bound by these Rules. Any violation of these
          Rules will not only prevent you from participating in any Movement
          Vault referral program (now or in the future), but could also result
          in the forfeiture or retraction of Movement Vault credits, money, or
          other rewards earned through the program and even the deactivation of
          your account.
          <br />
          In addition, there may be other terms that apply to your Referral
          Reward based on the city where you signed up, including what actions
          you may need to take as well as the type and amount of the Referral
          Reward. See the additional terms that came with your invitation or
          those provided in our app or website when you made your referral.{' '}
          <br />
          Please note that these Rules are in addition to the Terms of Use
          governing your use of Movement Vault's apps as well as any agreement
          (if one exists) between you and Movement Vault or any of its
          subsidiaries and affiliates related to your interactions with Movement
          Vault.
        </div>
        <div className={classes.label}>
          1. Who is eligible to be a referrer/inviter?
        </div>
        <div className={classes.paragraph}>
          Anyone may be a referrer/inviter who (a) is a legal resident of
          his/her country, (b) is of legal age where they live, and (c) has a
          Movement Vault account in good standing. Referrers/inviters can be any
          type of Movement Vault user; however, they cannot have more than one
          account for each Movement Vault product or service.
        </div>
        <div className={classes.label}>
          2. Who is eligible to be a referee/invitee?
        </div>
        <div className={classes.paragraph}>
          Your friends, family, and other people you know (but not yourself) may
          be eligible to be referees/invitees. To determine eligibility, keep in
          mind the following stipulations: <br />
          Eligible referees/invitees for those who order services through a
          Movement Vault app: <br />
          To receive a Referral Reward for referring someone who orders services
          through a Movement Vault app, your referee/invitee must (a) be a new
          Movement Vault user of that service, (b) meet the conditions Movement
          Vault has for using the app and/or website, and (c) complete the
          actions required by the specific referral program (i.e. new user
          completes their first payment to Movement Vault). Eligible
          referees/invitees for those who provide services through an Movement
          Vault app: <br />
          To receive a Referral Reward for referring someone who provides
          services through a Movement Vault app and/or website, your
          referee/invitee must (a) not have a previous Movement Vault account;
          (b) use your referral code and/or link when they sign up (d) complete
          the specific action outlined currently for the referral program you
          sign up for. These conditions/terms for the referral program change
          often and without notice. Contact our team for specific current
          referral program rewards rules as it pertains to payouts and new user
          discounts (e) take any other actions required by the specific referral
          program. Your referees/invitees can be referred only once, so if
          someone else has referred them and they have accepted that invitation
          they will not be able to accept yours. In addition, please note that
          fleet partners cannot earn Referral Rewards for inviting or referring
          drivers in their fleet. <br />
          Referrers/inviters cannot do anything else prohibited by Movement
          Vault's Improper Use policy. <br />
          If you are providing Movement Vault with the contact information for
          your referees/invitees, you represent that you have the right to
          provide that information.
        </div>
        <div className={classes.label}>3. How can I use my referral code?</div>
        <div className={classes.paragraph}>
          Movement Vault has the right to limit the number of times you may use
          or share your referral code based on the referral program in which you
          participate. To see if your code has a limit, refer to the terms of
          the specific referral program in which you are participating. <br />
          Movement Vault wants you to share your referral code and earn
          Referral. You agree that you will not:
          <br />
          <ol>
            <li>
              Duplicate, sell, or transfer your referral code in any manner.
            </li>
            <li>
              Try to get referees/invitees by spamming, bulk emailing, or
              sending large numbers of unsolicited emails. The only people you
              should be emailing are people you know personally;
            </li>
            <li>
              Use, display, or manipulate Movement Vault intellectual property
              (such as Movement Vault's logos, trademarks, and
              copyright-protected works) in any way, except as to identify
              yourself as a Movement Vault user, Movement Vault
              referrer/inviter, or referrer/inviter for Movement Vault;
            </li>
            <li>
              Create or register any (i) businesses, (ii) URLs, (iii) domain
              names, (iv) software application names or titles, or (v) social
              media handles or profiles that include the word "Movement Vault"
              or any of Movement Vault's other trademarks or any words that are
              confusingly similar to Movement Vault's trademarks.
            </li>
            <li>
              Use Movement Vault's trademarks as your social media profile
              picture or wallpaper or use any of Movement Vault's
              copyright-protected works (such as graphics, photos, images,
              drawings, and screenshots from Movement Vault's website or app)
              without Movement Vault's express written permission;
            </li>
            <li>
              Purchase keywords (including, but not limited to Google AdWords)
              that contain any of Movement Vault's trademarks;
            </li>
            <li>
              Use automated systems or bots through any channel to distribute,
              post, or otherwise share your referral code;
            </li>
            <li>
              Use scripts or programmed or automatic dialers to send invites or
              otherwise share your referral code;
            </li>
            <li>
              Make misleading claims about Movement Vault, use offensive/abusive
              content, create fake websites/webpages/social media profiles/apps,
              misrepresent your connection to Movement Vault, or otherwise make
              any false or misleading statements to get a referee/invitee to use
              your code; or
            </li>
            <li>
              Use your referral code in any manner that violates the law or the
              rights of anyone else.
            </li>
          </ol>
          <br />
          Remember, when you share your referral code, you should explain that
          you are a Movement Vault referrer.
          <br />
        </div>
        <div className={classes.label}>
          4. How do I earn my Referral Reward as a referrer/inviter?
        </div>
        <div className={classes.paragraph}>
          As long as you and your referee/invitee follow these Rules and the
          Terms of Use governing your use of Movement Vault's apps, you should
          receive your Referral Reward after your referee/invitee uses your code
          to sign up with Movement Vault and completes the requirements for the
          particular Referral Reward (such as the new user using your code and
          making their first payment). <br />
          Referral Rewards are discretionary and the amount of a Referral Reward
          and the requirements to get it can vary both by city and within a
          city, as well as the time when the referral/sign has taken place. As a
          result, if your referee/invitee signs up in a city that is different
          from yours, they may receive a different referral offer than the one
          stated in your invitation (they may receive the offer available in
          their sign-up city). That means you might end up with a different
          Referral Reward than you thought (you may receive the Referral Reward
          available for the invitee's sign-up city). <br />
          Movement Vault reserves the right to set a limit on the number of
          times you may use your referral code. The requirements for receiving,
          and the amounts of, Referral Rewards are subject to change at Movement
          Vault's sole discretion. Referral Rewards in the form of Movement
          Vault credits are not transferable, have no cash value, and may
          expire.
        </div>
        <div className={classes.label}>
          5. How can I earn a Referral Reward as a referee/invitee?
        </div>
        <div className={classes.paragraph}>
          For Movement Vault users, referees/invitees may get a discount the
          first time they use a Movement Vault app and/or website. The amount
          and form of the discount will be shown in their invitation. <br />
          Termination and changes <br />
          Movement Vault reserves the right to change, end, or pause, in whole
          or in part, any referral program, as well as any referrer/inviter's or
          referee/invitee's ability to participate in any referral program or
          receive Referral Rewards at any time for any reason, including
          suspected fraud (including by either the referrer and/or
          referee/invitee), abuse, or any violation of these Rules. If Movement
          Vault ends any referral program, any unused or unredeemed Referral
          Rewards may be forfeited at that time, provided we give you 14 days of
          notice to use or redeem your Referral Rewards. <br />
          Movement Vault may update these Rules at any time. If Movement Vault
          makes an update, Movement Vault will post the update on the
          Movementvault.com website and applications and provide you notice of
          the update. Continued participation in any referral program after any
          update will mean that you have agreed to the update.
        </div>
      </div>
    </Box>
  );
};

ToS.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ToS);
