import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

import ActionButtons from './ActionButtons';
import ListItemProperty from './ListItemProperty';
import PlaylistPreview from './PlaylistPreview';

import mediaNotUploaded from 'assets/img/media-not-uploaded.png';
import { sortByTitle } from 'core/utils/sort';

const styles = ({ palette: { background, secondary } }) => ({
  addedExercise: {
    '& > div': {
      padding: 12,
    },
    '& img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    borderRadius: 6,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.05)',
  },
  addedExercises: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  container: {
    width: '50vw',
    padding: '1rem',
  },
  content: {
    background: background.paper,
    borderRadius: 6,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: 'calc(100vh - 174px)',
    overflow: 'auto',
    padding: 24,
  },
  finePrint: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    letterSpacing: 0.3,
    lineHeight: 1.25,
  },
  label: {
    color: secondary.grayText,
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    lineHeight: 1.8,
    textTransform: 'uppercase',
  },
  listItemProperties: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  media: {
    '& > div': {
      width: '50%',
    },
    '& > div:first-child': {
      marginRight: 15,
    },
    '& video': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    display: 'flex',
    margin: '12px 0px',
    width: '100%',
  },
  mediaImage: {
    height: 200,
    objectFit: 'cover',
    width: '100%',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  text: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.4,
    lineHeight: 1.8,
    textTransform: 'uppercase',
  },
});

const getFormattedDate = (date) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const ListItemDetails = ({
  baseUri,
  classes,
  item,
  onDelete,
  onDuplicate,
  title,
}) => {
  const {
    palette: { primary },
  } = useTheme();
  const videoRef = useRef(null);
  const videoLocation = item?.video?.location;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoLocation]);

  if (!item) return null;

  return (
    <div className={classes.container}>
      <ActionButtons
        baseUri={baseUri}
        id={item.id}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
      />
      <div className={classes.content}>
        <div className={classes.label}>{title}</div>
        <div className={classes.text}>{item.title}</div>
        <div
          className={classes.listItemProperties}
          style={{ marginTop: 24, marginBottom: 12 }}
        >
          <ListItemProperty
            label='status'
            style={{
              color: item.status === 'published' ? primary.main : '',
              fontWeight: 'bold',
            }}
            text={item.status}
            textAlign='left'
          />
          <ListItemProperty
            label='date of publishing'
            text={getFormattedDate(item.activeSince)}
            textAlign='left'
          />
          {item.frontEndVisible !== undefined && (
            <ListItemProperty
              label='frontend visible'
              text={!!item.frontEndVisible ? 'Yes' : 'No'}
              textAlign='left'
            />
          )}
        </div>
        <div className={classes.label}>description</div>
        <div className={classes.finePrint}>{item.description}</div>
        <div className={classes.media}>
          <div>
            <div className={classes.label}>photo</div>
            <img
              className={classes.mediaImage}
              src={item?.thumbnail?.location || mediaNotUploaded}
              alt='thumbnail'
            />
          </div>
          <div>
            <div className={classes.label}>video</div>
            {item?.playlist ? (
              <PlaylistPreview
                length={item?.playlist?.length || ''}
                thumbnailUrl={
                  item?.playlist[0]?.exercise?.thumbnail?.location ||
                  mediaNotUploaded
                }
              />
            ) : item?.video?.location ? (
              <video preload='metadata' controls ref={videoRef}>
                <source
                  src={`${item?.video?.location}#t=0.1`}
                  type='video/mp4'
                />
              </video>
            ) : (
              <img
                className={classes.mediaImage}
                src={item?.thumbnail?.location || mediaNotUploaded}
                alt='thumbnail'
              />
            )}
          </div>
        </div>
        <div
          className={classes.listItemProperties}
          style={{ marginBottom: 16 }}
        >
          <ListItemProperty
            label='usage area'
            text={item.access}
            style={{ fontSize: 18 }}
          />
          <ListItemProperty
            label='difficulty'
            text={item.difficulty}
            style={{ fontSize: 18 }}
          />
          <ListItemProperty
            label='modality'
            text={item.modalities
              ?.sort(sortByTitle)
              .map((m) => m.title)
              .join(', ')}
            style={{ fontSize: 18 }}
          />
        </div>
        <div className={classes.listItemProperties}>
          <ListItemProperty
            label='movement prep'
            text={item.movementPreps
              ?.sort(sortByTitle)
              .map((m) => m.title)
              .join(', ')}
            style={{ fontSize: 18 }}
          />
          <ListItemProperty
            label='equipment'
            text={item.equipments?.map((e) => e.title).join(', ')}
            style={{ fontSize: 18 }}
          />
          <ListItemProperty
            label='area of the body'
            text={item.bodyAreas
              ?.sort(sortByTitle)
              .map((m) => m.title)
              .join(', ')}
            style={{ fontSize: 18 }}
          />
        </div>
        {item?.addedExercises?.length > 0 && (
          <>
            <div className={classes.label} style={{ marginTop: 16 }}>
              added exercises
            </div>
            <div className={classes.addedExercises}>
              {item?.addedExercises.map((exercise) => (
                <div className={classes.addedExercise} key={exercise.id}>
                  <img
                    src={exercise.thumbnail.location}
                    alt='exercise thumbnail'
                  />
                  <div>
                    <div className={classes.subtitle}>{exercise.title}</div>
                    <div className={classes.finePrint}>
                      {exercise.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ListItemDetails);
