import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';
import MuiDialog from '@material-ui/core/Dialog';

import closeImg from 'assets/svg/close.svg';

const styles = ({ breakpoints }) => ({
  closeButton: {
    cursor: 'pointer',
    height: 40,
    width: 40,
    padding: 12,
    borderRadius: 20,
    backgroundColor: '#0003',
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: 50,
    [breakpoints.down('md')]: {
      top: 12,
      right: 12,
    },
  },
  container: {
    maxWidth: 800,
    overflow: 'auto',
    width: 'auto',
  },
  root: {
    [breakpoints.down('md')]: {
      margin: 0,
    },
  },
});

const Dialog = ({
  children,
  className,
  classes,
  onClose,
  open,
  fullScreen = false,
}) => {
  return (
    <MuiDialog
      classes={{ paper: cx(classes.container, className), root: classes.root }}
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
    >
      <img
        alt='close button'
        className={classes.closeButton}
        onClick={onClose}
        src={closeImg}
        data-id='close-button'
      />
      {children}
    </MuiDialog>
  );
};

export default withStyles(styles)(Dialog);
