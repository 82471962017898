import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Button } from 'components';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CloseQuestionAndAnswer = ({ classes, hideModal, callback, text }) => (
  <div style={{ maxWidth: 350 }}>
    <div style={{ marginTop: 16, marginBottom: 48 }}>{text}</div>
    <div className={classes.buttons}>
      <div className={classes.input}>
        <Button title='Resume' outlined onClick={() => hideModal()} />
      </div>
      <div className={classes.input}>
        {/* <a href="/dashboard/home"/> */}
        <Button
          title='Quit Assessment'
          onClick={() => {
            callback();
            hideModal();
          }}
        />
        {/* <a/> */}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(CloseQuestionAndAnswer);
