import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button } from 'components';

const styles = ({
  breakpoints,
  palette: { background, primary, secondary },
}) => ({
  buttonNext: {
    background: secondary.main,
    color: primary.hunterGreen,
    width: 120,
    '&:hover': {
      background: "#CEFF00",
    },
  },
});
const NextButton = ({ classes, onClick }) => (
  <Button className={classes.buttonNext} onClick={onClick} title='next' />
);
export default withStyles(styles)(NextButton);
