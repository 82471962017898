import React from 'react';

import List from './List';
import { GETTING_STARTED } from './data';

export default () => {
  return (
    <List
      baseUrl='getting-started'
      items={GETTING_STARTED}
      title='Getting Started'
    />
  );
};
