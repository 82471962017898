import React from 'react';

export default ({ fill }) => (
  <svg
    width='20'
    height='18'
    viewBox='0 0 20 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='M19.541 16.1396C18.4712 15.655 17.3396 15.2558 16.1647 14.9462C17.7063 13.3562 18.5936 11.2131 18.5936 8.96078C18.5936 4.21118 14.7502 0.367188 9.99999 0.367188C5.25054 0.367188 1.40639 4.21057 1.40639 8.96078C1.40639 11.213 2.29369 13.3561 3.83529 14.9462C2.66036 15.2558 1.52877 15.655 0.458976 16.1396C0.0659096 16.3177 -0.108193 16.7806 0.0698768 17.1737C0.247947 17.5666 0.710746 17.7408 1.10381 17.5628C6.50802 15.1142 13.492 15.1142 18.8962 17.5628C19.2898 17.7413 19.7525 17.5658 19.9301 17.1737C20.1082 16.7806 19.9339 16.3177 19.541 16.1396ZM5.71395 14.5341C3.98971 13.2055 2.96889 11.1458 2.96889 8.96078C2.96889 5.08383 6.12304 1.92969 9.99999 1.92969C13.8769 1.92969 17.0311 5.08383 17.0311 8.96078C17.0311 11.1458 16.0103 13.2055 14.2859 14.5341C12.8918 14.2873 11.4538 14.1608 9.99999 14.1608C8.54613 14.1608 7.10815 14.2873 5.71395 14.5341ZM10.5038 7.50281V5.95496C11.1929 6.0079 11.3943 6.34726 11.7442 6.34726C12.2107 6.34726 12.4016 5.76407 12.4016 5.47781C12.4016 4.74631 11.161 4.57663 10.5038 4.55542V4.29037C10.5038 4.14191 10.3023 4.00412 10.1009 4.00412C9.8677 4.00412 9.69802 4.14191 9.69802 4.29037V4.58716C8.57421 4.74631 7.57751 5.39297 7.57751 6.73941C7.57751 8.09653 8.72268 8.55246 9.69802 8.92355V10.6411C8.91341 10.5033 8.553 9.87784 8.10775 9.87784C7.70476 9.87784 7.38677 10.4079 7.38677 10.7683C7.38677 11.4469 8.42574 12.1042 9.69802 12.1467V12.4117C9.69802 12.5602 9.8677 12.698 10.1009 12.698C10.3023 12.698 10.5038 12.5602 10.5038 12.4117V12.1042C11.7442 11.9028 12.5925 11.1077 12.5925 9.793C12.5925 8.36172 11.4685 7.86337 10.5038 7.50281ZM9.80407 7.24844C9.40124 7.07877 9.12551 6.88788 9.12551 6.58041C9.12551 6.32605 9.32693 6.08221 9.80407 5.98669V7.24844ZM10.3978 10.6306V9.20981C10.7794 9.39001 11.0445 9.61279 11.0445 9.96268C11.0445 10.3443 10.7582 10.5457 10.3978 10.6306Z'
    />
  </svg>
);
