import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(
  ({ breakpoints, palette: { background, primary } }) => ({
    container: {
      borderRadius: 6,
      color: primary.generalText,
      background: background.paper,
      fontSize: 16,
      maxWidth: 800,
      lineHeight: 1.7,
      padding: 40,
      width: 1000,
      [breakpoints.down('sm')]: {
        padding: 24,
        paddingLeft: 0,
        paddingRight: 0,
        width: 'auto',
      },
    },
    layoutContent: {
      display: 'block',
    },
    label: {
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    link: {
      color: primary.generalText,
      fontWeight: 'bold',
      lineBreak: 'anywhere',
      paddingLeft: 3,
      paddingRight: 1,
    },
    list: {
      '& li': {
        marginBottom: 16,
      },
    },
    paragraph: {
      fontSize: 16,
      lineHeight: 1.7,
      marginBottom: 16,
      marginTop: 16,
      [breakpoints.down('xs')]: {
        wordBreak: 'break-word',
      },
    },
    subtitle: {
      color: primary.hunterGreen,
      fontSize: 24,
      fontWeight: 900,
      lineHeight: 1.5,
      marginBottom: 16,
      marginTop: 32,
      textTransform: 'uppercase',
    },
    subtitleSmall: {
      color: primary.hunterGreen,
      fontSize: 18,
      fontWeight: 900,
      lineHeight: 1.8,
      marginBottom: 16,
      marginTop: 32,
      textTransform: 'uppercase',
    },
    title: {
      color: primary.hunterGreen,
      fontSize: 36,
      fontWeight: 900,
      marginBottom: 36,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  })
);

const Cookies = () => {
  const classes = useStyles();
  return (
    <Box display='flex' justifyContent='center' pl='5vw' pr='5vw'>
      <div className={classes.container}>
        <div className={classes.subtitle}>
          1. Information Collected Using Cookies and other Web Technologies
        </div>
        <div className={classes.paragraph}>
          Like many website owners and operators we as well as third parties
          that provide content advertising or other functionality on our
          Services may use cookies, pixel tags, local storage, and other
          technologies (“Technologies”) to automatically collect information
          through the Services. We use Technologies that are essentially small
          data files placed on your computer tablet, mobile phone, or other
          devices (referred to collectively as a “device”) that allow us to
          record certain pieces of information whenever you visit or interact
          with our sites, services, applications, messaging, and tools, and to
          recognize you across devices. We use automated data collection tools
          such as Cookies and Web Beacons to collect certain information.
        </div>
        <div className={classes.subtitle}>2. Cookies</div>
        <div className={classes.paragraph}>
          “Cookies” are small text files that are placed on your device by a web
          server when you access our Services. We may use both session cookies
          and persistent cookies to identify that you’ve logged in to the
          Services and to tell us how and when you interact with our Services.
          We may also use cookies to monitor aggregate usage and web traffic
          routing on our Services and to customize and improve our Services.
          Unlike persistent cookies, session cookies are deleted when you log
          off from the Services and close your browser. Although most browsers
          automatically accept cookies, you can change your browser options to
          stop automatically accepting cookies or to prompt you before accepting
          cookies. Please note however that if you don’t accept cookies you may
          not be able to access all portions or features of the Services. Some
          third-party services providers that we engage (including third-party
          advertisers) may also place their own cookies on your device. Note
          that this Privacy Policy covers only our use of cookies and does not
          include use of cookies by such third parties.
        </div>
        <div className={classes.subtitle}>3. Pixels</div>
        <div className={classes.paragraph}>
          “Pixels”/“Web Beacons” (also known as Web bugs pixel tags or clear
          GIFs) are tiny graphics with a unique identifier that may be included
          on our Services for several purposes including to deliver or
          communicate with Cookies to track and measure the performance of our
          Services to monitor how many visitors view our Services and to monitor
          the effectiveness of our advertising. Unlike Cookies which are stored
          on the user’s hard drive. Web Beacons are typically embedded invisibly
          on web pages (or in an e-mail).
        </div>
        <div className={classes.subtitle}>4. Widgets</div>
        <div className={classes.paragraph}>
          “Social Media Widgets” such as the Facebook “Like” button and LinkedIn
          (that might include widgets such as the share this button or other
          interactive miniprograms) may be on our Site. These features may
          collect your IP address which page you are visiting on our site and
          may set a cookie to enable the feature to function properly. These
          social media features are either hosted by a third party or hosted
          directly on our Website. Your interactions with these features are
          governed by the privacy policy of the company providing it.
        </div>
        <div className={classes.subtitle}>5. Analytics</div>
        <div className={classes.paragraph}>
          We may also use Google Analytics and Google Analytics Demographics and
          Interest Reporting to collect information regarding visitor behavior
          and visitor demographics on some of our Services and to develop
          website content. This analytics data is not tied to any Personal
          Information. For more information about Google Analytics please visit
          www.google.com/policies/privacy/partners/. You can opt out of Google’s
          collection and Processing of data generated by your use of the
          Services by going to tools.google.com/dlpage/gaoptout.
        </div>
        <div className={classes.paragraph}>
          Our uses of such Technologies fall into the following general
          categories:
        </div>
        <div className={classes.subtitleSmall}>Operationally Necessary </div>
        <div className={classes.paragraph}>
          We may use cookies, web beacons, or other similar technologies that
          are necessary to the operation of our sites, services, applications,
          and tools. This includes technologies that allow you access to our
          sites, services, applications, and tools; that are required to
          identify irregular site behavior prevent fraudulent activity and
          improve security; or that allow you to make use of our functions such
          as shopping-carts saved search or similar functions.
        </div>
        <div className={classes.subtitleSmall}>Performance Related</div>
        <div className={classes.paragraph}>
          We may use cookies, web beacons or other similar technologies to
          assess the performance of our websites applications services and tools
          including as part of our analytic practices to help us understand how
          our visitors use our websites determine if you have interacted with
          our messaging determine whether you have viewed an item or link or to
          improve our website content applications services or tools.
        </div>
        <div className={classes.subtitleSmall}>Functionality Related</div>
        <div className={classes.paragraph}>
          We may use cookies, web beacons or other similar technologies that
          allow us to offer you enhanced functionality when accessing or using
          our sites services, applications, or tools. This may include
          identifying you when you sign into our sites or keeping track of your
          specified preferences, interests, or past items viewed so that we may
          enhance the presentation of content on our sites;
        </div>
        <div className={classes.subtitleSmall}>Advertising or Targeting </div>
        <div className={classes.paragraph}>
          Related We may use first-party or third-party cookies and web beacons
          to deliver content including ads relevant to your interests on our
          sites or on third party sites. This includes using technologies to
          understand the usefulness to you of the advertisements and content
          that has been delivered to you such as whether you have clicked on an
          advertisement. If you would like to opt-out of the Technologies we
          employ on our sites services applications or tools you may do so by
          blocking deleting or disabling them as your browser or device permits.
        </div>
        <div className={classes.subtitle}>
          6. Cookies and Interest-Based Advertising
        </div>
        <div className={classes.paragraph}>
          As noted above you may stop or restrict the placement of cookies on
          your computer or remove them from your browser by adjusting your web
          browser preferences. Please note that cookie-based opt-outs are not
          effective on mobile applications. However on many mobile devices
          application users may opt out of certain mobile ads via their device
          settings. The online advertising industry also provides websites from
          which you may opt-out of receiving targeted ads from our data partners
          and our other advertising partners that participate in self-regulatory
          programs. You can access these and also learn more about targeted
          advertising and consumer choice and privacy at
          www.networkadvertising.org/managing/opt_out.asp or
          youronlinechoices.eu/ and aboutads.info/choices. You can also choose
          not to be included in Google Analytics here.
        </div>
        <div className={classes.paragraph}>
          To be clear whether you are using our opt-out or an online industry
          opt-out these cookie based opt-outs must be performed on each device
          and browser that you wish to have opted-out. For example if you have
          opted-out on your computer browser that opt-out will not be effective
          on your mobile device. You must separately opt-out on each device.
          Advertisements on third party websites that contain the AdChoices link
          and that link to this Privacy Policy may have been directed to you
          based on data collected by advertising partners over time and across
          websites. These advertisements provide a mechanism to opt-out of the
          advertising partners’ use of this information for interest-based
          advertising purposes.
        </div>
        <div className={classes.subtitle}>Questions?</div>
        <div className={classes.paragraph}>
          Please contact us at support.movementvault.com if you have any
          questions about our practices or this Cookie Policy.
        </div>
      </div>
    </Box>
  );
};

export default Cookies;
