export const getAsessmentVideoSettings = ({
  question,
  onResolutionSelected,
  resolution,
}) => {
  const availableResolutions = ['video', 'videoHi', 'videoLo'].filter(
    (resolution) => !!question[resolution]
  );

  // Default to 'video'
  const selectedResolution = availableResolutions.includes(resolution)
    ? resolution
    : availableResolutions[0];

  const videoObj = question[selectedResolution];

  const {
    location: videoLocation,
    additionalData: { duration: videoDuration, streamUrl },
  } = videoObj;

  const playbackSettings = {
    src: streamUrl || videoLocation,
    videoDuration,
  };

  const selectResolution = (res) => () => {
    onResolutionSelected(res);
    localStorage.setItem('resolution', res);
  };

  const resolutionSettings = availableResolutions.map((res) => {
    const isSelected = res === selectedResolution;

    if (res === 'video') {
      return {
        isSelected,
        onSelect: selectResolution(res),
        resolution: 720,
      };
    }
    if (res === 'videoHi') {
      return {
        isSelected,
        onSelect: selectResolution(res),
        resolution: 1080,
      };
    }

    if (res === 'videoLo') {
      return {
        isSelected,
        onSelect: selectResolution(res),
        resolution: 480,
      };
    }

    return {};
  });

  return { playbackSettings, resolutionSettings };
};
