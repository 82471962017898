import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';

import ArrowDown from 'components/icons/ArrowDown';
import ArrowUp from 'components/icons/ArrowUp';

import Checkbox from 'assets/svg/checkbox.svg';
import CheckboxChecked from 'assets/svg/checkbox-checked.svg';

const styles = ({ palette: { primary, background } }) => ({
  container: {
    color: '#4F4F4F',
    position: 'relative',
    zIndex: 10,
  },
  accordion: {
    background: background.paper,
    left: 0,
    outline: `1px solid ${primary.main}`,
    position: 'absolute',
    top: 45,
    width: 320,
  },
  accordionHeader: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    display: 'flex',
    fontWeight: 600,
    height: 45,
    justifyContent: 'space-between',
    lineHeight: 1,
    padding: 16,
  },
  accordionItem: {
    alignItems: 'center',
    background: '#fafafa',
    cursor: 'pointer',
    display: 'flex',
    lineHeight: 1,
    height: 45,
    '& > img': {
      marginLeft: 16,
      marginRight: 16,
    },
    '&:hover': {
      background: primary.light,
      color: primary.main,
    },
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: primary.main,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
  },
  select: {
    alignItems: 'center',
    background: primary.contrastText,
    color: primary.dark,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    height: 43,
    marginTop: 1,
    justifyContent: 'space-between',
    padding: 16,
    width: 130,
    '&:focus': {
      color: primary.main,
      outline: `1px solid ${primary.main}`,
    },
  },
  tagCount: {
    alignItems: 'center',
    background: '#E1E3E6',
    borderRadius: '50%',
    color: primary.main,
    display: 'flex',
    fontSize: 12,
    fontWeight: 600,
    height: 25,
    justifyContent: 'center',
    marginRight: 8,
    width: 25,
  },
});

let TagCount = ({ classes, count }) => {
  if (count < 1) return null;
  return <div className={classes.tagCount}>{count}</div>;
};

TagCount = withStyles(styles)(TagCount);

let AccordionPanel = ({
  classes,
  items,
  onItemClick,
  selectedValues,
  title,
}) => {
  const {
    palette: { primary, secondary },
  } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const AccordionItem = ({ item: { label, value } }) => {
    const isChecked = selectedValues.includes(value);
    const iconSrc = isChecked ? CheckboxChecked : Checkbox;

    return (
      <div
        className={classes.accordionItem}
        onMouseDown={(e) => {
          e.preventDefault();
          onItemClick(isChecked, value);
        }}
      >
        <img src={iconSrc} alt='checkbox' />
        <div>{label}</div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={classes.select}
        onMouseDown={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        style={{
          width: '100%',
          border: 'none',
          borderTop: `1px solid ${secondary.gray}`,
          boxSizing: 'border-box',
        }}
      >
        <div className={classes.text}>{title}</div>
        <div className={classes.icons}>
          <TagCount count={selectedValues.length} />
          {isOpen ? (
            <ArrowUp fill={primary.generalText} />
          ) : (
            <ArrowDown fill={primary.generalText} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={classes.accordionItems}>
          {items
            .sort((a, b) => {
              const labelA = a.label.toUpperCase();
              const labelB = b.label.toUpperCase();
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            })
            .map((item) => (
              <AccordionItem key={item.value} item={item} />
            ))}
        </div>
      )}
    </div>
  );
};

AccordionPanel = withStyles(styles)(AccordionPanel);

const TagFilter = ({
  classes,
  onSelectionsChange,
  initialSelections,
  placeholder = 'Select',
  selections,
  ...props
}) => {
  const {
    palette: { primary },
  } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const color = isActive ? primary.main : primary.generalText;

  const total = Object.keys(selections).reduce(
    (acc, curr) => selections[curr].length + acc,
    0
  );

  const updateSelections = (updatedSelections) => {
    onSelectionsChange(updatedSelections);
  };

  const handleAccordionItemClick = (accordionId) => (isItemChecked, itemId) => {
    let updatedSelections;

    if (isItemChecked) {
      updatedSelections = selections[accordionId].filter((s) => s !== itemId);
    } else {
      updatedSelections = selections[accordionId].concat(itemId);
    }

    updateSelections({ ...selections, [accordionId]: updatedSelections });
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.select}
        onBlur={(e) => {
          setIsActive(false);
          setIsOpen(false);
        }}
        onClick={() => setIsOpen(!isOpen)}
        onFocus={() => setIsActive(true)}
        tabIndex='1'
        style={{
          color: isActive ? primary.main : primary.dark,
          outline: `1px solid ${isActive ? primary.main : primary.gray}`,
        }}
      >
        <div className={classes.text}>{placeholder}</div>
        {isOpen ? <ArrowUp fill={color} /> : <ArrowDown fill={color} />}
      </div>
      {isOpen && (
        <div className={classes.accordion}>
          <div className={classes.accordionHeader}>
            <div>Tags</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TagCount count={total} />
              {total > 0 && (
                <div
                  className={classes.link}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    updateSelections(initialSelections);
                  }}
                >
                  Clear All
                </div>
              )}
            </div>
          </div>
          {Object.keys(selections).map((selectionKey) => (
            <AccordionPanel
              items={props[selectionKey]}
              key={selectionKey}
              onItemClick={handleAccordionItemClick(selectionKey)}
              selectedValues={selections[selectionKey]}
              title={selectionKey}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  tags: {
    bodyArea,
    difficulty,
    equipment,
    frontEndVisible,
    modality,
    movementPrep,
    status,
    usageArea,
    workoutType,
    muscles,
  },
}) => ({
  'Area of the Body': bodyArea,
  Difficulty: difficulty,
  'Frontend Visible': frontEndVisible,
  Equipment: equipment,
  Modality: modality,
  Muscles: muscles,
  'Movement Prep': movementPrep,
  Status: status,
  'Usage Area': usageArea,
  'Workout Type': workoutType,
});

export default connect(mapStateToProps)(withStyles(styles)(TagFilter));
