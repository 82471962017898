import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='-4 -2 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.95096 19.2188C8.95096 19.6503 8.60107 20 8.16956 20H0.78125C0.349731 20 0 19.6503 0 19.2188V0.78125C0 0.349731 0.349731 0 0.78125 0H8.16971C8.60107 0 8.95096 0.349731 8.95096 0.78125C8.95096 1.21277 8.60123 1.5625 8.16971 1.5625H1.5625V18.4375H8.16956C8.60107 18.4375 8.95096 18.7872 8.95096 19.2188ZM19.7716 9.44748L15.3522 5.02808C15.047 4.72305 14.5525 4.72305 14.2473 5.02808C13.9423 5.33325 13.9423 5.82779 14.2473 6.13297L17.3331 9.21875H3.75015C3.31863 9.21875 2.9689 9.56848 2.9689 10C2.9689 10.4315 3.31863 10.7812 3.75015 10.7812H17.3331L14.2473 13.8669C13.9423 14.1721 13.9423 14.6667 14.2473 14.9718C14.5525 15.2769 15.0471 15.2768 15.3522 14.9718L19.7717 10.5524C19.8766 10.4478 19.9551 10.3059 19.9855 10.1526C20.0342 9.90677 19.9602 9.63593 19.7716 9.44748Z'
      fill={fill}
    />
  </svg>
);
