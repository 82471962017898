import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';

import {
  Button,
  FormCheckbox,
  Form,
  FormInput,
  FormRow,
  Text,
} from 'components';

const styles = (theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  input: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
});

const ParentPlanForm = ({
  classes,
  error,
  onSubmit,
  processing,
  title,
  onCancel,
  buttonText,
  parentPlan,
}) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form error={error} showDivider={false}>
        <FormRow>
          <FormInput
            error={error}
            label='plan name'
            name='title'
            placeholder='Plan Name'
          />
        </FormRow>
        <FormRow>
          <FormCheckbox label={<Text>Private</Text>} name='private' />
        </FormRow>
        <FormRow>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button title='cancel' outlined onClick={onCancel} />
            </div>
            <div className={classes.input}>
              <Button
                disabled={!isValid}
                processing={processing}
                processingLabel='Creating...'
                title={buttonText}
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    title: string().min(6).required('Plan name is required'),
  });

  return (
    <Formik
      initialValues={{ title: parentPlan?.title, private: '' }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default withStyles(styles)(ParentPlanForm);
