import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Creators as ModalCreators } from 'modules/modal';
import { Creators as TagCreators } from 'modules/admin/dashboard/programming/tags';
import { Creators as ExerciseCreators } from 'modules/admin/dashboard/programming/exercise-creator';
import { Creators } from '../reducer';

import List from '../../components/List';

const Workouts = ({
  deleteWorkout,
  deleteWorkouts,
  duplicateWorkout,
  exerciseDirty,
  getExercises,
  getWorkouts,
  getWorkout,
  items,
  processing,
  showModal,
  tagsDirty,
  ui,
  updateExerciseState,
  updateTagState,
  updateUI,
}) => {
  useEffect(() => {
   
    if (tagsDirty || exerciseDirty) {
      getExercises();
      getWorkouts();
      getWorkout()
      updateTagState({ key: 'isDirty', value: false });
      updateExerciseState({ key: 'isDirty', value: false });
    }
  }, [
    exerciseDirty,
    getExercises,
    getWorkouts,
    tagsDirty,
    updateExerciseState,
    updateTagState,
    getWorkout,
  ]);

  return (
    <>
      <Helmet>
        <title>Workouts</title>
      </Helmet>
      
      <List
        baseUri='workout-creator'
        buttonTitle='create new workout'
        fetchDetails={getWorkout}
        items={items}
        onBulkDelete={deleteWorkouts}
        onDelete={deleteWorkout}
        onDuplicate={duplicateWorkout}
        processing={processing}
        showModal={showModal}
        ui={ui}
        updateUI={updateUI}
      />
    </>
  );
};

const mapDispatchToProps = {
  deleteWorkout: Creators.deleteWorkout,
  deleteWorkouts: Creators.deleteWorkouts,
  getExercises: ExerciseCreators.getExercises,
  getWorkouts: Creators.getWorkouts,
  getWorkout: Creators.getWorkout,
  showModal: ModalCreators.showModal,
  updateExerciseState: ExerciseCreators.updateState,
  updateTagState: TagCreators.updateState,
  updateUI: Creators.updateUI,
  duplicateWorkout : Creators.duplicateWorkout,

};

const mapStateToProps = ({
  workoutCreator: { workouts, processing, ui },
  tags: { isDirty: tagsDirty },
  exerciseCreator: { isDirty: exerciseDirty },
}) => {
  return {
    exerciseDirty,
    items: workouts,
    processing,
    tagsDirty,
    ui,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workouts);
