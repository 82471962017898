import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { push } from 'connected-react-router';

import { Creators } from 'modules/auth';
import Doctor from 'components/icons/Doctor';
import Favorite from 'components/icons/Favorite';
import HeartRate from 'components/icons/HeartRate';
import Home from 'components/icons/Home';
import Invite from 'components/icons/Invite';
import Lifter from 'components/icons/Lifter';
import Logout from 'components/icons/Logout';
import Schedule from 'components/icons/Schedule';
import Stretching from 'components/icons/Stretching';
import Profile from 'components/icons/Profile';
import TeamDashboard from 'components/icons/TeamDashboard';
import Upgrade from 'components/icons/Upgrade';
import YogaMat from 'components/icons/YogaMat';
import Assessement from 'components/icons/Assessement'

import logo from 'assets/svg/mv-logo-navbar.svg';
import { Box } from '@material-ui/core';

const styles = ({ breakpoints, palette: { primary, secondary } }) => ({
  container: {
    height: '100%',
    width: 236,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  content: {
    position: 'sticky',
    top: 0,
    padding: '30px 0px',
    [breakpoints.down('xs')]: {
      padding: '15px 0px',
    },
  },
  icon: {
    alignItems: 'center',
    background: 'rgba(235, 249, 179, 0.18)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 8,
    height: 36,
    width: 36,
    '& > svg': {
      width: 20,
      height: 20,
    },
  },
  iconSelected: {
    background: 'rgba(204, 255, 0, 0.22)',
  },
  logo: {
    display: 'flex',
    position: 'relative',
    height: '1.5rem',
    width: '100%',
    maxHeight: '1.5rem',
    justifyItems: 'center',
    padding: '0px 24px',
    [breakpoints.down('xs')]: {
      maxWidth: '80% !important',
      marginBottom: -8,
    },
    '& > img': {
      maxWidth: '10.75rem',
      position: 'relative',
      left: 0,
    },
  },
  menu: {
    padding: 16,
    [breakpoints.down('xs')]: {
      padding: 24,
    },
  },
  menuItem: {
    color: primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: 0.1,
    padding: '0.5rem 0',
    '&:hover': {
      color: secondary.main,
      fill: secondary.main,
    },
  },
  menuItemSelected: {
    color: secondary.main,
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    marginRight: '24px',
    color: 'white',
  },
});

const HOME_URL = '/dashboard';

export const SideBar = ({
  classes,
  signOut,
  isSmallScreen = false,
  user,
  goTo,
  closeDialog = () => {},
}) => {
  const {
    palette: { secondary },
  } = useTheme();
  const location = useLocation();

  const redirectToPricing = () => {
    goTo('/dashboard/payment/choose-plan');
  };


  const MENU = [
    {
      icon: Home,
      name: 'Home',
      title: 'Home',
      url: `${HOME_URL}/home`,
    },
    {
      icon: Lifter,
      name: 'exercises',
      title: 'Exercises',
      url: `${HOME_URL}/exercises`,
    },
    {
      icon: YogaMat,
      name: 'daily-classs',
      title: 'Daily Class',
      url: `${HOME_URL}/daily-class`,
    },
    {
      icon: Doctor,
      name: 'pain-and-injury',
      title: 'Programs',
      url: `${HOME_URL}/pain-and-injury`,
    },
    // {
    //   icon: YogaMat,
    //   name: 'programs',
    //   title: 'Programs',
    //   url: `${HOME_URL}/programs`,
    // },
    {
      icon: Stretching,
      name: 'pre-workout',
      title: 'Pre-Workout',
      url: `${HOME_URL}/pre-workout`,
    },
    {
      icon: HeartRate,
      name: 'recovery',
      title: 'Recovery',
      url: `${HOME_URL}/recovery`,
    },
    {
      icon: Schedule,
      name: 'work-routines',
      title: 'Work Routines',
      url: `${HOME_URL}/work-routine`,
    },
    
    {
      icon: Assessement,
      name: 'assessment',
      title: 'Assessment',
      url: `${HOME_URL}/assessment`,
    },
    {
      icon: Favorite,
      name: 'favorites',
      title: 'Favorites',
      url: `${HOME_URL}/favorites`,
    },
    {
      icon: Upgrade,
      name: 'upgrade',
      title: 'Upgrade',
      url: `${HOME_URL}/payment/choose-plan`,
    },
    {
      icon: Invite,
      name: 'invite-friends',
      title: 'Invite Friends',
      url: `${HOME_URL}/invite-friends`,
    },
    {
      icon: Profile,
      name: 'profile',
      title: 'Profile',
      url: `${HOME_URL}/profile`,
    },
    {
      icon: TeamDashboard,
      name: 'team-dashboard',
      title: 'Team Dashboard',
      url: `${HOME_URL}/team-dashboard`,
      remove: !user.isCompanyAdmin
    },
    {
      icon: Logout,
      name: 'logout',
      onClick: signOut,
      title: 'Logout',
    },
  ].filter((obj) => !obj.remove);



  

  const isItemSelected = (url) =>
    url && url === location.pathname.substr(0, url.length);

  const getIcon = (Icon, itemSelected) => {
    const fill = itemSelected ? secondary.main : undefined;
    return (
      <div
        className={cx(classes.icon, itemSelected ? classes.iconSelected : '')}
      >
        <Icon fill={fill} />
      </div>
    );
  };

  const MenuItem = ({ item  }) => {
    const isSelected = isItemSelected(item.url);

    const MenuItemBody = () => (
      <>
      <div
        className={cx(
          classes.menuItem,
          isSelected ? classes.menuItemSelected : ''
        )}
        onClick={item?.onClick}
      >
        {getIcon(item.icon, isSelected)}

        <div>{item.title}</div>

      </div>
      </>
    );
    return (
      <>
        {item.url ? (
          <NavLink
            exact
            to={item.url}
            onClick={() => isSmallScreen && closeDialog()}
            style={{ textDecoration: 'none' }}
          >
            <MenuItemBody />
          </NavLink>
        ) : (
          <MenuItemBody />
        )}
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <div className={classes.logo}>
              <img src={logo} alt='logo' />
            </div>
            {isSmallScreen && (
              <Box display='flex' alignItems='flex-start'>
                <Close
                  className={classes.closeIcon}
                  onClick={() => closeDialog()}
                />
              </Box>
            )}
          </Box>

          <div className={classes.menu}>
            {/* <div onClick={() => goTo('/dashboard/home/welcome')}>
              <MenuItem
                item={{
                  icon: Home,
                  name: 'test',
                  title: 'Home',
                }}
              />
            </div> */}
            {MENU.map((item) => {
              return user.status === 'freemium' ? (
                <div key={item.name} onClick={redirectToPricing}>
                  <MenuItem item={item} />
                </div>
              ) : (
                <>
                  {/* <MenuItem item={item} /> */}
                  {user.isCompanyAdmin === true ? <MenuItem item={item}  /> : <MenuItem item={item} />}
                  {/* {user.status === "premium" ? <MenuItem item={item}  /> : null } */}
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* <div className={classes.content}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <div className={classes.logo}>
            <img src={logo} alt='logo' />
          </div>
          {isSmallScreen && (
            <Box display='flex' alignItems='flex-start'>
              <Close
                className={classes.closeIcon}
                onClick={() => closeDialog()}
              />
            </Box>
          )}
        </Box>
        <div className={classes.menu}>
          {user.status != "freemium" ?
          <>          {MENU.map((item) => (
            <div key={item.name}
            onClick={
              user.status === "freemium" ? () => redirectToPricing() : null
             }
            >
              <MenuItem item={item} />
            </div>
          ))}
          </> : null}
        </div>
      </div> */}
    </>
  );
};

const mapStateToProps = ({ auth: { user, error, processing } }) => ({
  user,
  error,
  processing,
});

const mapDispatchToProps = {
  signOut: Creators.signOut,
  goTo: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SideBar));
