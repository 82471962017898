import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
  container: {
    color: theme.palette.primary.main,
    fontSize: 14,
    letterSpacing: theme.letterSpacing.veryNarrow,
  },
});

export const Link = ({ classes, children, style, target = '', title, to }) => (
  <NavLink className={classes.container} style={style} target={target} to={to}>
    {title || children}
  </NavLink>
);

Link.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  target: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(Link);
