import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { go } from 'connected-react-router';

import { Creators } from '../reducer';
import { Button } from 'components';

import workoutCompletedImg from 'assets/img/image-invite-friends.png';

const styles = ({ palette: { primary } }) => ({
  button: {
    height: 56,
    marginTop: 32,
    width: 200,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '80vh',
    minHeight: '720px',
  },
  content: {
    width: 360,
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 4,
  },
  infoPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    color: primary.main,
    fontSize: 20,
    fontWeight: 900,
    marginLeft: 8,
  },
  text: {
    color: primary.black,
    fontSize: 20,
    letterSpacing: 0.25,
  },
  title: {
    color: primary.black,
    fontSize: 36,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: 3,
    textTransform: 'uppercase',
  },
});

const WorkoutResults = ({ classes, results, navigate, updateResults }) => {
  useEffect(() => {
    return () => updateResults(null);
  }, [updateResults]);

  if (!results) return null;

  const { completed, count, duration } = results;

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60) || 1;

  let hoursLabel = '';
  let minutesLabel = '';

  if (hours > 0) {
    hoursLabel = `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  // To see the duration label one must exercise for at least 20 seconds.
  if (duration >= 20) {
    minutesLabel = `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  const percentComplete = completed / count;

  const generateMessage = () => {
    if (percentComplete < 0.51) return 'Nice Start!';
    if (percentComplete < 0.9) return 'Excellent Effort!';
    return 'Great Job!';
  };

  const shouldHideStatsForSingleWorkout = percentComplete < 0.9;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img
          width={360}
          height={360}
          src={workoutCompletedImg}
          alt='workout completed'
        />
        <div className={classes.infoPanel}>
          <div className={classes.title}>{generateMessage()}</div>
          {(hoursLabel || minutesLabel) && (
            <div className={classes.info}>
              <div className={classes.text}>Duration:</div>
              <div
                className={classes.label}
              >{`${hoursLabel} ${minutesLabel}`}</div>
            </div>
          )}
          {!shouldHideStatsForSingleWorkout && !!count && count > 0 && (
            <div className={classes.info}>
              <div className={classes.text}>Movements completed:</div>
              <div className={classes.label}>{`${completed}/${count}`}</div>
            </div>
          )}
          <Button
            className={classes.button}
            onClick={() => navigate(-2)}
            title='done'
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ workout: { results } }) => ({
  results,
});

export default connect(mapStateToProps, {
  navigate: go,
  updateResults: Creators.updateResults,
})(withStyles(styles)(WorkoutResults));
