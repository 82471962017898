import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Dialog, Slide, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Menu } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import logo from 'assets/svg/mv-logo-navbar.svg';
import backgroundImg from 'assets/img/background@2x.jpg';
import { SideBar } from './index';

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingLeft: '1vw',
    paddingRight: '1vw',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5vw',
    },
    zIndex: 1,
  },
  logoImage: {
    maxWidth: '50vw',
    height: '2rem',
    marginBottom: '-0.5rem',
  },
  sidebar: {
    backgroundImage: `url(${backgroundImg})`,
    backdropFilter: 'blur(3px)',
    objectFit: 'contain',
    height: 'max(100%, 730px)',
  },
  root: {
    height: '100%',
    paddingLeft: '10vw',
    paddingRight: '10vw',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  navbar: {
    background: theme.palette.primary.hunterGreen,
    height: 50,
    overflow: 'auto',
  },
  menuButton: {
    color: 'white',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='left' ref={ref} {...props} />
));

const Navbar = ({ isIndexPage = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles({
    isSmallScreen,
    isIndexPage,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <AppBar className={classes.navbar} elevation={0} position='static'>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className={classes.root}
      >
        <Box alignItems='center' display='flex' className={classes.logo}>
          <img src={logo} alt='movementvault' className={classes.logoImage} />
        </Box>
        <Box display='flex'>
          <Box
            display={{
              md: 'flex',
              sm: 'none',
            }}
            pr={{
              sm: 0,
            }}
          >
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => setIsMenuOpen(true)}
            >
              <Menu height={40} width={40} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        TransitionComponent={Transition}
      >
        <div className={classes.sidebar}>
          <SideBar isSmallScreen closeDialog={() => setIsMenuOpen(false)} />
        </div>
      </Dialog>
    </AppBar>
  );
};

Navbar.propTypes = {
  transparent: PropTypes.bool,
  tab: PropTypes.string,
  position: PropTypes.string,
  isIndexPage: PropTypes.bool,
};

export default Navbar;
