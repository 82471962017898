import React from 'react';
import { Button } from '../../../../components';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 56,
    width: 208,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 3.5,
    letterSpacing: 1.23,
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    width: 424,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  leftAlignDialogActions: {
    justifyContent: 'flex-start',
    paddingLeft: '24px',
  },
  dialog: {
    maxWidth: 650,
    padding: '64px 92px',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '40px 16px',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    color: '#010300',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: 'left',
    },
  },
  infoText: {
    fontSize: 14,
    lineHeight: 1.71,
    color: '#010300',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  link: {
    fontSize: 14,
    lineHeight: 2.86,
    color: '#28aae2',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  appleInfoText: {
    fontSize: 14,
    lineHeight: 1.71,
    color: '#010300',
    marginBottom: 20,
  },
}));

const RedeemModal = ({ isOpen, setIsOpen, method = 'stripe' }) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth='sm'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{ paper: classes.dialog }}
    >
      {method === 'stripe' && (
        <div className={classes.container}>
          <div className={classes.title}>
            {'Your credits have been applied to your account.'.toUpperCase()}
          </div>
          <div className={classes.infoText}>
            Credits will be used for your future payments.
          </div>
          <Button
            title='OKAY'
            className={classes.button}
            onClick={() => setIsOpen(false)}
          />
        </div>
      )}
      {method === 'in-app' && (
        <div>
          <div className={classes.appleInfoText}>
            You signed up using Apple in-app purchases and unfortunately Apple
            does not allow the use of credit. There is a simple fix. Cancel your
            Apple in-app purchase subscription using the link below and then
            resume your subscription from our website on your account page after
            logging in. You will then be able to use all of your credits/claim
            your referral money. You will also not lose any subscription/account
            info and will still have access to our app when resuming your
            subscription on our website. We are also sending you an email with
            instructions.
          </div>
          {/* TODO Add proper link */}
          <a href='/' className={classes.link}>
            Cancel Apple in-app subscription
          </a>
        </div>
      )}
    </Dialog>
  );
};

export default RedeemModal;
