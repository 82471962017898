import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import NavBar from '../../../components/NavBar';
import { Creators } from '../reducer';
import { Creators as PlanCreators } from '../../plan/reducer';
import CouponForm from './CouponForm';

import { HOME_URL } from '..';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const CreateNewCoupon = ({
  classes,
  createCoupon,
  processing,
  plans,
  getPlans,
}) => {
  const title = 'Create New Coupon';
  useEffect(() => {
    if (plans === null) {
      getPlans();
    }
  }, [getPlans, plans]);
  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={HOME_URL} />
      <div className={classes.content}>
        <CouponForm
          onSubmit={createCoupon}
          pageTitle={title}
          processing={processing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ plan }) => ({
  processing: false,
  plans: plan.plans,
});

export default connect(mapStateToProps, {
  createCoupon: Creators.createCoupon,
  getPlans: PlanCreators.getPlanGroups,
})(withStyles(styles)(CreateNewCoupon));
