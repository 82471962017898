import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cs from 'classnames';

import helpingImage from 'assets/img/helping-you@2x.jpg';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: primary.hunterGreen,
    [breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  dividerText: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.56,
    color: primary.main,
    [breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  text: {
    fontSize: 18,
    lineHeight: 1.56,
    color: primary.generalText,
    maxWidth: 470,
    [breakpoints.down('xs')]: {
      maxWidth: 'none',
      fontSize: 16,
    },
  },
  textCursive: {
    fontStyle: 'italic',
  },
  divider: {
    width: '100%',
    'border-bottom': `1px solid ${primary.main}`,
  },
  image: {
    width: '100%',
    maxWidth: 704,
    maxHeight: 485,
    height: 'auto',
  },
}));

const HelpingYouBlock = () => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection={{
        xs: 'column-reverse',
        sm: 'row',
      }}
    >
      <Box
        display='flex'
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}
        alignItems='center'
        width='100%'
        pt={{
          xs: 5,
          sm: 0,
        }}
        px={{
          xs: '15px',
          sm: 0,
        }}
      >
        <img src={helpingImage} alt='our-mission' className={classes.image} />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pl={{ xs: '15px', sm: 5 }}
        pr={{
          xs: '15px',
          sm: 0,
        }}
        width='100%'
      >
        <Typography className={classes.dividerText} variant='h4'>
          CREATING MOVEMENT VAULT
        </Typography>
        <div className={classes.divider} />
        <Typography className={classes.title} variant='h4'>
          HELPING YOU
        </Typography>
        <Typography className={classes.text} variant='body1'>
          After working with patients and athletes 1-on-1 for years, he
          developed a system of flexibility, mobility, and movement techniques
          that truly achieve results. He knew he had to bring this to the world.
          Movement Vault delivers the most effective techniques to help you
          achieve results in the shortest amount of time.
        </Typography>
        <Typography
          className={cs(classes.text, classes.textCursive)}
          variant='body1'
        >
          “I see way too many people either not working on their flexibility and
          mobility or if they are, they are performing techniques that are not
          effective, essentially wasting their time.”
        </Typography>
        <Typography className={classes.text} variant='body1'>
          Movement Vault delivers the most effective techniques to help you
          achieve results in the shortest amount of time.
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpingYouBlock;
