import React from 'react';
import MUICheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export const TableCheckbox = React.forwardRef(
  ({ size = 'small', ...rest }, ref) => (
    <MUICheckbox
      color='primary'
      inputProps={{ 'aria-label': 'primary checkbox' }}
      ref={ref}
      size={size}
      {...rest}
    />
  )
);

TableCheckbox.propTypes = {
  size: PropTypes.string,
};

export default TableCheckbox;
