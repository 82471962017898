import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiSlider from '@material-ui/core/Slider';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
}));

const Slider = withStyles(
  ({ breakpoints, palette: { background, secondary } }) => ({
    markLabel: {
      fontSize: 16,
      marginTop: 8,
      width: 70,
      marginLeft: 16,
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      '&[data-index="0"]': {
        marginLeft: 24,
      },
      [breakpoints.down('xs')]: {
        width: 50,
        marginLeft: 12,
        fontSize: 12,
      },
    },
    rail: {
      borderRadius: 4,
      height: 8,
      marginBottom: 8,
    },
    root: {
      color: secondary.main,
      height: 8,
    },
    thumb: {
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      backgroundColor: background.paper,
      border: `5px solid #44444b`,
      height: 24,
      marginLeft: -12,
      marginTop: -8,
      width: 24,
    },
    track: {
      borderRadius: 4,
      height: 8,
    },
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
  })
)(MuiSlider);

export default function ({
  defaultValue,
  marks,
  min,
  max,
  onChangeCommitted,
  step,
  value,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Slider
        defaultValue={defaultValue}
        marks={marks}
        max={max}
        min={min}
        onChangeCommitted={onChangeCommitted}
        step={step}
        valueLabelDisplay='off'
        value={value}
      />
    </div>
  );
}
