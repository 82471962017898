import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ListItem } from '../index';

const styles = ({ palette: { background, primary } }) => ({
  list: {
    background: background.paper,
    borderRight: `1px solid ${primary.gray}`,
    borderBottom: `1px solid ${primary.gray}`,
    height: 'calc(100vh - 128px)',
    overflowY: 'auto',
    width: 460,
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
});

const List = ({ classes, items, handleClick, selectedIndex }) => {
  return (
    <Box className={classes.list}>
      {items.map(({ message, title, onClick }, index) => (
        <ListItem
          onClick={() => handleClick(index)}
          key={index}
          text={message}
          title={title}
          isSelected={selectedIndex === index}
        />
      ))}
    </Box>
  );
};

export default withStyles(styles)(List);
