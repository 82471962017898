import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog } from '@material-ui/core';
import search from 'assets/img/new-search-icon.png';
import { connect } from 'react-redux';
// import { Creators } from '../reducer';
import { Creators } from '../../../admin/dashboard/membership/members/reducer';
import { Creators as regenerateCreator } from '../../../auth/reducer';

const styles = ({ palette: { primary }, breakpoints }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: '70px',
    paddingBottom: '70px',
    paddingLeft: '30px',
    paddingRight: '30px',
    [breakpoints.down('xs')]: {
      paddingTop: '40px',
      paddingLeft: '0px',
      paddingRight: '0px',
      paddingBottom: '40px',
    },
  },
  button: {
    width: 208,
    height: 64,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  teamAccountName: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    lineHeight: '44px',
    letterSpacing: '0.35px',
    textTransform: 'uppercase',
    color: '#020301',
    [breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  usersAddedText: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.26px',
    color: '#63646F',
    paddingTop: '23px',
    [breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  button: {
    width: 208,
    height: 64,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  teamAccountName: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    lineHeight: '44px',
    letterSpacing: '0.35px',
    textTransform: 'uppercase',
    color: '#020301',
    [breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  usersAddedText: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.26px',
    color: '#63646F',
    paddingTop: '23px',
  },
  lineBreakerMobile: {
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '1px',
      borderStyle: 'solid',
      borderColor: '#DFE2E6',
      marginTop: '24px',
      borderWidth: '0.5px',
    },
  },
  lineBreaker: {
    width: '100%',
    height: '1px',
    borderStyle: 'solid',
    borderColor: '#DFE2E6',
    marginTop: '24px',
    borderWidth: '0.5px',
  },
  trLine: {
    borderBottom: '1px solid #DFE2E6',
  },

  addUserButton: {
    width: '183px',
    height: '48px',
    color: 'white',
    backgroundColor: '#28AAE2',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  importCsvButton: {
    width: '183px',
    height: '48px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    marginLeft: '16px',
    '&:hover': {
      background: 'white',
    },
    [breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginTop: '10px',
      width: '100%',
    },
  },
  detleButton: {
    width: '183px',
    height: '48px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #FF2121',
    color: '#FF2121',
    '&:hover': {
      background: 'white',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '80px',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: '40px',
    },
  },
  youHaventAddedAnyUsersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  youHaventAddedAnyUsersText: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 900,
    textTransform: 'uppercase',
    paddingTop: '45px',
  },
  addStepsContainer: {
    display: 'flex',
    paddingTop: '24px',
    flexDirection: 'column',
  },
  addStepsText: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '16px',
    paddingTop: '16px',
  },
  singUpLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '80px',
    [breakpoints.down('xs')]: {
      paddingTop: '40px',
    },
  },
  singUpLinkText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '24px',
    letterSpacing: '0.35px',
    textTransform: 'capitalize',
    [breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  singUpLinkDescription: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '0.35px',
    color: '#6F6F7B',
    paddingTop: '48px',
    width: 813,
    lineHeight: '31px',
    [breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '16px',
      lineHeight: '24px',
      paddingTop: '25px',
    },
  },
  linkUrlInputContainer: {
    paddingTop: '63px',
  },
  urlLink: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#9899A9',
  },
  linkBox: {
    width: '497px',
    height: '40px',
    border: '1px solid #DFE2E6',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    paddingRight: '15px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '60px',
    },
  },
  copyLinkText: {
    color: '#43B1E9',
    borderBottom: '1px solid #43B1E9',
    width: 73,
    cursor: 'pointer',
  },
  addMemberPopUp: {
    // paddingLeft: 96,
    // paddingRight: 96,
    // paddingTop: 64,
    // paddingBottom: 64,
    // height: 504,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  importTeamMembersPopUp: {
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    height: 344,
    display: 'flex',
    // alignItems : "center",
    // justifyContent : "center",
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 40,
      paddingBottom: 40,
      height: '100%',
    },
  },
  addMemberPopUpButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40px',
    width: '70%',
  },
  popupText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 22,
    color: '#010200',
    textWrap: 'balance',
    lineHeight: '30px',
  },
  popUpButtonCancel: {
    width: '208px',
    height: '56px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    fontSize: '16px',
    fontWeight: 700,
    '&:hover': {
      background: 'white',
    },
    [breakpoints.down('xs')]: {
      width: '120px',
    },
  },
  paper: {
    minWidth: '632px',
    minHeight: '0px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  submitButton: {
    fontSize: '16px',
    fontWeight: 700,
    width: '100%',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      width: '120px',
    },
  },
  regenrateButton: {
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  inputField: {
    height: 40,
    borderColor: '#DFE2E6',
    borderStyle: 'solid',
    width: '100%',
    marginTop: '2px',
    outline: 'none',

    '&:focus': {
      borderColor: '#28AAE2',
    },
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  inputFieldNames: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#A3A3B3',
  },
  inputContainer: {
    paddingTop: '16px',
    // paddingBottom: '16px',
  },
  wrapper: {
    position: 'relative',
  },

  icon: {
    height: '15px',
    width: '15px',
    backgroundImage: `url(${search})`,
    backgroundSize: 'cover',
    marginLeft: '15px',

    // backgroundImage: `url('assets/img/new-search-icon.png')`,
    backgroundRepeat: 'no-repeat',
    // padding: "4px",
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '2px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },

  searchInput: {
    height: '48px',
    boxSizing: 'border-box',
    paddingLeft: '40px',
    outline: 'none',
    width: '333px',
    border: '1px solid #DFE2E6',
    '&:focus': {
      borderColor: '#28AAE2',
    },
  },
  columnDirection: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  userInfoContainer: {
    width: '100%',
    paddingTop: '55px',
    borderCollapse: 'collapse',
    borderSpacing: '0 4px',
  },
  userFirstName: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#63626C',
  },
  deleteText: {
    color: '#FF2121',
    borderBottom: '1px solid #FF2121',
    width: '100%',
  },
  editText: {
    color: '#28AAE2',
    borderBottom: '1px solid #28AAE2',
    width: '100%',
  },
  deleteTextBorder: {
    color: '#FF2121',
    borderBottom: '1px solid #FF2121',
    width: 48,
    cursor: 'pointer',
  },
  editTextBorder: {
    color: '#28AAE2',
    borderBottom: '1px solid #28AAE2',
    width: 30,
    cursor: 'pointer',
  },
  test: {
    '&$checked': {
      color: 'red',
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: 'red',
        '&$checked': {
          color: 'red',
        },
      },
    },
  },
  deleteAndEditButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15,
  },
  membersName: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#020301',
  },
  test: {
    width: '100%',
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    minWidth: '0px',
    minHeight: '0px',
    boxShadow: 'none',
    [breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  regenerateButtonContainer: {
    marginTop: 20,
  },
  tst: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '15px',
    marginRight: '15px',
    cursor: 'pointer',
  },
});

const FullTeamMembers = ({
  classes,
  onClose,
  onOpen,
  updateTeamAccountInviteId,
  accountId,
  regenerateTeamAccountInviteId,
  user,
  text,
}) => {
  const closeModal = () => {
    onClose(!onClose);
  };

  return (
    <Dialog open={onOpen} classes={{ paper: classes.paper }}>
      <div className={classes.tst}>
        <div onClick={onClose}>X</div>
      </div>
      <div className={classes.container}>
        <div className={classes.addMemberPopUp}>
          <span className={classes.popupText}>
            {/* Your email list exceeds your plan’s total team member login amount.
            Please edit your list based on the number of logins included in your
            plan and try again. You contact us at support@movementvault.com to
            increase your team’s login amount. */}
            {text}
          </span>
          <div className={classes.addMemberPopUpButtonContainer}>
            {/* <Button
              className={classes.popUpButtonCancel}
              title='Cancel'
              type='button'
              onClick={onClose}
            >
              Cancel
            </Button> */}
            <Button
              className={classes.submitButton}
              title='Continue'
              type='submit'
              onClick={() => {
                closeModal();
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

// const mapStateToProps = () => ({});

// const mapStateToProps = ({ auth: { user } }) => {
//   return {
//     user,
//   };
// };

// const mapDispatchToProps = {
//   updateTeamAccountInviteId: Creators.updateTeamAccountInviteId,
//   regenerateTeamAccountInviteId:
//     regenerateCreator.regenerateTeamAccountInviteId,
// };

export default withStyles(styles)(FullTeamMembers);
