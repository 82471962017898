import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import  GeneralVideoPlayer  from 'modules/dashboard/components/GeneralVideoPlayer';
import tick from '../../../../assets/img/icon_tick.png';
import cross from '../../../../assets/img/icon-cross.png';
import crosshair from '../../../../assets/img/icon-crosshair.png';
import  {getAsessmentVideoSettings}  from 'modules/dashboard/utils/assessmentVideo';
import { connect } from 'react-redux';


const styles = ({ breakpoints, palette: { primary } }) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.down('xs')]: {},
  },
  title: {
    fontSize: '32px',
    fontWeight: 900,
    color: '#010200',
    [breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  otherContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    paddingLeft: '72px',
    [breakpoints.down('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingTop: '32px',
    },
  },
  instructions: {
    fontSize: '20px',
    color: '#020401',
    fontWeight: 700,
    paddingTop: '40px',
    [breakpoints.down('xs')]: {
      fontSize: '18px',
      paddingTop: '20px',
    },
  },
  line: {
    // borderBottom: "solid",
    // color : "#28AAE2",
    // width  : "44px",
    // height : "4px",
    // borderRadius : "10px",
    // borderWidth : "4px"
    border: '2px solid #28AAE2',
    borderRadius: '5px',
    width: '48px',
    marginTop: '13px',
  },
  doContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  doText: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#010200',
    paddingLeft: '12px',
  },
  textIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '6px',
  },
  textIconContainerDonts: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textGoalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '16px',
    [breakpoints.down('xs')]: {
      marginBottom : "50px",
    },
  },
  icon: {
    width: '12px',
    height: '8px',
    display: 'flex',
  },
  crossIcon: {
    width: '16px',
    height: '16px',
    display: 'flex',
  },
  dos: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      // paddingLeft: '1rem',
      // textIndent: '-0.7rem',
    },
  },
  row: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeButton: {
    cursor: 'pointer',
    height: 40,
    width: 40,
    padding: 12,
    borderRadius: 20,
    backgroundColor: '#0003',
    position: 'absolute',
    right: 24,
    top: 24,
    zIndex: 50,
    [breakpoints.down('md')]: {
      top: 12,
      right: 12,
    },
  },
});

const Question = ({ classes, question ,questions,videoIndex,onClose }) => {


  const [resolution, setResolution] = useState(
    localStorage.getItem('resolution') || 'video'
  );
  const {
    resolutionSettings,
    playbackSettings: { src, videoDuration },
  } = getAsessmentVideoSettings({
    question,
    onResolutionSelected: setResolution,
    resolution,
  });




  const length = questions?.data?.length
  // const videooo = questions[index]


  return (
    <div className={classes.mainContainer}>
      {/* <video width='100%' controls>
        <source
          src='https://www.youtube.com/watch?v=QFcv5Ma8u8k&ab_channel=DrDreVEVO'
          type='video/mp4'
        />
      </video> */}
      {question ? (
        <GeneralVideoPlayer
          
          videoToPlay={{
            // src:question?.videoHi.location,
            src,
            title: question?.title,
            duration: videoDuration,
            videoDuration,
            isTimeVisible: false,
            resolution,
            allVideos : length,
            videoIndex: videoIndex,
            onClose:onClose,
            // resolutionSettings
            

            // resolutionSettings: [
            //   { isSelected: true, resolution: 720 },
            //   { isSelected: false, resolution: 1080 },
            //   { isSelected: false, resolution: 480 },
            // ],
            resolutionSettings
          }}
          isTimeVisible={true}
          // workoutDuration={workoutDuration}
          // workoutDurationVisited={workoutDurationVisited}
          onFullscreenStatusChange={() => {}}
          // onWatched90PercentOfWorkout={handleOnWatched90PercentOfWorkout}
        />
      ) : null}
{/* 
<video width="320" height="240" controls>
  <source src={question?.videoHi.location} type="video/mp4"/>
</video> */}

    
      <div className={classes.otherContainer}>
        <div className={classes.title}>{question?.title}</div>
        <div className={classes.instructions}>Instructions:</div>
        <div className={classes.line} />
        <div className={classes.doContainer}>
          <div className={classes.textIconContainer}>
            <ul className={classes.dos}>
              {question?.dos?.map((dos) => (
                <li key={dos}>
                  <div className={classes.row}>
                    <img className={classes.icon} src={tick} />
                    <div className={classes.doText}> {dos} </div>
                  </div>
                </li>
              ))}
            </ul>
            {/* <div className={classes.row}>
                    <img className={classes.icon} src={tick} />
                    <div className={classes.doText}> {question?.dos} </div>
                  </div> */}
          </div>

          <div className={classes.textIconContainerDonts}>
            {/* <ul className={classes.dos}>
              {question?.data?.donts?.split(';').map((donts) => (
                <li key={donts}>
                  <div className={classes.row}>
                    <img className={classes.crossIcon} src={cross} />{' '}
                    <div className={classes.doText}> {donts} </div>{' '}
                  </div>
                </li>
              ))}
            </ul> */}
                        <ul className={classes.dos}>

            {question?.donts?.map((donts) => (
                <li key={donts}>
                  <div className={classes.row}>
                    <img className={classes.crossIcon} src={cross} />
                    <div className={classes.doText}> {donts} </div>
                  </div>
                </li>
              ))}
                          </ul>


{/* <div className={classes.row}>
<img className={classes.crossIcon} src={cross} />
                    <div className={classes.doText}> {question?.donts} </div>
                  </div> */}
          </div>

          <div className={classes.textGoalContainer}>
            <img className={classes.crossIcon} src={crosshair} />
            <div className={classes.doText}> {question?.goal}</div>
          </div>
        </div>
      </div>
     
    </div>
  );
};


const mapDispatchToProps = {
  // getUserAssessment: Creators.getUserAssessment,
  // postResults: Creators.postResults,

};

const mapStateToProps = ({ userAssessment }) => ({
  // activeTask: assessment.activeTask,
  questions: userAssessment.tasks,
 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Question));





// export default withStyles(styles)(Question);
