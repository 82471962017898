import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { Link } from 'components';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(({ palette: { background } }) => ({
  button: {
    alignItems: 'center',
    background: background.paper,
    borderRadius: 2,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    marginLeft: 24,
    width: 45,
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0px',
  },
}));

const ActionButtons = ({ baseUri, id, onDelete }) => {
  const classes = useStyles();
  const {
    palette: { primary },
  } = useTheme();

  return (
    <Box className={classes.container}>
      <Link to={`${baseUri}/edit/${id}`}>
        <Box className={classes.button}>
          <Edit height={24} width={24} fill={primary.dark} />
        </Box>
      </Link>
      <Box className={classes.button} onClick={() => onDelete(id)}>
        <Bin height={24} width={24} fill={primary.red} />
      </Box>
    </Box>
  );
};

export default ActionButtons;
