import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 -1 14 11' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.4 3.37753V0.00252686H11.025V1.12753H12.4072L6.9895 6.53401L5.04844 4.59294L2.59277 7.04861C1.62872 5.95745 1.125 4.94626 1.125 4.08582C1.125 2.3425 2.25846 1.125 3.88125 1.125C4.30323 1.125 4.67238 1.1885 4.99208 1.28914C6.3 1.83065 7.21857 3.52299 7.21857 3.52299C7.21857 3.52299 8.04375 1.91503 9.35013 1.30781C9.52602 1.24849 9.70961 1.20421 9.9 1.17367V0.037793C8.83817 0.167981 8.09077 0.616003 7.62792 1.00206C7.48312 1.1228 7.33766 1.26332 7.20461 1.41218C7.0686 1.2587 6.9193 1.11445 6.77054 0.991296C6.22441 0.538989 5.27992 0 3.88125 0C2.81733 0 1.84175 0.394519 1.13434 1.11105C0.392212 1.86251 0 2.89116 0 4.08582C0 5.51887 0.910547 7.09541 2.70648 8.77159C4.33466 10.2914 6.11917 11.3581 6.4604 11.5565C6.60256 11.6391 6.91897 11.8216 6.91897 11.8216L7.20011 11.9838L7.48114 11.8215C7.48114 11.8215 7.79733 11.6391 7.93938 11.5565C8.28062 11.3581 10.0652 10.2913 11.6935 8.77159C13.3171 7.25614 14.216 5.82253 14.3737 4.50253H13.2369C13.062 5.51096 12.2726 6.6921 10.9258 7.94916C9.38573 9.38661 7.6968 10.3961 7.37391 10.5839C7.32843 10.6104 7.26504 10.6471 7.19989 10.6848C7.13485 10.6471 7.07135 10.6104 7.02587 10.5839C6.70298 10.3961 5.01416 9.38672 3.4741 7.94916C3.44081 7.91818 3.4084 7.8873 3.37588 7.85643L5.04844 6.18387L6.98862 8.12406L13.275 1.85087V3.37753H14.4Z'
      fill={fill}
    />
  </svg>
);
