import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg
    width='12'
    height='15'
    viewBox='0 0 12 15'
    fill={fill}
    xmlns='http://www.w3.org/2000/svg'

  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.66037 1.66526C7.66037 2.58355 6.9133 3.33062 5.99501 3.33062C5.07682 3.33062 4.32975 2.58355 4.32975 1.66526C4.32975 0.74707 5.07682 0 5.99501 0C6.9133 0 7.66037 0.74707 7.66037 1.66526ZM6.55013 1.66526C6.55013 1.35916 6.30111 1.11024 5.99501 1.11024C5.68891 1.11024 5.43989 1.35916 5.43989 1.66526C5.43989 1.97135 5.68891 2.22038 5.99501 2.22038C6.30111 2.22038 6.55013 1.97135 6.55013 1.66526ZM11.99 4.9681V3.85796H0V4.9681H4.32975V9.13227L0.841688 13.5326L1.7117 14.2222L5.19716 9.8252H6.79286L10.2783 14.2222L11.1483 13.5326L7.66037 9.13227V4.9681H11.99ZM5.43989 4.9681V8.71495H6.55013V4.9681H5.43989Z'
      fill={fill}
    />
  </svg>
);
