import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { push } from 'connected-react-router';
import { isEqual, orderBy, uniqBy } from 'lodash';

import { Loader, Paginate } from 'components';
import { ListItem, SearchBar, WorkOutListItem } from 'modules/dashboard';
import { Box } from '@material-ui/core';
import { getPageFromLocation } from 'components/Paginate';
import { sortByActiveSince } from 'core/utils/sort';
import { useRecoilState } from 'recoil';
import { mixpanelAtoms } from 'core/utils/mixpanel';
import { Link } from 'react-router-dom';

const styles = ({ breakpoints, palette: { primary } }) => ({
  categoryTitle: {
    color: primary.generalText,
    fontSize: 24,
    fontWeight: '900',
    height: 70,
    lineHeight: '70px',
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      fontSize: 20,
      letterSpacing: 0.29,
      padding: '0 15px',
    },
  },
  container: {
    width: '100%',
  },
  message: {
    color: primary.generalText,
    lineHeight: 1.6,
    padding: '16px 0px',
  },
  paginate: {
    margin: '12px 0px',
  },
  title: {
    color: primary.hunterGreen,
    fontSize: 32,
    fontWeight: '900',
    marginBottom: 20,
    textTransform: 'capitalize',
    [breakpoints.down('xs')]: {
      padding: '32px 15px 0px',
      [breakpoints.down('xs')]: {
        fontSize: 24,
        lineHeight: 1.42,
        letterSpacing: 0.35,
      },
    },
  },
  titleL: {
    color: primary.black,
    fontSize: 36,
    lineHeight: 2,
    fontWeight: '900',
    marginTop: 16,
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: 1.5,
      letterSpacing: 0.35,
      padding: '24px 15px',
      marginTop: 0,
    },
  },
});

const INITIAL_FILTER = {
  bodyArea: [],
  difficulty: 1,
  equipment: [],
  modality: [],
  movementPrep: [],
  muscles: [],
  title : []
};

const PAGE_SIZE = 40;

const WorkOutList = ({
  addFavorite,
  classes,
  deleteFavorite,
  goTo,
  isDailyClassList = false,
  isExerciseList = false,
  isFavoriteList = false,
  isPremiumUser = false,
  isFreeUser = false,
  items,
  onClick = () => {},
  processing,
  processedItemId = -1,
  showTitle = true,
  title,
  orderByField = 'title',
  orderByDirection = 'asc',
}) => {
  const [filter, setFilter] = useState(INITIAL_FILTER);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(getPageFromLocation());
  const [pageCount, setPageCount] = useState(0);
  const [itemsToDisplay, setItemsToDisplay] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const isFirstLoad = useRef(true);
  // eslint-disable-next-line no-unused-vars
  const [currentListTitle, setCurrentListTitle] = useRecoilState(
    mixpanelAtoms.currentListTitle
  );

  // console.log(filter , "FILTERRR")

  useEffect(() => {
    if (window.location.pathname.includes('favorites')) {
      setCurrentListTitle('Favorites');
      return;
    }

    setCurrentListTitle(title);
  }, [title, setCurrentListTitle]);

  const categorizeExercisesByBodyArea = (exercises) => {
    if (!exercises || exercises.length < 1) return [];

    const resultObj = exercises.reduce((acc, currentExercise) => {
      if (acc[currentExercise.bodyArea]) {
        acc[currentExercise.bodyArea] = acc[currentExercise.bodyArea].concat(
          currentExercise
        );
      } else {
        acc[currentExercise.bodyArea] = [currentExercise];
      }
      return acc;
    }, {});

    const compare = ({ title, activeSince: a }, { activeSince: b }) => {
      const firstDate = new Date(a);
      const secondDate = new Date(b);
      return firstDate < secondDate ? 1 : firstDate > secondDate ? -1 : 0;
    };

    return Object.keys(resultObj).reduce(
      (acc, bodyArea) =>
        acc.concat({
          items: resultObj[bodyArea].sort(compare).map((exercise) => ({
            ...exercise,
            chips: exercise.modalities.map((m) => m.title),
            duration: exercise.video?.additionalData?.duration || 0,
          })),
          title: bodyArea,
        }),
      []
    );
  };

  const categorizeWorkoutsByBodyArea = (workouts) => {
    if (!workouts || workouts.length < 1) return [];

    const workoutsByBodyArea = workouts.reduce((acc, currentWorkout) => {
      if (acc[currentWorkout.bodyArea]) {
        acc[currentWorkout.bodyArea] = acc[currentWorkout.bodyArea].concat(
          currentWorkout
        );
      } else {
        acc[currentWorkout.bodyArea] = [currentWorkout];
      }
      return acc;
    }, {});

    return Object.keys(workoutsByBodyArea).map((bodyArea) => ({
      items: workoutsByBodyArea[bodyArea],
      title: bodyArea,
    }));
  };

  const filterItems = React.useCallback(() => {
    const itemsFlattened = items.reduce((acc, currentItem) => {
      currentItem.bodyAreas.map((bodyArea) =>
        acc.push({
          ...currentItem,
          bodyArea: bodyArea.title,
          bodyAreaId: bodyArea.id,
        })
      );
      return acc;
    }, []);

    const sortIteratee = [
      !isPremiumUser ? (item) => item.access : null,
      isDailyClassList ? null : (item) => item.bodyArea,
      isDailyClassList
        ? (item) => item.activeSince
        : (item) => item[orderByField],
    ];

    const sortDirections = [
      isPremiumUser ? 'asc' : null,
      isDailyClassList ? null : 'asc',
      isDailyClassList ? 'desc' : orderByDirection,
    ];

    const sortedItems = orderBy(itemsFlattened, sortIteratee, sortDirections);;


    let result = sortedItems
      .filter((item) => {
        if (filter.bodyArea.length > 0) {
          return filter.bodyArea.includes(item.bodyAreaId);
        }
        return true;
      })
      .filter((item) => {
        if (filter.equipment.length > 0) {
          return item.equipments.some((eq) => filter.equipment.includes(eq.id));
        }
        return true;
      })
      .filter((item) => {
        if (filter.modality.length > 0) {
          return item.modalities.some((m) => filter.modality.includes(m.id));
        }
        return true;
      })
      // .filter((item) => {
      //   if (filter.title.length > 0) {
      //     return item.title.some((m) => filter.title.includes(m.id));
      //   }
      //   return true;
      // })
      .filter((item) => {
        if (filter.movementPrep.length > 0) {
          if (!(item?.movementPreps?.length > 0)) {
            return false;
          }

          return item.movementPreps.some((m) =>
            filter.movementPrep.includes(m.id)
          );
        }
        return true;
      })
      .filter((item) => {
        if (filter.muscles.length > 0) {
          if (!(item?.muscles?.length > 0)) {
            return false;
          }

          return item.muscles.some((m) => filter.muscles.includes(m.id));
        }
        return true;
      })
      .filter((item) => {
        if (filter.difficulty > 1) {
          switch (filter.difficulty) {
            case 2:
              return item.difficulty === 'easy';
            case 3:
              return item.difficulty === 'medium';
            case 4:
              return item.difficulty === 'hard';
            default:
              return true;
          }
        }
        return true;
      })
      .filter((item) => {
        if (search) {
          console.log(search ,item, "SEARCH")
          return (
            item.title.toLowerCase().includes(search.toLowerCase()) ||
            item.description.toLowerCase().includes(search.toLowerCase())||
            item.programTitle?.toLowerCase().includes(search.toLowerCase()) 
          );
        }
        return true;
      });

    if (isDailyClassList) {
      result = uniqBy(result, 'id');
    }

    const start = currentPage * PAGE_SIZE;
    const end = start + PAGE_SIZE;

    if (isDailyClassList) {
      const uniqueItems = result.reduce((acc, curr) => {
        if (!acc.find((a) => a.id === curr.id)) return acc.concat(curr);
        return acc;
      }, []);

      setTotalResults(uniqueItems.length);
    } else {
      setTotalResults(result.length);
    }

    setPageCount(Math.ceil(result.length / PAGE_SIZE));
    setItemsToDisplay(result);
  }, [
    currentPage,
    filter,
    isDailyClassList,
    items,
    search,
    setItemsToDisplay,
    orderByDirection,
    orderByField,
    isPremiumUser,
  ]);


  // console.log(itemsToDisplay , "FILTER")
  // console.log(items , "FILTER1111111")

  useEffect(() => {
    filterItems();
  }, [filter, filterItems, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    if (isFirstLoad.current) {
      return;
    }

    setCurrentPage(0);
  }, [filter, search]);

  useEffect(() => {
    setTimeout(() => {
      isFirstLoad.current = false;
    }, 0);
  }, []);


  const redirectToPricing = () => {
    goTo('/dashboard/payment/choose-plan');
  };
  

  const test = (items) =>{
    

    for (let i = 0; i < items.length; i++) {

      const item = items[i];

    }

  }

  
  
  const getPrograms = (filteredWorkouts, allWorkouts) => {
    const sortedAllWorkouts = allWorkouts.sort((itemA, itemB) => {
      const a = itemA.programWorkOutOrder || 999999999;
      const b = itemB.programWorkOutOrder || 999999999;
      return a > b ? 1 : -1
    });
    let programs  = [];
    for (let i = 0; i < filteredWorkouts.length; i++) {

      // const test = items.filter((opa) => opa.programWorkOutOrder === 1);

      const item = filteredWorkouts[i];

        
        if(item?.programTitle){
          if(!programs.find(( pogram ) => pogram.title === item.programTitle)){
            // emptyPrograms.push(item.programTitle);
            programs.push({title : item.programTitle , workout: item});
          }
        }
      
      // if(item.programTitle){
      //   emptyPrograms.push(item.programTitle);
      // }
    }
    
    const result = programs.map(program => {
      return {
        ...program,
        workout: sortedAllWorkouts.find((sortedWorkout) => {
          return sortedWorkout.programTitle == program.title
        })
      }
    })




    return result;
  };


  const existingPrograms = [];

  
  // const test = items.filter((opa) => opa.programWorkOutOrder === 1);
  // console.log(test , items , "FILTER3333")

  const ItemCategory = ({ category, isPremium = false }) => {
    if (category.items < 1) return null;

    const redirectToPricing = () => {
      goTo('/dashboard/payment/choose-plan');
    };




    // const testArr = () =>{
    //   category.items
    // } 


    

    return (
      <div className={classes.container}>
        <div className={classes.categoryTitle}>{category.title}</div>



        {/* {items ?  getPrograms(items).map((t)=>{
    console.log(t , "ASD")
    return (
      <div>
        {t.title}
        
        </div>
    );
  }) : "Loading..." } */}

       
        {category.items
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .map((item) => (
            <WorkOutListItem
              item={item}
              isExerciseList={isExerciseList}
              isFavoriteList={isFavoriteList}
              isFavorite={item.isFavorite}
              key={`${item.id}-${category.title}`}
              onAddFavorite={addFavorite}
              onClick={
                isPremium && isFreeUser ? () => redirectToPricing() : () => onClick(item)
              }
              onDeleteFavorite={deleteFavorite}
              processing={processedItemId === item.id}
            />
          ))}
      </div>
    );
  };

  const Items = ({ isPremium = false, items }) => {
    if (items.length < 1) return null;

    const redirectToPricing = () => {
      goTo('/dashboard/payment/choose-plan');
    };

    return (
      <div className={classes.container}>
        {items.sort(sortByActiveSince).map((item) => (
          <WorkOutListItem
            item={item}
            isFavoriteList={isFavoriteList}
            isFavorite={item.isFavorite}
            key={item.id}
            onAddFavorite={addFavorite}
            onClick={
              isPremium && isFreeUser ? () => redirectToPricing() : () => onClick(item)
            }
            onDeleteFavorite={deleteFavorite}
            processing={processedItemId === item.id}
          />
        ))}
        
      </div>
    );
  };

  let itemsByBodyArea;

  if (isExerciseList) {
    itemsByBodyArea = categorizeExercisesByBodyArea(itemsToDisplay);
  } else {
    itemsByBodyArea = categorizeWorkoutsByBodyArea(itemsToDisplay);
  }
  const freeContent = itemsByBodyArea.map((category) => ({
    ...category,
    items: category.items.filter((item) => item.access === 'free'),
  }));

  const premiumContent = itemsByBodyArea.map((category) => ({
    ...category,
    items: category.items.filter((item) => item.access === 'premium'),
  }));

  const freeContentEmpty = freeContent.every(
    (category) => category.items.length < 1
  );
  const premiumContentEmpty = premiumContent.every(
    (category) => category.items.length < 1
  );
  const contentEmpty = itemsByBodyArea.every(
    (category) => category.items.length < 1
  );

  const noResults =
    (freeContent.length < 1 || freeContentEmpty) &&
    (premiumContent.length < 1 || premiumContentEmpty) &&
    (itemsToDisplay.length < 1 || contentEmpty);

  const filterEmpty = isEqual(filter, INITIAL_FILTER) && !search;



  return (
    <div className={classes.container}>
      {showTitle && <div className={classes.title}>{title}</div>}
      <SearchBar
        filter={filter}
        onClearFilters={() => setFilter(INITIAL_FILTER)}
        onFilterChange={(value) => setFilter(value)}
        onSearchChange={({ target: { value } }) => setSearch(value)}
        search={search}
        totalResults={totalResults}
      />
      {processing && <Loader />}
      {!processing && noResults && !filterEmpty && (
        <div className={classes.message}>
          <div>No results for your search.</div>
          <div>Try resetting or choosing fewer filters before searching.</div>
          <div>You can also try using a different search term.</div>
        </div>
      )}
      {!processing && noResults && filterEmpty && (
        <div className={classes.message}>
          {isFavoriteList ? (
            <Box textAlign='center'>
              You have not added any videos to your favorites yet.
              <br />
              Press the star icon on a workout or video to add it to your
              favorites.
              <br />
              You can then access the video here
            </Box>
          ) : (
            <div>Coming soon...You're amazing btw</div>
          )}
        </div>
      )}
      {!isPremiumUser && !freeContentEmpty && (
        <>
          <div className={classes.titleL}>{`free ${title}`}</div>
          {isDailyClassList ? (
            <Items items={itemsToDisplay.filter((i) => i.access === 'free')} />
          ) : (
            freeContent.map((category) => (<>
              <ItemCategory category={category} key={category.title} />   
              
             </>
            ))
          )}
        </>
      )}
      {!isPremiumUser && !premiumContentEmpty && (
        <>
          <div className={classes.titleL}>{`premium ${title}`}</div>
          {isDailyClassList ? (
            <Items
              isPremium
              items={itemsToDisplay.filter((i) => i.access === 'premium')}
            />
          ) : (
            premiumContent.map((category) => (
              <ItemCategory
                category={category}
                key={category.title}
                isPremium
              />
            ))
          )}
        </>
      )}
      {isPremiumUser && itemsByBodyArea?.length > 0 && (
        <>
          {isDailyClassList ? (
            <Items items={itemsToDisplay} />
          ) : (
            // itemsByBodyArea.map((category) => (
            //   <ItemCategory category={category} key={category.title} />
            // ))
            <div style={{paddingBottom : "20px"}}>
            

          

            {itemsToDisplay ?  getPrograms(itemsToDisplay, items).sort((a, b) => (a.title > b.title ? 1 : -1)).map((t)=>{
              // console.log(t , "ASD")
              // console.log(t.workout.thumbnail?.publicLocation , "VAAA")
              return (
                <div>
                  <Link style={{ textDecoration: 'none' }} to={`/dashboard/pain-and-injury/programs/${t.title}`} >
                  {/* {t.title} */}
                  
                  <WorkOutListItem
                   
                    item={t}

              //  onClick={
              //   isPremiumUser && isFreeUser ? () => redirectToPricing() : () => onClick(t.title)
              // }
              // isExerciseList={isExerciseList}
              // isFavoriteList={isFavoriteList}
              // isFavorite={item.isFavorite}
              // key={`${item.id}-${category.title}`}
              // onAddFavorite={addFavorite}
              // onClick={
              //   isPremium && isFreeUser ? () => redirectToPricing() : () => onClick(item)
              // }
              // onDeleteFavorite={deleteFavorite}
              // processing={processedItemId === item.id}
              />
                   {/* {category.items
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .map((item) => (
            <WorkOutListItem
              item={item}
              isExerciseList={isExerciseList}
              isFavoriteList={isFavoriteList}
              isFavorite={item.isFavorite}
              key={`${item.id}-${category.title}`}
              onAddFavorite={addFavorite}
              onClick={
                isPremium && isFreeUser ? () => redirectToPricing() : () => onClick(item)
              }
              onDeleteFavorite={deleteFavorite}
              processing={processedItemId === item.id}
            />
          ))} */}
</Link>
                  </div>
              );
            }) : "Loading..." }
           </div>
          )}
        </>
      )}
      {/* {!noResults && (
        <div className={classes.paginate}>
          <Paginate
            forcePage={currentPage}
            pageCount={pageCount}
            onPageChange={({ selected }) => setCurrentPage(selected)}
          />
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (
  {
    auth: {
      user: { status },
    },
  },
  { items }
) => ({
  items,
  isPremiumUser: status === 'premium',
});

const mapDispatchToProps = {
  goTo: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WorkOutList));
