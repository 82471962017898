import React, { useState, useEffect } from 'react';
import { Button, Link, Loader } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { List } from '../../index';
import { Details } from '../index';
import { connect } from 'react-redux';
import { Creators as ModalCreators } from '../../../../../modal';
import { Creators } from '../reducer';

const useStyles = makeStyles(
  ({ palette: { background, primary, secondary } }) => ({
    container: {
      minHeight: '100vh',
      width: '100%',
    },
    header: {
      borderBottom: `1px solid ${primary.gray}`,
      height: 80,
      padding: '0px 16px',
    },
    button: {
      textTransform: 'capitalize',
    },
  })
);

const Movements = ({ data, getMovementMoments, showModal, deleteMovement }) => {
  const classes = useStyles();
  const processing = false;
  const baseUri = 'movement-moments';
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getMovementMoments();
  }, [getMovementMoments]);

  useEffect(() => {
    setItems(data.reminders);
  }, [data]);

  return (
    <Box display='flex' flexDirection='column' className={classes.container}>
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        className={classes.header}
      >
        <Box ml={16}>
          <Link style={{ textDecoration: 'none' }} to={`${baseUri}/create`}>
            <Button
              className={classes.button}
              title='Create New Notification'
            />
          </Link>
        </Box>
      </Box>
      {processing ? (
        <Loader />
      ) : (
        <Box display='flex'>
          <List
            items={items}
            selectedIndex={selectedIndex}
            handleClick={(index) => setSelectedIndex(index)}
          />
          <Details
            id={items[selectedIndex]?.id}
            baseUri={baseUri}
            title={items[selectedIndex]?.title}
            text={items[selectedIndex]?.message}
            showModal={showModal}
            deleteFunction={deleteMovement}
            type='movement'
          />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ movementMoment }) => ({
  data: movementMoment,
});

export default connect(mapStateToProps, {
  getMovementMoments: Creators.getMovementMoments,
  deleteMovement: Creators.deleteMovementMoment,
  showModal: ModalCreators.showModal,
})(Movements);
