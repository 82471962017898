export const toTime = (secondsFloat) => {
  if (parseInt(secondsFloat) === 0) return '0:00:00';

  if (!secondsFloat || Number.isNaN(secondsFloat)) return '-:--:--';

  let seconds = Math.floor(secondsFloat);
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  return `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
};
