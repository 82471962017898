import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 12 12' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6408 4.30186C11.7971 4.32919 11.9252 4.44094 11.9736 4.59199C12.0104 4.74217 11.9631 4.90051 11.8499 5.00586L9.34108 7.51466L9.83602 11.4784C9.86265 11.6462 9.78724 11.8137 9.64402 11.9051C9.57534 11.9494 9.49537 11.9731 9.41362 11.9733C9.3327 11.9757 9.2528 11.955 9.18322 11.9136L6.03442 9.95519L2.80455 11.9136C2.66517 12.0019 2.4874 12.0019 2.34802 11.9136C2.20641 11.8211 2.13278 11.6538 2.16028 11.4869L2.71495 7.51892L0.15495 5.01439C0.0416693 4.90377 -0.00103727 4.73951 0.0440163 4.58772C0.0910679 4.43501 0.219506 4.32139 0.376816 4.29332L3.99068 3.64479L5.62055 0.269855C5.69045 0.123058 5.83771 0.0287473 6.00028 0.0266551C6.16706 0.0205413 6.32209 0.112227 6.39708 0.261322L8.10375 3.64052L11.6408 4.30186ZM8.48775 7.42932C8.471 7.29919 8.51506 7.16858 8.60722 7.07519L10.6936 4.99305L7.71548 4.44266C7.5848 4.41856 7.4728 4.33496 7.41255 4.21652L6.00028 1.41759L4.66482 4.20799C4.60576 4.33077 4.49161 4.41797 4.35762 4.44266L1.34962 4.99305L3.48295 7.07092C3.58207 7.16557 3.62969 7.30208 3.61095 7.43785L3.13735 10.7147L5.87228 9.08906C6.00429 9.01284 6.16694 9.01284 6.29895 9.08906L8.91442 10.7104L8.48775 7.42932Z'
      fill={fill}
    />
  </svg>
);
