import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';

import { Button, Link } from 'components';
import { Creators as ModalCreators } from 'modules/modal';
import { Creators } from '../reducer';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import mediaNotUploaded from 'assets/img/media-not-uploaded.png';

const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0px',
  
  },
  actionButton: {
    cursor: 'pointer',
    margin: '5px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cell: {
    width: 190,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
  tagGrid: {
    display: 'grid',
    maxWidth: '800px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  tagType: {
    borderTop: `1px solid ${theme.palette.primary.gray}`,
    padding: 16,
    cursor: 'pointer',
    '& > div': {
      marginBottom: 4,
    },
  },
  selectedTask: {
    backgroundColor: theme.palette.secondary.inactive,
  },
  taskList: {
    background: theme.palette.background.paper,
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
    width: 400,
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  tagCard: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    height: '120px',
    padding: '10px 10px 10px 15px',
    overflow: 'hidden',
    margin: '20px',
  },
  cardLabel: {
    fontSize: '16px',
    color: theme.palette.secondary.grayText,
    textTransform: 'uppercase',
  },
  content: {
    display: 'flex',
  },
  details: {
    marginLeft: 24,
    width: 600,
  },
  detailsPanel: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    '& > div:first-child': {
      paddingTop: 24,
    },
    '& > div:last-child': {
      paddingBottom: 24,
    },
  },
  header: {
    fontSize: 21,
    fontWeight: 900,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  label: {
    color: 'rgba(74, 75, 107, 0.4)',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  money: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },

  row: {
    display: 'flex',
    padding: '8px 24px',
  },
  rowOdd: {
    background: theme.palette.background.default,
  },
  text: {
    fontSize: 16,
  },
  textXL: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  textXXL: {
    fontSize: 20,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  topBar: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'flex-end',
    padding: '0px 16px',
  },

  block: {
    display: 'block',
  },
  mb: {
    marginBottom: `${theme.spacing()}px !important`,
  },

  half: {
    width: '50%',
  },

  dos: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      paddingLeft: '1rem',
      textIndent: '-0.7rem',
      '&::before': {
        color: '#28AAE2',
        fontWeight: 900,
        content: "'+ '",
      },
    },
  },

  donts: {
    listStyle: 'none',
    padding: 0,
    margin: 0,

    '& li': {
      paddingLeft: '1rem',
      textIndent: '-0.7rem',
      '&::before': {
        color: '#FF2121',
        fontWeight: 900,
        content: "'- '",
      },
    },
  },

  media: {
    '& > div': {
      width: '50%',
    },
    '& > div:first-child': {
      marginRight: 15,
    },
    '& video': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },

    '& img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },

    display: 'flex',
    margin: '12px 0px',
    width: '100%',
  },
});

const Tasks = ({
  classes,
  data,
  deleteTask,
  activeTask,
  getTasks,
  setActiveTask,
  showModal,
}) => {
  const theme = useTheme();

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  const onDelete = (id) => {
    deleteTask(id);
  };

  const ActionButtons = ({ onDelete, onEdit }) => {
    return (
      <div className={classes.actionButtons}>
        <Link to={`assessments/${data[activeTask].id}`}>
          <div className={classes.actionButton}>
            <Edit height={24} width={24} fill={theme.palette.primary.dark} />
          </div>
        </Link>
        <div
          className={classes.actionButton}
          onClick={() =>
            showModal(
              'deleteConfirmation',
              () => onDelete(data[activeTask].id),
              'Are you sure you want to delete the assessment task?'
            )
          }
        >
          <Bin height={24} width={24} fill={theme.palette.primary.red} />
        </div>
      </div>
    );
  };

  const TaskList = ({ setActiveTask }) => {
    return (
      <div className={classes.taskList}>
        {data.map((task, i) => (
          <div
            className={cx(
              classes.tagType,
              classes.title,
              activeTask === i ? classes.selectedTask : ''
            )}
            key={i}
            onClick={() => setActiveTask(i)}
          >
            {task.title}
            
          </div>
        ))}
      </div>
    );
  };

  const TaskDetails = ({ task }) => {
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.load();
      }
    });
    return (
      <div className={classes.detailsPanel} key={task.id}>
        <div className={cx(classes.row, classes.block)}>
          <div className={classes.label}>task name</div>
          <div className={classes.header}>{task.title}</div>
        </div>
        <div className={cx(classes.row, classes.block)}>
          <div className={classes.label}>Instructions</div>
          <div className={classes.row}>
            <div className={classes.half}>
              <ul className={classes.dos}>
                {task.dos?.split(';').map((l) => (
                  <li key={l}>{l}</li>
                ))}
              </ul>
            </div>
            <div className={classes.half}>
              <div>
                <ul className={classes.donts}>
                  {task.donts?.split(';').map((l) => (
                    <li key={l}>{l}</li>
                  ))}
                </ul>
              </div>
              <div className={classes.goal}>{task.goal}</div>
            </div>
          </div>
        </div>
        <div className={cx(classes.row)}>
          <div className={cx(classes.block, classes.half)}>
            <div className={classes.label}>Area of the body</div>
            <div>{task.bodyArea?.title}</div>
          </div>
          <div className={cx(classes.block, classes.half)}>
            <div className={classes.label}>Double Sided</div>
            <div>{task.doubleSided ? 'Yes' : 'No'}</div>
          </div>
        </div>
        <div className={cx(classes.row)}>
          <div className={cx(classes.block, classes.half)}>
            <div className={classes.label}>First side text</div>
            <div>{task.firstText}</div>
          </div>
          {task.doubleSided && (
            <div className={cx(classes.block, classes.half)}>
              <div className={classes.label}>Second side text</div>
              <div>{task.secondText}</div>
            </div>
          )}
        </div>
        <div className={cx(classes.row, classes.block, classes.media)}>
          <div className={classes.block}>
            <div className={classes.label}>video</div>
            {task.video?.location ? (
              <video preload='metadata' controls ref={videoRef}>
                <source
                  src={`${task?.video?.location}#t=0.1`}
                  type='video/mp4'
                />
              </video>
            ) : (
              <img
                className={classes.mediaImage}
                src={mediaNotUploaded}
                alt='thumbnail'
              />
            )}
          </div>
        </div>
        <div className={cx(classes.row, classes.media)}>
          <div className={cx(classes.block)}>
            <div className={classes.label}>{'Result Image #1'}</div>
            <div>
              <img
                className={classes.mediaImage}
                src={task.image1?.location || mediaNotUploaded}
                alt='thumbnail'
              />
            </div>
          </div>
          <div className={cx(classes.block)}>
            <div className={classes.label}>{'Result Image #2'}</div>
            <div>
              <img
                className={classes.mediaImage}
                src={task.image2?.location || mediaNotUploaded}
                alt='thumbnail'
              />
            </div>
          </div>
        </div>
        <div className={cx(classes.row, classes.media)}>
          <div className={cx(classes.block)}>
            <div className={classes.label}>{'Result Image #3'}</div>
            <div>
              <img
                className={classes.mediaImage}
                src={task.image3?.location || mediaNotUploaded}
                alt='thumbnail'
              />
            </div>
          </div>
          <div className={cx(classes.block)}>
            <div className={classes.label}>{'Result Image #4'}</div>
            <div>
              <img
                className={classes.mediaImage}
                src={task.image4?.location || mediaNotUploaded}
                alt='thumbnail'
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.topBar}>
        <div>
          <Link to='assessments/create'>
            <Button title='create new task' />
          </Link>
        </div>
      </div>
      <div className={classes.content}>
        <TaskList setActiveTask={setActiveTask} />
        <div className={classes.details}>
          {data.length > 0 ? (
            <>
              <ActionButtons onDelete={onDelete} />
              <TaskDetails task={data[activeTask]} />
            </>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteTask: Creators.deleteTask,
  showModal: ModalCreators.showModal,
  getTasks: Creators.getTasks,
  setActiveTask: Creators.setActiveTask,
};

const mapStateToProps = ({ assessment }) => ({
  activeTask: assessment.activeTask,
  data: assessment.tasks,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Tasks));
