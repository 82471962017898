import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Footer from './Footer';

import logo from 'assets/svg/logo.svg';
import logoWhite from 'assets/svg/logo-white.svg';
import pattern from 'assets/svg/pattern.svg';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    background: `url(${pattern}), ${theme.palette.primary.main}`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    display: 'flex',
    minHeight: '100vh',
    paddingTop: 24,
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      paddingTop: 0,
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0px',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      margin: '16px',
      height: '100%',
      width: 'calc(100vw - 32px)',
    },
  },
  logo: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    margin: `0 auto`,
    width: 680,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  pattern: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '100vh',
    left: 0,
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
  topBarMobile: {
    alignItems: 'center',
    background: theme.palette.primary.dark,
    bottom: 0,
    color: theme.palette.primary.contrastText,
    display: 'none',
    height: 40,
    fontSize: 14,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      '& > img': {
        paddingLeft: 16,
      },
    },
  },
});

const AdminLayout = ({ children, classes, contentClass }) => (
  <div className={classes.container}>
    <a href='https://movementvault.com' style={{ zIndex: 3 }}>
      <div className={classes.logo}>
        <img src={logo} alt='logo' />
      </div>
    </a>
    <div className={classes.topBarMobile}>
      <img src={logoWhite} alt='logo' />
    </div>
    <div className={cx(classes.content, contentClass)}>
      <div className={classes.children}>{children}</div>
    </div>
    <Footer />
  </div>
);

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  contentClass: PropTypes.string,
};

export default withStyles(styles)(AdminLayout);
