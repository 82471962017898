export const BASE_URL = process.env.REACT_APP_API;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const REFRESH_TOKEN_INTERVAL_IN_SECONDS =
  process.env.REACT_APP_REFRESH_TOKEN_INTERVAL_IN_SECONDS;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const REACT_APP_URL = process.env.REACT_APP_URL;

export const PUBLIC_ENDPOINTS = [
  'auth/register',
  'auth/login',
  'auth/reset-password',
  'auth/reset-password-request',
  'auth/reset-password-request',
  'auth/login-facebook',
];
export const REFRESH_TOKEN = 'RefreshToken';
export const TOKEN = 'Token';
export const REMEMBER_ME_STATUS = 'RememberMeStatus';
export const TOKEN_EXPIRY_DATE = 'TokenExpiresIn';

export const INSTAGRAM_URL = 'https://www.instagram.com/movementvault/';
export const FACEBOOK_URL = 'https://www.facebook.com/movementvault/';
export const TWITTER_URL = 'https://www.twitter.com/movementvault/';
export const IOS_APP_URL =
  'https://apps.apple.com/us/app/movement-vault/id1474254318';
