import React from 'react';

export default ({ fill, height = 14, width = 13 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 14 13`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 3.36331C0 3.02859 0.271343 2.75725 0.606061 2.75725H11.5152C11.8499 2.75725 12.1212 3.02859 12.1212 3.36331C12.1212 3.69803 11.8499 3.96937 11.5152 3.96937H0.606061C0.271343 3.96937 0 3.69803 0 3.36331Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.84848 1.54513C4.68775 1.54513 4.53359 1.60898 4.41994 1.72264C4.30628 1.8363 4.24242 1.99045 4.24242 2.15119V2.75725H7.87879V2.15119C7.87879 1.99045 7.81494 1.8363 7.70128 1.72264C7.58762 1.60898 7.43347 1.54513 7.27273 1.54513H4.84848ZM9.09091 2.75725V2.15119C9.09091 1.66898 8.89935 1.20652 8.55838 0.865541C8.2174 0.524566 7.75494 0.333008 7.27273 0.333008H4.84848C4.36627 0.333008 3.90381 0.524566 3.56284 0.865541C3.22186 1.20652 3.0303 1.66898 3.0303 2.15119V2.75725H1.81818C1.48346 2.75725 1.21212 3.02859 1.21212 3.36331V11.8482C1.21212 12.3304 1.40368 12.7928 1.74465 13.1338C2.08563 13.4748 2.54809 13.6663 3.0303 13.6663H9.09091C9.57312 13.6663 10.0356 13.4748 10.3766 13.1338C10.7175 12.7928 10.9091 12.3304 10.9091 11.8482V3.36331C10.9091 3.02859 10.6377 2.75725 10.303 2.75725H9.09091ZM2.42424 3.96937V11.8482C2.42424 12.0089 2.4881 12.1631 2.60175 12.2767C2.71541 12.3904 2.86957 12.4542 3.0303 12.4542H9.09091C9.25165 12.4542 9.4058 12.3904 9.51946 12.2767C9.63312 12.1631 9.69697 12.0089 9.69697 11.8482V3.96937H2.42424Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.84848 5.78755C5.1832 5.78755 5.45455 6.0589 5.45455 6.39361V10.03C5.45455 10.3647 5.1832 10.636 4.84848 10.636C4.51377 10.636 4.24242 10.3647 4.24242 10.03V6.39361C4.24242 6.0589 4.51377 5.78755 4.84848 5.78755Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.27273 5.78755C7.60745 5.78755 7.87879 6.0589 7.87879 6.39361V10.03C7.87879 10.3647 7.60745 10.636 7.27273 10.636C6.93801 10.636 6.66667 10.3647 6.66667 10.03V6.39361C6.66667 6.0589 6.93801 5.78755 7.27273 5.78755Z'
      fill={fill}
    />
  </svg>
);
