import 'react-dropzone-uploader/dist/styles.css';
import React, { useEffect, useState } from 'react';
import Dropzone, { Preview } from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { withStyles } from '@material-ui/styles';

import { localStorage } from 'core';
import { BASE_URL, TOKEN, BUCKET_URL } from 'core/constants';
import axios from 'axios';

import { Dialog, DialogTitle } from '@material-ui/core';
import { Button } from 'components';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import { Creators } from '../reducer';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { call } from 'redux-saga/effects';
import FullTeamMembers from './FullTeamMembers';

const styles = ({ palette, breakpoints }) => ({
  button: {
    backgroundColor: palette.primary.main,
    borderRadius: 2,
    color: palette.primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: 15,
  },
  container: {
    height: '100%',
    width: '100%',
  },
  fileUploaded: {
    '& > img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > video': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > label': {
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      bottom: 0,
      color: palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      height: 40,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      width: '100%',
    },
  },
  fileNotUploaded: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: 16,
  },
  text: {
    color: palette.primary.generalText,
    fontSize: 14,
    lineHeight: 1.5,
  },
  textS: {
    color: palette.secondary.grayText,
    fontSize: 14,
    lineHeight: 1.2,
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  submitButtonDisabled: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#cccccc',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: 10,
    '&:hover': {
      backgroundColor: '#cccccc',
    },
    [breakpoints.down('xs')]: {
      width: '135px',
    },
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  inputContainer: {
    paddingTop: '16px',
    // paddingBottom: '16px',
  },
  inputFieldNames: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#A3A3B3',
  },
  inputField: {
    // height: 40,
    borderColor: '#DFE2E6',
    borderStyle: 'solid',
    // width: '100%',
    marginTop: '2px',
    outline: 'none',
    padding: '4px',
    // height: 40,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&:focus': {
      borderColor: '#28AAE2',
    },
  },
  uploadedFilesText: {
    // width : "100%",
    // height: "100%",
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#DFE2E6',
    // padding: '8px',
    height: '100%',
    // marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#020301',
    padding: '5px',
  },
  importTeamMembersPopUp: {
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    height: '100%',
    display: 'flex',
    // alignItems : "center",
    // justifyContent : "center",
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 40,
      paddingBottom: 40,
      height: '100%',
    },
  },
  popupText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    color: '#010200',
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  addMemberPopUpButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  popUpButtonCancel: {
    width: '208px',
    height: '56px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    fontSize: '16px',
    fontWeight: 700,
    '&:hover': {
      background: 'white',
    },
    [breakpoints.down('xs')]: {
      width: '135px',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      width: '135px',
    },
  },
  paper: {
    minWidth: '632px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  test: {
    padding: '0px !important',
    minHeight: '30px !important',
    display: 'flex',
    flexDirection: 'row',
  },
  // childOfInput:{
  //   display : "flex"
  // }
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  browseButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    // width: '208px',
    // height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    padding: 15,
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  previewContainer: {
    // backgroundColor : "yellow",
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

const FileUploaderMember = ({
  acceptPattern,
  classes,
  teamAccountId,
  onClose,
  onOpen,
  createMultipleTeamMemberAccount,
  currentMembersCount,
  maxMemberCount,
}) => {
  const [values, setValues] = useState([]);

  const closeModal = () => {
    onClose(!onClose);
  };

  const [isOpen, setIsOpen] = useState(false);

  const openRegenatePopuphandleClickOpen = () => {
    setIsOpen(true);
  };
  const openRegenatePopuphandleClickClose = () => {
    setIsOpen(false);
  };


  // console.log(teamAccountOwner.teamMembersCount , "TEAM ACCOUNT COUNT")
  // console.log(teamAccountOwner , "TEAM ACCOUNT OWNR")

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const uploadedMembersLeft = maxMemberCount - currentMembersCount;

  const getCSV = (file) => {
    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        const newAccounts = results.data.map((d) => {
          return {
            email: d[0],
            firstName: d[1],
            lastName: d[2],
          };
        });

        const invalidEmails = newAccounts.filter(
          (account) => !emailRegex.test(account.email?.toLowerCase())
        );
        // if (invalidEmails.length > 0) {
        //   toastr.error('Error', 'Invalid Emals');
        //   // closeModal()
        // }

        // const uploadedMembersLeft = maxMemberCount - currentMembersCount;
        if (invalidEmails.length > 0) {
          toastr.error('Error', 'Invalid Emals');
        } else if (newAccounts.length > uploadedMembersLeft) {
          // toastr
          //   .error(
          //     'Error',
          //     // `You can only add ${
          //     //   uploadedMembersLeft < 0 ? 0 : uploadedMembersLeft
          //     // } more accounts`
          //     'Your email list exceeds your plan’s total team member login amount. Please edit your list based on the number of logins included in your plan and try again. You contact us at support@movementvault.com to increase your team’s login amount.'
          //   )
          // return <FullTeamMembers/>
          // closeModal()
          setIsOpen(true)
        } else {
          setValues(newAccounts);
        }

        // if(newAccounts.length > UploadedMembersLeft){
        //   toastr.error('Error', `You can only add ${UploadedMembersLeft} more accounts`);
        // }
        // // }else{
        // //   setValues(newAccounts)
        // // }
        // setValues(newAccounts)
      },
    });
  };

  const getFilesFromEvent = async (event) => {
    const files = (await getDroppedOrSelectedFiles(event)).map(
      (f) => f.fileObject
    );
    getCSV(files[0]);

    return files;
  };

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
    onFiles,
  }) => {
    return (
      <Dialog open={onOpen} classes={{ paper: classes.paper }}>
        <div className={classes.importTeamMembersPopUp}>
          <DialogTitle style={{ padding: '0px' }}>
            <span className={classes.popupText}> IMPORT TEAM MEMBERS </span>
          </DialogTitle>
          <div>
            <div className={classes.inputFieldContianer}>
              <div className={classes.inputContainer}>
                <div className={classes.inputFieldNames}>
                  Choose .csv file to import
                </div>
                <div className={classes.inputField}>
                  {/* <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  > */}
                  <div {...dropzoneProps}>
                    <label
                    // className={
                    //   previews.length > 0 ? classes.uploadedFilesText : null
                    // }
                    >
                      {values.length === 0 ? (
                        <span className={classes.labelContainer}>
                          <span>Drag or drop .csv</span>
                          <span title='Browse' className={classes.browseButton}>
                            Browse
                          </span>
                        </span>
                      ) : null}

                      <span style={{ display: 'none' }}>{input}</span>
                      <input
                        // accept={accept}
                        multiple
                        type='file'
                        accept='.csv'
                        style={{ display: 'none' }}
                        // onChange={(e) => {
                        //   getFilesFromEvent(e).then((chosenFiles) => {
                        //     onFiles(chosenFiles);
                        //   });
                        // }}
                        // onChange={changeHandler}
                        className={classes.test}
                      ></input>
                    </label>
                    <span
                      className={
                        values.length > 0 ? classes.uploadedFilesText : null
                      }
                      onClick={() => {
                        setValues([]);
                      }}
                    >
                      {values.length > 0 ? <>{previews}</> : null}
                    </span>
                    {/* {previews}VAXXXX */}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className={classes.addMemberPopUpButtonContainer}>
              <Button
                className={classes.popUpButtonCancel}
                title='Cancel'
                type='button'
                onClick={onClose}
              >
                Cancel
              </Button>
              <div>
                {values.length == 0 ? (
                  <Button
                    disabled
                    title='Import File'
                    className={classes.submitButtonDisabled}
                  >
                    import file
                  </Button>
                ) : (
                  <Button
                    title='Import File'
                    className={classes.submitButton}
                    // disabled={!isValidEmail}
                    onClick={() => {
                      createMultipleTeamMemberAccount(values, teamAccountId);
                      closeModal();
                      setValues([]);
                    }}
                  >
                    import file
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <>
   
    {isOpen === true ?  <FullTeamMembers text={`You can only add ${uploadedMembersLeft < 0 ? 0 : uploadedMembersLeft} more accounts. Your email list exceeds your plan's total team member login amount. Please edit your list based on the number of logins included in your plan and try again. You can contact us at mailto:support@movementvault.com to increase your team's login amount.`} onOpen={isOpen} onClose={openRegenatePopuphandleClickClose}/> : 
    
    <Dropzone
        accept={acceptPattern}
        canCancel
        getFilesFromEvent={getFilesFromEvent}
        PreviewComponent={(props) => {
          return <Preview {...props} className={classes.previewContainer} />;
        }}
        //getUploadParams={changeHandler}
        // InputComponent={Input}
        maxFiles={1}
        multiple={false}
        // PreviewComponent={Preview}
        LayoutComponent={Layout}
        // LayoutComponent={NoDropzoneLayout}
        // onChangeStatus={handleChangeStatus}
        // onChangeStatus={changeHandler}
        styles={{
          dropzone: {
            // display: flex;
            // flexDirection: "row",
            // align-items: center;
            // width: 100%;
            // min-height: 120px;
            // overflow: scroll;
            // margin: 0 auto;
            // position: relative;
            // box-sizing: border-box;
            // transition: all .15s linear;
            border: 'none',
            minHeight: '0px',
            overflow: 'hidden',
            // width: '100%',
            // border-radius: 4px;
          },
        }}
        // styles={{backgroundColor :"red"}}
      />
    }
      {/* <Dropzone
        accept={acceptPattern}
        canCancel
        getFilesFromEvent={getFilesFromEvent}
        PreviewComponent={(props) => {
          return <Preview {...props} className={classes.previewContainer} />;
        }}
        //getUploadParams={changeHandler}
        // InputComponent={Input}
        maxFiles={1}
        multiple={false}
        // PreviewComponent={Preview}
        LayoutComponent={Layout}
        // LayoutComponent={NoDropzoneLayout}
        // onChangeStatus={handleChangeStatus}
        // onChangeStatus={changeHandler}
        styles={{
          dropzone: {
            // display: flex;
            // flexDirection: "row",
            // align-items: center;
            // width: 100%;
            // min-height: 120px;
            // overflow: scroll;
            // margin: 0 auto;
            // position: relative;
            // box-sizing: border-box;
            // transition: all .15s linear;
            border: 'none',
            minHeight: '0px',
            overflow: 'hidden',
            // width: '100%',
            // border-radius: 4px;
          },
        }}
        // styles={{backgroundColor :"red"}}
      /> */}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createMultipleTeamMemberAccount: Creators.createMultipleTeamMemberAccount,
  getTeamMemberAccounts: Creators.getTeamMemberAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FileUploaderMember));

// export default withStyles(styles)(FileUploaderMember);

// /* padding: 40px 3%; */
// display: flex;
// flex-direction: row;
// align-items: center;
// justify-content: space-between;
// position: relative;
// width: 100%;
// min-height: 60px;
// z-index: 1;
// border-bottom: 1px solid #ECECEC;
// box-sizing: border-box;
// padding-left: 5px;
// padding-right: 5px;





// You can only add ${uploadedMembersLeft < 0 ? 0 : uploadedMembersLeft} more accounts Your email list exceeds your plan’s total team member login amount. Please edit your list based on the number of logins included in your plan and try again. You contact us at support@movementvault.com to increase your team’s login amount.`