import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { parseError } from 'core';
import { Types } from './reducer';
import { HOME_URL } from './';

import { Creators as exerciseCreators } from 'modules/dashboard/exercises';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* requestFailedWithConflicts(action) {
  try {
    console.error(
      'The following exercises are used in the following workouts, please manage the exercise in these workouts before trying to delete this exercise'
    );
    console.table(JSON.parse(action.payload.response.message).conflicts);

    yield call(
      toastr.error,
      'Error',
      'Conflicts occurred while deleting. Please check console logs.'
    );
  } catch (exception) {
    yield call(requestFailed, action);
    return;
  }
}

export function* exerciseDeleted() {
  yield call(toastr.success, 'Info', 'Exercise deleted successfully');
}

export function* exercisesDeleted() {
  yield call(toastr.success, 'Info', 'Exercises deleted successfully');
}

export function* exerciseDuplicated() {
  yield call(toastr.success, 'Info', 'Exercise duplicated successfully');
  yield put(exerciseCreators.getExercises());
}

export function* exerciseSaved() {
  yield call(toastr.success, 'Info', 'Exercise saved successfully');
  yield put(push(HOME_URL));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_EXERCISE_SUCCESS, exerciseSaved),
    takeEvery(Types.UPDATE_EXERCISE_SUCCESS, exerciseSaved),
    takeEvery(Types.DELETE_EXERCISE_SUCCESS, exerciseDeleted),
    takeEvery(Types.DELETE_EXERCISES_SUCCESS, exercisesDeleted),
    takeEvery(Types.DUPLICATE_EXERCISE_SUCCESS, exerciseDuplicated),
    takeEvery(Types.CREATE_EXERCISE_FAIL, requestFailed),
    takeEvery(Types.UPDATE_EXERCISE_FAIL, requestFailed),
    takeEvery(Types.DELETE_EXERCISE_FAIL, requestFailedWithConflicts),
    takeEvery(Types.DELETE_EXERCISES_FAIL, requestFailedWithConflicts),
  ]);
}
