import React from 'react';
import cx from 'classnames';
import { push } from 'connected-react-router';
import { useTheme, withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotFoundDashboard } from 'components';
import { PaymentForm } from './payment';
import { NewChoosePlan } from './payment';
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import { Invite } from 'modules/dashboard/profile';
import { AddTeamMemberForm, TeamDashboard } from 'modules/dashboard/team-dashboard';
import { Tasks,QuestionAndAnswer } from 'modules/dashboard/assessment';
// import { QuestionAndAnswer } from 'modules/dashboard/assessment/components';
import { ExerciseList } from 'modules/dashboard/exercises';
import { Favorites } from 'modules/dashboard/workouts';
import {
  DailyClassList,
  DailyClassPreview,
  PainAndInjuryList,
  PainAndInjuryPreview,
  PreWorkoutList,
  PreWorkoutPreview,
  RecoveryList,
  RecoveryPreview,
  WorkRoutineList,
  WorkRoutinePreview,
  WorkoutResults,
  ProgramList,
  WorkOutProgramListMain
} from 'modules/dashboard/workouts';
import {
  AboutUs,
  Announcements,
  AnnouncementDetails,
  ContactUs,
  Cookies,
  GettingStarted,
  GettingStartedDetails,
  Home,
  PrivacyPolicy,
  ToS,
  Welcome,
} from 'modules/dashboard/home';

import backgroundImg from 'assets/img/background.png';

import {
  EditProfile,
  PaymentInfo,
  ProfileMenu,
  Stats,
  Credits,
} from 'modules/dashboard/profile';
import { useMediaQuery } from '@material-ui/core';
import Navbar from './components/Navbar';
import WorkOutProgramList from './components/WorkOutProgramList';
// import WorkOutProgramListMain from './workouts/components/WorkOutProgramListMain';

const styles = (theme) => ({
  container: {
    minHeight: '90vh',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      flexGrow: 1,
    },
  },
  content: {
    padding: '2rem',
    background: 'white',
    gridArea: 'content',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
  },
  contentFooterVisible: {
    marginLeft: 0,
  },
  contentNoPadding: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  contentNoBackground: {
    background: 'none',
  },
  footer: {
    background: theme.palette.primary.dark,
    gridArea: 'footer',
    height: '10vh',
  },
  sidebar: {
    background: 'rgba(0, 0, 0, 0.61)',
    backdropFilter: 'blur(3px)',
    width: 236,
  },
  sideBarFooterVisible: {
    position: 'initial',
    height: 'initial',
  },
  page: {
    background: `url(${backgroundImg})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
  },
});

const Routes = ({ user, goTo, classes, match: { url } }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // TODO: Refactor this
  const useNoPadding = [
    '/dashboard/home/announcements',
    '/dashboard/home/getting-started',
    '/dashboard/payment/choose-plan',
    '/dashboard/payment/team-dashboard',
    '/dashboard/profile',
    '/dashboard/profile/edit',
    '/dashboard/profile/subscription',
    '/dashboard/profile/stats',
    '/dashboard/about-us',
    '/dashboard/privacy-policy',
    '/dashboard/terms',
    '/dashboard/cookies',
    '/dashboard/invite-friends',
    '/dashboard/home/welcome',
  ].includes(pathname);

  const useNoBackground = [
    '/dashboard/home',
    '/dashboard/home/welcome',
    '/dashboard/home/announcements',
    '/dashboard/home/getting-started',
  ].includes(pathname);

  const renderRoutes = () => (
    <div
      className={cx(
        classes.content,
        useNoPadding || isSmallScreen ? classes.contentNoPadding : '',
        useNoBackground ? classes.contentNoBackground : ''
      )}
    >
      {user.status == 'premium' ? (
        <Switch>
          <Route
            component={Invite}
            exact={true}
            path={`${url}/invite-friends`}
          />
          <Route
            component={PaymentForm}
            exact={true}
            path={`${url}/payment/payment-form`}
          />
          <Route
            component={NewChoosePlan}
            exact={true}
            path={`${url}/payment/choose-plan`}
          />
          <Route
            component={TeamDashboard}
            exact={true}
            path={`${url}/team-dashboard`}
          />
          <Route
            component={Tasks}
            exact={true}
            path={`${url}/assessment`}
          />
          <Route
            component={QuestionAndAnswer}
            exact={true}
            path={`${url}/assessment/question-and-answer`}
          />

          {/* <Route
            component={TeamDashboard}
            exact={true}
            path={`${url}/team-accounts-dashboard/:id`}
          /> */}

          <Route
            component={ExerciseList}
            exact={true}
            path={`${url}/exercises`}
          />
          <Route
            component={DailyClassList}
            exact={true}
            path={`${url}/daily-class`}
          />
          <Route
            component={DailyClassPreview}
            exact={true}
            path={`${url}/daily-class/:id`}
          />
          <Route
            component={PreWorkoutList}
            exact={true}
            path={`${url}/pre-workout`}
          />
          <Route
            component={PreWorkoutPreview}
            exact={true}
            path={`${url}/pre-workout/:id`}
          />
          <Route
            component={RecoveryList}
            exact={true}
            path={`${url}/recovery`}
          />
          <Route
            component={RecoveryPreview}
            exact={true}
            path={`${url}/recovery/:id`}
          />
          <Route
            component={WorkRoutineList}
            exact={true}
            path={`${url}/work-routine`}
          />
          <Route
            component={WorkRoutinePreview}
            exact={true}
            path={`${url}/work-routine/:id`}
          />


          <Route
            component={PainAndInjuryList}
            exact={true}
            path={`${url}/pain-and-injury`}
          />
        <Route
            component={WorkOutProgramListMain}
            exact={true}
            path={`${url}/pain-and-injury/programs/:programTitle`}
          />
        {/* <Route
            component={PainAndInjuryPreview}
            exact={true}
            path={`${url}/pain-and-injury/programs/:title:id`}
          /> */}
          <Route
            component={PainAndInjuryPreview}
            exact={true}
            path={`${url}/pain-and-injury/:id`}
          />
          <Route component={Favorites} exact={true} path={`${url}/favorites`} />
          <Route
            component={WorkoutResults}
            exact={true}
            path={`${url}/workout-results`}
          />
          <Route component={ProfileMenu} exact={true} path={`${url}/profile`} />
          <Route
            component={EditProfile}
            exact={true}
            path={`${url}/profile/edit`}
          />
          <Route component={Home} exact={true} path={`${url}/home`} />
          <Route component={AboutUs} exact={true} path={`${url}/about-us`} />
          <Route
            component={PrivacyPolicy}
            exact={true}
            path={`${url}/privacy-policy`}
          />
          <Route component={Cookies} exact={true} path={`${url}/cookies`} />
          <Route component={ToS} exact={true} path={`${url}/terms`} />
          <Route
            component={ContactUs}
            exact={true}
            path={`${url}/contact-us`}
          />
          <Route
            component={PainAndInjuryList}
            exact={true}
            path={`${url}/propain-and-injurygrams`}
          />
          <Route
            component={Announcements}
            exact={true}
            path={`${url}/home/announcements`}
          />
          <Route
            component={AnnouncementDetails}
            exact={true}
            path={`${url}/home/announcements/:id`}
          />
          <Route
            component={GettingStarted}
            exact={true}
            path={`${url}/home/getting-started`}
          />
          <Route
            component={Welcome}
            exact={true}
            path={`${url}/home/welcome`}
          />
          <Route
            component={GettingStartedDetails}
            exact={true}
            path={`${url}/home/getting-started/:id`}
          />
          <Route component={Stats} exact={true} path={`${url}/profile/stats`} />
          <Route
            component={PaymentInfo}
            exact={true}
            path={`${url}/profile/subscription`}
          />
          <Route
            component={Credits}
            exact={true}
            path={`${url}/profile/credits`}
          />
          <Route path='*' exact={true} component={NotFoundDashboard} />
        </Switch>
      ) : (
        <Switch>
          <Route
            component={NewChoosePlan}
            exact={true}
            path={`${url}/payment/choose-plan`}
          />
          <Route path='*' exact={true} component={NotFoundDashboard} />
        </Switch>
      )}
    </div>
  );

  if (isSmallScreen) {
    return (
      <>
        <div className={classes.page}>
          <div className={classes.container}>
            {user.status == 'premium' ? <Navbar /> : null}
            {renderRoutes()}
          </div>

          {user.status == 'premium' ? (
            <div className={classes.footer}>
              <Footer />
            </div>
          ) : null}

          {/* // <div className={classes.footer}>
        //   <Footer />
        // </div> */}
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.page}>
        <div className={classes.container}>
          {user.status === 'premium' ? (
            <div className={classes.sidebar}>
              <SideBar />
            </div>
          ) : null}
          {/* <div className={classes.sidebar}>
           <SideBar />
         </div> */}
          {renderRoutes()}
        </div>
        {user.status === 'premium' ? (
          <div className={classes.footer}>
            <Footer />
          </div>
        ) : null}
      </div>
    </>
  );
};

Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { user, error, processing } }) => ({
  user,
  error,
  processing,
});

const mapDispatchToProps = {
  goTo: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Routes));
