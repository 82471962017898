import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import { push } from 'connected-react-router';

import { Creators as ModalCreators } from 'modules/modal';
import { Creators } from '../reducer';
import { default as TagForm } from './TagForm';
import { default as EditTag } from './EditTag';
import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import { sortByLabel } from 'core/utils/sort';

const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 0px',
  },
  actionButton: {
    cursor: 'pointer',
    margin: '5px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cell: {
    width: 190,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '400px auto',
    paddingTop: '80px',
    minHeight: '100vh',
    width: '100%',
  },
  tagGrid: {
    display: 'grid',
    maxWidth: '800px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  tagType: {
    borderTop: `1px solid ${theme.palette.primary.gray}`,
    padding: 16,
    cursor: 'pointer',
    '& > div': {
      marginBottom: 4,
    },
  },
  selectedType: {
    backgroundColor: theme.palette.secondary.inactive,
  },
  tagTypes: {
    background: theme.palette.background.paper,
    minHeight: 'calc(100vh - 80px)',
    '& > div:first-child': {
      borderTop: 'none',
    },
  },
  tagCard: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    height: '120px',
    padding: '10px 10px 10px 15px',
    overflow: 'hidden',
    margin: '20px',
  },
  cardLabel: {
    fontSize: '16px',
    color: theme.palette.secondary.grayText,
    textTransform: 'uppercase',
  },
  content: {
    display: 'flex',
  },
  details: {
    marginLeft: 24,
    width: 600,
  },
  detailsPanel: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    '& > div:first-child': {
      paddingTop: 24,
    },
    '& > div:last-child': {
      paddingBottom: 24,
    },
  },
  header: {
    fontSize: 21,
    fontWeight: 900,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  label: {
    color: 'rgba(74, 75, 107, 0.4)',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  money: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },

  row: {
    display: 'flex',
    padding: '8px 24px',
  },
  rowOdd: {
    background: theme.palette.background.default,
  },
  text: {
    fontSize: 16,
  },
  textXL: {
    fontSize: 18,
    textTransform: 'capitalize',
  },
  textXXL: {
    fontSize: 20,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  block: {
    display: 'block',
  },
  mb: {
    marginBottom: `${theme.spacing()}px !important`,
  },
});

const Tags = ({
  classes,
  data,
  getWorkoutTypes,
  createWorkoutType,
  updateWorkoutType,
  getBodyArea,
  createBodyArea,
  deleteBodyArea,
  updateBodyArea,
  getEquipment,
  createEquipment,
  updateEquipment,
  deleteEquipment,
  getModality,
  createModality,
  updateModality,
  deleteModality,
  setActiveTag,
  setActiveType,
  getMovementPrep,
  createMovementPrep,
  updateMovementPrep,
  deleteMovementPrep,
  getMuscles,
  createMuscles,
  updateMuscles,
  deleteMuscles,
  showModal,
}) => {
  const theme = useTheme();

  const actionsMap = {
    bodyArea: {
      title: 'Body Area',
      create: createBodyArea,
      save: updateBodyArea,
      get: getBodyArea,
      delete: deleteBodyArea,
    },
    equipment: {
      title: 'Equipment',
      create: createEquipment,
      save: updateEquipment,
      get: getEquipment,
      delete: deleteEquipment,
    },
    modality: {
      title: 'Modality',
      create: createModality,
      save: updateModality,
      get: getModality,
      delete: deleteModality,
    },
    movementPrep: {
      title: 'Movement Prep',
      create: createMovementPrep,
      save: updateMovementPrep,
      get: getMovementPrep,
      delete: deleteMovementPrep,
    },
    muscles: {
      title: 'Muscles',
      create: createMuscles,
      save: updateMuscles,
      get: getMuscles,
      delete: deleteMuscles,
    },
    workoutType: {
      title: 'Workout Types',
      create: createWorkoutType,
      save: updateWorkoutType,
      get: getWorkoutTypes,
    },
  };

  const TagTypes = ({ setActiveType }) => {
    return (
      <div className={classes.tagTypes}>
        {Object.keys(actionsMap).map((key) => (
          <div
            className={cx(
              classes.tagType,
              classes.title,
              data.activeType === key ? classes.selectedType : ''
            )}
            key={key}
            onClick={() => setActiveType(key)}
          >
            {actionsMap[key].title}
          </div>
        ))}
      </div>
    );
  };

  const TagCard = ({ tag, deleteAction, setActiveTag }) => {
    return (
      <div className={classes.tagCard}>
        <div className={classes.cardHeader}>
          <div className={classes.cardLabel}>{'TAG NAME'}</div>
          <div className={classes.actionButtons}>
            <div
              className={classes.actionButton}
              onClick={() => setActiveTag(tag.value)}
            >
              <Edit height={16} width={16} fill={theme.palette.primary.dark} />
            </div>
            {deleteAction && (
              <div
                className={classes.actionButton}
                onClick={() =>
                  showModal(
                    'deleteConfirmation',
                    () => deleteAction(tag.value),
                    `This is a cascade action. Deleting a tag will make it disappear from everywhere it's used. Are you sure?`
                  )
                }
              >
                <Bin height={16} width={16} fill={theme.palette.primary.red} />
              </div>
            )}
          </div>
        </div>
        <div className={classes.textXXL}>{tag.label}</div>
      </div>
    );
  };

  const AddTag = ({ createFunction }) => {
    return <TagForm onSubmit={createFunction} />;
  };

  const TagList = ({ tags, deleteAction, setActiveTag }) => {
    return (
      <div className={classes.tagGrid}>
        {tags.sort(sortByLabel).map((tag) =>
          tag.value === data.activeTag ? (
            <EditTag
              tag={tag}
              onOutsideClick={() => setActiveTag(null)}
              onSubmit={(values) => {
                setActiveTag(null);
                actionsMap[data.activeType].save(values);
              }}
            />
          ) : (
            <TagCard
              tag={tag}
              key={tag.value}
              deleteAction={deleteAction}
              setActiveTag={setActiveTag}
            />
          )
        )}
      </div>
    );
  };
  return (
    <div className={classes.container}>
      <TagTypes setActiveType={setActiveType} />
      <div>
        <AddTag createFunction={actionsMap[data.activeType].create} />
        <TagList
          tags={data[data.activeType]}
          setActiveTag={setActiveTag}
          deleteAction={actionsMap[data.activeType].delete}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  goTo: push,
  getWorkoutTypes: Creators.getWorkoutTypes,
  createWorkoutType: Creators.createWorkoutType,
  updateWorkoutType: Creators.updateWorkoutType,
  getBodyArea: Creators.getBodyArea,
  createBodyArea: Creators.createBodyArea,
  updateBodyArea: Creators.updateBodyArea,
  deleteBodyArea: Creators.deleteBodyArea,
  getEquipment: Creators.getEquipment,
  createEquipment: Creators.createEquipment,
  updateEquipment: Creators.updateEquipment,
  deleteEquipment: Creators.deleteEquipment,
  getModality: Creators.getModality,
  createModality: Creators.createModality,
  updateModality: Creators.updateModality,
  deleteModality: Creators.deleteModality,
  setActiveType: Creators.setActiveType,
  setActiveTag: Creators.setActiveTag,
  getMovementPrep: Creators.getMovementPrep,
  createMovementPrep: Creators.createMovementPrep,
  updateMovementPrep: Creators.updateMovementPrep,
  deleteMovementPrep: Creators.deleteMovementPrep,
  getMuscles: Creators.getMuscles,
  createMuscles: Creators.createMuscles,
  updateMuscles: Creators.updateMuscles,
  deleteMuscles: Creators.deleteMuscles,
  showModal: ModalCreators.showModal,
};

const mapStateToProps = ({ tags }) => ({
  data: tags,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Tags));
