import 'react-dropzone-uploader/dist/styles.css';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { withStyles } from '@material-ui/styles';
import { localStorage } from 'core';
import { BASE_URL, TOKEN, BUCKET_URL } from 'core/constants';
import axios from 'axios';
import placeholderImage from 'assets/svg/placeholder-image.svg';
import placeholderVideo from 'assets/svg/placeholder-video.svg';

const styles = ({ palette }) => ({
  button: {
    backgroundColor: palette.primary.main,
    borderRadius: 2,
    color: palette.primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: 15,
  },
  container: {
    height: '100%',
    width: '100%',
  },
  fileUploaded: {
    '& > img': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > video': {
      height: 200,
      objectFit: 'cover',
      width: '100%',
    },
    '& > label': {
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      bottom: 0,
      color: palette.primary.main,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      height: 40,
      justifyContent: 'center',
      left: 0,
      position: 'absolute',
      width: '100%',
    },
  },
  fileNotUploaded: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-evenly',
    padding: 16,
  },
  text: {
    color: palette.primary.generalText,
    fontSize: 14,
    lineHeight: 1.5,
  },
  textS: {
    color: palette.secondary.grayText,
    fontSize: 14,
    lineHeight: 1.2,
  },
});

const FileUploader = ({
  acceptPattern,
  classes,
  fileType,
  initialFileUrl,
  onFileUploaded,
  text,
  title,
}) => {
  const [fileUrl, setFileUrl] = useState('');
  const [fileId, setFileId] = useState('');
  const isImage = fileType === 'image';

  const Input = ({ accept, onFiles, getFilesFromEvent }) => {
    const previewUrl = fileUrl || initialFileUrl;
    if (previewUrl) {
      return (
        <div className={classes.fileUploaded}>
          {isImage ? (
            <img alt='preview' src={previewUrl} />
          ) : (
            <video preload='metadata' controls>
              <source src={`${previewUrl}#t=0.1`} type='video/mp4' />
            </video>
          )}
          <label>
            {`Change ${isImage ? 'Photo' : 'Video'}`}
            <input
              style={{ display: 'none' }}
              type='file'
              accept={accept}
              multiple
              onChange={(e) => {
                getFilesFromEvent(e).then((chosenFiles) => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </label>
        </div>
      );
    }

    return (
      <div className={classes.fileNotUploaded}>
        <img
          alt='placeholder'
          className={classes.placeholderImage}
          src={isImage ? placeholderImage : placeholderVideo}
        />
        <div className={classes.text}>
          {text ||
            (isImage
              ? 'Drag and Drop your .png or .jpeg file'
              : 'Drag and Drop your .mp4 file')}
        </div>
        <div className={classes.textS}>or</div>
        <label className={classes.button}>
          Browse
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </label>
      </div>
    );
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const token = localStorage.getItem(TOKEN);
    if (fileType === 'image') {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        url: `${BASE_URL}/multimedia/${fileType}`,
      };
    }
    const presignedUrl = await axios.get(
      `${BASE_URL}/multimedia/signedurl?filename=${name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFileId(presignedUrl.data.id);
    setFileUrl(`${BUCKET_URL}${presignedUrl.data.name}`);
    return {
      headers: { 'Content-Type': 'video/mp4' },
      method: 'PUT',
      body: file,
      url: presignedUrl.data.url,
    };
  };

  const handleChangeStatus = ({ meta, remove, xhr }, status) => {
    if (status === 'done') {
      if (fileType === 'image') {
        const responseObj = JSON.parse(xhr.response);
        onFileUploaded({ id: responseObj.id, url: responseObj.location });
        setFileUrl(responseObj.location);
      } else {
        onFileUploaded({
          id: fileId,
          url: fileUrl,
        });
      }
      remove();
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.text} style={{ marginBottom: 4 }}>
        {title || (isImage ? 'Photo' : 'Video')}
      </div>
      <Dropzone
        accept={acceptPattern}
        canCancel
        getFilesFromEvent={getFilesFromEvent}
        getUploadParams={getUploadParams}
        InputComponent={Input}
        maxFiles={1}
        multiple={false}
        onChangeStatus={handleChangeStatus}
        styles={{
          dropzone: {
            borderRadius: 2,
            borderStyle: fileUrl ? 'none' : 'dotted',
            height: 200,
            overflow: 'hidden',
          },
          preview: {
            flexDirection: 'column',
          },
          previewImage: {
            maxHeight: 100,
          },
        }}
      />
    </div>
  );
};

export default withStyles(styles)(FileUploader);
