import React from 'react';
import { Helmet } from 'react-helmet';

import List from './List';

const ExerciseList = () => (
  <>
    <Helmet>
      <title>Exercises</title>
    </Helmet>
    <List title='Exercises' />
  </>
);

export default ExerciseList;
