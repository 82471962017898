import React from 'react';
import { withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';

const styles = ({ palette: { primary } }) => ({
  field: {
    margin: '10px 0',
  },
  label: {
    color: primary.generalText,
    fontSize: 14,
    lineHeight: '24px',
  },
});

const ReadOnlyField = ({ classes, label, ...props }) => (
  <div className={classes.field}>
    <div className={classes.label}>{label}</div>
    <TextField {...props} fullWidth />
  </div>
);

export default withStyles(styles)(ReadOnlyField);
