import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { push as pushTo } from 'connected-react-router';

import Admin from 'modules/admin/Routes';
import { Auth } from 'modules/auth';
import { Dashboard } from 'modules/dashboard';
import { Loader, NotFound } from 'components';
import {
  identify,
  mixpanelAtoms,
  MixpanelEvents,
  setSuperProperty,
  track,
} from './utils/mixpanel';
import { useRecoilState } from 'recoil';

const AUTH_RULES = [
  {
    isPublic: true,
    pattern: '/auth',
  },
  {
    isPublic: true,
    pattern: '/admin/auth',
  },
  {
    allowedRoles: ['admin', 'user'],
    isPublic: false,
    pattern: '/dashboard',
  },
  {
    allowedRoles: ['admin'],
    isPublic: false,
    pattern: '/admin/dashboard',
  },
];

const HOME_ROUTE = {
  admin: '/admin/dashboard',
  user: '/dashboard',
};

const Routes = ({ initializing, push, role, id, email }) => {
  const { pathname } = useLocation();
  const [, setCurrentListTitle] = useRecoilState(
    mixpanelAtoms.currentListTitle
  );
  const [, setCurrentFavoriteTab] = useRecoilState(
    mixpanelAtoms.currentFavoriteTab
  );

  useEffect(() => {
    setCurrentListTitle('');
    setCurrentFavoriteTab('');
  }, [pathname, setCurrentFavoriteTab, setCurrentListTitle]);

  identify(id);

  const processRoute = useCallback(() => {
    const route = AUTH_RULES.find((route) => {
      return route.pattern === pathname.substr(0, route.pattern.length);
    });

    if (!route || initializing) return;

    if (route.isPublic) {
      // Logged in user accessing a public route? Redirect to home.
      if (role) {
        track(MixpanelEvents.pageViewed, { pageViewed: HOME_ROUTE[role] });
        setSuperProperty({ pageViewed: HOME_ROUTE[role], platform: 'web' });
        push(HOME_ROUTE[role]);
      }
    } else {
      if (role) {
        // Authenticated user accessing an unauthorized route? Redirect to home.
        if (!route.allowedRoles.includes(role)) {
          track(MixpanelEvents.pageViewed, { pageViewed: HOME_ROUTE[role] });
          setSuperProperty({ pageViewed: HOME_ROUTE[role], platform: 'web' });
          push(HOME_ROUTE[role]);
        }
      } else {
        // Unauthenticated user accessing a private route? Redirect to Sign In.
        track(MixpanelEvents.pageViewed, { pageViewed: HOME_ROUTE[role] });
        setSuperProperty({ pageViewed: HOME_ROUTE[role], platform: 'web' });
        push('/auth');
      }
    }

    track(MixpanelEvents.pageViewed, { pageViewed: pathname });
    setSuperProperty({ pageViewed: pathname, platform: 'web' });
  }, [initializing, pathname, push, role]);

  useEffect(() => {
    processRoute();
  }, [processRoute, pathname]);

  // Authentication in progress? Show loading screen.
  if (initializing) return <Loader height='100vh' />;

  return (
    <Switch>
      <Route path='/admin' component={Admin} />
      <Route path='/auth' component={Auth} />
      <Route path='/dashboard' component={Dashboard} />
      <Redirect exact={true} from={'/'} to={'/auth'} />
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = ({
  auth: {
    initializing,
    user: { role, id, email },
  },
}) => ({
  initializing,
  role,
  id,
  email,
});

const mapDispatchToProps = { push: pushTo };

export default connect(mapStateToProps, mapDispatchToProps)(Routes);