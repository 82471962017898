import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Creators, INITIAL_FILTER } from '../reducer';
import { Creators as ModalCreators } from 'modules/modal';
import { withStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';
import search from 'assets/img/new-search-icon.png';

const styles = ({ palette: { primary }, breakpoints, theme }) => ({
  wrapper: {
    position: 'relative',
  },

  icon: {
    height: '15px',
    width: '15px',
    backgroundImage: `url(${search})`,
    backgroundSize: 'cover',
    marginLeft: '15px',

    // backgroundImage: `url('assets/img/new-search-icon.png')`,
    backgroundRepeat: 'no-repeat',
    // padding: "4px",
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '2px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      marginTop: 5,
    },
  },

  searchInput: {
    height: '48px',
    boxSizing: 'border-box',
    paddingLeft: '40px',
    outline: 'none',
    width: '333px',
    border: '1px solid #DFE2E6',
    '&:focus': {
      borderColor: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      marginTop: 10,
      width: '100%',
    },
  },
});

function Search({ classes, value, onSearchChange }) {
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.icon}></div>
        <input
          placeholder='Search Sub Accounts...'
          className={classes.searchInput}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          value={value}
        ></input>
      </div>
    </>
  );
}

export default withStyles(styles)(Search);
