import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

import home1Img from 'assets/img/home1.png';
import home2Img from 'assets/img/home2.png';
import home3Img from 'assets/img/home3.png';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';

const styles = ({ breakpoints, palette: { primary } }) => ({
  container: {
    maxWidth: 1200,
    padding: '1rem',
    margin: '0 auto',
  },
  button: {
    background: primary.main,
    border: 'none',
    borderRadius: 2,
    color: primary.contrastText,
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '1em',
    padding: '0.5rem 1rem',
    letterSpacing: 0.6,
    textTransform: 'uppercase',
    minWidth: 140,
  },
  list: {
    marginTop: 48,
  },
  listItem: {
    background: 'rgba(0, 0, 0, 0.61)',
    backdropFilter: 'blur(3px)',
    borderRadius: 4,
    display: 'flex',
    marginBottom: 18,
    padding: '1rem',
    paddingRight: '2rem',
    transition: 'all 0.15s',
    '& > img': {
      height: 110,
      borderRadius: 4,
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.7)',
    },
  },
  listItemContent: {
    alignItems: 'center',
    color: primary.contrastText,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    paddingLeft: 18,
    gap: '1rem',
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '0.5rem',
      flexDirection: 'column',
    },
  },
  listItemTitle: {
    fontSize: 18,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: '28px',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  pageTitle: {
    color: primary.contrastText,
    fontSize: 32,
    fontWeight: '900',
    textShadow: '0px 2px 4px rgba(0,0,0,0.5)',
  },
});

const LIST = [
  {
    action: 'start class',
    image: home1Img,
    title: 'Daily Movement Vault class',
    url: 'daily-class',
  },
  {
    action: 'explore',
    image: home2Img,
    title: 'Announcements',
    url: 'home/announcements',
  },
  {
    action: 'explore',
    image: home3Img,
    title: 'Getting Started',
    url: 'home/getting-started',
  },
];

const Home = ({ classes, items, processing }) => {
  const [dailyClassId, setDailyClassId] = useState(null);

  useEffect(() => {
    if (items.length) {
      setDailyClassId(
        items.reduce((a, b) =>
          new Date(a.activeSince) > new Date(b.activeSince) ? a : b
        ).id
      );
    }
  }, [items]);

  const ListItem = ({ item: { action, image, title, url } }) => (
    <NavLink
      to={url === 'daily-class' ? `${url}/${dailyClassId}` : url}
      onClick={(e) => {
        if (processing) {
          e.preventDefault();
        }
      }}
      exact
      style={{ textDecoration: 'none ' }}
    >
      <div className={classes.listItem}>
        <img src={image} alt='thumbnail' />
        <div className={classes.listItemContent}>
          <div className={classes.listItemTitle}>{title}</div>
          <Box
            display='flex'
            justifyContent='flex-start'
            width={{ xs: '100%', sm: 'auto' }}
          >
            <button className={classes.button}>{action}</button>
          </Box>
        </div>
      </div>
    </NavLink>
  );

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className={classes.pageTitle}>Home</div>
      <div className={classes.list}>
        {LIST.map((item) => (
          <ListItem item={item} key={item.title} />
        ))}
      </div>
    </Box>
  );
};

export default connect(
  ({
    workout,
    auth: {
      user: { status: userStatus },
    },
  }) => ({


    // items: workout.dailyClass?.filter((workout) => {

    //   if (userStatus === 'freemium' && workout.access === 'premium') {
    //     return true;
    //   }

    //   return workout.workoutTypes.some((wt) => wt.key === 'daily-class');
    // }),

    
    items: workout.dailyClass?.filter((workout) => {
      
      if (userStatus !== 'premium' && workout.access === 'premium') {
        return false;
      }

      return workout.workoutTypes.some((wt) => wt.key === 'daily-class');
    }),

    processing: workout.processing,
  }),
  null
)(withStyles(styles)(Home));