import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 13 16' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.9144 3.64348L11.0428 3.7864C11.6701 4.48426 12.0156 5.38469 12.0156 6.32177V13.7238C12.0156 14.7283 11.1959 15.5455 10.1882 15.5455H1.84304C0.835343 15.5455 0.015625 14.7283 0.015625 13.7238V6.33493C0.015625 5.38765 0.367663 4.4801 1.00666 3.77928L1.14253 3.63031V1.82174C1.14253 0.817174 1.96225 0 2.96994 0H9.09177C10.0995 0 10.9192 0.817174 10.9192 1.82174V3.64348H10.9144ZM9.09177 1.21449H2.96994C2.63408 1.21449 2.3608 1.48692 2.3608 1.82174V2.4042L2.38817 2.42899H9.67878L9.7009 2.4093V1.82174C9.7009 1.48692 9.42763 1.21449 9.09177 1.21449ZM10.7974 13.7238C10.7974 14.0586 10.5241 14.331 10.1882 14.331H1.84304C1.50718 14.331 1.2339 14.0586 1.2339 13.7238V6.33493C1.2339 5.69057 1.47339 5.07312 1.90811 4.59634L2.77697 3.64348H9.27855L10.1355 4.59693C10.5623 5.0717 10.7974 5.68428 10.7974 6.32177V13.7238ZM8.574 8.59254H6.27284L6.92933 5.43486L3.54862 9.80703H5.80088L5.19329 12.6611L8.574 8.59254Z'
      fill={fill}
    />
  </svg>
);
