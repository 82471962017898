import { createActions, createReducer } from 'reduxsauce';

const INITIAL_STATE = {
  rule: null,
  processing: false,
};

// Action creators
const { Types, Creators } = createActions(
  {
    getRuleFail: ['error'],
    getRuleSuccess: ['response'],
    getRule: () => ({
      type: 'invite/GET_RULE',
      payload: {
        request: {
          url: '/invite-rule',
          method: 'get',
        },
      },
    }),
    saveRuleFail: ['error'],
    saveRuleSuccess: ['response'],
    saveRule: (rule) => ({
      type: 'invite/SAVE_RULE',
      payload: {
        request: {
          url: '/invite-rule',
          method: 'post',
          data: rule,
        },
      },
    }),
  },
  { prefix: 'invite/' }
);

// Reducers
const getRule = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getRuleSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    rule: data,
    processing: false,
  };
};

const saveRule = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const saveRuleSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    rule: data,
  };
};

const saveRuleFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});
const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RULE]: getRule,
  [Types.GET_RULE_SUCCESS]: getRuleSuccess,
  [Types.SAVE_RULE_SUCCESS]: saveRuleSuccess,
  [Types.SAVE_RULE_FAIL]: saveRuleFail,
  [Types.SAVE_RULE]: saveRule,
});

export { Creators, Types, Reducer };
