import React from 'react';

export default ({ fill }) => (
  <svg
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={fill}
      d='M15.5838 3.98361L11.8289 0.228729C11.6824 0.0822449 11.4837 0 11.2765 0H0.96875C0.537231 0 0.1875 0.349731 0.1875 0.78125V19.2188C0.1875 19.6503 0.537231 20 0.96875 20H15.0312C15.4628 20 15.8125 19.6503 15.8125 19.2188V4.53598C15.8125 4.32877 15.7303 4.1301 15.5838 3.98361ZM14.25 18.4375H1.75V1.5625H10.9529L14.25 4.85962V18.4375ZM6.63281 7.07031H3.89844C3.46692 7.07031 3.11719 7.42004 3.11719 7.85156V10.5859C3.11719 11.0175 3.46692 11.3672 3.89844 11.3672H6.63281C7.06433 11.3672 7.41406 11.0175 7.41406 10.5859V7.85156C7.41406 7.42004 7.06433 7.07031 6.63281 7.07031ZM5.85156 9.80469H4.67969V8.63281H5.85156V9.80469ZM9.46484 8.4375H12.1992C12.6307 8.4375 12.9805 8.78723 12.9805 9.21875C12.9805 9.65027 12.6307 10 12.1992 10H9.46484C9.03333 10 8.68359 9.65027 8.68359 9.21875C8.68359 8.78723 9.03333 8.4375 9.46484 8.4375ZM6.63281 12.5391H3.89844C3.46692 12.5391 3.11719 12.8888 3.11719 13.3203V16.0547C3.11719 16.4862 3.46692 16.8359 3.89844 16.8359H6.63281C7.06433 16.8359 7.41406 16.4862 7.41406 16.0547V13.3203C7.41406 12.8888 7.06433 12.5391 6.63281 12.5391ZM5.85156 15.2734H4.67969V14.1016H5.85156V15.2734ZM12.9805 14.6875C12.9805 15.119 12.6307 15.4688 12.1992 15.4688H9.46484C9.03333 15.4688 8.68359 15.119 8.68359 14.6875C8.68359 14.256 9.03333 13.9062 9.46484 13.9062H12.1992C12.6307 13.9062 12.9805 14.256 12.9805 14.6875Z'
    />
  </svg>
);
