import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Button } from 'components';
import { Creators } from '../reducer';

const styles = (theme) => ({
  fbButton: {
    backgroundColor: '#526495',
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    width: 260,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

const FacebookButton = ({ classes, signInWithFb }) => {
  const responseFacebook = (response) => {
    if (response && response.status !== 'unknown') {
      signInWithFb(response);
    }
  };

  return (
    <FacebookLogin
      version='9.0'
      appId='2316621321989158'
      callback={responseFacebook}
      render={(renderProps) => (
        <Button
          className={classes.fbButton}
          onClick={renderProps.onClick}
          title='sign in with facebook'
          type='button'
        />
      )}
    />
  );
};

const mapDispatchToProps = { signInWithFb: Creators.signInWithFb };

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(FacebookButton));
