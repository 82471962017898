import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Select, Button, Link } from 'components';

import bin from 'assets/svg/bin.svg';
import search from 'assets/svg/search.svg';

const styles = (theme) => ({
  clearAll: {
    bottom: 3,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    position: 'absolute',
    right: 16,
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'space-between',
    padding: '0px 16px',
  },
  deleteButton: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '& > img': {
      width: 19,
      height: 20,
    },
    '& > span': {
      color: theme.palette.secondary.red,
      paddingLeft: 8,
    },
  },
  input: {
    border: `1px solid ${theme.palette.secondary.gray}`,
    padding: 16,
    height: 45,
    fontSize: 14,
    width: 460,
    '&:focus': {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    '&::placeholder': {
      color: theme.palette.secondary.gray,
    },
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchButton: {
    alignItems: 'center',
    background: theme.palette.primary.gray,
    cursor: 'pointer',
    display: 'flex',
    height: 38,
    justifyContent: 'center',
    marginLeft: 3,
    width: 38,
    '& > img': {
      height: 24,
      width: 24,
    },
  },
  select: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 24,
    position: 'relative',
  },
  selectLabel: {
    color: theme.palette.secondary.grayText,
    paddingRight: 8,
    fontSize: 14,
  },
  selectSearch: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 24,
    position: 'absolute',
    right: 3,
  },
  button: {
    textTransform: 'capitalize',
    width: 210,
  },
  buttonContainer: {
    marginLeft: 24,
  },
  checkText : {
    fontWeight : 400,
    fontSize : 14,
    lineHeight : 17,
    fontFamily : "Lato",
    color : "#28AAE2",
    cursor : "pointer",
  }
});

const TopBar = ({
  classes,
  onBulkDelete,
  onClearAll,
  onSearch,
  planSelect,
  statusSelect,
  searchInput,
  searchSelect,
  showDelete,
}) => {
  return (
    <div className={classes.container}>
      <div onClick={onBulkDelete} className={classes.deleteButton}>
        {showDelete && (
          <>
            <img src={bin} alt='bin' />
            <span>Delete</span>
          </>
        )}
      </div>
      <div className={classes.searchBar}>
        <div className={classes.select}>
          {/* <div className={classes.selectLabel}>{`${statusSelect.label}`}:</div> */}
          <div className={classes.selectLabel}>Filter:</div>
          <Select
            onChange={({ value }) => statusSelect.onChange(value)}
            options={statusSelect.options}
            value={statusSelect.value}
          />
        </div>
        <div className={classes.select}>
          <input
            placeholder='Search by filter'
            onChange={(e) => {
              searchInput.onChange(e.target.value);
            }}
            className={classes.input}
            value={searchInput.value}
          />
          <div className={classes.selectSearch}>
            <Select
              height={38}
              options={searchSelect.options}
              onChange={({ value }) => searchSelect.onChange(value)}
              value={searchSelect.value}
              width={180}
            />
            <div onClick={onSearch} className={classes.searchButton}>
              <img src={search} alt='search icon' />
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Link
            style={{ textDecoration: 'none' }}
            to='/admin/dashboard/membership/team-accounts/create'
          >
            <Button className={classes.button} title='Add Team Account' />
          </Link>
        </div>
        {/* <div className={classes.clearAll} onClick={onClearAll}>
          Clear all
        </div> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(TopBar);
