import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 14 14' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7 5.38734e-05C5.92635 -0.00419002 4.86654 0.242357 3.905 0.720054C3.73121 0.79018 3.61123 0.951598 3.59418 1.13822C3.57712 1.32484 3.66586 1.50534 3.82405 1.6058C3.98225 1.70627 4.18334 1.70984 4.345 1.61505C6.20546 0.69703 8.40816 0.805255 10.1697 1.90124C11.9312 2.99722 13.0015 4.92543 13 7.00005C13 9.44005 11.69 12.1601 9.5 12.5001C9.02916 12.6126 8.53268 12.51 8.145 12.2201C7.70988 11.7392 7.4786 11.1082 7.5 10.4601C9.22046 10.2117 10.498 8.73835 10.5 7.00005V4.00005C10.5 3.72391 10.2761 3.50005 10 3.50005H8.5C8.22386 3.50005 8 3.72391 8 4.00005C8 4.2762 8.22386 4.50005 8.5 4.50005H9.5V7.00005C9.5 8.38077 8.38071 9.50005 7 9.50005C5.61929 9.50005 4.5 8.38077 4.5 7.00005V4.50005H5.5C5.77614 4.50005 6 4.2762 6 4.00005C6 3.72391 5.77614 3.50005 5.5 3.50005H4C3.72386 3.50005 3.5 3.72391 3.5 4.00005V7.00005C3.50204 8.73835 4.77954 10.2117 6.5 10.4601C6.565 11.6101 6.89 12.4601 7.47 12.9601C7.92245 13.3381 8.50119 13.531 9.09 13.5001C9.2739 13.5002 9.45761 13.4885 9.64 13.4651C12.47 13.0551 14 9.82505 14 7.00005C14 3.13406 10.866 5.38734e-05 7 5.38734e-05Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.87018 10.1099C2.07307 10.0403 2.2857 10.0032 2.50018 9.99993C3.4463 9.9951 4.26636 10.6539 4.46557 11.5788C4.66477 12.5037 4.18862 13.4417 3.32441 13.8268C2.46019 14.2118 1.44438 13.9387 0.889883 13.172C0.335387 12.4054 0.393928 11.3552 1.03018 10.6549C-0.630031 7.94167 -0.252124 4.45014 1.95018 2.15493C2.06952 2.01031 2.25974 1.94461 2.4429 1.98476C2.62605 2.0249 2.77135 2.16415 2.81925 2.34543C2.86715 2.52671 2.8096 2.71955 2.67018 2.84493C0.789123 4.80603 0.460921 7.78652 1.87018 10.1099ZM1.50018 11.9999C1.50018 12.5522 1.9479 12.9999 2.50018 12.9999C3.05247 12.9999 3.50018 12.5522 3.50018 11.9999C3.50018 11.4476 3.05247 10.9999 2.50018 10.9999C1.9479 10.9999 1.50018 11.4476 1.50018 11.9999Z'
      fill={fill}
    />
  </svg>
);
