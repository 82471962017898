import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { NavBar } from 'modules/admin/dashboard';
import { Creators } from '../reducer';
import WorkoutForm from './WorkoutForm';
import { HOME_URL } from '../';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const CreateNewWorkout = ({
  addedExercises,
  classes,
  createWorkout,
  draft,
  playlist,
  processing,
}) => {
  const title = 'Create New Workout';
  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={HOME_URL} />
      <div className={classes.content}>
        <WorkoutForm
          draft={draft}
          initialAddedExercises={addedExercises}
          initialPlaylist={playlist}
          onSubmit={createWorkout}
          pageTitle={title}
          processing={processing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  workoutCreator: { addedExercises, draft, playlist, processing },
}) => ({
  addedExercises,
  draft,
  playlist,
  processing,
});

export default connect(mapStateToProps, {
  createWorkout: Creators.createWorkout,
})(withStyles(styles)(CreateNewWorkout));
