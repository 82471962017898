import React from 'react';

export default ({ fill }) => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15 0H4C1.79086 0 0 1.79086 0 4V15C0 17.2091 1.79086 19 4 19H15C17.2091 19 19 17.2091 19 15V4C19 1.79086 17.2091 0 15 0Z'
      fill='#F7F8FA'
    />
    <path
      d='M10.7778 9.35582C10.7778 10.1413 10.141 10.778 9.35557 10.778C8.5701 10.778 7.93334 10.1413 7.93334 9.35582C7.93334 8.57034 8.5701 7.93359 9.35557 7.93359C10.141 7.93359 10.7778 8.57034 10.7778 9.35582Z'
      fill={fill}
    />
    <path
      d='M15.7556 9.35582C15.7556 10.1413 15.1188 10.778 14.3333 10.778C13.5479 10.778 12.9111 10.1413 12.9111 9.35582C12.9111 8.57034 13.5479 7.93359 14.3333 7.93359C15.1188 7.93359 15.7556 8.57034 15.7556 9.35582Z'
      fill={fill}
    />
    <path
      d='M5.80001 9.35582C5.80001 10.1413 5.16326 10.778 4.37779 10.778C3.59232 10.778 2.95557 10.1413 2.95557 9.35582C2.95557 8.57034 3.59232 7.93359 4.37779 7.93359C5.16326 7.93359 5.80001 8.57034 5.80001 9.35582Z'
      fill={fill}
    />
  </svg>
);
