import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { Link } from 'react-router-dom';
import { Button, Form, FormRow, Text } from 'components';
import { Creators } from '../reducer';

const styles = (theme) => ({
  button: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

const EmailSentForm = ({ classes, error, requestReset }) => {
  const FormBody = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form
        title='Email Has Been Sent'
        renderHeader={() => (
          <>
            <FormRow>
              <Text>
                Thank You. If your account is associated with this email address
                you will receive an email to reset your password. Check your
                inbox, spam, and promotions folders
              </Text>
            </FormRow>
          </>
        )}
      >
        <FormRow>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <div className={classes.button}>
              <Button title='Ok' type='submit' />
            </div>
          </Link>
        </FormRow>
      </Form>
    );
  };

  return (
    <Formik>
      <FormBody />
    </Formik>
  );
};

const mapStateToProps = ({ auth: { error } }) => ({
  error,
});

const mapDispatchToProps = { requestReset: Creators.requestReset };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmailSentForm));
