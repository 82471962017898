import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';

import { Button, Link } from 'components';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'space-between',
    padding: '0px 16px',
  },
  switch: {
    display: 'flex',
  },
  switchOption: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 16,
    fontWeight: 'bold',
    justifyContent: 'center',
    height: 45,
    width: 220,
  },
  switchOptionInactive: {
    backgroundColor: theme.palette.secondary.inactive,
    color: theme.palette.secondary.green,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
});

const TopBar = ({
  activeSwitchOption,
  classes,
  onLeftSwitchClick,
  onRightSwitchClick,
}) => {
  const Switch = () => (
    <div className={classes.switch}>
      <div
        onClick={onLeftSwitchClick}
        className={cx(
          classes.switchOption,
          activeSwitchOption === 'parent' ? classes.switchOptionInactive : ''
        )}
      >
        Child Plans
      </div>
      <div
        onClick={onRightSwitchClick}
        className={cx(
          classes.switchOption,
          activeSwitchOption === 'child' ? classes.switchOptionInactive : ''
        )}
      >
        Parent Plans
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <div>
        <Switch />
      </div>
      <div>
        {activeSwitchOption === 'child' ? (
          <Link to='plan/child/create'>
            <Button title='create new child plan' />
          </Link>
        ) : (
          <Link to='plan/parent/create'>
            <Button title='create new parent plan' />
          </Link>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(TopBar);
