import React from 'react';
import WorkOutListNew from './WorkOutListNew';
import { Helmet } from 'react-helmet';

export default () => (

  
  <>
  
    <Helmet>
      <title>Programs</title>
    </Helmet>
    <WorkOutListNew
      title='Programs'
      workoutKey='pain-and-injury'
      workoutType='painAndInjury'
      
    />
  </>
);
