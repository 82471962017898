import React from 'react';

export default ({ fill }) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.0546 0.333496H11.8909H2.10916H1.94552C1.49986 0.385911 1.08871 0.606367 0.791127 0.95248C0.493541 1.29859 0.330523 1.74593 0.333412 2.2085V8.4585C0.333412 8.95578 0.524969 9.43269 0.865942 9.78432C1.20692 10.136 1.66937 10.3335 2.15158 10.3335H11.8485C12.3307 10.3335 12.7932 10.136 13.1341 9.78432C13.4751 9.43269 13.6667 8.95578 13.6667 8.4585V2.2085C13.6696 1.74593 13.5065 1.29859 13.209 0.95248C12.9114 0.606367 12.5002 0.385911 12.0546 0.333496ZM11.7758 1.5835L7.6061 5.8835C7.49255 5.9999 7.33894 6.06524 7.17883 6.06524C7.01872 6.06524 6.86511 5.9999 6.75156 5.8835L2.58188 1.5835H11.7758ZM12.4546 8.4585C12.4546 8.62426 12.3907 8.78323 12.277 8.90044C12.1634 9.01765 12.0092 9.0835 11.8485 9.0835H2.15158C1.99085 9.0835 1.83669 9.01765 1.72304 8.90044C1.60938 8.78323 1.54553 8.62426 1.54553 8.4585V2.46475L5.7152 6.76475C6.05611 7.11587 6.51822 7.3131 7.00004 7.3131C7.48186 7.3131 7.94397 7.11587 8.28488 6.76475L12.4546 2.46475V8.4585Z'
      fill={fill}
    />
  </svg>
);
