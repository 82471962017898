import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Footer from './Footer';
import Header from './Header';

import pattern from 'assets/svg/pattern.svg';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    background: `url(${pattern}), ${theme.palette.primary.main}`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      paddingTop: 0,
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0px',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      margin: '16px',
      height: '100%',
      width: 'calc(100vw - 32px)',
    },
  },
  pattern: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '100vh',
    left: 0,
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
});

const Layout = ({ children, classes, contentClass, useLayout = true }) => (
  <>
    {useLayout ? (
      <div className={classes.container}>
        <Header />
        <div className={cx(classes.content, contentClass)}>
          <div>{children}</div>
        </div>
        <Footer />
      </div>
    ) : (
      <div>{children}</div>
    )}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  contentClass: PropTypes.string,
};

export default withStyles(styles)(Layout);
