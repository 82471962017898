import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';

const INITIAL_STATE = {
  tasks: [],
  isDirty: false,
  processing: false,
  activeTask: 0,
};

// Action creators
const { Types, Creators } = createActions(
  {
    updateUI: ['ui'],
    setActiveTask: ['taskIndex'],
    getTasksFail: ['error'],
    getTasksSuccess: ['response'],
    getTasks: () => ({
      type: 'assessment/GET_TASKS',
      payload: {
        request: {
          url: '/assessment-task-management',
          method: 'get',
        },
      },
    }),
    createTaskFail: ['error'],
    createTaskSuccess: ['response'],
    createTask: (task) => ({
      type: 'assessment/CREATE_TASK',
      payload: {
        request: {
          url: '/assessment-task-management',
          method: 'post',
          data: task,
        },
      },
    }),
    updateTaskSuccess: ['response'],
    updateTaskFail: ['error'],
    updateTask: ({ id, task }) => ({
      type: 'assessment/UPDATE_TASK',
      payload: {
        request: {
          url: `/assessment-task-management/${id}`,
          method: 'patch',
          data: task,
        },
      },
    }),
    deleteTaskSuccess: ['response'],
    deleteTaskFail: ['error'],
    deleteTask: (id) => ({
      type: 'assessment/DELETE_TASK',
      payload: {
        request: {
          url: `/assessment-task-management/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
  },
  { prefix: 'assessment/' }
);

// Reducers
const getTasks = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getTasksSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  console.log("GANDAGAA")
  return {
    ...state,
    tasks: data,
    processing: false,
  };
};
const setActiveTask = (state = INITIAL_STATE, { taskIndex }) => ({
  ...state,
  activeTask: taskIndex,
});

const createTask = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createTaskSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    tasks: [...state.tasks, { ...data }],
  };
};

const createTaskFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updateTask = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateTaskSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    tasks: state.tasks.map((e) => {
      if (e.id === data.id) {
        return {
          ...data,
        };
      }
      return e;
    }),
  };
};

const updateTaskFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const deleteTaskSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    tasks: state.tasks.filter((e) => e.id !== id),
  };
};

const deleteTaskFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TASKS]: getTasks,
  [Types.GET_TASKS_SUCCESS]: getTasksSuccess,
  [Types.SET_ACTIVE_TASK]: setActiveTask,
  [Types.CREATE_TASK_SUCCESS]: createTaskSuccess,
  [Types.CREATE_TASK_FAIL]: createTaskFail,
  [Types.CREATE_TASK]: createTask,
  [Types.UPDATE_TASK_SUCCESS]: updateTaskSuccess,
  [Types.UPDATE_TASK_FAIL]: updateTaskFail,
  [Types.UPDATE_TASK]: updateTask,
  [Types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [Types.DELETE_TASK_FAIL]: deleteTaskFail,
});

export { Creators, Types, Reducer };
