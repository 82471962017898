import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push, go } from 'connected-react-router';

import { parseError, removeTokenData, setTokenData } from 'core';
import { Creators, Types } from './reducer';

export function* passwordReset() {
  yield put(push('/auth/signin'));
  yield call(toastr.success, 'Info', 'You changed your password successfully.');
}

export function* userUpdated() {
  yield call(toastr.success, 'Info', 'Changes saved.');
}

export function* resetRequested() {
  yield put(push('/auth/email-sent'));
}

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* updateUserFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}
export function* signedIn(action) {
  const state = yield select();
  const { persona, rememberMe } = state.auth;
  yield call(setTokenData, action.payload.data.authToken, rememberMe);
  const redirectTo = persona === 'admin' ? '/admin/dashboard' : '/dashboard';
  yield put(Creators.me());

  yield put(push(redirectTo));
}

export function* signedInWithFb(action) {
  yield call(setTokenData, action.payload.data.user.authToken, true);
  yield put(Creators.me());
  yield put(push('/dashboard'));
}

export function* signedUp(action) {
  yield call(setTokenData, action.payload.data.authToken, true);
  const state = yield select();

  if (state.auth.planId) {
    yield put(push('/dashboard/payment/choose-plan'));
  } else {
    // yield put(push('/dashboard/payment/choose-plan'));
    yield put(push('/dashboard/home/welcome'));
  }
}

export function* signOut() {
  yield call(removeTokenData);
  const {
    auth: { persona },
  } = yield select();
  const prefix = persona === 'admin' ? '/admin' : '';
  const redirectTo = `${prefix}/auth/signin`;
  yield put(push(redirectTo));
  yield put(go(0));
}

export function* deactivate() {
  yield call(removeTokenData);
  yield call(toastr.success, 'Good bye', 'Your account is deactivated.');
}

export function* sendInvite() {
  yield call(toastr.success, 'Info', 'Your invite has been sent.');
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.REQUEST_RESET_SUCCESS, resetRequested),
    takeEvery(Types.UPDATE_ME_SUCCESS, userUpdated),
    takeEvery(Types.DEACTIVATE_ME_SUCCESS, deactivate),
    takeEvery(Types.RESET_PASSWORD_SUCCESS, passwordReset),
    takeEvery(Types.RESET_PASSWORD_SUCCESS, passwordReset),
    takeEvery(Types.SIGN_IN_SUCCESS, signedIn),
    takeEvery(Types.SIGN_OUT, signOut),
    takeEvery(Types.SIGN_UP_SUCCESS, signedUp),
    takeEvery(Types.SIGN_IN_WITH_FB_SUCCESS, signedInWithFb),
    // Consider pattern matching against '..._FAIL' to make this more generic.
    takeEvery(Types.SIGN_IN_WITH_FB_FAIL, requestFailed),
    //  takeEvery(Types.UPDATE_ME_FAIL, updateUserFailed),
    takeEvery(Types.DEACTIVATE_ME_FAIL, requestFailed),
    takeEvery(Types.UPDATE_PASSWORD_FAIL, requestFailed),
    takeEvery(Types.SEND_INVITE_SUCCESS, sendInvite),
  ]);
}
