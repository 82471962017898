import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { NavBar } from 'modules/admin/dashboard';
import { Creators } from '../reducer';
import ExerciseForm from './ExerciseForm';

import { HOME_URL } from '../';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const CreateNewExercise = ({ classes, createExercise, processing }) => {
  const title = 'Create New Exercise';
  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={HOME_URL} />
      <div className={classes.content}>
        <ExerciseForm
          onSubmit={createExercise}
          pageTitle={title}
          processing={processing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ exercise: { processing } }) => ({
  processing,
});

export default connect(mapStateToProps, {
  createExercise: Creators.createExercise,
})(withStyles(styles)(CreateNewExercise));
