import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

import Layout from './Layout';

import barbellImg from 'assets/svg/barbell.svg';
import subscription from 'assets/svg/subscription.svg';
import chevronRightImg from 'assets/svg/chevron-right.svg';
import graph from 'assets/svg/graph.svg';
import money from 'assets/svg/money-bag.svg';

const MENU = [
  {
    image: barbellImg,
    path: 'profile/edit',
    title: 'Edit Profile',
  },
  {
    image: subscription,
    path: 'profile/subscription',
    title: 'Manage Subscriptions',
  },
  {
    image: graph,
    path: 'profile/stats',
    title: 'My Stats',
  },
  {
    image: money,
    path: 'profile/credits',
    title: 'My Credits',
  },
];

const styles = ({ breakpoints, palette: { primary } }) => ({
  overlay: {
    backdropFilter: 'blur(3px)',
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: '-2rem',
    width: '100%',
    [breakpoints.down('xs')]: {
      minHeight: 'calc(90vh - 50px)',
      left: 0,
      width: '100%',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    [breakpoints.down('xs')]: {
      minHeight: 'calc(90vh - 50px)',
      width: '100%',
      left: 0,
    },
  },
  menuContainer: {
    [breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  menuItem: {
    background: primary.contrastText,
    borderRadius: 6,
    color: primary.generalText,
    display: 'flex',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
    padding: '16px 32px',
    minWidth: 'min(460px, 100%)',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  menuItemBody: {
    '& > img': {
      marginLeft: '1rem',
      height: 20,
      width: 20,
    },
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  menuItemIcon: {
    alignItems: 'center',
    background: primary.main,
    borderRadius: 24,
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    width: 48,
    minWidth: 48,
    marginRight: '1rem',
  },
  title: {
    color: primary.contrastText,
    fontSize: 32,
    fontWeight: 'bold',
    marginBottom: 24,
    [breakpoints.down('xs')]: {
      width: 'auto',
      alignSelf: 'flex-start',
      paddingLeft: '10%',
    },
  },
});

const Home = ({ classes }) => {
  const MenuItem = ({ data: { image, path, title } }) => (
    <NavLink exact to={`${path}`} style={{ textDecoration: 'none' }}>
      <div className={classes.menuItem}>
        <div className={classes.menuItemIcon}>
          <img src={image} alt={title} />
        </div>
        <div className={classes.menuItemBody}>
          <div>{title}</div>
          <img src={chevronRightImg} alt='arrow right' />
        </div>
      </div>
    </NavLink>
  );

  return (
    <Layout pageTitle='Profile'>
      <div className={classes.overlay} />
      <div className={classes.container}>
        <div className={classes.title}>Profile</div>
        <div className={classes.menuContainer}>
          {MENU.map((menuItem) => (
            <MenuItem key={menuItem.path} data={menuItem} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default withStyles(styles)(Home);
