import React from 'react';

export default ({ fill, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={fill}
      d='M17.967 11.934c0-2.387-1.326-4.376-3.315-5.304v2.917l3.315 3.315v-.928zm3.315 0c0 1.193-.265 2.386-.663 3.447l1.989 1.99c.928-1.592 1.326-3.581 1.326-5.57 0-5.702-3.978-10.475-9.282-11.668v2.784c3.845 1.326 6.63 4.774 6.63 9.017zM1.79 0L.066 1.724l6.232 6.232H.066v7.956H5.37l6.63 6.63v-8.885l5.702 5.702c-.929.663-1.857 1.193-3.05 1.591v2.785c1.856-.398 3.447-1.194 4.906-2.387L22.21 24l1.724-1.724L12 10.343 1.79 0zM12 1.326L9.215 4.11 12 6.895V1.326z'
    />
  </svg>
);
