import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { number, object, string } from 'yup';
import { goBack } from 'connected-react-router';
import { Button, Form, FormInput, FormRow, FormSelect } from 'components';
import { Creators } from '../../members';
import { push } from 'connected-react-router';


const styles = (theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    width: '50%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '106vh',
    justifyContent: 'center',
    position: 'relative',
  },
  form: {
    padding: `40px 32px`,
  },
  header: {
    alignItems: 'center',
    borderBottom: '1px solid #DFE2E6',
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  input: {
    // width: 'calc(50% - 8px)',
    width: '302px',
  },
  secondInput: {
    width: '620px',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    left: 16,
    position: 'absolute',
  },
  labelXl: {
    color: theme.palette.primary.dark,
    fontWeight: 900,
    fontSize: 21,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  button: {
    width: 302,
    marginTop: 10,
    textTransform: 'capitalize',
  },
});

const EditTeamAccountsForm = ({
  classes,
  error,
  id,
  member,
  processing,
  navigateBack,
  updateCompanyAdmin,
  goTo
}) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);



    return (
      <Form containerClass={classes.form} showDivider={false}>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='First Name'
                name='firstName'
                placeholder='First Name'
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.secondInput}>
              <FormInput
                error={error}
                label='Company Name'
                name='companyName'
                placeholder='Company Name'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.secondInput}>
              <FormInput
                error={error}
                label='Email address'
                name='email'
                placeholder='Email address'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Team Account Price'
                name='teamAccountPrice'
                placeholder='Team Account Price'
                type='number'
              />
            </div>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Number of Logins Included'
                name='teamMembersCount'
                placeholder='Number of Logins Included'
                type='number'
              />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <div className={classes.inputRow}>
            <div className={classes.input}>
              <FormInput
                error={error}
                label='Subscription Length'
                name='subscriptionLength'
                placeholder='Subscription Length'
                type='number'
              />
            </div>
            <div className={classes.input} style={{ marginTop: 2 }}>
                    <FormSelect
                      height={50}
                      width='100%'
                      name='subscriptionInterval'
                      options={[
                        {
                          label: 'Months',
                          value: 'month',
                        },
                        {
                          label: 'Weeks',
                          value: 'week',
                        },
                        {
                          label: 'Years',
                          value: 'year',
                        },
                      ]}
                    />
                  </div>
          </div>
        </FormRow>
        <FormRow>
          <a href={`/admin/dashboard/membership/team-accounts-dashboard/${member?.id}`}>
          <Button className={classes.button} title='Acces Team Dashboard' />
          </a>

        </FormRow>
        <FormRow>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button onClick={() => navigateBack()} title='Cancel' outlined />
            </div>
            <div style={{ marginLeft: '16px' }} className={classes.input}>
              <Button
                disabled={!isValid}
                processing={processing}
                processingLabel='saving...'
                title='save'
                type='submit'
                // onClick={console.log("ASD")}
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    subscriptionInterval : string().required('Interval is required'),
    email: string().email().required('Email is required'),
    companyName: string().required('company name is required'),
    teamAccountPrice: number()
      .min(0)
      .required('team account price is required'),
    teamMembersCount: number().min(0).required('number of logins is required'),
    subscriptionLength: number().min(0).required('subscrption length is required'),
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div onClick={navigateBack} className={classes.link}>{`<< Back`}</div>
        <div className={classes.labelXl}>Edit Team Account</div>
      </div>
      <Formik
        initialValues={{
          firstName: member?.firstName,
          lastName: member?.lastName,
          email: member?.email,
          role: member?.role,
          status: member?.status,
          companyName: member?.companyName,
          teamAccountPrice: member?.teamAccountPrice / 100,
          teamMembersCount: member?.teamMembersCount,
          subscriptionLength: member?.subscriptionLength,
          subscriptionInterval : member?.subscriptionInterval,
          role: 'user',
          // status: 'freemium',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          console.log("VAAA")
          updateCompanyAdmin({ id, user: {
            ...values,
              teamAccountPrice: values.teamAccountPrice * 100
          } });
        }}
        // onSubmit={(values) => console.log(111, values)}//updateUser({ id, user: values })}

      >
        
        <FormBody />
      </Formik>
    </div>
  );
};

const mapStateToProps = (
  { membership: { error, users, processing } },
  {
    match: {
      params: { id },
    },
  }
) => ({
  error,
  processing,
  member: users.find((user) => user.id === id),
  id,
});

const mapDispatchToProps = {
  navigateBack: goBack,
  updateCompanyAdmin: Creators.updateCompanyAdmin,
  goTo: push,

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditTeamAccountsForm));
