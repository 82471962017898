import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import cx from 'classnames';

import FormError from './FormError';

const styles = ({ breakpoints, palette, spacing }) => ({
  container: {
    width: '100%',
  },
  content: {
    borderRadius: 2,
    '& > input, & > textarea': {
      border: `1px solid ${palette.primary.gray}`,
      boxSizing: 'border-box',
      padding: spacing(2),
      width: '100%',
    },
    '& > input:focus, & > textarea:focus': {
      outline: `2px solid ${palette.primary.main}`,
      border: 'none',
    },
  },
  label: {
    color: palette.primary.generalText,
    fontSize: 14,
    marginBottom: spacing(0.5),
    textTransform: 'capitalize',
  },
  labelError: {
    color: palette.messages.error,
  },
  inputStyle: {
    [breakpoints.down('xs')]: {
      // Font size less than this will cause iOS default zoom in when input is focused
      fontSize: 16,
    },
  },
});

export const FormInput = ({
  classes,
  error,
  label,
  name,
  placeholder,
  rows = 1,
  type,
}) => {
  const [field, meta] = useField(name);
  const hasError = (error && error[name]) || (meta.error && meta.touched);
  const errorMessage = (error && error[name]) || meta.error;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const value = field.value;
  return (
    <div className={classes.container}>
      <div className={cx(classes.label, hasError ? classes.labelError : '')}>
        {label}
      </div>
      <div className={classes.content}>
        {type === 'currency' ? (
          <NumberFormat
            thousandSeparator={true}
            prefix={'$'}
            value={value}
            onBlur={() => {
              setFieldTouched(field.name, true);
            }}
            onValueChange={({ formattedValue, value }) => {
              setFieldValue(field.name, value);
            }}
            renderText={(value) => (
              <input {...field} placeholder={placeholder} />
            )}
          />
        ) : rows === 1 ? (
          <input
            {...field}
            placeholder={placeholder}
            type={type}
            autoCorrect='off'
            value={value || ''}
            className={classes.inputStyle}
          />
        ) : (
          <textarea
            rows={rows}
            {...field}
            placeholder={placeholder}
            type={type}
            className={classes.inputStyle}
          />
        )}

        {hasError && <FormError>{errorMessage}</FormError>}
      </div>
    </div>
  );
};

FormInput.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
};

export default withStyles(styles)(FormInput);
