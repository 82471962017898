import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
} from 'react';
import { withStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import { toTime } from 'core';
import { Button } from 'components';
import { Favorite } from 'modules/dashboard';
import MuteIcon from 'components/icons/Mute';
import NextIcon from 'components/icons/Next';
import PrevIcon from 'components/icons/Prev';
import SettingsIcon from 'components/icons/Settings';
import CheckMarkIcon from 'components/icons/CheckMark';
import Popover from '@material-ui/core/Popover';

import fullScreenImg from 'assets/svg/full-screen.svg';
import pauseImg from 'assets/svg/pause.svg';
import playImg from 'assets/svg/play.svg';
import closeImg from 'assets/svg/close.svg';
import videoPreviewImage from 'assets/img/background.png';
import { useMediaQuery } from '@material-ui/core';
import usePrevious from 'core/hooks/usePrevious';
import isUndefined from 'lodash/isUndefined';
import { useRecoilState } from 'recoil';
import { mixpanelAtoms } from 'core/utils/mixpanel';
import NextButton from './NextButton';

const styles = ({
  breakpoints,
  palette: { background, primary, secondary },
}) => ({
  progressContainer: {
    position: 'relative',
    zIndex: 10,
  },
  onScreenInfo: {
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 24,
    left: 20,
    zIndex: 44,
    background: '#0005',
    borderRadius: '3rem',
    [breakpoints.down('md')]: {
      top: 12,
      left: 12,
    },
  },
  circularProgressContainer: {
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0.5rem',
      left: '0.5rem',
      width: '24px',
      height: '24px',
      borderRadius: '3rem',
      border: 'solid 4px #0003',
    },
  },
  onScreenTitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    zIndex: 46,
    padding: '0 1rem',
    width: 'fit-content',
    height: 40,
  },
  comingNext: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: primary.contrastText,
    fontSize: 48,
    fontWeight: 900,
    height: '100%',
    letterSpacing: 0.8,
    padding: 32,
    position: 'fixed',
    textTransform: 'uppercase',
    width: '100%',
    zIndex: 50,
  },
  container: {
    background: background.default,
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    '& > div': {
      alignItems: 'center',
      display: 'flex',
      fontWeight: 'bold',
      letterSpacing: 0.4,
      gap: '1rem',
      [breakpoints.down('md')]: {
        gap: '0.3rem',
      },
    },
    '& > div:nth-child(1) > *': {
      marginRight: '1rem',
    },
    '& > div:nth-child(2) > *': {
      marginLeft: '1rem',
    },
    '& img, svg': {
      cursor: 'pointer',
    },
    alignItems: 'center',
    background: '#f7f7f7',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 32px',
    transition: 'opacity .15s',
    [breakpoints.down('md')]: {
      padding: 16,
    },
  },
  controlsFullScreen: {
    '& > div': {
      fontSize: 20,
    },
    '& img, svg': {
      height: 20,
      width: 20,
    },
    background: '#f7f7f7',
  },
  controlsHiddenAfterTimeout: {
    opacity: 0,
    pointerEvents: 'none',
  },
  countdown: {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.6)',
    color: primary.contrastText,
    display: 'flex',
    fontSize: 150,
    fontWeight: 900,
    height: '100%',
    justifyContent: 'center',
    position: 'fixed',
    width: 800,
    zIndex: 45,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  fullScreenIcons: {
    display: 'flex',
    position: 'absolute',
    top: 32,
    right: 32,
    zIndex: 50,
    gap: '0.5rem',
    alignItems: 'flex-start',
    '& img': {
      cursor: 'pointer',
      marginLeft: 24,
      padding: 8,
      height: '2.5rem !important',
      width: '2.5rem !important',
    },
    '& img:last-child': {
      height: 20,
      width: 20,
    },
  },
  navigationFullScreen: {
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  popover: {
    marginBottom: 12,
    zIndex: 100000,
  },
  progress: {
    '& > progress[value]': {
      border: 'none',
      height: 0.01,
      width: '100%',
      background: '#f7f7f7',
      top: -7,
      position: 'relative',
    },
    '& > progress[value]::-moz-progress-bar': {
      backgroundColor: secondary.main,
    },
    '& > progress[value]::-webkit-progress-bar': {
      backgroundColor: '#e5e5e5',
    },
    '& > progress[value]::-webkit-progress-value': {
      backgroundColor: secondary.main,
    },
    cursor: 'pointer',
    height: 10,
    transition: 'opacity .15s',
    '&::before': {
      background: '#e5e5e5',
      width: '100%',
      display: 'block',
      content: '""',
      height: 10,
      position: 'absolute',
      top: 0,
      zIndex: 2,
    },
    '&::after': {
      background: secondary.main,
      width: 'var(--progress-percentage)',
      display: 'block',
      content: '""',
      height: 10,
      position: 'absolute',
      top: 0,
      zIndex: 3,
      transition: 'width .15s',
    },
  },
  remainingTime: {
    position: 'relative',
    paddingRight: '1rem',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    zIndex: 46,
  },
  progressFullScreen: {
    height: 10,
  },
  setting: {
    alignItems: 'center',
    color: primary.darkGray,
    cursor: 'pointer',
    display: 'flex',
    marginTop: 24,
    '& svg': {
      marginRight: 12,
    },
  },
  settings: {
    padding: 24,
    width: 200,
    zIndex: 50,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.3,
  },
  video: {
    objectFit: 'contain',
    height: 450,
    width: '100%',
    backgroundColor: 'black',
    position: 'relative',
    zIndex: 9,
    [breakpoints.down('xs')]: {
      height: 200,
    },
  },
  videoFullScreen: {
    height: '100vh',
    width: '100vw',
    maxHeight: '100vh',
  },
  hidden: {
    height: 0,
    width: 0,
    opacity: 0,
    pointerEvent: 'none',
  },
  nextButtonContainer: {
    position: 'absolute',
    top: 140,
    right: 10,
    zIndex: 45,
  },
});

const CircularProgress = forwardRef(({ className }, ref) => {
  const [percentageCompleted, setPercentageCompleted] = useState(100);

  // Use this to avoid unneeded re-rendering.
  useImperativeHandle(ref, () => ({
    setValue: (value) => setPercentageCompleted(value),
  }));

  return (
    <div className={className} style={{ position: 'relative' }}>
      <MuiCircularProgress
        color='#fff'
        size={32}
        thickness={5}
        value={percentageCompleted}
        variant='static'
      />
    </div>
  );
});

const VideoPlayer = ({
  classes,
  isFavorite,
  isMulti = false,
  isProcessingFavorite,
  onAddFavorite,
  onDeleteFavorite,
  onDurationUpdated,
  videoToPlay,
  onFullscreenStatusChange,
  workoutDuration,
  workoutDurationVisited,
  onWatched90PercentOfWorkout,
}) => {
  const {
    duration,
    exerciseId,
    exerciseType = 'rep-based',
    instructions = '',
    isLastItem,
    isPrevDisabled,
    isProgressBarHidden = false,
    isTimeVisible = false,
    markExerciseCompleted = () => {},
    nextTrack,
    onWorkoutCompleted,
    onWatched90PercentOfExercise,
    prevTrack,
    resolution,
    resolutionSettings,
    src,
    title,
    videoDuration,
    hasNextButton = true,
  } = videoToPlay;

  const {
    palette: { primary },
    breakpoints,
  } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  const [isPaused, setIsPaused] = useState(isMulti);
  const [isVolumeOn, setIsVolumeOn] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isCountdownVisible, setIsCountdownVisible] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    isProgressBarHiddenAfterTimeout,
    setIsProgressBarHiddenAfterTimeout,
  ] = useState(false);

  const circularProgressRef = useRef(null);
  const completedLoopsRef = useRef(0);
  const shouldVideoLoop = useRef(false);
  const progressBarRef = useRef(null);
  const progressRef = useRef(null);
  const remainingTimeRef = useRef('');
  const screenTimeRef = useRef({
    duration: 0,
    lastTimestamp: new Date().getTime(),
  });
  const timeLabelRef = useRef(null);
  const timeoutRef = useRef(null);
  const videoContainerRef = useRef(null);
  const videoRef = useRef(null);
  const prevResolution = usePrevious(resolution);
  const onWatched90PercentOfExerciseEmitted = useRef(false);
  const onWatched90PercentOfWorkoutEmitted = useRef(false);
  const [
    // eslint-disable-next-line no-unused-vars
    workoutPercentageCompleted,
    setWorkoutPercentageCompleted,
  ] = useRecoilState(mixpanelAtoms.currentWorkoutPercentageCompleted);

  useEffect(() => {
    if (!isFullScreen) {
      setIsProgressBarHiddenAfterTimeout(false);
      return;
    }

    const timeout = setTimeout(() => {
      if (!isProgressBarHiddenAfterTimeout) {
        setIsProgressBarHiddenAfterTimeout(true);
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isFullScreen, isProgressBarHiddenAfterTimeout]);

  useEffect(() => {
    onFullscreenStatusChange(isFullScreen);
  }, [isFullScreen, onFullscreenStatusChange]);

  const supportsVideo = !!document.createElement('video').canPlayType;

  const fullScreen = () => {
    return !!(
      document.fullScreen ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    );
  };

  const play = useCallback(() => {
    if (document.querySelector('video').paused) {
      videoRef.current.play();
    }
  }, [videoRef]);

  const pause = () => {
    if (!document.querySelector('video').paused) {
      videoRef.current.pause();
    }
  };

  const replay = useCallback(() => {
    if (videoRef?.current) {
      videoRef.current.currentTime = 0;
      play();
    }
    if (completedLoopsRef.current > 0) {
      setIsVolumeOn(false);
      videoRef.current.muted = true;
    }
  }, [play, videoRef]);

  const loadNextVideo = useCallback(() => {
    if (isLastItem) {
      onWorkoutCompleted();
    } else {
      nextTrack();
      setIsPreviewMode(true);

      timeoutRef.current = setTimeout(() => {
        setIsPreviewMode(false);
        replay();
      }, 8000);
    }
  }, [isLastItem, nextTrack, replay, onWorkoutCompleted]);

  const handleNextClick = () => {
    if (exerciseType === 'rep-based') {
      markExerciseCompleted(exerciseId);
    }
    loadNextVideo();
    //    videoRef.current.muted = !isVolumeOn;
  };

  const completeExercise = useCallback(() => {
    // Only clicking on the next button/icon
    // can complete a time-based exercise.
    if (exerciseType === 'rep-based') return;

    completedLoopsRef.current = 0;
    loadNextVideo();
  }, [exerciseType, loadNextVideo]);

  const isExerciseCompleted = useCallback(
    (currentTime, percentageNeeded = 1) => {
      // Only clicking on the next button/icon
      // can complete a time-based exercise.
      if (exerciseType === 'rep-based') return false;

      return (
        Math.floor(videoDuration * completedLoopsRef?.current + currentTime) >=
        Math.floor(percentageNeeded * duration)
      );
    },
    [exerciseType, duration, videoDuration]
  );

  const canMarkAsCompleted = useCallback(
    (currentTime) => {
      return isExerciseCompleted(currentTime, 0.9);
    },
    [isExerciseCompleted]
  );

  const handleVideoEnded = useCallback(() => {
    if (canMarkAsCompleted(videoDuration)) {
      markExerciseCompleted(exerciseId);
    }

    if (isExerciseCompleted(videoDuration)) {
      completeExercise();
    } else {
      completedLoopsRef.current = completedLoopsRef.current + 1;
      shouldVideoLoop.current = true;
      replay();
    }
  }, [
    canMarkAsCompleted,
    completeExercise,
    exerciseId,
    isExerciseCompleted,
    markExerciseCompleted,
    replay,
    videoDuration,
  ]);

  const handleTimeUpdated = useCallback(
    (currentTime) => {
      if (!isPaused && !isPreviewMode && !isCountdownVisible) {
        const currentTimestamp = new Date().getTime();
        if (currentTimestamp - screenTimeRef.current.lastTimestamp > 1000) {
          const newDuration = screenTimeRef.current.duration + 1;
          screenTimeRef.current = {
            duration: newDuration,
            lastTimestamp: currentTimestamp,
          };

          onDurationUpdated(newDuration);
        }
      }

      if (canMarkAsCompleted(currentTime)) {
        markExerciseCompleted(exerciseId);
      }

      if (isExerciseCompleted(currentTime)) {
        completeExercise();
      }
    },
    [
      canMarkAsCompleted,
      completeExercise,
      exerciseId,
      isExerciseCompleted,
      isPaused,
      isPreviewMode,
      isCountdownVisible,
      markExerciseCompleted,
      onDurationUpdated,
    ]
  );

  // As the video is playing, update the progress bar
  const handleTimeUpdate = useCallback(() => {
    const video = videoRef.current;
    const progress = progressRef.current;
    const progressBar = progressBarRef.current;

    // For mobile browsers, ensure that the progress element's max attribute is set
    if (!progress.getAttribute('max'))
      progress.setAttribute('max', video.duration);

    progress.value = video.currentTime;
    const _percentageRowValue =
      Math.floor(((video.currentTime * 100) / video.duration) * 100) / 100;
    const _percentage = _percentageRowValue + '%';

    progressBar.style.width = _percentage;
    progress.parentElement.style.setProperty(
      '--progress-percentage',
      _percentage
    );

    if (
      _percentageRowValue > 90 &&
      !onWatched90PercentOfExerciseEmitted.current
    ) {
      if (onWatched90PercentOfExercise) {
        onWatched90PercentOfExerciseEmitted.current = true;
        onWatched90PercentOfExercise();
      }
    }

    if (_percentageRowValue < 4) {
      onWatched90PercentOfExerciseEmitted.current = false;
    }

    if (video) {
      const videoRemainingDurationLabel = toTime(
        video.duration - video.currentTime
      );

      if (timeLabelRef?.current && isTimeVisible) {
        if (exerciseType === 'standard' || !isMulti) {
          timeLabelRef.current.innerText = `${videoRemainingDurationLabel}`;
        }
      }

      if (!isPreviewMode && exerciseType === 'time-based') {
        const timeRemaining =
          duration -
          videoDuration * completedLoopsRef?.current -
          videoRef?.current?.currentTime;

        const completionPercentage = Math.floor(
          (timeRemaining / duration) * 100
        );

        let totalTimeRemaining = timeRemaining;

        if (!isNaN(+workoutDuration) && !isUndefined(workoutDurationVisited)) {
          totalTimeRemaining =
            workoutDuration -
            workoutDurationVisited -
            (duration - timeRemaining);
        }

        if (remainingTimeRef?.current)
          remainingTimeRef.current.innerText = toTime(timeRemaining);

        if (circularProgressRef?.current?.setValue)
          circularProgressRef.current.setValue(completionPercentage);

        if (timeLabelRef.current)
          timeLabelRef.current.innerText = `${toTime(totalTimeRemaining)}`;
      }

      const _workoutPercentage = Math.floor(
        ((workoutDurationVisited + video.currentTime) / workoutDuration) * 100
      );
      setWorkoutPercentageCompleted(_workoutPercentage);

      if (
        _workoutPercentage > 90 &&
        !onWatched90PercentOfWorkoutEmitted.current
      ) {
        if (onWatched90PercentOfWorkout) {
          onWatched90PercentOfWorkoutEmitted.current = true;
          onWatched90PercentOfWorkout();
        }
      }

      if (_workoutPercentage < 4) {
        onWatched90PercentOfWorkoutEmitted.current = false;
      }
    }

    handleTimeUpdated(video.currentTime);
  }, [
    handleTimeUpdated,
    onWatched90PercentOfExercise,
    isTimeVisible,
    isPreviewMode,
    exerciseType,
    workoutDurationVisited,
    workoutDuration,
    setWorkoutPercentageCompleted,
    isMulti,
    duration,
    videoDuration,
    onWatched90PercentOfWorkout,
  ]);

  const handleProgressClick = (e) => {
    const progress = progressRef.current;
    if (!progress) {
      return;
    }

    const video = videoRef.current;

    const calculateOffset = (element, offset = 0) => {
      if (!element.offsetParent) {
        return element.offsetLeft;
      }

      return offset + calculateOffset(element.offsetParent, element.offsetLeft);
    };
    var rect = progressRef.current.getBoundingClientRect();
    const pos =
      (e.pageX - rect.left - calculateOffset(progress)) / progress.offsetWidth;
    video.currentTime = pos * video.duration;
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    const initialize = () => {
      if (!supportsVideo) return;

      const progress = document.getElementById('progress');
      const video = videoRef.current;

      const listenerVideo = () => {
        progress.setAttribute('max', video.duration);
      };

      const listenerDocument1 = (e) => {
        setIsFullScreen(!!(document.fullScreen || document.fullscreenElement));
      };

      const listenerDocument2 = () => {
        setIsFullScreen(!!document.webkitIsFullScreen);
      };

      const listenerDocument3 = () => {
        setIsFullScreen(!!document.mozFullScreen);
      };

      const listenerDocument4 = () => {
        setIsFullScreen(!!document.msFullscreenElement);
      };

      video.addEventListener('loadedmetadata', listenerVideo);
      document.addEventListener('fullscreenchange', listenerDocument1);
      document.addEventListener('webkitfullscreenchange', listenerDocument2);
      document.addEventListener('mozfullscreenchange', listenerDocument3);
      document.addEventListener('msfullscreenchange', listenerDocument4);

      return () => {
        video.removeEventListener('loadedmetadata', listenerVideo);
        document.removeEventListener('fullscreenchange', listenerDocument1);
        document.removeEventListener(
          'webkitfullscreenchange',
          listenerDocument2
        );
        document.removeEventListener('mozfullscreenchange', listenerDocument3);
        document.removeEventListener('msfullscreenchange', listenerDocument4);
      };
    };

    initialize();
  }, [handleTimeUpdate, supportsVideo, handleVideoEnded, videoRef]);

  const isFullScreenEnabled = () =>
    !!(
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled ||
      document.webkitSupportsFullscreen ||
      document.webkitFullscreenEnabled ||
      document.createElement('video').webkitRequestFullScreen
    );

  // Fullscreen
  const handleFullscreen = () => {
    // const video = videoRef.current;
    const videoContainer = videoContainerRef.current;
    // If fullscreen mode is active...
    if (fullScreen() || isFullScreen) {
      // ...exit fullscreen mode
      // (Note: this can only be called on document)
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen)
        document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      setIsFullScreen(false);
    } else {
      // ...otherwise enter fullscreen mode
      // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.mozRequestFullScreen) {
        videoContainer.mozRequestFullScreen();
      } else if (videoContainer.webkitRequestFullScreen) {
        // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and
        // ensures that our custom controls are visible:
        // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
        // figure[data-fullscreen=true] .controls { z-index:2147483647; }
        // --
        // Note: Disabled native fullscreen on Safari, since the custom UI overlay doesn't seem to be visible when fullscreen
        // Node: Uncommented since it caused unstable behavior on different Macbooks
        videoContainer.webkitRequestFullScreen();
      } else if (videoContainer.msRequestFullscreen) {
        videoContainer.msRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };

  useEffect(() => {
    videoRef.current.muted = !isVolumeOn;
  }, [isVolumeOn]);

  useEffect(() => {
    videoRef.current.load();
    completedLoopsRef.current = 0;
    setIsVolumeOn(true);
    videoRef.current.muted = false;
    if (!isCountdownVisible) {
      if (isPaused) {
        setIsPaused(false);
      } else {
        play();
      }
    }
  }, [exerciseId]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (videoRef?.current?.currentTime) {
      const currentTime = videoRef.current.currentTime;
      videoRef.current.load();
      videoRef.current.currentTime = currentTime;
      play();
    }
  }, [resolution]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isPaused) {
      pause();
    } else {
      play();
    }
  }, [isPaused, play]);

  useEffect(
    () => {
      if (resolution && resolution !== prevResolution) {
        return;
      }
      if (!isCountdownVisible) {
        if (shouldVideoLoop.current) {
          shouldVideoLoop.current = false;
        } else {
          setIsPreviewMode(true);
          timeoutRef.current = setTimeout(() => {
            setIsPreviewMode(false);
            replay();
          }, 8000);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [replay, src]
  );



  if (!supportsVideo) return <div>HTML Video not supported.</div>;

  const Prev = () =>
    isMulti ? (
      <div
        onClick={
          isPrevDisabled
            ? () => {}
            : () => {
                prevTrack();
                completedLoopsRef.current = 0;
              }
        }
      >
        <PrevIcon fill={isPrevDisabled ? primary.darkGray : primary.main} />
      </div>
    ) : null;

  const Next = () =>
    isMulti ? (
      <div
        onClick={() => {
          handleNextClick();
        }}
      >
        <NextIcon fill={isLastItem ? primary.darkGray : primary.main} />
      </div>
    ) : null;

  const nextButton =
    isMulti && hasNextButton ? (
      <NextButton
        onClick={() => {
          handleNextClick();
        }}
      />
    ) : null;

  const Countdown = ({ count, onTimeElapsed }) => {
    const [timeLeft, setTimeLeft] = useState(count);
    useEffect(() => {
      if (timeLeft === -1) {
        onTimeElapsed();
        return;
      }

      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft, onTimeElapsed]);

    return (
      <div className={classes.countdown}>{timeLeft <= 0 ? 'Go' : timeLeft}</div>
    );
  };

  const handleVideoOnclick = () => {
    if (!isFullScreen) {
      setIsPaused(!isPaused);
      return;
    }

    setIsProgressBarHiddenAfterTimeout(!isProgressBarHiddenAfterTimeout);
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const isCircularProgressBarVisible =
    !isPreviewMode && !isCountdownVisible && exerciseType === 'time-based';

  return (
    <div
      className={cx(
        classes.container,
        isFullScreen ? classes.containerFullScreen : ''
      )}
      id='video-container'
      ref={videoContainerRef}
    >
      {isFullScreen && (
        <div className={classes.fullScreenIcons}>
          <Favorite
            isFavorite={isFavorite}
            onAddFavorite={onAddFavorite}
            onDeleteFavorite={onDeleteFavorite}
            processing={isProcessingFavorite}
          />
          <img onClick={handleFullscreen} src={closeImg} alt='close icon' />
        </div>
      )}
      {isCircularProgressBarVisible && (
        <div className={classes.onScreenInfo}>
          <CircularProgress
            ref={circularProgressRef}
            className={classes.circularProgressContainer}
          />
          <div className={classes.remainingTime} ref={remainingTimeRef} />
        </div>
      )}
      {isMulti && isCountdownVisible && (
        <Countdown
          count={5}
          onTimeElapsed={() => {
            setIsPaused(false);
            setIsPreviewMode(false);
            setIsCountdownVisible(false);
          }}
        />
      )}
      {!isPreviewMode &&
        isMulti &&
        exerciseType === 'rep-based' &&
        instructions && (
          <div className={cx(classes.onScreenInfo, classes.onScreenTitle)}>
            {instructions}
          </div>
        )}
      {isMulti && isPreviewMode && (
        <div className={classes.comingNext}>Coming next...</div>
      )}
      <video
        poster={videoPreviewImage}
        className={cx(
          classes.video,
          isFullScreen ? classes.videoFullScreen : ''
        )}
        id='video'
        onClick={handleVideoOnclick}
        onDoubleClick={handleFullscreen}
        onEnded={handleVideoEnded}
        onTimeUpdate={handleTimeUpdate}
        preload='metadata'
        playsInline
        ref={videoRef}
      >
        <source src={src} type='video/mp4' />
      </video>
      {isSmallScreen && (
        <div className={classes.nextButtonContainer}>{nextButton}</div>
      )}
      <div
        className={cx(
          isFullScreen ? classes.navigationFullScreen : '',
          classes.progressContainer
        )}
      >
        <div
          className={cx(
            classes.progress,
            isFullScreen ? classes.progressFullScreen : '',
            isProgressBarHidden || isCircularProgressBarVisible
              ? classes.hidden
              : '',
            isProgressBarHiddenAfterTimeout
              ? classes.controlsHiddenAfterTimeout
              : ''
          )}
          onClick={handleProgressClick}
        >
          <progress id='progress' min='0' ref={progressRef} value='0'>
            <span ref={progressBarRef} id='progress-bar'></span>
          </progress>
        </div>
        <div
          className={cx(
            classes.controls,
            isFullScreen ? classes.controlsFullScreen : '',
            isProgressBarHiddenAfterTimeout
              ? classes.controlsHiddenAfterTimeout
              : ''
          )}
          id='controls'
        >
          <div>
            <Prev />
            {isPaused ? (
              <img
                alt='play button'
                src={playImg}
                onClick={() => setIsPaused(false)}
              />
            ) : (
              <img
                alt='pause button'
                src={pauseImg}
                onClick={() => setIsPaused(true)}
              />
            )}
            <Next />
            {(isFullScreen || isMulti) && !isSmallScreen && (
              <div className={classes.title}>{title}</div>
            )}
          </div>
          <div>
            <SettingsIcon fill={primary.main} onClick={handleSettingsClick} />
            <MuteIcon
              fill={isVolumeOn ? primary.gray : primary.main}
              onClick={() => setIsVolumeOn(!isVolumeOn)}
            />
            {isFullScreenEnabled && (
              <img
                alt='fullscreen button'
                src={fullScreenImg}
                onClick={handleFullscreen}
              />
            )}
            {!isSmallScreen && nextButton}
            {isTimeVisible && <div ref={timeLabelRef} />}
          </div>
        </div>
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popover }}
        container={document.getElementById('video-container')}
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        onClose={handleSettingsClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.settings}>
          <div className={classes.title}>Quality</div>
          {resolutionSettings
            .sort((a, b) => b.resolution - a.resolution)
            .map((videoSetting) => (
              <div
                className={classes.setting}
                key={videoSetting.resolution}
                onClick={() => videoSetting.onSelect()}
              >
                <div style={{ width: 20 }}>
                  {videoSetting.isSelected && (
                    <CheckMarkIcon fill={primary.darkGray} />
                  )}
                </div>
                <div>{`${videoSetting.resolution}p`}</div>
              </div>
            ))}
        </div>
      </Popover>
    </div>
  );
};
export default withStyles(styles)(VideoPlayer);
