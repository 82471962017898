import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { goBack } from 'connected-react-router';

import { Creators } from '../reducer';
import ParentPlanForm from './ParentPlanForm';
import TopBar from './TopBar2';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
});

const CreateParentPlan = ({ classes, createPlan, navigateBack }) => {
  return (
    <div className={classes.container}>
      <TopBar onBack={navigateBack} title='create new parent plan' />
      <ParentPlanForm
        onCancel={navigateBack}
        buttonText='Create'
        onSubmit={createPlan}
      />
    </div>
  );
};

const mapStateToProps = ({ plan: { error, processing } }) => ({
  error,
  processing,
});

const mapDispatchToProps = {
  navigateBack: goBack,
  createPlan: Creators.createPlanGroup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateParentPlan));
