import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = ({ palette: { secondary } }) => ({
  label: {
    color: secondary.grayText,
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    lineHeight: 1.8,
    textTransform: 'uppercase',
  },
  container: {
    padding: '1rem 0.5rem',
    borderRadius: '0.5rem',
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.04)',
  },
});

let ListItemProperty = ({
  classes,
  label,
  style,
  text,
  textAlign = 'center',
}) => (
  <div className={classes.container}>
    <div className={classes.label} style={{ textAlign }}>
      {label}
    </div>
    <div
      style={{
        ...style,
        textTransform: 'capitalize',
        marginTop: 3,
        textAlign,
      }}
    >
      {text || '-'}
    </div>
  </div>
);

export default withStyles(styles)(ListItemProperty);
