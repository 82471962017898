import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='2 2 16 9' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0625 0V0.234375C15.0625 1.00983 14.4317 1.64062 13.6562 1.64062H8.45715C8.1824 0.69397 7.30743 0 6.27344 0C5.0199 0 4 1.0199 4 2.27344V9.1875C4 9.19629 4.00015 9.20508 4.00031 9.21387L4.00046 9.22266L4.00031 9.23145C4.00015 9.24023 4 9.24902 4 9.25781H4.00092C4.03836 10.7763 5.28522 12 6.8125 12H13.6562C14.9486 12 16 10.9486 16 9.65625V0H15.0625ZM6.27344 0.9375C7.01007 0.9375 7.60938 1.5368 7.60938 2.27344V7.10815C7.28564 6.89658 6.89911 6.77344 6.48438 6.77344C5.89999 6.77344 5.36221 6.97632 4.9375 7.31525V2.27344C4.9375 1.5368 5.5368 0.9375 6.27344 0.9375ZM6.8125 11.0625H13.6562C14.4317 11.0625 15.0625 10.4317 15.0625 9.65625V2.10818C14.6705 2.40308 14.1834 2.57812 13.6562 2.57812H8.54688V8.76562H8.5455C8.54633 8.78897 8.54688 8.81241 8.54688 8.83594C8.54688 9.60352 7.92862 10.229 7.16406 10.2416V10.2422L7.15152 10.2419L7.14062 10.2422V10.2416C6.48584 10.2307 5.9184 9.76154 5.78638 9.11755L6.70474 8.92923C6.7485 9.14264 6.93527 9.29828 7.15161 9.30414C7.40494 9.29828 7.60938 9.09073 7.60938 8.83594C7.60938 8.21558 7.10474 7.71094 6.48438 7.71094C5.64319 7.71094 4.95718 8.38596 4.93842 9.22266C4.95728 10.2404 5.79041 11.0625 6.8125 11.0625Z'
        fill={fill}
      />
    </g>
    <defs>
      <filter
        id='filter0_d'
        x='0'
        y='0'
        width='20'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);
