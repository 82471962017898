import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';
import { sortByActiveSince } from 'core/utils/sort';

const INITIAL_STATE = {
  exercises: [],
  isDirty: false,
  processing: false,
  ui: {
    checkedItems: [],
    currentPage: 0,
    filter: {
      month: '',
      search: '',
      tags: {
        Status: [],
        'Usage Area': [],
        Difficulty: [],
        'Frontend Visible': [],
        'Movement Prep': [],
        Equipment: [],
        'Area of the Body': [],
        Modality: [],
        Muscles: [],
      },
    },
    selectedItemIndex: 0,
  },
};

// Action creators
const { Types, Creators } = createActions(
  {
    updateUI: ['ui'],
    getExercisesSuccess: ['response'],
    getExercises: () => ({
      type: 'exerciseCreator/GET_EXERCISES',
      payload: {
        request: {
          url: '/exercises-management',
          method: 'get',
        },
      },
    }),
    createExerciseSuccess: ['response'],
    createExerciseFail: ['error'],
    createExercise: (exercise) => ({
      type: 'exerciseCreator/CREATE_EXERCISE',
      payload: {
        request: {
          url: '/exercises-management',
          method: 'post',
          data: exercise,
        },
      },
    }),
    updateExerciseSuccess: ['response'],
    updateExerciseFail: ['error'],
    updateExercise: ({ id, exercise }) => ({
      type: 'exerciseCreator/UPDATE_EXERCISE',
      payload: {
        request: {
          url: `/exercises-management/${id}`,
          method: 'patch',
          data: exercise,
        },
      },
    }),
    deleteExerciseSuccess: ['response'],
    deleteExerciseFail: ['error'],
    deleteExercise: (id) => ({
      type: 'exerciseCreator/DELETE_EXERCISE',
      payload: {
        request: {
          url: `/exercises-management/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
    deleteExercisesSuccess: ['response'],
    deleteExercisesFail: ['error'],
    deleteExercises: ({ ids }) => ({
      type: 'exerciseCreator/DELETE_EXERCISES',
      payload: {
        request: {
          url: `/exercises-management/deleteByIds`,
          method: 'post',
          data: { ids },
        },
      },
    }),
    duplicateExerciseSuccess: ['response'],
    duplicateExerciseFail: ['error'],
    duplicateExercise: (id) => ({
      type: 'exerciseCreator/DUPLICATE_EXERCISE',
      payload: {
        request: {
          url: `/exercises-management/${id}/duplicate`,
          method: 'post',
          data: { id },
        },
      },
    }),
    updateState: ['payload'],
  },
  { prefix: 'exerciseCreator/' }
);

// Reducers
const getExercises = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getStatus = (activeSince) => {
  return !activeSince
    ? 'draft'
    : new Date(activeSince) <= Date.now()
    ? 'published'
    : 'scheduled';
};

const getExercisesSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    exercises: data
      .map((exercise) => ({
        ...exercise,
        status: getStatus(exercise.activeSince),
      }))
      .sort(sortByActiveSince),
    processing: false,
  };
};

const createExercise = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createExerciseSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    exercises: [{ ...data, status: getStatus(data.activeSince) }].concat(
      state.exercises
    ),
  };
};

const createExerciseFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const updateExercise = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateExerciseSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    exercises: state.exercises.map((e) => {
      if (e.id === data.id) {
        return {
          ...data,
          status: getStatus(data.activeSince),
        };
      }
      return e;
    }),
  };
};

const updateExerciseFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const deleteExerciseSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    exercises: state.exercises.filter((e) => e.id !== id),
  };
};

const deleteExerciseFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const deleteExercisesSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { ids },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    exercises: state.exercises.filter((e) => !ids.includes(e.id)),
  };
};

const deleteExercisesFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const duplicateExercise = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const duplicateExerciseSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    exercises: [{ ...data, status: getStatus(data.activeSince) }].concat(
      state.exercises
    ),
    ui: {
      ...state.ui,
      selectedItemIndex: 0,
    },
  };
};

const duplicateExerciseFail = (state = INITIAL_STATE, { payload }) => {
  console.log({ payload });
  return {
    ...state,
    error: toFormErrorObject(payload.response),
    processing: false,
  };
};

const updateUI = (state = INITIAL_STATE, { ui }) => ({
  ...state,
  ui,
});

const updateState = (state = INITIAL_STATE, { payload: { key, value } }) => {
  return {
    ...state,
    [key]: value,
  };
};

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_EXERCISES]: getExercises,
  [Types.GET_EXERCISES_SUCCESS]: getExercisesSuccess,
  [Types.CREATE_EXERCISE]: createExercise,
  [Types.CREATE_EXERCISE_SUCCESS]: createExerciseSuccess,
  [Types.CREATE_EXERCISE_FAIL]: createExerciseFail,
  [Types.UPDATE_EXERCISE]: updateExercise,
  [Types.UPDATE_EXERCISE_SUCCESS]: updateExerciseSuccess,
  [Types.UPDATE_EXERCISE_FAIL]: updateExerciseFail,
  [Types.DELETE_EXERCISE_SUCCESS]: deleteExerciseSuccess,
  [Types.DELETE_EXERCISE_FAIL]: deleteExerciseFail,
  [Types.DELETE_EXERCISES_SUCCESS]: deleteExercisesSuccess,
  [Types.DELETE_EXERCISES_FAIL]: deleteExercisesFail,
  [Types.DUPLICATE_EXERCISE]: duplicateExercise,
  [Types.DUPLICATE_EXERCISE_SUCCESS]: duplicateExerciseSuccess,
  [Types.DUPLICATE_EXERCISE_FAIL]: duplicateExerciseFail,
  [Types.UPDATE_UI]: updateUI,
  [Types.UPDATE_STATE]: updateState,
});

export { Creators, Types, Reducer };
