import React from 'react';
import { withStyles } from '@material-ui/styles';
import MUIButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import cx from 'classnames';

const styles = (theme) => ({
  root: {
    borderRadius: 3,
    fontSize: 14,
    fontWeight: 'bold',
    height: 45,
    letterSpacing: 1.2,
    padding: '0 20px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
      background: theme.palette.primary.mainDark,
    },
  },
  contained: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  outlined: {
    background: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  disabled: {
    background: theme.palette.primary.gray,
    color: `${theme.palette.primary.contrastText} !important`,
    border: 'none',
  },
});

export const Button = ({
  classes,
  className,
  disabled = false,
  onClick,
  outlined = false,
  processing = false,
  processingLabel = '',
  title,
  type = 'button',
  ...props
}) => (
  <MUIButton
    classes={{ disabled: disabled || processing ? classes.disabled : '' }}
    className={cx(
      classes.root,
      outlined ? classes.outlined : classes.contained,
      className
    )}
    disabled={disabled || processing}
    onClick={onClick}
    type={type}
    {...props}
  >
    {processing ? processingLabel : title} 
  </MUIButton>
);

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  processing: PropTypes.bool,
  processingLabel: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default withStyles(styles)(Button);
