import React from 'react';

export default ({ fill }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
  >
    <g fill='none' fillRule='evenodd'>
      <g fill={fill} fillRule='nonzero'>
        <g>
          <g>
            <g>
              <g>
                <path
                  d='M9.984 0H12v12H9.984V0zM0 12V0l8.496 6L0 12z'
                  transform='translate(-226 -1168) translate(151 564) translate(1) translate(0 578) matrix(-1 0 0 1 86 26)'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
