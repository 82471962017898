import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Filter } from 'modules/dashboard';

import searchImg from 'assets/svg/search.svg';
import { mixpanelAtoms, MixpanelEvents, track } from 'core/utils/mixpanel';
import { useRecoilState } from 'recoil';

const styles = ({ breakpoints }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginBottom: '2rem',
    [breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  searchBox: {
    backgroundColor: '#ffffff',
    border: 'solid 1px #dfe2e6',
    borderRadius: '2px',
    boxShadow: '0 2px 20px 0 rgba(34, 40, 89, 0.08)',
    flex: 1,
    height: '40px',
    width: '100%',
  },
  searchIcon: {
    margin: '17px',
    width: '15px',
  },
});

const SearchBar = ({
  classes,
  filter,
  onClearFilters,
  onFilterChange,
  onSearchChange,
  search,
  totalResults,
}) => {
  const [currentListTitle] = useRecoilState(mixpanelAtoms.currentListTitle);
  const [currentFavoriteTab] = useRecoilState(mixpanelAtoms.currentFavoriteTab);
  const [currentFilterSelection] = useRecoilState(
    mixpanelAtoms.currentFilterSelection
  );

  useEffect(() => {
    if (currentFilterSelection.length < 2) {
      return;
    }

    track(MixpanelEvents.appliedFilter, {
      filters: currentFilterSelection,
      resultCount: totalResults,
      list: currentListTitle ? currentListTitle : undefined,
      workoutType: currentFavoriteTab ? currentFavoriteTab : undefined,
    });
  }, [
    currentFavoriteTab,
    currentFilterSelection,
    currentListTitle,
    onFilterChange,
    totalResults,
  ]);

  return (
    <div className={classes.container}>
      <Input
        className={classes.searchBox}
        disableUnderline={true}
        onChange={onSearchChange}
        placeholder={'Search...'}
        value={search}
        startAdornment={
          <InputAdornment position='start' style={{ marginRight: 0 }}>
            <img
              className={classes.searchIcon}
              alt={'search icon'}
              src={searchImg}
            />
          </InputAdornment>
        }
      />
      <Filter
        onChange={onFilterChange}
        onClearAll={onClearFilters}
        selections={filter}
        totalResults={totalResults}
      />
    </div>
  );
};

export default withStyles(styles)(SearchBar);
