import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';
import { LOCATION_CHANGE } from 'connected-react-router';

import { HOME_URL } from './';
import { sortByActiveSince } from 'core/utils/sort';

export const INITIAL_DRAFT = { addedExercises: [], playlist: [] };
const INITIAL_STATE = {
  addedExercises: [],
  orderedExerciseIds: [],
  draft: {
    ...INITIAL_DRAFT,
  },
  playlist: [],
  processing: false,
  workouts: [],
  ui: {
    checkedItems: [],
    currentPage: 0,
    filter: {
      month: '',
      search: '',
      tags: {
        Status: [],
        'Usage Area': [],
        Difficulty: [],
        'Movement Prep': [],
        Equipment: [],
        'Area of the Body': [],
        Modality: [],
        Muscles: [],
        'Workout Type': [],
      },
    },
    selectedItemId: null,
    selectedItemIndex: 0,
  },
};

const getStatus = (activeSince) => {
  return !activeSince
    ? 'draft'
    : new Date(activeSince) <= Date.now()
    ? 'published'
    : 'scheduled';
};

// Action creators
const { Types, Creators } = createActions(
  {
    getWorkoutsSuccess: ['response'],
    getWorkouts: () => ({
      type: 'workoutCreator/GET_WORKOUTS',
      payload: {
        request: {
          url: '/workouts-management',
          method: 'get',
        },
      },
    }),
    getWorkoutSuccess: ['response'],
    getWorkout: (id) => ({
      type: 'workoutCreator/GET_WORKOUT',
      payload: {
        request: {
          url: `/workouts/${id}`,
          method: 'get',
        },
      },
    }),
    createWorkoutSuccess: ['response'],
    createWorkoutFail: ['error'],
    createWorkout: (workout) => ({
      type: 'workoutCreator/CREATE_WORKOUT',
      payload: {
        request: {
          url: '/workouts-management',
          method: 'post',
          data: workout,
        },
      },
    }),
    updateWorkoutSuccess: ['response'],
    updateWorkoutFail: ['error'],
    updateWorkout: ({ workout }) => ({
      type: 'workoutCreator/UPDATE_WORKOUT',
      payload: {
        request: {
          url: `/workouts-management/${workout.id}`,
          method: 'patch',
          data: workout,
        },
      },
    }),
    deleteWorkoutSuccess: ['response'],
    deleteWorkoutFail: ['error'],
    deleteWorkout: (id) => ({
      type: 'workoutCreator/DELETE_WORKOUT',
      payload: {
        request: {
          url: `/workouts-management/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
    deleteWorkoutsSuccess: ['response'],
    deleteWorkoutsFail: ['error'],
    deleteWorkouts: ({ ids }) => ({
      type: 'workoutCreator/DELETE_WORKOUTS',
      payload: {
        request: {
          url: `/workouts-management/deleteByIds`,
          method: 'post',
          data: { ids },
        },
      },
    }),
    duplicateWorkoutSuccess: ['response'],
    duplicateWorkoutFail: ['error'],
    duplicateWorkout: (id) => ({
      type: 'workoutCreator/DUPLICATE_WORKOUT',
      payload: {
        request: {
          url: `/workouts-management/${id}/duplicate`,
          method: 'post',
          data: { id },
        },
      },
    }),
    updateAddedExercises: ['exercises'],
    updatePlaylist: ['exercises'],
    saveDraft: ['draft'],
    resetDraft: null,
    updateUI: ['ui'],

    // updateState: ['payload'],

  },
  { prefix: 'workoutCreator/' }
);

// Reducers
const getWorkouts = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const getWorkoutsSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    workouts: data
      .map((workout) => ({
        ...workout,
        status: getStatus(workout.activeSince),
      }))
      .sort(sortByActiveSince),
  };
};

const getWorkout = (state = INITIAL_STATE) => ({
  ...state,
  fetchingWorkout: true,
});

const getWorkoutSuccess = (state = INITIAL_STATE, { payload: { data } }) => ({
  ...state,
  fetchingWorkout: false,
  workouts: state.workouts
    .map((workout) => {
      if (workout.id === data.id)
        return { ...data, status: getStatus(workout.activeSince) };
      return workout;
    })
    .sort(sortByActiveSince),
});

const createWorkout = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createWorkoutSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  const addedWorkout = {
    ...data,
    status: getStatus(data.activeSince),
  };
  return {
    ...state,
    processing: false,
    workouts: [addedWorkout].concat(state.workouts),
  };
};

const createWorkoutFail = (state = INITIAL_STATE) => ({
  ...state,
  processing: false,
});

const updateWorkout = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateWorkoutSuccess = (state = INITIAL_STATE, { payload: { data } }) => {
  return {
    ...state,
    processing: false,
    workouts: state.workouts.map((workout) => {
      if (workout.id === data.id) {
        return {
          ...data,
          status: getStatus(data.activeSince),
        };
      }
      return workout;
    }),
  };
};

const updateWorkoutFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const deleteWorkoutSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { id },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    workouts: state.workouts.filter((w) => w.id !== id),
  };
};

const deleteWorkoutFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});



const duplicateWorkout = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const duplicateWorkoutSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  console.log(data);
  return {
    ...state,
    processing: false,
    workouts: [
      { label: data.title, value: data.id, key: data.id, ...data },
      ...state.workouts,
      ,
    ],
  };
};


const duplicateWorkoutFail = (state = INITIAL_STATE, { payload }) => {
  console.log({ payload });
  return {
    ...state,
    error: toFormErrorObject(payload.response),
    processing: false,
  };
};



const updateUI = (state = INITIAL_STATE, { ui }) => ({
  ...state,
  ui,
});

const updateAddedExercises = (state = INITIAL_STATE, { exercises }) => {
  const uniqueExercises = exercises.filter(
    (exercise, index) =>
      index === exercises.findIndex((e) => e.id === exercise.id)
  );

  return {
    ...state,
    orderedExerciseIds: uniqueExercises,
    addedExercises: uniqueExercises,
  };
};

const updatePlaylist = (state = INITIAL_STATE, { exercises }) => ({
  ...state,
  playlist: exercises,
});

const resetDraft = (state = INITIAL_STATE) => ({
  ...state,
  draft: { ...INITIAL_DRAFT },
});

const saveDraft = (state = INITIAL_STATE, { draft }) => ({
  ...state,
  draft,
});

const locationChange = (
  state = INITIAL_STATE,
  {
    payload: {
      location: { pathname },
    },
  }
) => {
  if (
    ![
      `${HOME_URL}/create`,
      `${HOME_URL}/edit`,
      `${HOME_URL}/add-exercises/playlist`,
      `${HOME_URL}/add-exercises/add`,
    ].some((pattern) => {
      return pattern === pathname.substr(0, pattern.length);
    })
  ) {
    return {
      ...state,
      draft: { ...INITIAL_DRAFT },
    };
  }
  return state;
};

const deleteWorkoutsSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: {
          request: {
            data: { ids },
          },
        },
      },
    },
  }
) => {
  return {
    ...state,
    processing: false,
    workouts: state.workouts.filter((w) => !ids.includes(w.id)),
  };
};

const deleteWorkoutsFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.GET_WORKOUTS]: getWorkouts,
  [Types.GET_WORKOUTS_SUCCESS]: getWorkoutsSuccess,
  [Types.GET_WORKOUT]: getWorkout,
  [Types.GET_WORKOUT_SUCCESS]: getWorkoutSuccess,
  [Types.CREATE_WORKOUT]: createWorkout,
  [Types.CREATE_WORKOUT_SUCCESS]: createWorkoutSuccess,
  [Types.CREATE_WORKOUT_FAIL]: createWorkoutFail,
  [Types.UPDATE_WORKOUT]: updateWorkout,
  [Types.UPDATE_WORKOUT_SUCCESS]: updateWorkoutSuccess,
  [Types.UPDATE_WORKOUT_FAIL]: updateWorkoutFail,
  [Types.DELETE_WORKOUT_SUCCESS]: deleteWorkoutSuccess,
  [Types.DELETE_WORKOUT_FAIL]: deleteWorkoutFail,
  [Types.DELETE_WORKOUTS_SUCCESS]: deleteWorkoutsSuccess,
  [Types.DELETE_WORKOUTS_FAIL]: deleteWorkoutsFail,
  [Types.DUPLICATE_WORKOUT]: duplicateWorkout,
  [Types.DUPLICATE_WORKOUT_SUCCESS]: duplicateWorkoutSuccess,
  [Types.DUPLICATE_WORKOUT_FAIL]: duplicateWorkoutFail,
  [Types.UPDATE_UI]: updateUI,
  [Types.UPDATE_ADDED_EXERCISES]: updateAddedExercises,
  [Types.UPDATE_PLAYLIST]: updatePlaylist,
  [Types.RESET_DRAFT]: resetDraft,
  [Types.SAVE_DRAFT]: saveDraft,
  [LOCATION_CHANGE]: locationChange,
});

export { Creators, Types, Reducer };
