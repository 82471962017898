import { createActions, createReducer } from 'reduxsauce';
import { toFormErrorObject } from 'core';

const INITIAL_STATE = {
  reminder: [],
  isDirty: false,
  processing: false,
};

const { Types, Creators } = createActions(
  {
    createReminderSuccess: ['response'],
    createReminderFail: ['error'],
    createReminder: (reminder) => ({
      type: 'reminder/CREATE_REMINDER',
      payload: {
        request: {
          url: '/notifications/reminder',
          method: 'post',
          data: {
            ...reminder,
          },
        },
      },
    }),
    updateReminderSuccess: ['response'],
    updateReminderFail: ['error'],
    updateReminder: ({ id, reminder }) => ({
      type: 'reminder/UPDATE_REMINDER',
      payload: {
        id: id,
        request: {
          url: `/notifications/reminder/${id}`,
          method: 'patch',
          data: {
            ...reminder,
          },
        },
      },
    }),
    getReminderSuccess: ['response'],
    getReminder: () => ({
      type: 'reminder/GET_REMINDER',
      payload: {
        request: {
          url: '/notifications/reminder',
          method: 'get',
        },
      },
    }),
    deleteReminderSuccess: ['response'],
    deleteReminderFail: ['error'],
    deleteReminder: (id) => ({
      type: 'reminder/DELETE_REMINDER',
      payload: {
        request: {
          url: `/notifications/reminder/${id}`,
          method: 'delete',
          data: { id },
        },
      },
    }),
  },
  { prefix: 'reminder/' }
);

const getReminder = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createReminder = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const createReminderSuccess = (
  state = INITIAL_STATE,
  { payload: { data } }
) => {
  return {
    ...state,
    processing: false,
    reminder: [data],
  };
};

const createReminderFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const updateReminder = (state = INITIAL_STATE) => ({
  ...state,
  processing: true,
});

const updateReminderSuccess = (
  state = INITIAL_STATE,
  {
    meta: {
      previousAction: {
        payload: { id },
      },
    },
    meta: {
      previousAction: {
        payload: {
          request: { data },
        },
      },
    },
  }
) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    reminder: data,
  };
};

const updateReminderFail = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const getReminderSuccess = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    reminder: [payload.data],
    processing: false,
  };
};

const deleteReminderSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isDirty: true,
    processing: false,
    reminder: null,
  };
};

const deleteReminderFail = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  error: toFormErrorObject(payload.response),
  processing: false,
});

const Reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_REMINDER]: createReminder,
  [Types.CREATE_REMINDER_SUCCESS]: createReminderSuccess,
  [Types.CREATE_REMINDER_FAIL]: createReminderFail,
  [Types.UPDATE_REMINDER]: updateReminder,
  [Types.UPDATE_REMINDER_SUCCESS]: updateReminderSuccess,
  [Types.UPDATE_REMINDER_FAIL]: updateReminderFail,
  [Types.GET_REMINDER]: getReminder,
  [Types.GET_REMINDER_SUCCESS]: getReminderSuccess,
  [Types.DELETE_REMINDER_SUCCESS]: deleteReminderSuccess,
  [Types.DELETE_REMINDER_FAIL]: deleteReminderFail,
});

export { Creators, Types, Reducer };
