import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

import { Formik, useFormikContext } from 'formik';
import { object, ref, string } from 'yup';

import { Button, Dialog, Form, FormInput, FormRow } from 'components';
import FileUploader from './FileUploader';
import Layout from './Layout';
import { Creators } from 'modules/auth';

const styles = ({ breakpoints, palette: { messages, primary } }) => ({
  button: {
    height: 56,
    marginRight: 24,
    marginBottom: 16,
    width: 210,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    marginLeft: 50,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  deleteButton: {
    border: `1px solid ${primary.red}`,
    color: primary.red,
    height: 56,
    marginBottom: 16,
    marginRight: 24,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dialogDeleteButton: {
    background: messages.error,
    color: 'white',
    height: 56,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
    [breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 10,
    },
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 600,
    padding: 64,
    [breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  dialogButton: {
    height: 56,
    width: 210,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
    [breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  dialogForm: {
    boxShadow: 'none',
    overflowY: 'initial',
    padding: 0,
    width: '100%',
  },
  dialogText: {
    color: primary.generalText,
    marginTop: 16,
    lineHeight: 1.5,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    textTransform: 'uppercase',
  },
  form: {
    boxShadow: 'none',
    padding: 0,
    overflowY: 'initial',
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${primary.gray}`,
    display: 'flex',
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: 3,
    textTransform: 'uppercase',
    width: '100%',
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  label: {
    color: primary.generalText,
    fontSize: 14,
    lineHeight: '24px',
    marginBottom: 16,
  },
  quitDialog: {
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    justifyContent: 'space-between',
    padding: 64,
  },
  submitButtons: {
    borderTop: `1px solid ${primary.gray}`,
    marginTop: 24,
    paddingTop: 16,
  },
});

const Info = ({
  classes,
  deactivateMe,
  error,
  processing,
  updateMe,
  updatePassword,
  user: { bio, email, firstName, lastName, ...user },
}) => {
  const [avatarUrl, setAvatarUrl] = useState(user.avatarUrl);
  const [avatarId, setAvatarId] = useState(user.avatar);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      document.title = 'Edit Profile';
    }, []);

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form containerClass={classes.form} error={error} showDivider={false}>
        <FormRow>
          <div className={classes.label}>Your Avatar</div>
          <div className={classes.container}>
            <div>
              <FileUploader
                initialFileUrl={avatarUrl}
                onFileUploaded={({ id, url }) => {
                  setAvatarUrl(url);
                  setAvatarId(id);
                }}
              />
            </div>
            <div className={classes.buttons}>
              <Button title='delete image' onClick={() => setAvatarUrl('')} />
            </div>
          </div>
        </FormRow>
        <FormRow>
          <FormInput label='First Name' name='firstName' />
        </FormRow>
        <FormRow>
          <FormInput label='Last Name' name='lastName' />
        </FormRow>
        <FormRow>
          <FormInput label='Email' name='email' error={error} />
        </FormRow>
        <FormRow>
          <FormInput label='Bio' name='bio' rows={3} />
        </FormRow>
        <FormRow>
          <div className={classes.header}>change password</div>
        </FormRow>
        <FormRow>
          <FormInput label='New Password' name='password' type='password' />
        </FormRow>
        <FormRow>
          <FormInput
            label='Confirm New Password'
            name='confirmPassword'
            type='password'
          />
        </FormRow>
        <div className={classes.submitButtons}>
          <FormRow>
            <Button
              className={classes.deleteButton}
              title='delete account'
              onClick={() => setIsDialogOpen(true)}
              outlined
            />
          </FormRow>
          <FormRow>
            <NavLink
              exact
              to='/dashboard/profile'
              style={{ textDecoration: 'none' }}
            >
              <Button className={classes.button} title='cancel' outlined />
            </NavLink>
            <Button
              className={classes.button}
              processing={processing}
              disabled={!isValid}
              title='save changes'
              type='submit'
            />
          </FormRow>
        </div>
      </Form>
    );
  };

  const DialogFormBody = () => {
    const { validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <FormRow>
        <Form
          containerClass={classes.dialogForm}
          error={error}
          showDivider={false}
        >
          <FormInput label='' name='confirmationWord' />
          <div className={classes.dialogButtons}>
            <Button
              className={classes.dialogButton}
              outlined
              onClick={() => {
                setIsDialogOpen(false);
              }}
              title='cancel'
            />
            <Button
              className={classes.dialogDeleteButton}
              // onClick={() => setIsDialogOpen(false)}
              type='submit'
              title='delete'
            />
          </div>
        </Form>
      </FormRow>
    );
  };

  const validationSchema = object().shape({
    confirmPassword: string().oneOf([ref('password')], "Passwords don't match"),
    email: string().email().required('Email is required'),
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    password: string().min(6),
  });

  const validationSchemaDialog = object().shape({
    confirmationWord: string()
      .lowercase()
      .required('Confirmation word is required')
      .oneOf(['delete'], 'confirmation word incorrect'),
  });

  return (
    <Layout title='your info'>
      <Formik
        initialValues={{
          bio,
          email,
          firstName,
          lastName,
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (values.password) updatePassword(values.password);

          updateMe({ ...values, avatar: avatarId, avatarUrl });
        }}
      >
        <FormBody />
      </Formik>
      <Dialog open={isDialogOpen}>
        <div className={classes.dialog}>
          <div className={classes.dialogTitle}>delete account?</div>
          <div className={classes.dialogText}>
            IMPORTANT! You are about to delete your account and all information
            associated with your account. Please note that account deletions are
            permanent and CANNOT be reversed. When an account is deleted, the
            complete session history and all saved sessions are deleted.
          </div>
          <div className={classes.dialogText}>
            Type in the word DELETE below to continue with account deletion.
          </div>
          <Formik
            initialValues={{
              confirmationWord: '',
            }}
            validationSchema={validationSchemaDialog}
            onSubmit={deactivateMe}
          >
            <DialogFormBody />
          </Formik>
        </div>
      </Dialog>
    </Layout>
  );
};

const mapStateToProps = ({ auth: { user, error, processing } }) => ({
  user,
  error,
  processing,
});

const mapDispatchToProps = {
  deactivateMe: Creators.deactivateMe,
  updateMe: Creators.updateMe,
  updatePassword: Creators.updatePassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Info));
