import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = ({
  breakpoints,
  palette: { background, primary, secondary },
}) => ({
  container: {
    '& > div:first-child': {
      color: primary.hunterGreen,
      fontSize: 20,
      fontStretch: 'normal',
      fontWeight: 600,
      letterSpacing: 0.3,
      lineHeight: 'normal',
      marginBottom: 16,
    },
    '& > div:last-child': {
      color: secondary.grayText,
      fontFamily: 'Lato',
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.3,
      lineHeight: 'normal',
    },
    background: background.paper,
    boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.04)',
    height: 'fit-content',
    minHeight: 150,
    overflow: 'hidden',
    padding: 24,
    [breakpoints.down('md')]: {
      minHeight: 'unset',
      padding: '1rem',
    },
  },
});

const TagCard = ({ classes, text, title }) => (
  <div className={classes.container}>
    <div>{title}</div>
    <div>{text}</div>
  </div>
);
export default withStyles(styles)(TagCard);
