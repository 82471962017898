import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, useFormikContext } from 'formik';
import { Button, FormInput, FormRow } from 'components';
import { object, string } from 'yup';
import { withStyles } from '@material-ui/styles';
import { Creators } from 'modules/auth';
import { MixpanelEvents, track } from 'core/utils/mixpanel';

const styles = (theme) => ({
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

const EmailInviteForm = ({ classes, invite, onCancel }) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form>
        <FormRow>
          <FormInput
            label='Email address'
            name='email'
            placeholder='Email address'
          />
        </FormRow>
        <FormRow>
          <div className={classes.buttons}>
            <div className={classes.input} onClick={onCancel}>
              <Button title='Cancel' outlined />
            </div>
            <div className={classes.input}>
              <Button
                disabled={!isValid}
                processingLabel='Sending email...'
                title='Send Invite'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    email: string().email().required('Email is required'),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        invite(values);
        onCancel();
        track(MixpanelEvents.pressedInviteFriendsEmailButton);
      }}
    >
      <FormBody />
    </Formik>
  );
};

const mapStateToProps = ({ auth: { error, processing } }) => ({
  error,
  processing,
});

const mapDispatchToProps = { invite: Creators.sendInvite };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmailInviteForm));
