import React from 'react';
import PropTypes from 'prop-types';
import { Modal as SimpleModal } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
});

const Component = ({ classes, isOpen, hideModal, children }) => {
  return (
    <div>
      <SimpleModal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={isOpen}
        className={classes.modal}
      >
        <div onClick={hideModal} className={classes.paper}>
          <div onClick={(e) => e.stopPropagation()}>
            {/* <img onClick={hideModal} src='/' alt='close-button' /> */}
            {children}
          </div>
        </div>
      </SimpleModal>
    </div>
  );
};

Component.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element,
  hideModal: PropTypes.func.isRequired,
};

export const Modal = withStyles(styles)(Component);
