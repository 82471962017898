import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext, useField } from 'formik';
import { object } from 'yup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  Button,
  Form,
  FormCheckbox,
  FormInput,
  FormRow,
  FormDatePicker,
  FormSelect,
} from 'components';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  fileUploaders: {
    display: 'flex',
    width: '100%',
    '& > div:first-child': {
      marginRight: 15,
    },
  },
  form: {
    padding: '24px 0px',
    overflow: 'visible',
  },
  formRow: {
    padding: '8px 24px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  fullRow: {
    padding: '8px 24px',
    margin: 0,
  },
  countInput: {
    width: '30%',
    paddingRight: '5px',
  },
  costInput: {
    width: '100px',
    paddingRight: '5px',
  },
  input: {
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  inputs: {
    display: 'grid',
    gridGap: 16,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  '& video': {
    height: 200,
    objectFit: 'cover',
    width: '100%',
  },
});

const RadioControl = () => {
  const [field] = useField('discountMode');
  return (
    <RadioGroup
      row
      aria-label='position'
      name='discountMode'
      defaultValue='top'
      onChange={field.onChange('discountMode')}
    >
      <FormControlLabel
        value='standard'
        control={<Radio color='primary' />}
        label='Standard'
      />
      <FormControlLabel
        value='firstPayment'
        control={<Radio color='primary' />}
        label='First Payment'
      />
      <FormControlLabel
        value='recurring'
        control={<Radio color='primary' />}
        label='Recurring'
      />
    </RadioGroup>
  );
};

const CouponForm = ({
  plans,
  classes,
  coupon,
  error,
  isEdit = false,
  onSubmit,
  pageTitle,
  processing,
}) => {
  const FormBody = () => {
    const { isValid, values } = useFormikContext();
    return (
      <Form
        error={error}
        containerClass={classes.form}
        pageTitle={pageTitle}
        showDivider={false}
      >
        <FormRow containerClass={classes.formRow}>
          <FormInput error={error} label={`Code`} name='code' placeholder='' />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <RadioControl />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.costInput}>
            <FormInput
              containerClass={classes.countInput}
              error={error}
              label={`Amount`}
              name='amount'
              placeholder=''
            />
          </div>
          <div className={classes.costInput}>
            <FormSelect
              height={50}
              label='Discount Type'
              name='type'
              width='100%'
              options={[
                {
                  label: '%',
                  value: 'percent',
                },
                {
                  label: '$',
                  value: 'amount',
                },
                {
                  label: 'credit',
                  value: 'credit',
                },
              ]}
            />
          </div>
          {values.discountMode === 'recurring' && (
            <div className={classes.costInput}>
              <FormInput
                containerClass={classes.countInput}
                error={error}
                label={`# months`}
                name='durationInMonths'
                placeholder=''
              />
            </div>
          )}
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.input}>
            <FormInput
              containerClass={classes.countInput}
              error={error}
              label={`Usage Count`}
              name='maxUsage'
              placeholder=''
            />
          </div>
          <div className={classes.input}>
            {values.expires && (
              <FormDatePicker label='Expire coupon' name='couponExpiration' />
            )}
          </div>
          <div className={classes.input}>
            <FormCheckbox label='Expires' name='expires' />
          </div>
        </FormRow>
        <FormRow containerClass={classes.fullRow}>
          <FormSelect
            height={50}
            closeMenuOnSelect={false}
            isMulti={true}
            label='Apply coupon to specific plans'
            name='membershipPlans'
            width='100%'
            options={plans}
          />
        </FormRow>
        <FormRow containerClass={classes.formRow}>
          <div className={classes.buttons}>
            <div className={classes.button}>
              <Button
                id='btnSave'
                disabled={!isValid}
                processing={processing}
                processingLabel='saving...'
                title='save'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };
  const transformValues = (values) => {
    const extractValue = (item) => {
      return { code: item.value };
    };
    return {
      ...values,
      couponExpiration: values.expires ? values.couponExpiration : null,
      membershipPlans: values.membershipPlans?.map(extractValue) || [],
    };
  };

  if (isEdit && !coupon) return null;

  const validationSchema = object().shape({
    // access: string().required('Required field'),
    // difficulty: string().required('Required field'),
    // frontEndVisible: string().required('Required field'),
  });

  return (
    <Formik
      initialValues={coupon || { active: true, expires: true }}
      onSubmit={(values) => onSubmit(transformValues(values))}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};
const flattenPlans = (plans) => {
  const allPlans = plans?.map((plan) => plan.plans);
  return allPlans?.flat().map((plan) => {
    return { label: plan.title, value: plan.code };
  });
};
export default connect(({ plan: { plans } }) => ({
  plans: flattenPlans(plans),
}))(withStyles(styles)(CouponForm));
