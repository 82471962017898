import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import { Creators as AuthCreators } from 'modules/auth';

import Layout from './Layout';

const styles = ({ breakpoints, palette: { messages, primary } }) => ({
  button: {
    height: 56,
    marginRight: 24,
    marginBottom: 16,
    width: 210,
  },
  buttons: {
    display: 'flex',
    marginLeft: 72,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
  },
  deleteButton: {
    border: `1px solid ${primary.red}`,
    color: primary.red,
    height: 56,
    marginBottom: 16,
    marginRight: 24,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
  },
  dialogDeleteButton: {
    background: messages.error,
    color: 'white',
    height: 56,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 600,
    padding: 64,
  },
  dialogButton: {
    height: 56,
    width: 210,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  dialogForm: {
    boxShadow: 'none',
    overflowY: 'initial',
    padding: 0,
    width: '100%',
  },
  dialogText: {
    color: primary.generalText,
    marginTop: 16,
    lineHeight: 1.5,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    textTransform: 'uppercase',
  },
  form: {
    boxShadow: 'none',
    padding: 0,
    overflowY: 'initial',
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${primary.gray}`,
    display: 'flex',
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: 3,
    textTransform: 'uppercase',
    width: '100%',
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  statLabel: {
    color: primary.darkGray,
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: 16,
  },
  statValue: {
    color: primary.generalText,
    fontSize: 32,
    fontWeight: 600,
    marginBottom: 16,
  },
  statSection: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  statContainer: {
    flex: '1 1 0',
    padding: '15px',
    margin: '15px 10px',
    backgroundColor: '#fff',
    minHeight: '168px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.04)',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  statContents: {
    borderLeft: '5px solid',
    display: 'flex',
    paddingLeft: '10px',
    minHeight: '120px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  borderColor: {
    borderColor: (props) => props.color || 'transparent',
  },
});

const StatBlockRaw = (props) => {
  const { classes } = props;
  return (
    <div className={classes.statContainer}>
      <div className={cx(classes.statContents, classes.borderColor)}>
        <div className={classes.statLabel}>{props.statName}</div>
        <div className={classes.statValue}>{props.value}</div>
      </div>
    </div>
  );
};
const StatBlock = withStyles(styles)(StatBlockRaw);

const Stats = ({ classes, user, me }) => {
  useEffect(() => {
    me({ loadingVisible: false });
  }, [me]);

  return (
    <Layout title='My Stats' background='#FEFEFE'>
      <StatBlock statName='Current Streak' value={user.currentStreak} />
      <div className={classes.statSection}>
        <StatBlock
          color='#2cd3c1'
          statName='Total mobility time'
          value={`${Math.round(user.totalMobilityTime / 60)} minutes`}
        />
        <StatBlock
          color='#28aae2'
          statName='Sessions completed'
          value={user.sessionsComplete}
        />
        <StatBlock
          color='#2676d7'
          statName='Average duration'
          value={`
          ${Math.round(user.averageDuration / 60)} minutes`}
        />
        <StatBlock
          color='#694ffc'
          statName='Longest streak'
          value={user.maxStreak}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

const mapDispatchToProps = {
  me: AuthCreators.me,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Stats));
