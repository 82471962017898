import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const NotFoundComponent = ({ user, url }) => {
  return <div>Not Found!</div>;
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export const NotFound = connect(mapStateToProps)(NotFoundComponent);
