import React from 'react';

export default ({ fill }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.6562 16.875V0.78125C17.6562 0.349766 17.3065 0 16.875 0H0.78125C0.349766 0 0 0.349766 0 0.78125V16.875C0 17.3065 0.349766 17.6562 0.78125 17.6562C1.46008 17.6562 16.1039 17.6562 16.875 17.6562C17.3065 17.6562 17.6562 17.3065 17.6562 16.875ZM3.38539 16.0938C3.76578 13.4307 6.06164 11.3769 8.82812 11.3769C11.5946 11.3769 13.8905 13.4307 14.2709 16.0938H3.38539ZM6.46969 7.45598C6.46969 6.15555 7.5277 5.09754 8.82812 5.09754C10.1286 5.09754 11.1866 6.15555 11.1866 7.45598C11.1866 8.75641 10.1286 9.81441 8.82812 9.81441C7.5277 9.81441 6.46969 8.75641 6.46969 7.45598ZM16.0938 16.0938H15.8453C15.5563 13.4806 13.8356 11.293 11.4872 10.3347C12.2626 9.61789 12.7491 8.59273 12.7491 7.45598C12.7491 5.29395 10.9902 3.53504 8.82812 3.53504C6.66609 3.53504 4.90719 5.29395 4.90719 7.45598C4.90719 8.59273 5.39363 9.61789 6.16906 10.3347C3.82062 11.293 2.1 13.4806 1.81098 16.0938H1.5625V1.5625H16.0938V16.0938Z'
      fill={fill}
    />
    <path
      d='M19.2188 2.96875C18.7873 2.96875 18.4375 3.31852 18.4375 3.75V18.4375H3.75C3.31852 18.4375 2.96875 18.7873 2.96875 19.2188C2.96875 19.6502 3.31852 20 3.75 20H19.2188C19.6502 20 20 19.6502 20 19.2188V3.75C20 3.31852 19.6502 2.96875 19.2188 2.96875Z'
      fill={fill}
    />
  </svg>
);
