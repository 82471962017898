import React, { useEffect } from 'react';
import SignUpForm from './SignUpForm';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';




const SignUpFormWithTeamToken = ({ goTo }) => {

  const { teamToken } = useParams();

  console.log(teamToken , "TEAM TOKENICHA")

  console.log("BICHOOOOOOOO")

  useEffect(() => {
    if(!teamToken){
      goTo("/auth/signup")
    }
  },[teamToken]);


  return (
      <SignUpForm  />
  );
};

// export default SignUpFormWithTeamToken;


// onst mapStateToProps = ({ auth: { error, processing } }) => ({
//   error,
//   processing,
// });

const mapDispatchToProps = { goTo: push,};

export default connect(
  null,
  mapDispatchToProps
)(SignUpFormWithTeamToken);