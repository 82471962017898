import React, { forwardRef, useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import cx from 'classnames';
import { withStyles } from '@material-ui/styles';

import { Creators } from '../reducer';
import { Favorite, videoUtils, VideoPlayer } from 'modules/dashboard';
import {
  increment,
  MixpanelEvents,
  MixpanelIncrementProperties,
  track,
} from 'core/utils/mixpanel';
import {
  getWorkoutFormat,
  getWorkoutTags,
  getWorkoutType,
} from 'core/utils/workouts';
import moment from 'moment';

const styles = ({ breakpoints, palette: { primary } }) => ({
  content: {
    background: '#fefefe',
    maxHeight: '30vh',
  },
  container: {
    maxWidth: 800,
    overflow: 'auto',
    position: 'relative',
    [breakpoints.down('xs')]: {
      width: 'auto',
      height: '100%',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    padding: '16px 32px',
    justifyContent: 'space-between',
    '& img': {
      height: 32,
      width: 32,
      cursor: 'pointer',
    },
  },
  playlistItem: {
    cursor: 'pointer',
    display: 'grid',
    padding: '16px 0px',
    fontSize: 20,
    color: primary.hunterGreen,
    letterSpacing: 0.3,
    lineHeight: 1.5,
    gridTemplateColumns: '1fr 1fr 5fr',
    '& > div:first-child': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div:last-child': {
      justifyContent: 'center',
      display: 'flex',
      marginLeft: 32,
      flexDirection: 'column',
      '& > div:last-child': {
        color: primary.darkGray,
      },
    },
    '& img': {
      width: 90,
      height: 90,
      objectFit: 'cover',
    },
  },
  playlistItemSelected: {
    background: primary.main,
    color: primary.contrastText,
    '& > div:last-child': {
      '& > div:last-child': {
        color: primary.contrastText,
      },
    },
  },
  title: {
    color: primary.hunterGreen,
    fontSize: 24,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
  },
});

const WorkoutPlayer = ({
  addFavorite,
  classes,
  deleteFavorite,
  isFavorite,
  onDurationUpdated,
  onExerciseCompleted,
  onWorkoutCompleted,
  startTime,
  playlist,
  processedItemId,
  workouts,
  workoutDuration,
  title,
  workoutId,
  onFullscreenStatusChange,
}) => {
  const [resolution, setResolution] = useState(
    localStorage.getItem('resolution') || 'video'
  );
  const [currentPlaylistItem, setCurrentPlaylistItem] = useState(playlist[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentItemRef = useRef(null);
  const videoPlayerRef = useRef(null);
  useEffect(() => {
    if (currentItemRef.current) {
      videoPlayerRef.current.scrollTo(0, 0);
    }
  }, [currentPlaylistItem]);

  useEffect(() => {
    setCurrentPlaylistItem(playlist[currentIndex]);
  }, [currentIndex, playlist]);

  useEffect(() => {
    if (!workoutId) return;
    const workout = workouts.find((workout) => workout.id === workoutId);

    track(MixpanelEvents.workoutStarted, {
      title: workout.title,
      type: getWorkoutType(workout),
      tags: getWorkoutTags(workout),
      format: getWorkoutFormat(workout),
      exercises:
        workout.addedExercises?.map((exercise) => exercise.title) || [],
      exerciseCount: workout.addedExercises?.length,
      trainer: workout.trainer.title,
      isFavorite: workout.isFavorite,
      duration: moment
        .utc(moment.duration({ minutes: workoutDuration }))
        .format('mm:ss'),
    });

    increment(MixpanelIncrementProperties.totalWorkoutsStarted);
  }, [workoutDuration, workouts, workoutId]);

  const handleOnWatched90PercentOfWorkout = () => {
    if (!workoutId) return;
    const workout = workouts.find((workout) => workout.id === workoutId);

    track(MixpanelEvents.completed90PercentOfWorkout, {
      title: workout.title,
      type: getWorkoutType(workout),
      tags: getWorkoutTags(workout),
      format: getWorkoutFormat(workout),
      exercises:
        workout.addedExercises?.map((exercise) => exercise.title) || [],
      exerciseCount: workout.addedExercises?.length,
      trainer: workout.trainer.title,
      isFavorite: workout.isFavorite,
      duration: moment
        .utc(moment.duration({ minutes: workoutDuration }))
        .format('mm:ss'),
    });

    increment(MixpanelIncrementProperties.totalWorkoutsCompleted);
  };

  const PlaylistItem = forwardRef(
    ({ item, index, isSelected, onClick }, ref) => {
      const {
        instructions,
        exercise: {
          title,
          thumbnail: { publicLocation },
        },
      } = item;

      return (
        <div
          className={cx(
            classes.playlistItem,
            isSelected ? classes.playlistItemSelected : ''
          )}
          onClick={onClick}
          ref={ref}
        >
          <div>{index + 1}.</div>
          <img
            loading='lazy'
            src={`${publicLocation}?width=180`}
            alt='exercise thumbnail'
          />
          <div>
            <div>{title}</div>
            <div>{instructions}</div>
          </div>
        </div>
      );
    }
  );

  const {
    instructions,
    duration: exerciseDuration,
    exercise: { id, title: videoTitle },
  } = currentPlaylistItem;

  let workoutDurationVisited;
  if (workoutDuration) {
    workoutDurationVisited = 0;
    for (let i = 0; i < currentIndex; i++) {
      workoutDurationVisited += playlist[i].duration;
    }
  }

  const {
    resolutionSettings,
    playbackSettings: { src, videoDuration },
  } = videoUtils.getVideoSettings({
    exercise: currentPlaylistItem.exercise,
    onResolutionSelected: setResolution,
    resolution,
  });

  const duration = exerciseDuration || videoDuration;
  const exerciseType = currentPlaylistItem.type || 'standard';
  const videoToPlay = {
    duration,
    exerciseId: id,
    exerciseType,
    instructions,
    isLastItem: currentIndex === playlist.length - 1,
    isPrevDisabled: currentIndex === 0,
    isProgressBarHidden: exerciseType !== 'standard',
    isTimeVisible: exerciseType !== 'rep-based',
    hasNextButton: exerciseType === 'rep-based',
    markExerciseCompleted: (exerciseId) => {
      onExerciseCompleted(exerciseId);
    },
    nextTrack: () => {
      setCurrentIndex(currentIndex + 1);
    },
    onWorkoutCompleted: () => onWorkoutCompleted(startTime),
    prevTrack: () => {
      setCurrentIndex(currentIndex - 1);
    },
    resolution,
    resolutionSettings,
    src,
    title: videoTitle,
    videoDuration,
  };

  const memoizedPlaylistItems = useMemo(() => {
    return playlist
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return (
          <PlaylistItem
            item={item}
            index={i}
            isSelected={currentPlaylistItem.id === item.id}
            key={item.id}
            onClick={() => {
              setCurrentIndex(i);
              // setCurrentPlaylistItem(
              //   playlist.find((p) => p.id === item.id)
              // );
            }}
            ref={currentPlaylistItem.id === item.id ? currentItemRef : null}
          />
        );
      });
  }, [currentPlaylistItem.id, playlist]);

  return (
    <div className={classes.container} ref={videoPlayerRef}>
      <VideoPlayer
        isFavorite={isFavorite}
        isMulti
        isProcessingFavorite={workoutId === processedItemId}
        onAddFavorite={() => addFavorite({ id: workoutId })}
        onDeleteFavorite={() => deleteFavorite({ id: workoutId })}
        onDurationUpdated={onDurationUpdated}
        videoToPlay={videoToPlay}
        isTimeVisible={true}
        workoutDuration={workoutDuration}
        workoutDurationVisited={workoutDurationVisited}
        onFullscreenStatusChange={onFullscreenStatusChange}
        onWatched90PercentOfWorkout={handleOnWatched90PercentOfWorkout}
      />
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
          <Favorite
            isFavorite={isFavorite}
            onAddFavorite={() => addFavorite({ id: workoutId })}
            onDeleteFavorite={() => deleteFavorite({ id: workoutId })}
            processing={processedItemId === workoutId}
            size={36}
          />
        </div>
        <div className={classes.playlist}>{memoizedPlaylistItems}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ workout: { workouts, processedItemId } }) => ({
  workouts,
  processedItemId,
});

const mapDispatchToProps = {
  addFavorite: Creators.addFavorite,
  deleteFavorite: Creators.deleteFavorite,
  goTo: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WorkoutPlayer));
