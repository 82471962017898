import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as ToastrReducer } from 'react-redux-toastr';
import { resettableReducer } from 'reduxsauce';

import { Reducer as AuthReducer } from 'modules/auth';
import { Reducer as CouponReducer } from 'modules/admin/dashboard/membership/coupons';
import { Reducer as ExerciseCreatorReducer } from 'modules/admin/dashboard/programming/exercise-creator';
import { Reducer as ExerciseReducer } from 'modules/dashboard/exercises';
import { Reducer as TeamMembersReducer } from 'modules/dashboard/team-dashboard/reducer';
// import { Reducer as UserAssessmentReducer } from 'modules/dashboard/assessment/reducer';
import { Reducer as MembershipReducer } from 'modules/admin/dashboard/membership/members';
import { Reducer as ModalReducer } from 'modules/modal';
import { Reducer as PaymentReducer } from 'modules/dashboard/payment';
import { Reducer as PlanReducer } from 'modules/admin/dashboard/membership/plan';
import { Reducer as TagReducer } from 'modules/admin/dashboard/programming/tags';
import { Reducer as TaskReducer } from 'modules/admin/dashboard/assessment';
import { Reducer as WorkoutCreatorReducer } from 'modules/admin/dashboard/programming/workout-creator';
import { Reducer as WorkoutReducer } from 'modules/dashboard/workouts/reducer';
import { Reducer as UserAssessmentReducer } from 'modules/dashboard/assessment/reducer';

import { Reducer as InviteReducer } from 'modules/admin/dashboard/affiliate';
import { Reducer as MovementMomentReducer } from 'modules/admin/dashboard/notifications/movementMoments';
import { Reducer as RecommendationReducer } from 'modules/admin/dashboard/notifications/recommendations';
import { Reducer as ReminderReducer } from 'modules/admin/dashboard/notifications/reminders';


const resettable = resettableReducer('auth/SIGN_OUT');

export const createRootReducer = (history) =>
  combineReducers({
    auth: AuthReducer,
    exercise: resettable(ExerciseReducer),
    exerciseCreator: resettable(ExerciseCreatorReducer),
    membership: resettable(MembershipReducer),
    modal: ModalReducer,
    payment: resettable(PaymentReducer),
    plan: resettable(PlanReducer),
    router: connectRouter(history),
    assessment: resettable(TaskReducer),
    movementMoment: resettable(MovementMomentReducer),
    recommendation: resettable(RecommendationReducer),
    reminder: resettable(ReminderReducer),
    tags: resettable(TagReducer),
    toastr: ToastrReducer,
    workout: resettable(WorkoutReducer),
    workoutCreator: resettable(WorkoutCreatorReducer),
    coupons: resettable(CouponReducer),
    inviteRule: resettable(InviteReducer),
    teamMembers: resettable(TeamMembersReducer),
    userAssessment: resettable(UserAssessmentReducer),
  });
