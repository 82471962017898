import React from 'react';
import { connect } from 'react-redux';

import Preview from './Preview';

const PainAndInjuryPreview = ({ id }) => {
  return (
    <Preview workoutType='painAndInjury' title='Programs' workoutId={id} />
  );
};

const mapStateToProps = (
  _,
  {
    match: {
      params: { id },
    },
  }
) => ({
  id,
});

export default connect(mapStateToProps)(PainAndInjuryPreview);
