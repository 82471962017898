import React, { useState } from 'react';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import facebook from 'assets/svg/facebook.svg';
import twitter from 'assets/svg/twitter.svg';
import email from 'assets/svg/envelope.svg';
import { useTheme } from '@material-ui/styles';
import { WEBSITE_URL } from 'core/constants';
import EmailInviteForm from './EmailInviteForm';
import { MixpanelEvents, track } from 'core/utils/mixpanel';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 424,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  emailContainer: {
    width: 424,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      padding: '0 15px',
    },
  },
  dialog: {
    maxWidth: 650,
    padding: '64px 92px',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      margin: 0,
      padding: '40px 0',
      marginTop: '40%',
      borderRadius: '6px',
    },
  },
  dialogContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    color: '#010300',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
    },
  },
  shareButtonContainer: {
    borderRadius: 2,
    border: ({ borderColor }) => `solid 2px ${borderColor}`,
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  facebookContainer: {
    borderRadius: 2,
    border: 'solid 2px #1877f2',
    width: '100%',
    cursor: 'pointer',
  },
  twitterContainer: {
    borderRadius: 2,
    border: 'solid 2px #da1f3',
    width: '100%',
    cursor: 'pointer',
  },
  shareButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 3.5,
    letterSpacing: 1.23,
    color: ({ borderColor }) => borderColor,
  },
}));

const ShareButton = ({ icon, label, borderColor }) => {
  const classes = useStyles({ borderColor });

  return (
    <Box
      display='flex'
      alignItems='center'
      className={classes.shareButtonContainer}
    >
      <Box ml='26px' display='flex' alignItems='center'>
        <img src={icon} alt='share' height={38} width={38} />
      </Box>
      <Box
        ml='-64px'
        flexGrow={1}
        display='flex'
        justifyContent='center'
        className={classes.shareButtonLabel}
      >
        {label.toUpperCase()}
      </Box>
    </Box>
  );
};

const InviteModal = ({
  isOpen,
  setIsOpen,
  affiliateCode,
  firstName,
  lastName,
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  const url = `${WEBSITE_URL}invite/${affiliateCode}`;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const date = new Date(Date.now());
  date.setDate(date.getDate() + 7);

  const handleOnClickFacebookButton = () => {
    track(MixpanelEvents.pressedInviteFriendsFacebookButton);
  };

  const handleOnClickTwitterButton = () => {
    track(MixpanelEvents.pressedInviteFriendsTwitterButton);
  };

  const handleOnClickEmailButton = () => {
    setIsDialogOpen(true);
  };

  return (
    <Dialog
      maxWidth='sm'
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{ paper: classes.dialog, container: classes.dialogContainer }}
    >
      <div className={classes.container}>
        <Box pb={4} className={classes.title}>
          INVITE FRIENDS
        </Box>
        <div>
          <FacebookShareButton
            onClick={handleOnClickFacebookButton}
            url={url}
            style={{ padding: isSmallScreen ? '0 15px' : '0px', width: '100%' }}
          >
            <Box py={1}>
              <ShareButton
                icon={facebook}
                label='facebook'
                borderColor='#1877f2'
              />
            </Box>
          </FacebookShareButton>
          <TwitterShareButton
            onClick={handleOnClickTwitterButton}
            url={url}
            style={{ padding: isSmallScreen ? '0 15px' : '0px', width: '100%' }}
          >
            <Box py={1}>
              <ShareButton
                icon={twitter}
                label='twitter'
                borderColor='#1da1f3'
              />
            </Box>
          </TwitterShareButton>
          {/* Facebook disabled messenger sharing from the web */}
          {/*<FacebookMessengerShareButton url={url} style={{ width: '100%' }}>*/}
          {/*  <Box py={1}>*/}
          {/*    <ShareButton icon={messenger} label='messenger' borderColor='#0084ff' />*/}
          {/*  </Box>*/}
          {/*</FacebookMessengerShareButton>*/}
          <div
            onClick={handleOnClickEmailButton}
            style={{ padding: isSmallScreen ? '0 15px' : '0px', width: '100%' }}
          >
            <Box py={1}>
              <ShareButton icon={email} label='email' borderColor='#5a5a5a' />
            </Box>
          </div>
        </div>
      </div>
      <Dialog
        open={isDialogOpen}
        maxWidth='sm'
        onClose={(e) => {
          e.stopPropagation();
          setIsDialogOpen(false);
        }}
        classes={{ paper: classes.dialog, container: classes.dialogContainer }}
      >
        <div className={classes.emailContainer}>
          <EmailInviteForm
            onCancel={() => {
              setIsDialogOpen(false);
            }}
          />
        </div>
        {/* <div className={classes.dialog}>
          <div className={classes.dialogText}>
            {`Are you sure you want to remove  from favorites?`}
          </div>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Button
                title='cancel'
                outlined
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDialogOpen(false);
                }}
              />
            </div>
            <div className={classes.input}>
              <Button
                title='remove'
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDialogOpen(false);
                }}
              />
            </div>
          </div>
        </div> */}
      </Dialog>
    </Dialog>
  );
};

export default InviteModal;
