import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { NavBar } from 'modules/admin/dashboard';
import { Creators } from '../reducer';
import TaskForm from './TaskForm';

import { HOME_URL } from '../';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const EditTask = ({ classes, updateTask, processing, id, task }) => {
  const title = 'Edit Task';
  return (
    <div className={classes.container}>
      <NavBar title={title} backUrl={HOME_URL} />
      <div className={classes.content}>
        <TaskForm
          onSubmit={(values) => {
            values.id = id;
            updateTask({ id, task: values });
          }}
          isEdit
          pageTitle={title}
          task={task}
          processing={processing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (
  { assessment: { tasks, processing } },
  {
    match: {
      params: { id },
    },
  }
) => {
  const taskToEdit = tasks.find((e) => e.id === id);
  const mediaTransform = (media) =>
    media ? { id: media.id, url: media.location } : null;
  if (!taskToEdit) return { processing, taskToEdit: null };

  return {
    task: {
      ...taskToEdit,
      bodyArea: taskToEdit.bodyArea?.id,
      doubleSided: taskToEdit.doubleSided || false,
      image1: mediaTransform(taskToEdit.image1),
      image2: mediaTransform(taskToEdit.image2),
      image3: mediaTransform(taskToEdit.image3),
      image4: mediaTransform(taskToEdit.image4),
      video: mediaTransform(taskToEdit.video),
      videoHi: mediaTransform(taskToEdit.videoHi),
      videoLo: mediaTransform(taskToEdit.videoLo),
    },
    id,
    processing,
  };
};

export default connect(mapStateToProps, {
  updateTask: Creators.updateTask,
})(withStyles(styles)(EditTask));
