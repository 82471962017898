import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = ({ breakpoints, palette: { primary } }) => ({
  container: {
    backgroundColor: primary.chipGray,
    borderRadius: 24,
    color: primary.generalText,
    display: 'inline-flex',
    width: 'fit-content',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: 14,
    letterSpacing: 0.3,
    padding: '0.5rem 1rem',
    textTransform: 'capitalize',
    [breakpoints.down('xs')]: {
      height: 36,
      fontSize: 12,
      letterSpacing: 0.17,
    },
  },
});

const Chip = ({ classes, text }) => {
  return <div className={classes.container}>{text}</div>;
};

export default withStyles(styles)(Chip);
