import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useMediaQuery } from '@material-ui/core';
import bg from 'assets/svg/welcome-bg.svg';
import iPhone from 'assets/img/iphonex.png';
import { IOS_APP_URL } from 'core/constants';
import MobileStoreButton from 'react-mobile-store-button';
import { Button } from '../../../../components';
import { useTheme } from '@material-ui/styles';

import { connect } from 'react-redux';
import { push as pushTo } from 'connected-react-router';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  root: {
    height: '100%',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100% 0%',
    backgroundColor: '#f7f8fa',
    borderLeft: '19px solid #ccff00',
    paddingLeft: '1rem',
    [breakpoints.down('xs')]: {
      borderLeft: 'none',
      objectFit: 'contain',
      backgroundPosition: '100% 100%',
      backgroundSize: 'contain',
      paddingLeft: 0,
    },
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: 315,
    maxHeight: 649,
    objectFit: 'cover',
    [breakpoints.down('xs')]: {
      width: 'auto',
      maxWidth: 'none',
    },
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
    lineHeight: 1.35,
    letterSpacing: 0.59,
    textTransform: 'uppercase',
    color: primary.hunterGreen,
    [breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  mainColor: {
    color: primary.main,
  },
  subtitle: {
    fontSize: 26,
    fontWeight: 900,
    lineHeight: 1.38,
    letterSpacing: 0.38,
    color: '#010300',
    [breakpoints.down('xs')]: {
      fontSize: 24,
      letterSpacing: 0.35,
      lineHeight: 1.42,
    },
  },
  text: {
    fontSize: 16,
    lineHeight: 1.75,
    color: primary.generalText,
  },
  button: {
    height: 56,
    fontSize: 16,
    marginTop: '1rem',
  },
}));

const Welcome = ({ push, user }) => {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('xs'));
  const classes = useStyles();

  useEffect(() => {
    if (user.email) {
      setTimeout(() => {
        window.rewardful('convert', {
          email: user.email,
          coupon: window.Rewardful.coupon,
        });
      }, 1000);
    }
  }, [user.email]);

  if (user.status == 'freemium') {
    return <Redirect to='/dashboard/payment/choose-plan' />;
  }

  return (
    <>
      <Box
        display='flex'
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
        className={classes.root}
      >
        <Box
          width={{
            xs: '100%',
            sm: '60%',
          }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          pl={{
            xs: '0',
            sm: '23px',
          }}
          px={{
            xs: '15px',
            sm: '0',
          }}
        >
          <Box
            className={classes.title}
            mb={{
              xs: '20px',
              sm: '32px',
            }}
            pt={{
              xs: '50px',
              sm: 0,
            }}
          >
            welcome to
            <Box className={classes.mainColor}>movement vault!</Box>
          </Box>
          <Box className={classes.subtitle} mb='16px' maxWidth={576}>
            Congrats on being on your way to no pain, decreased injuries, and
            increased performance!
          </Box>
          <Box className={classes.text} mb='40px' maxWidth={551}>
            You have found the most effective flexibility, mobility, and
            movement program that will help you move better, perform better,
            decrease pain, and decrease future injury risk. Your body thanks
            you.
          </Box>
          <Box className={classes.subtitle} mb='16px'>
            We also have an &nbsp;
            <span className={!isSmallScreen ? classes.mainColor : ''}>
              iOS app!
            </span>
          </Box>
          <Box className={classes.text} maxWidth={551}>
            Our Android app will be released soon. <br /> This means you can
            access Movement Vault anywhere.
          </Box>
          <Box mt='32px'>
            <MobileStoreButton
              store='ios'
              url={IOS_APP_URL}
              linkProps={{ title: 'Movement vault' }}
              width={192}
              height={64}
            />
            {isSmallScreen && (
              <Button
                className={classes.button}
                title='start using movement vault'
                onClick={() => push('/dashboard/home')}
              />
            )}
          </Box>
        </Box>
        <Box
          width={{
            xs: '100%',
            sm: '40%',
          }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          pt={{
            xs: 4,
            sm: 0,
          }}
        >
          <img src={iPhone} alt='iphone' className={classes.image} />
        </Box>
      </Box>
    </>
  );
};

const mapDispatchToProps = { push: pushTo };

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
