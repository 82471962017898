import React, { useEffect } from 'react';

import Details from './Details';
import { GETTING_STARTED } from './data';
import { MixpanelEvents, track } from 'core/utils/mixpanel';

export default ({
  match: {
    params: { id },
  },
}) => {
  const article = GETTING_STARTED.find((a) => a.id === id);

  useEffect(() => {
    track(MixpanelEvents.viewedGettingStartedArticle, { title: article.title });
  }, [article.title]);

  return <Details item={article} />;
};
