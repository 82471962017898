import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { parseError } from 'core';
import { Types } from './reducer';
import { HOME_URL } from './';
import { Creators as workoutCreators } from 'modules/dashboard/workouts';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* workoutDeleted() {
  yield call(toastr.success, 'Info', 'Workout deleted successfully');
}

export function* workoutsDeleted() {
  yield call(toastr.success, 'Info', 'Workouts deleted successfully');
}


export function* workoutDuplicated() {
  yield call(toastr.success, 'Info', 'Workout duplicated successfully');
  yield put(workoutCreators.getWorkouts());
}

export function* workoutSaved() {
  yield call(toastr.success, 'Info', 'Workout saved successfully');
  yield put(push(HOME_URL));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_WORKOUT_SUCCESS, workoutSaved),
    takeEvery(Types.UPDATE_WORKOUT_SUCCESS, workoutSaved),
    takeEvery(Types.DELETE_WORKOUT_SUCCESS, workoutDeleted),
    takeEvery(Types.DELETE_WORKOUTS_SUCCESS, workoutsDeleted),
    takeEvery(Types.DUPLICATE_WORKOUT_SUCCESS, workoutDuplicated),
    takeEvery(Types.CREATE_WORKOUT_FAIL, requestFailed),
    takeEvery(Types.UPDATE_WORKOUT_FAIL, requestFailed),
    takeEvery(Types.DELETE_WORKOUT_FAIL, requestFailed),
  ]);
}
