import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Button, Link, Loader } from 'components';
import { List } from '../../index';
import { Details } from '../../movementMoments';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Creators as ModalCreators } from '../../../../../modal';
import { Creators } from '../reducer';

const useStyles = makeStyles(
  ({ palette: { background, primary, secondary } }) => ({
    container: {
      minHeight: '100vh',
      width: '100%',
    },
    header: {
      borderBottom: `1px solid ${primary.gray}`,
      height: 80,
      padding: '0px 16px',
    },
    button: {
      textTransform: 'capitalize',
    },
  })
);

const Recommendations = ({
  showModal,
  data,
  deleteRecommendation,
  getRecommendations,
}) => {
  const classes = useStyles();
  const processing = false;
  const baseUri = 'recommendations';
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getRecommendations();
  }, [getRecommendations]);

  useEffect(() => {
    setItems(data.recommendations);
  }, [data]);

  const content = processing ? (
    <Loader />
  ) : (
    <Box display='flex'>
      <List
        items={items}
        selectedIndex={selectedIndex}
        handleClick={(index) => setSelectedIndex(index)}
      />
      <Details
        id={items[selectedIndex]?.id}
        baseUri={baseUri}
        title={items[selectedIndex]?.title}
        text={items[selectedIndex]?.message}
        showModal={showModal}
        type='recommendation'
        deleteFunction={deleteRecommendation}
        postingDate={items[selectedIndex]?.postingDate}
      />
    </Box>
  );

  return (
    <Box display='flex' flexDirection='column' className={classes.container}>
      <Box
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        className={classes.header}
      >
        <Box ml={16}>
          <Link style={{ textDecoration: 'none' }} to={`${baseUri}/create`}>
            <Button
              className={classes.button}
              title='Create New Recommendation'
            />
          </Link>
        </Box>
      </Box>
      {content}
    </Box>
  );
};

const mapStateToProps = ({ recommendation }) => ({
  data: recommendation,
});

export default connect(mapStateToProps, {
  getRecommendations: Creators.getRecommendations,
  deleteRecommendation: Creators.deleteRecommendation,
  showModal: ModalCreators.showModal,
})(Recommendations);
