import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import { push } from 'connected-react-router';

import { Dialog } from 'components';
import Layout from './Layout';
import ReadOnlyField from './ReadOnlyField';
import { PaymentMethod } from '../../payment';
import { Creators } from '../../payment/reducer';
import CustomDialog from '../../payment/components/CustomDialog';
import { useMediaQuery, useTheme } from '@material-ui/core';

const styles = ({ breakpoints, palette: { messages, primary } }) => ({
  button: {
    height: 56,
    marginRight: 24,
    marginBottom: 16,
    width: 210,
  },
  buttons: {
    display: 'flex',
    marginLeft: 72,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  deleteButton: {
    border: `1px solid ${primary.red}`,
    color: primary.red,
    height: 56,
    marginBottom: 16,
    marginRight: 24,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
  },
  dialogDeleteButton: {
    background: messages.error,
    color: 'white',
    height: 56,
    width: 210,
    '&:hover': {
      background: primary.red,
    },
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 600,
    padding: 64,
  },

  dialogButton: {
    height: 56,
    width: 210,
  },
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  dialogForm: {
    boxShadow: 'none',
    overflowY: 'initial',
    padding: 0,
    width: '100%',
  },
  dialogText: {
    color: primary.generalText,
    marginTop: 16,
    lineHeight: 1.5,
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: 1.42,
    letterSpacing: 0.35,
    textTransform: 'uppercase',
  },
  field: {
    margin: '10px 0',
  },
  form: {
    boxShadow: 'none',
    padding: 0,
    overflowY: 'initial',
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${primary.gray}`,
    display: 'flex',
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: 0.5,
    lineHeight: 3,
    textTransform: 'uppercase',
    width: '100%',
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  label: {
    color: primary.generalText,
    fontSize: 14,
    lineHeight: '24px',
  },
  quitDialog: {
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    justifyContent: 'space-between',
    padding: 64,
  },
  submitButtons: {
    borderTop: `1px solid ${primary.gray}`,
    marginTop: 24,
    paddingTop: 16,
  },
  action: {
    color: `${primary.main}`,
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingLeft: '5px',
  },
  planActions: {
    display: 'flex',
  },
});

const PaymentInfo = ({
  classes,
  membership,
  paymentMethod,
  cancelPlan,
  setPaymentMethod,
  goTo,
  isCancelledState,
  setIsCancelledState,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = React.useState(false);

  const { breakpoints } = useTheme();

  const ConfirmDelete = () => (
    <CustomDialog
      titleText='Are you sure you want to cancel your plan?'
      open={isDeleteConfirm}
      setIsDialogOpen={setIsDeleteConfirm}
      onOk={() => {
        setIsDeleteConfirm(false);
        cancelPlan();
      }}
      okTitle='Yes'
      showCacnel={true}
      cacnelTitle='No'
    />
  );

  const DialogCancelPlanSuccess = () => (
    <CustomDialog
      titleText='Your plan has been successfully canceled.'
      text={
        <>
          <p>You will no longer be billed.</p>
          <p>
            We are sad to see you go, but understand. Check back soon on our app
            and website, as we are always adding new features and routines. We
            will continue to get better to help you get better!
          </p>
        </>
      }
      open={isCancelledState}
      setIsDialogOpen={setIsDeleteConfirm}
      onOk={() => {
        setIsCancelledState(false);
      }}
      okTitle='OKAY'
      showCacnel={false}
    />
  );

  if (!membership.name) {
    goTo('/dashboard/payment/choose-plan');
  }

  const fullScreen = useMediaQuery(breakpoints.down('md'));

  // this is last4digits test
  // const test = paymentMethod.last4digits = undefined

  const last4digits = paymentMethod.last4digits === undefined ? "Apple In App Purchases" : paymentMethod.last4digits

  let expDate = paymentMethod.expMonth + '/' + paymentMethod.expYear


  // this is exp date Test
  // let test = expDate = undefined 

  const expDateField = expDate === undefined ? "Apple In App Purchases" : expDate

  return (
    <Layout title='Manage Subscriptions'>
      {membership && (
        <div className={classes.container}>
          <ReadOnlyField
            id='standard-read-only-input'
            label='Membership'
            value={membership.name}
            variant='outlined'
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <div className={classes.planActions}>
                    {(membership?.status === 'Active' ||
                      membership?.status === 'Trial Period') && (
                      <div
                        onClick={() => {
                          setIsDeleteConfirm(true);
                        }}
                        className={classes.action}
                      >
                        <span>Cancel</span>
                      </div>
                    )}
                    <NavLink
                      exact
                      to={`/dashboard/payment/choose-plan`}
                      className={classes.action}
                    >
                      Change Plan
                    </NavLink>
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <ReadOnlyField
            id='standard-read-only-input'
            label='Status'
            value={membership.status}
            InputProps={{
              readOnly: true,
            }}
            variant='outlined'
          />
          <ReadOnlyField
            id='standard-read-only-input'
            label='Created'
            value={moment(membership.created).format('LLLL')}
            InputProps={{
              readOnly: true,
            }}
            variant='outlined'
          />
          <ReadOnlyField
            id='standard-read-only-input'
            label='Expires'
            value={moment(membership.expires).format('LLLL')}
            InputProps={{
              readOnly: true,
            }}
            variant='outlined'
          />
        </div>
      )}
      {paymentMethod && (
        <div className={classes.container}>
          <div className={classes.header}> Payment Details</div>
          <ReadOnlyField
            id='standard-read-only-input'
            label='Card Number'
            value={`***` + last4digits}
            variant='outlined'
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <div
                    onClick={() => setIsDialogOpen(true)}
                    className={classes.action}
                  >
                    <span>Change Card</span>
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <ReadOnlyField
            id='standard-read-only-input'
            label='Expiry Date'
            value={expDateField}
            InputProps={{
              readOnly: true,
            }}
            variant='outlined'
          />
        </div>
      )}
      <Dialog
        open={isDialogOpen}
        fullScreen={fullScreen}
        classes={{ container: classes.dialogContainer }}
        onClose={() => setIsDialogOpen(false)}
      >
        <PaymentMethod
          onSuccess={(paymentMethod) => {
            setPaymentMethod({ paymentMethod });
            setIsDialogOpen(false);
          }}
          onCancelClick={() => {
            setIsDialogOpen(false);
          }}
        />
      </Dialog>
      <ConfirmDelete />
      <DialogCancelPlanSuccess />
    </Layout>
  );
};

const mapStateToProps = ({
  auth: {
    user: { membership, paymentMethod },
  },
  payment: { isCancelledState },
}) => {
  return {
    isCancelledState,
    membership,
    paymentMethod,
  };
};

const mapDispatchToProps = {
  cancelPlan: Creators.cancelPlan,
  setPaymentMethod: Creators.setPaymentMethod,
  setIsCancelledState: Creators.setIsCancelledState,
  goTo: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentInfo));
