import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import MobileStoreButton from 'react-mobile-store-button';

import { IOS_APP_URL } from 'core/constants';

import iPhone from 'assets/img/i-phone-x@2x.jpg';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    root: {
      backgroundImage: `url('assets/img/pattern.svg')`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '70% -90%',
      position: 'relative',
    },
    download: {
      fontSize: 100,
      fontWeight: 900,
      lineHeight: 0.44,
      letterSpacing: 1.47,
      color: primary.hunterGreen,
      paddingTop: 150,
      paddingBottom: 30,
      [breakpoints.down('xs')]: {
        fontSize: 42,
      },
    },
    movement: {
      fontSize: 110,
      fontWeight: 900,
      lineHeight: 0.91,
      letterSpacing: 1.62,
      color: primary.main,
      [breakpoints.down('xs')]: {
        fontSize: 52,
      },
    },
    appStoreBadge: {
      marginTop: spacing(2),
    },
    button: {
      height: 64,
      width: '100%',
      maxWidth: 312,
      borderRadius: 2,
      marginTop: spacing(2),
    },
    buttonText: {
      overflow: 'auto',
      fontWeight: 'bold',
      fontSize: 18,
      color: primary.hunterGreen,
      lineHeight: 3.56,
      letterSpacing: 1.38,
    },
    image: {
      maxWidth: 405,
      width: '100%',
      height: 'auto',
      [breakpoints.down('xs')]: {
        width: 360,
        height: 720,
      },
    },
    text: {
      maxWidth: 480,
      fontSize: 26,
      fontWeight: 900,
      lineHeight: 1.23,
      letterSpacing: 0.38,
      color: primary.hunterGreen,
      [breakpoints.down('xs')]: {
        textAlign: 'center',
        maxWidth: 'none',
      },
    },
    backgroundText: {
      position: 'absolute',
      color: '#f2f3f5',
      opacity: 0.68,
      fontSize: 160,
      letterSpacing: 14,
      marginLeft: '-20%',
      marginTop: '-3%',
      zIndex: -1,
      maxWidth: '110%',
      overflow: 'hidden',
      [breakpoints.down('xs')]: {
        fontSize: 80,
      },
    },
  })
);

const DownloadMovementVault = () => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column'>
      <Box pb={8}>
        <Typography
          className={cs(classes.download, classes.backgroundText)}
          variant='h4'
        >
          DOWNLOAD
        </Typography>
        <Typography className={classes.download} variant='h4'>
          DOWNLOAD
        </Typography>
        <Typography className={classes.movement} variant='h4'>
          MOVEMENT VAULT
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Box
          display='flex'
          justifyContent={{
            xs: 'center',
            sm: 'flex-start',
          }}
        >
          <img src={iPhone} alt='iphone' className={classes.image} />
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          width='100%'
        >
          <Box
            pl={{
              xs: 0,
              sm: '10%',
            }}
            pb={4}
            pt={{
              xs: 4,
              sm: 0,
            }}
          >
            <Typography className={classes.text} variant='h4'>
              DOWNLOAD THE MOVEMENT VAULT APP OR SIGN UP ONLINE RIGHT NOW TO
              START GETTING MOBILE AND FLEXIBLE TODAY.
            </Typography>
          </Box>
          <Box
            display='flex'
            flexWrap='wrap'
            pl={{
              xs: 0,
              sm: '10%',
            }}
            mt={-2}
            justifyContent={{
              xs: 'center',
              sm: 'flex-start',
            }}
          >
            <MobileStoreButton
              className={classes.appStoreBadge}
              store='ios'
              url={IOS_APP_URL}
              linkProps={{ title: 'Movement vault' }}
              width={220}
              height={70}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadMovementVault;
