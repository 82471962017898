import { all, takeEvery, put } from 'redux-saga/effects';
import { Creators } from '../../auth/reducer';
import { Types } from './reducer';
import { Creators as ExerciseCreators } from '../exercises/reducer';
import { Creators as WorkoutCreators } from '../workouts/reducer';

export function* plansChanged() {
  yield put(ExerciseCreators.getExercises());
  yield put(WorkoutCreators.getWorkouts());
  yield put(Creators.me({ loadingVisible: false }));
}

export function* setPaymentMethodSuccess() {
  yield put(Creators.me({ loadingVisible: false }));
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CANCEL_PLAN_SUCCESS, plansChanged),
    takeEvery(Types.SET_PAYMENT_METHOD_SUCCESS, setPaymentMethodSuccess),
  ]);
}
