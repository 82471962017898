import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

import Bin from 'components/icons/Bin';
import Edit from 'components/icons/Edit';
import { Creators } from '../reducer';
import { ContextMenu } from 'components';
import { Creators as ModalCreators } from 'modules/modal';

export const TableMenu = ({ deleteCoupon, goTo, showModal, couponId }) => {
  const theme = useTheme();

  const textColor = theme.palette.primary.generalText;
  const redColor = theme.palette.secondary.red;

  const options = [
    {
      onClick: () => goTo(`coupons/${couponId}`),
      icon: <Edit fill={textColor} />,
      text: 'View',
    },
    {
      onClick: () =>
        showModal(
          'deleteConfirmation',
          () => deleteCoupon(couponId),
          'Are you sure you want to delete the Coupon?'
        ),
      icon: <Bin fill={redColor} />,
      text: 'Delete',
      textColor: redColor,
    },
  ];

  const ref = React.useRef();

  return <ContextMenu ref={ref} options={options} />;
};

TableMenu.propTypes = {
  deleteCoupon: PropTypes.func,
  goTo: PropTypes.func,
  showModal: PropTypes.func,
  couponId: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  deleteCoupon: Creators.deleteCoupon,
  showModal: ModalCreators.showModal,
  goTo: push,
};

export default connect(null, mapDispatchToProps)(TableMenu);
