import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';

import DownloadMovementVault from './DownloadMovementVault';
import HelpingYouBlock from './HelpingYouBlock';
import OurMissionBlock from './OurMissionBlock';
import TightMusclesBlock from './TightMusclesBlock';

import headerImg from 'assets/img/header@2x.jpg';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 535,
    background: `url(${headerImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('xs')]: {
      height: 300,
    },
  },
  headerOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(18, 29, 50, 0.62)',
    height: 535,
    [theme.breakpoints.down('xs')]: {
      height: 300,
    },
  },
  headerContainer: {
    paddingTop: 180,
  },
  title: {
    zIndex: 1,
    color: 'white',
    fontSize: 44,
    fontWeight: 900,
    letterSpacing: 0.65,
    lineHeight: 1.27,
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
    textAlign: 'center',
  },
  subtitle: {
    color: 'white',
    fontSize: 24,
    letterSpacing: 0.65,
    paddingTop: 21,
    paddingBottom: 112,
    textAlign: 'center',
  },
  downloadMovementVaultContainer: {
    paddingLeft: '10vw',
    paddingRight: '10vw',
    paddingBottom: 60,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '3vw',
      paddingRight: '3vw',
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>About us</title>
      </Helmet>
      <Box>
        <Box className={classes.header}>
          <div className={classes.headerOverlay} />
          <Box
            display='flex'
            alignItems='center'
            flexDirection='column'
            className={classes.headerContainer}
          >
            <Typography className={classes.title} variant='h2'>
              ABOUT US
            </Typography>
          </Box>
        </Box>
        <Box py={{ xs: 4, sm: 8 }}>
          <Box pt={5} pb={5}>
            <OurMissionBlock />
          </Box>
          <Box pt={5} pb={5}>
            <TightMusclesBlock />
          </Box>
          <Box pt={5} pb={5}>
            <HelpingYouBlock />
          </Box>
        </Box>
        <div className={classes.downloadMovementVaultContainer}>
          <DownloadMovementVault />
        </div>
      </Box>
    </div>
  );
};

export default AboutUs;
