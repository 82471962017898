import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import missionImage from 'assets/img/mission@2x.jpg';

const useStyles = makeStyles(({ breakpoints, palette: { primary } }) => ({
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 38,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.56,
    color: primary.hunterGreen,
    [breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  dividerText: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: 1.56,
    color: primary.main,
    [breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  text: {
    fontSize: 18,
    lineHeight: 1.56,
    color: primary.generalText,
    maxWidth: 470,
    [breakpoints.down('xs')]: {
      maxWidth: 'none',
      fontSize: 16,
    },
  },
  divider: {
    width: '100%',
    'border-bottom': `1px solid ${primary.main}`,
  },
  image: {
    width: '100%',
    maxWidth: 704,
    maxHeight: 485,
    height: 'auto',
  },
}));

const OurMissionBlock = () => {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      flexDirection={{
        xs: 'column-reverse',
        sm: 'row',
      }}
      justifyContent='center'
    >
      <Box
        display='flex'
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}
        alignItems='center'
        width='100%'
        pt={{
          xs: 5,
          sm: 0,
        }}
        px={{
          xs: '15px',
          sm: 0,
        }}
      >
        <img src={missionImage} alt='our-mission' className={classes.image} />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pl={{
          xs: '15px',
          sm: 5,
        }}
        pr={{
          xs: '15px',
          sm: 0,
        }}
        width='100%'
      >
        <Typography className={classes.dividerText} variant='h4'>
          WHO WE ARE
        </Typography>
        <div className={classes.divider} />
        <Typography className={classes.title} variant='h4'>
          OUR MISSION
        </Typography>
        <Typography className={classes.text} variant='body1'>
          Our mission is to help the world move better, perform better, decrease
          pain and live better lives. Movement Vault’s founder Doctor of
          Physical Therapy, Dr. Grayson Wickham, PT, DPT, CSCS noticed a pattern
          with the people and athletes that he worked with in his physical
          therapy clinic in New York City.
          <br />
          <br />
          “Most of the people dealing with pain and injuries that come into to
          my clinic to see me, could have avoided their pain and injuries.”
          <br />
          <br />
          He estimates that up to 90% of their pain and injuries can be 100%
          avoided.
        </Typography>
      </Box>
    </Box>
  );
};

export default OurMissionBlock;
