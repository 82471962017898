import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Button, Link } from 'components';

import bin from 'assets/svg/bin.svg';
import search from 'assets/svg/search.svg';

const styles = (theme) => ({
  clearAll: {
    bottom: 3,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    position: 'absolute',
    right: 235,
  },
  createButton: {
    marginLeft: 5,
  },
  container: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    display: 'flex',
    height: 80,
    justifyContent: 'flex-end',
    padding: '0px 16px',
  },
  deleteButton: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    '& > img': {
      width: 19,
      height: 20,
    },
    '& > span': {
      color: theme.palette.secondary.red,
      paddingLeft: 8,
    },
  },
  input: {
    border: `1px solid ${theme.palette.secondary.gray}`,
    padding: 16,
    height: 45,
    fontSize: 14,
    width: 460,
    '&:focus': {
      outline: `1px solid ${theme.palette.primary.main}`,
    },
    '&::placeholder': {
      color: theme.palette.secondary.gray,
    },
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchButton: {
    alignItems: 'center',
    background: theme.palette.primary.gray,
    cursor: 'pointer',
    display: 'flex',
    height: 38,
    justifyContent: 'center',
    marginLeft: 3,
    width: 38,
    '& > img': {
      height: 24,
      width: 24,
    },
  },
  select: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 24,
    position: 'relative',
  },
  selectLabel: {
    color: theme.palette.secondary.grayText,
    paddingRight: 8,
    fontSize: 14,
  },
  selectSearch: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: 24,
    position: 'absolute',
    right: 3,
  },
});

const TopBar = ({
  classes,
  onBulkDelete,
  onClearAll,
  onSearch,
  statusSelect,
  searchInput,
  showDelete,
}) => {
  return (
    <div className={classes.container}>
      <div onClick={onBulkDelete} className={classes.deleteButton}>
        {showDelete && (
          <>
            <img src={bin} alt='bin' />
            <span>Delete</span>
          </>
        )}
      </div>
      <div className={classes.searchBar}>
        {/* <div className={classes.select}>
          <div className={classes.selectLabel}>{`${statusSelect.label}`}:</div>
          <Select
            onChange={({ value }) => statusSelect.onChange(value)}
            options={statusSelect.options}
            value={statusSelect.value}
          />
        </div> */}
        <div className={classes.select}>
          <input
            placeholder='Search by coupon Name'
            onChange={(e) => {
              searchInput.onChange(e.target.value);
            }}
            className={classes.input}
            value={searchInput.value}
          />
          <div className={classes.selectSearch}>
            <div onClick={onSearch} className={classes.searchButton}>
              <img src={search} alt='search icon' />
            </div>
          </div>
        </div>
        <div className={classes.clearAll} onClick={onClearAll}>
          Clear all
        </div>
      </div>
      <div className={classes.createButton}>
        <Link to='coupons/create'>
          <Button title='Create new coupon' />
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles)(TopBar);
