import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext, Form } from 'formik';
import { object, string } from 'yup';

import { Button, FormInput } from 'components';

const styles = (theme) => ({
  submitButton: {
    height: '50px',
  },
  container: {
    maxWidth: '800px',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateAreas: '"label label" "input submit" "footer footer"',
    alignItems: 'end',
  },
  input: {
    gridArea: 'input',
    padding: '0px 20px',
  },
  submit: {
    gridArea: 'submit',
    padding: '0px 20px',
  },
  label: {
    gridArea: 'label',
    alignText: 'left',
    paddingTop: '20px',
    paddingLeft: '20px',
  },
  footer: {
    margin: '20px 20px',
    gridArea: 'footer',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
  },
});

const TagForm = ({
  classes,
  error,
  onSubmit,
  processing,
  title,
  onCancel,
  buttonText,
  parentPlan,
}) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form>
        <div className={classes.container}>
          <div className={classes.input}>
            <FormInput
              error={error}
              name='title'
              placeholder='Tag Name'
              label='name of new tag'
            />
          </div>
          <div className={classes.submit}>
            <Button
              className={classes.submitButton}
              disabled={!isValid}
              processing={processing}
              processingLabel='Creating...'
              title='Add New Tag'
              type='submit'
            />
          </div>
          <div className={classes.footer} />
        </div>
      </Form>
    );
  };

  const validationSchema = object().shape({
    title: string().min(3).required('Plan name is required'),
  });

  return (
    <Formik
      initialValues={{ title: '' }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      <FormBody />
    </Formik>
  );
};

export default withStyles(styles)(TagForm);
