import React from 'react';
import { withStyles } from '@material-ui/styles';

import ProgrammingIcon from './icons/Programming';

const styles = ({ palette: { primary, secondary } }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '3rem 1rem',
    position: 'relative',
    color: '#aaa',

    '& > svg': {
      fill: '#ccc',
      width: 120,
      height: 120,
    },
  },
});

const Placeholder = ({ message, classes }) => {
  return (
    <div className={classes.container}>
      <ProgrammingIcon />
      <span>{message || 'No items available'}</span>
    </div>
  );
};

export default withStyles(styles)(Placeholder);
