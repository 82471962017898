import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 12 12' fill={fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.40625 1.40625C7.40625 2.1817 6.77545 2.8125 6 2.8125C5.22455 2.8125 4.59375 2.1817 4.59375 1.40625C4.59375 0.630798 5.22455 0 6 0C6.77545 0 7.40625 0.630798 7.40625 1.40625ZM6.46875 1.40625C6.46875 1.1478 6.25845 0.9375 6 0.9375C5.74155 0.9375 5.53125 1.1478 5.53125 1.40625C5.53125 1.6647 5.74155 1.875 6 1.875C6.25845 1.875 6.46875 1.6647 6.46875 1.40625ZM12 7.66406H11.0391V5.78906H10.1016V7.66406H8.34375V4.66406C8.34375 3.88861 7.71295 3.25781 6.9375 3.25781H5.0625C4.28705 3.25781 3.65625 3.88861 3.65625 4.66406V7.66406H1.89844V5.78906H0.960938V7.66406H0V8.60156H0.960938V10.4766H1.89844V8.60156H4.59375V12H5.53125V8.60156H6.46875V12H7.40625V8.60156H10.1016V10.4766H11.0391V8.60156H12V7.66406ZM5.0625 4.19531C4.80405 4.19531 4.59375 4.40561 4.59375 4.66406V7.66406H7.40625V4.66406C7.40625 4.40561 7.19595 4.19531 6.9375 4.19531H5.0625Z'
      fill={fill}
    />
  </svg>
);
