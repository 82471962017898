import { all, call, takeEvery, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { parseError } from 'core';
import { Types } from './reducer';

export function* requestFailed(action) {
  yield call(toastr.error, 'Error', parseError(action.payload.response));
}

export function* taskSaved(action) {
  yield call(toastr.success, '', 'Task saved successfully');
  yield put(push('/admin/dashboard/assessments'));
}

export function* taskDeleted() {
  yield call(toastr.success, 'Info', 'Task deleted successfully');
}

export default function* rootSaga() {
  yield all([takeEvery(Types.CREATE_TASK_SUCCESS, taskSaved)]);
  yield all([takeEvery(Types.UPDATE_TASK_SUCCESS, taskSaved)]);
  yield all([takeEvery(Types.DELETE_TASK_SUCCESS, taskDeleted)]);
}
