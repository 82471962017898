import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { Creators } from '../reducer';
import {
  Chip,
  Favorite,
  TagCard,
  VideoPlayer,
  videoUtils,
} from 'modules/dashboard';
import { sortByTitle } from 'core/utils/sort';
import {
  increment,
  MixpanelEvents,
  MixpanelIncrementProperties,
  track,
} from 'core/utils/mixpanel';
import moment from 'moment';
import { getExerciseTags } from 'core/utils/exercise';

const styles = ({
  breakpoints,
  palette: { background, primary, secondary },
}) => ({
  container: {
    [breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  chip: {
    backgroundColor: '#e8e8ec',
    borderRadius: 24,
    color: primary.generalText,
    fontSize: 14,
    height: 48,
    letterSpacing: 0.3,
    marginRight: 16,
    padding: '0px 8px',
  },
  chips: {
    display: 'flex',
    gap: '0.5rem',
    margin: '20px 0px',
    flexWrap: 'wrap',
    [breakpoints.down('xs')]: {
      padding: '0px 12px',
    },
  },
  content: {
    background: '#fefefe',
    height: 300,
    padding: 32,
    [breakpoints.down('xs')]: {
      padding: 0,
      height: '55%',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    '& img': {
      height: 32,
      width: 32,
      cursor: 'pointer',
    },
    [breakpoints.down('xs')]: {
      padding: '32px 12px',
    },
  },
  tagCard: {
    '& > div:first-child': {
      color: primary.hunterGreen,
      fontSize: 20,
      fontStretch: 'normal',
      fontWeight: 600,
      letterSpacing: 0.3,
      lineHeight: 'normal',
      marginBottom: 16,
    },
    '& > div:last-child': {
      color: secondary.grayText,
      fontFamily: 'Lato',
      fontSize: '20px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.3,
      lineHeight: 'normal',
    },
    background: background.paper,
    boxShadow: '0 2px 40px 0 rgba(0, 0, 0, 0.04)',
    height: 120,
    padding: 24,
  },
  tagCards: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: 16,
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  text: {
    whiteSpace: 'pre-line',
    fontSize: 16,
    lineHeight: 1.5,
    color: primary.generalText,
    padding: '12px 0',
    [breakpoints.down('xs')]: {
      padding: '0 12px',
    },
  },
  title: {
    color: primary.hunterGreen,
    fontSize: 32,
    fontWeight: 'bold',
    letterSpacing: 0.3,
    textTransform: 'uppercase',
    [breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
});

const ExercisePlayer = ({
  addFavorite,
  classes,
  deleteFavorite,
  exercise,
  processedItemId,
  onFullscreenStatusChange,
}) => {
  const [resolution, setResolution] = useState(
    localStorage.getItem('resolution') || 'video'
  );
  const {
    resolutionSettings,
    playbackSettings: { src, videoDuration },
  } = videoUtils.getVideoSettings({
    exercise,
    onResolutionSelected: setResolution,
    resolution,
  });


  console.log(JSON.stringify(resolutionSettings) , "HI")

  useEffect(() => {
    track(MixpanelEvents.exerciseVideoStarted, {
      title: exercise.title,
      tags: getExerciseTags(exercise),
      isFavorite: exercise.isFavorite,
      duration: moment
        .utc(moment.duration({ minutes: videoDuration }))
        .format('mm:ss'),
    });

    increment(MixpanelIncrementProperties.totalExercisesStarted);
  }, [exercise, videoDuration]);

  const handleOnWatched90PercentOfVideo = () => {
    track(MixpanelEvents.watched90PercentOfExerciseVideo, {
      title: exercise.title,
      tags: getExerciseTags(exercise),
      isFavorite: exercise.isFavorite,
      duration: moment
        .utc(moment.duration({ minutes: videoDuration }))
        .format('mm:ss'),
    });

    increment(MixpanelIncrementProperties.totalExercisesCompleted);
  };

  return (
    <div className={classes.container}>
      <VideoPlayer
        isFavorite={exercise.isFavorite}
        isProcessingFavorite={processedItemId === exercise.id}
        onAddFavorite={() => addFavorite({ id: exercise.id })}
        onDeleteFavorite={() => deleteFavorite({ id: exercise.id })}
        onFullscreenStatusChange={onFullscreenStatusChange}
        videoToPlay={{
          duration: videoDuration,
          isTimeVisible: true,
          resolution,
          resolutionSettings,
          src,
          title: exercise.title,
          videoDuration,
          onWatched90PercentOfExercise: handleOnWatched90PercentOfVideo,
        }}
      />
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>{exercise.title}</div>
          <Favorite
            isFavorite={exercise.isFavorite}
            onAddFavorite={() => addFavorite({ id: exercise.id })}
            onDeleteFavorite={() => deleteFavorite({ id: exercise.id })}
            processing={processedItemId === exercise.id}
            size={36}
          />
        </div>
        <div className={classes.chips}>
          {exercise.modalities &&
            exercise.modalities.map((m) => (
              <Chip className={classes.chip} key={m.id} text={m.title} />
            ))}
        </div>
        <div className={classes.tagCards}>
          <TagCard
            text={
              exercise.equipments &&
              exercise.equipments
                .sort(sortByTitle)
                .map((e) => e.title)
                .join(', ')
            }
            title='Equipment Needed'
          />
          <TagCard
            text={
              exercise.bodyAreas &&
              exercise.bodyAreas
                .sort(sortByTitle)
                .map((a) => a.title)
                .join(', ')
            }
            title='Area of the Body'
          />
          <TagCard
            text={
              exercise.muscles &&
              exercise.muscles
                .sort(sortByTitle)
                .map((m) => m.title)
                .join(', ')
            }
            title='Muscles'
          />
          <TagCard
            text={
              exercise.movementPreps &&
              exercise.movementPreps
                .sort(sortByTitle)
                .map((m) => m.title)
                .join(', ')
            }
            title='Movement Prep'
          />
        </div>
        <div className={classes.text}>{exercise.description}</div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ exercise: { processedItemId } }) => ({
  processedItemId,
});

const mapDispatchToProps = {
  addFavorite: Creators.addFavorite,
  deleteFavorite: Creators.deleteFavorite,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExercisePlayer));
