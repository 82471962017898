import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { push as pushTo } from 'connected-react-router';

import { Creators } from '../reducer';

import choosePlan from 'assets/img/choose-plan.png';

const styles = (theme) => ({
  container: {
    width: '100%',
  },
  header: {
    background: `url(${choosePlan})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 316,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  headerTitle: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 38,
    fontWeight: 900,
    letterSpacing: 0.5,
  },
  plan: {
    width: 360,
    height: 260,
    borderRadius: 6,
    margin: '0px 12px',
    boxShadow: `0 8px 16px 2px rgba(15, 18, 26, 0.08)`,
    cursor: 'pointer',
  },
  plans: {
    display: 'flex',
  },
  planHeader: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    height: 80,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  planSelected: { backgroundColor: theme.palette.secondary.main },
  planContent: {
    height: 180,
    padding: '0px 48px',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'center',
  },
  tab: {
    color: 'white',
    fontSize: 16,
    borderBottom: `2px solid ${theme.palette.primary.gray}`,
  },
  tabSelected: {
    color: 'white',
    fontSize: 16,
    fontWeight: 900,
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -120,
  },
  tabsContent: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  interval: {
    padding: '0px 48px',
    position: 'absolute',
    color: '#989ba1',
    fontSize: 16,
    marginTop: 24,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  titleL: {
    color: theme.palette.primary.main,
    fontSize: 30,
    lineHeight: 2,
    fontWeight: 'bold',
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    width: 230,
  },
  dialog: { maxWidth: 650 },
});

const SelectPlan = ({ classes, getPaymentPlans, plans, user, pushTo }) => {
  const [value, setValue] = React.useState(0);
  const currentPlan = user?.membership?.name;

  React.useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const getIntervalName = (interval) => {
    if (interval === 'year') return 'yearly';
    if (interval === 'month') return 'monthly';
    if (interval === '3months') return '3 months';
    if (interval === '6months') return '6 months';

    return 'custom';
  };

  const getIntervalBillingText = (interval) => {
    if (['year', 'month'].includes(interval))
      return `billled ${getIntervalName(interval)}`;
    if (['3months', '6months'].includes(interval))
      return `every ${getIntervalName(interval)}`;

    return 'custom';
  };

  const Plan = ({ isSelected, onClick, plan, index }) => (
    <div
      className={classes.plan}
      onClick={currentPlan === plan.title ? () => {} : onClick}
    >
      <div
        className={cx(
          classes.planHeader,
          isSelected ? classes.planSelected : ''
        )}
      >
        {!plan.promoText ? 'Regular Price' : plan.promoText}
      </div>
      <div className={classes.interval}>{getIntervalName(plan.interval)}</div>
      <div className={classes.planContent}>
        <div>
          <div className={classes.titleL}>
            {plan.trialDuration
              ? `Free ${plan.trialDuration}-Day Trial`
              : 'No Free Trial'}
          </div>
          <div className={classes.text}>
            {plan.trialDuration ? 'then' : ''} <b>${plan.price / 100}</b>{' '}
            {getIntervalBillingText(plan.interval)}.
            <br />
            {currentPlan === plan.title
              ? 'Your current plan'
              : 'Cancel anytime.'}
          </div>
        </div>
      </div>
    </div>
  );

  const Plans = ({ plans }) => (
    <div className={classes.plans}>
      {plans.map((plan, index) => (
        <Plan
          key={plan.code}
          plan={plan}
          index={index}
          onClick={() => pushTo(`signup/${plan.code}`)}
        />
      ))}
    </div>
  );

  if (plans.length < 1) return null;

  if (user.method === 'in-app') {
    return <div>You are using an in-app plan</div>;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>select a plan</div>
      </div>
      <div className={classes.tabsContainer}>
        <Tabs
          value={value}
          indicatorColor='secondary'
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          aria-label='membership plans'
        >
          {plans.map((plan) => (
            <Tab
              key={plan.id}
              classes={{ root: classes.tab, selected: classes.tabSelected }}
              label={plan.title}
            />
          ))}
        </Tabs>
        <div className={classes.tabsContent}>
          {plans.map((plan, index) => (
            <TabPanel key={plan.id} value={value} index={index}>
              <Plans plans={plan.plans.sort((a, b) => a.order - b.order)} />
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { user, planId }, payment: { plans } }) => ({
  plans,
  planId,
  user,
});

const mapDispatchToProps = {
  getPaymentPlans: Creators.getPaymentPlans,
  pushTo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SelectPlan));
