import React from 'react';

import Details from './Details';
import { ANNOUNCEMENTS } from './data';

export default ({
  match: {
    params: { id },
  },
}) => {
  return <Details item={ANNOUNCEMENTS.find((a) => a.id === id)} />;
};
