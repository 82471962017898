import React from 'react';
import { withStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import Dots from 'components/icons/Dots';

const styles = (theme) => ({
  icon: {
    cursor: 'pointer',
  },
  menu: {
    '& > div': {
      alignItems: 'center',
      boxShadow: '0px -0.5px 0px rgba(137, 150, 159, 0.3)',
      cursor: 'pointer',
      display: 'flex',
      fontSize: 16,
      padding: 16,
    },
    '& span': {
      marginLeft: 16,
    },
  },
});

export const ContextMenu = React.forwardRef(({ classes, options }, ref) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useImperativeHandle(ref, () => ({
    handleClose() {
      setAnchorEl(null);
    },
  }));

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const MenuItem = ({ children, onClick }) => (
    <div className={classes.menuItem} onClick={onClick}>
      {children}
    </div>
  );

  const Menu = () => (
    <div className={classes.menu}>
      {options.map((option) => (
        <MenuItem key={option.text} onClick={option.onClick}>
          {option.icon}
          <span style={{ color: option.textColor ? option.textColor : '' }}>
            {option.text}
          </span>
          
        </MenuItem>
      ))}
    </div>
  );

  return (
    <>
      <div className={classes.icon} onClick={handleClick}>
        <Dots fill={theme.palette.primary.main} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Menu />
      </Popover>
    </>
  );
});

ContextMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContextMenu);
