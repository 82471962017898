import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { Creators } from '../reducer';
import { Creators as CouponCreators } from '../../membership/coupons/reducer';
import InviteRuleForm from './InviteRuleForm';

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
});

const CreateNewRule = ({
  classes,
  createRule,
  processing,
  coupons,
  rule,
  getRule,
  getCoupons,
}) => {
  const title = 'Create New Rule';

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  useEffect(() => {
    getRule();
  }, [getRule]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <InviteRuleForm
          rule={rule}
          onSubmit={createRule}
          pageTitle={title}
          processing={processing}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ inviteRule: { rule, processing } }) => ({
  processing,
  rule: { ...rule, couponCode: rule?.couponCode?.code },
});

export default connect(mapStateToProps, {
  createRule: Creators.saveRule,
  getRule: Creators.getRule,
  getCoupons: CouponCreators.getCoupons,
})(withStyles(styles)(CreateNewRule));
