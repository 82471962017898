import React from 'react';
import RSelect, { components } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ArrowDown from 'components/icons/ArrowDown';
import ArrowUp from 'components/icons/ArrowUp';

export const Select = ({
  height = 45,
  isMulti = false,
  name,
  onBlur,
  onChange,
  options,
  placeholderColor,
  value,
  width = 200,
  ...rest
}) => {
  const theme = useTheme();

  const DropdownIndicator = (props) => {
    const {
      selectProps: { menuIsOpen },
    } = props;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <ArrowUp fill={theme.palette.primary.main} />
        ) : (
          <ArrowDown fill={theme.palette.primary.generalText} />
        )}
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.selectProps.menuColor,
      border: `1px solid ${theme.palette.primary.main}`,
      marginTop: 0,
      borderTop: 'none',
      boxShadow: 'none',
      background: `${theme.palette.background.paper}`,
    }),
    control: (_, { selectProps: { height = 45, width }, isFocused }) => ({
      width: width,
      display: 'flex',
      textTransform: 'capitalize',
      fontSize: 14,
      border: isFocused
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.secondary.gray}`,
      background: `${theme.palette.background.paper}`,
      height,
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'initial',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.palette.secondary.main,
      height: 50,
      alignItems: 'center',
      margin: 0,
      marginRight: 2,
      fontSize: 16,
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: theme.palette.primary.dark,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      cursor: 'pointer',
      height: 20,
      width: 20,
      borderRadius: 10,
      color: theme.palette.secondary.main,
      marginRight: 4,
      backgroundColor: theme.palette.primary.dark,
      ':hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.secondary.main,
      },
    }),
    option: (
      styles,
      { selectProps: { height = 45 }, data, isDisabled, isFocused, isSelected }
    ) => {
      return {
        ...styles,
        height,
        fontSize: 16,
        textTransform: 'capitalize',
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? `${theme.palette.primary.light}`
          : isFocused
          ? `${theme.palette.background.paper}`
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? `${theme.palette.primary.main}`
          : isFocused
          ? `${theme.palette.primary.main}`
          : null,
      };
    },
    placeholder: () => ({
      color: placeholderColor || 'rgba(74, 75, 107, 0.4)',
      paddingLeft: isMulti ? 16 : 0,
    }),
    singleValue: () => ({
      color: `${theme.palette.primary.main}`,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
      paddingLeft: isMulti ? 0 : 16,
    }),
  };

  let selectedValue;

  if (Array.isArray(value)) {
    selectedValue = value;
  } else {
    selectedValue = value ? options.find((o) => o.value === value) : '';
  }

  return (
    <>
      <RSelect
        components={{ DropdownIndicator }}
        height={height}
        isMulti={isMulti}
        isSearchable={false}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        styles={customStyles}
        value={selectedValue}
        width={width}
        {...rest}
      />
    </>
  );
};

Select.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
