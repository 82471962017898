import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import cx from 'classnames';
import moment from 'moment';

import NavBar from '../../../components/NavBar';

import { HOME_URL } from '..';
import { Creators } from '../reducer';
import { Creators as PlanCreators } from '../../plan/reducer';

const styles = (theme) => ({
  button: {
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    width: '60%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 80,
    padding: '10px 0px',
  },
  row: {
    display: 'flex',
    padding: '8px 24px',
  },
  label: {
    color: 'rgba(74, 75, 107, 0.4)',
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  details: {
    marginLeft: 24,
    width: 600,
  },
  detailsPanel: {
    background: theme.palette.background.paper,
    borderRadius: theme.radius,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    '& > div:first-child': {
      paddingTop: 24,
    },
    '& > div:last-child': {
      paddingBottom: 24,
    },
    minWidth: 500,
  },
  header: {
    fontSize: 21,
    fontWeight: 900,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  block: {
    display: 'block',
  },
});

const ViewCoupon = ({
  classes,
  coupons,
  getCoupons,
  coupon,
  plans,
  getPlans,
}) => {
  React.useEffect(() => {
    if (plans === null) {
      getPlans();
    }
    if (coupons === null) {
      getCoupons();
    }
  }, [coupons, getCoupons, getPlans, plans]);

  //  if (!coupons || coupons.length < 1) return null;

  return (
    <div className={classes.container}>
      <NavBar title={coupon.code} backUrl={HOME_URL} />
      <div className={classes.content}>
        <div className={classes.detailsPanel}>
          <div className={cx(classes.row, classes.block)}>
            <div className={classes.label}>Code</div>
            <div className={classes.header}>{coupon.code}</div>
          </div>
          <div className={cx(classes.row, classes.block)}>
            <div className={classes.label}>Discount Mode</div>
            <div className={classes.header}>{coupon.discountMode}</div>
          </div>
          <div className={cx(classes.row, classes.block)}>
            <div className={classes.label}>Amount</div>
            <div className={classes.header}>
              {coupon.type === 'amount'
                ? '$' + coupon.amount
                : coupon.amount + (coupon.type === 'credit' ? 'cr' : '%')}
            </div>
          </div>
          {coupon.discountMode === 'recurring' && (
            <div className={cx(classes.row, classes.block)}>
              <div className={classes.label}>Duration in months</div>
              <div className={classes.header}>{coupon.durationInMonths}</div>
            </div>
          )}
          {coupon.couponExpiration && (
            <div className={cx(classes.row, classes.block)}>
              <div className={classes.label}>Expireds</div>
              <div className={classes.header}>
                {moment(coupon.couponExpiration).format('LLLL')}
              </div>
            </div>
          )}
          <div className={cx(classes.row, classes.block)}>
            <div className={classes.label}>Usage</div>
            <div className={classes.header}>
              {(coupon.usageCount || '0') +
                '/' +
                (coupon.maxUsage || 'unlimited')}
            </div>
          </div>
          <div className={cx(classes.row, classes.block)}>
            <div className={classes.label}>Applies to</div>
            <div className={classes.header}>
              {coupon.membershipPlans.map((m) => m.title).join(', ')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  { coupons: { coupons }, plan: { plans } },
  {
    match: {
      params: { id },
    },
  }
) => ({
  coupon: coupons?.find((coupon) => coupon.code === id),
  coupons: coupons,
  plans,
});

export default connect(mapStateToProps, {
  getCoupons: Creators.getCoupons,
  getPlans: PlanCreators.getPlanGroups,
})(withStyles(styles)(ViewCoupon));
