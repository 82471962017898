import React from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const styles = (theme) => ({
  container: {
    alignItems: 'center',
    background: theme.palette.background.default,
    borderBottom: `1px solid #DFE2E6;`,
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 280,
    width: 'calc(100% - 280px)',
    zIndex: 100,
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    left: 16,
    position: 'absolute',
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: 900,
    fontSize: 21,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
});

const NavBar = ({ classes, goTo, backUrl, title }) => (
  <div className={classes.container}>
    <div
      onClick={() => goTo(backUrl)}
      className={classes.link}
    >{`<< Back`}</div>
    <div className={classes.title}>{title}</div>
  </div>
);

export default connect(null, {
  goTo: push,
})(withStyles(styles)(NavBar));
