import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Formik, useFormikContext } from 'formik';
import { object, string } from 'yup';
import { Link } from 'react-router-dom';

import { Button, Form, FormInput, FormRow, Text } from 'components';
import { Creators } from '../reducer';

const styles = (theme) => ({
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      width: '100%',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

const RequestResetForm = ({ classes, error, processing, requestReset }) => {
  const FormBody = () => {
    const { isValid, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [validateForm]);

    return (
      <Form
        title='Forgot Password?'
        error={error}
        renderHeader={() => (
          <>
            <FormRow>
              <Text>
                {
                  'Please enter your email address associated with your account below, and we will send you a link to reset your password.'
                }
              </Text>
            </FormRow>
          </>
        )}
      >
        <FormRow>
          <FormInput
            label='Email address'
            name='email'
            placeholder='Email address'
          />
        </FormRow>
        <FormRow>
          <div className={classes.buttons}>
            <div className={classes.input}>
              <Link to='/'>
                <Button title='Cancel' outlined />
              </Link>
            </div>
            <div className={classes.input}>
              <Button
                disabled={!isValid}
                processing={processing}
                processingLabel='Sending email...'
                title='reset password'
                type='submit'
              />
            </div>
          </div>
        </FormRow>
      </Form>
    );
  };

  const validationSchema = object().shape({
    email: string().email().required('Email is required'),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => requestReset(values)}
    >
      <FormBody />
    </Formik>
  );
};

const mapStateToProps = ({ auth: { error, processing } }) => ({
  error,
  processing,
});

const mapDispatchToProps = { requestReset: Creators.requestReset };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RequestResetForm));
