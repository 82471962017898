import React from 'react';

export default ({ fill = 'currentColor' }) => (
  <svg viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.60714 12.6429H4.44904C4.71136 13.047 5.03394 13.4086 5.40433 13.7143H1.60714C0.720912 13.7143 0 12.9934 0 12.1071V2.67857C0 1.79234 0.720912 1.07143 1.60714 1.07143H3.65426C3.87545 0.447824 4.47123 0 5.16964 0C5.86806 0 6.46383 0.447824 6.68502 1.07143H8.70536C9.59159 1.07143 10.3125 1.79234 10.3125 2.67857V5.826C9.97475 5.67292 9.61555 5.55898 9.24107 5.4895V2.67857C9.24107 2.3832 9.00073 2.14286 8.70536 2.14286H7.84821V4.25893H2.49107V2.14286H1.60714C1.31177 2.14286 1.07143 2.3832 1.07143 2.67857V12.1071C1.07143 12.4025 1.31177 12.6429 1.60714 12.6429ZM8.38393 6.48214C6.39007 6.48214 4.76786 8.10435 4.76786 10.0982C4.76786 12.0921 6.39007 13.7143 8.38393 13.7143C10.3778 13.7143 12 12.0921 12 10.0982C12 8.10435 10.3778 6.48214 8.38393 6.48214ZM8.38393 12.6429C6.98082 12.6429 5.83929 11.5013 5.83929 10.0982C5.83929 8.6951 6.98082 7.55357 8.38393 7.55357C9.78704 7.55357 10.9286 8.6951 10.9286 10.0982C10.9286 11.5013 9.78704 12.6429 8.38393 12.6429ZM4.63393 2.14286V1.60714C4.63393 1.31177 4.87427 1.07143 5.16964 1.07143C5.46502 1.07143 5.70536 1.31177 5.70536 1.60714V2.14286H6.77679V3.1875H3.5625V2.14286H4.63393ZM9.80357 9.5625H8.89286V8.30357H7.82143V10.6339H9.80357V9.5625Z'
      fill={fill}
    />
  </svg>
);
