import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { Button } from 'components';

const styles = (theme) => ({
  classicButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: 15,
    width: '50%',
    flex: 1,
  },
  button: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    width: 230,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    width: '100%',
  },
  dialog: {
    maxWidth: 650,
    padding: '1rem',
  },
  dialogContainer: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
});

const CustomDialog = ({
  classes,
  open,
  setIsDialogOpen,
  onOk,
  titleText,
  text,
  showCacnel = false,
  okTitle = 'Ok',
  cacnelTitle = 'Cancel',
  onFirstRender = () => {},
}) => {
  useEffect(() => {
    onFirstRender();
  });

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      fullWidth={true}
      onClose={() => setIsDialogOpen(false)}
      classes={{ paper: classes.dialog, container: classes.dialogContainer }}
    >
      <DialogTitle disableTypography id='alert-dialog-title'>
        <Typography variant='h4'>{titleText}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onOk}
          className={classes.classicButton}
          title={okTitle}
        />
        {showCacnel && (
          <Button
            onClick={() => setIsDialogOpen(false)}
            className={classes.classicButton}
            title={cacnelTitle}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default connect()(withStyles(styles)(CustomDialog));
