import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Checkbox } from '@material-ui/core';
import search from 'assets/img/new-search-icon.png';
import arrowDown from 'assets/img/new-arrow-down.png';
import arrowUp from 'assets/img/new-arrow-up.png';
import AddTeamMemberForm from './AddTeamMemberForm';
import { connect } from 'react-redux';
import { Creators, INITIAL_FILTER } from '../reducer';
import { Creators as ModalCreators } from 'modules/modal';
// import { Creators as getUsersCreators } from 'modules/admin/dashboard/membership/team-accounts';
import EditTeamMemberForm from './EditTeamMemberForm';
import Search from './Search';
import FileUploaderMember from './FileUploaderMember';
import ReactPaginate from 'react-paginate';
import { Paginate } from 'components';
import ImoprtTeamMembersByCsv from './ImoprtTeamMembersByCsv';
import { toCSV } from 'core';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import RegenerateTeamAccountInviteCode from './RegenerateTeamAccountInviteCode';
import { REACT_APP_URL } from 'core/constants';
import { Creators as UserCreator } from '../../../admin/dashboard/membership/members/reducer';
import FullTeamMembers from './FullTeamMembers';

const styles = ({ palette: { primary }, breakpoints, theme }) => ({
  container: {
    width: '100%',
    paddingLeft: '44px',
    paddingTop: '69px',
    paddingRight: '43px',
    [breakpoints.down('xs')]: {
      paddingTop: '40px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  button: {
    width: 208,
    height: 64,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  teamAccountName: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    lineHeight: '44px',
    letterSpacing: '0.35px',
    textTransform: 'uppercase',
    color: '#020301',
    [breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  usersAddedText: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.26px',
    color: '#63646F',
    paddingTop: '23px',
    [breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  button: {
    width: 208,
    height: 64,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 3.56,
    letterSpacing: 1.38,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  teamAccountName: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    lineHeight: '44px',
    letterSpacing: '0.35px',
    textTransform: 'uppercase',
    color: '#020301',
    [breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  usersAddedText: {
    fontFamily: 'Lato',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: '0.26px',
    color: '#63646F',
    paddingTop: '23px',
  },
  lineBreakerMobile: {
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '1px',
      borderStyle: 'solid',
      borderColor: '#DFE2E6',
      marginTop: '24px',
      borderWidth: '0.5px',
    },
  },
  lineBreaker: {
    width: '100%',
    height: '1px',
    borderStyle: 'solid',
    borderColor: '#DFE2E6',
    marginTop: '24px',
    borderWidth: '0.5px',
  },
  trLine: {
    borderTop: '1px solid #DFE2E6',
  },

  addUserButton: {
    width: '183px',
    height: '48px',
    color: 'white',
    backgroundColor: '#28AAE2',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  addUserButtonDisabled: {
    width: '183px',
    height: '48px',
    color: 'white',
    backgroundColor: '#cccccc',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  importCsvButton: {
    width: '183px',
    height: '48px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    marginLeft: '16px',
    '&:hover': {
      background: 'white',
    },
    [breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginTop: '10px',
      width: '100%',
    },
  },
  detleButton: {
    width: '183px',
    height: '48px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #FF2121',
    color: '#FF2121',
    marginTop: '23px',
    '&:hover': {
      background: 'white',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '80px',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: '40px',
    },
  },
  buttonsContainerSelected: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '9px',
    justifyContent: 'space-between',
    [breakpoints.down('xs')]: {
      flexDirection: 'column',
      paddingTop: '40px',
    },
  },
  youHaventAddedAnyUsersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  youHaventAddedAnyUsersText: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 900,
    textTransform: 'uppercase',
    paddingTop: '45px',
  },
  addStepsContainer: {
    display: 'flex',
    paddingTop: '24px',
    flexDirection: 'column',
  },
  addStepsText: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '16px',
    paddingTop: '16px',
  },
  singUpLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '80px',
    [breakpoints.down('xs')]: {
      paddingTop: '40px',
    },
  },
  singUpLinkText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '24px',
    letterSpacing: '0.35px',
    textTransform: 'capitalize',
    [breakpoints.down('xs')]: {
      fontSize: '20px',
    },
  },
  singUpLinkDescription: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '18px',
    letterSpacing: '0.35px',
    color: '#6F6F7B',
    paddingTop: '48px',
    width: 813,
    lineHeight: '31px',
    [breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '16px',
      lineHeight: '24px',
      paddingTop: '25px',
    },
  },
  linkUrlInputContainer: {
    paddingTop: '63px',
    paddingBottom: '30px',
  },
  urlLink: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#9899A9',
  },
  linkBox: {
    width: '100%',
    height: '40px',
    border: '1px solid #DFE2E6',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '15px',
    paddingRight: '15px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '60px',
      height: '75px',
    },
  },
  copyLinkText: {
    color: '#43B1E9',
    borderBottom: '1px solid #43B1E9',
    width: 73,
    cursor: 'pointer',
  },
  addMemberPopUp: {
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    height: 504,
    display: 'flex',
    // alignItems : "center",
    // justifyContent : "center",
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 40,
      paddingBottom: 40,
      height: '100%',
    },
  },
  importTeamMembersPopUp: {
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 64,
    paddingBottom: 64,
    height: 344,
    display: 'flex',
    // alignItems : "center",
    // justifyContent : "center",
    flexDirection: 'column',
    [breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 40,
      paddingBottom: 40,
      height: '100%',
    },
  },
  addMemberPopUpButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  popupText: {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 24,
    color: '#010200',
  },
  popUpButtonCancel: {
    width: '208px',
    height: '56px',
    color: '#28AAE2',
    backgroundColor: 'white',
    textTransform: 'none',
    border: '1px solid #28AAE2',
    fontSize: '16px',
    fontWeight: 700,
    '&:hover': {
      background: 'white',
    },
  },
  paper: {
    minWidth: '632px',
    [breakpoints.down('xs')]: {
      minWidth: '80%',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    width: '208px',
    height: '56px',
    color: 'white',
    backgroundColor: '#28AAE2',
    marginLeft: 24,
    textTransform: 'capitalize',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#28AAE2',
    },
  },
  inputField: {
    // height: 40,
    borderColor: '#DFE2E6',
    borderStyle: 'solid',
    // width: '100%',
    marginTop: '2px',
    outline: 'none',
    padding: '4px',

    '&:focus': {
      borderColor: '#28AAE2',
    },
  },
  inputFieldContianer: {
    paddingTop: '10px',
    paddingBottom: '32px',
  },
  inputFieldNames: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    color: '#A3A3B3',
  },
  inputContainer: {
    paddingTop: '16px',
    // paddingBottom: '16px',
  },
  wrapper: {
    position: 'relative',
  },

  icon: {
    height: '15px',
    width: '15px',
    backgroundImage: `url(${search})`,
    backgroundSize: 'cover',
    marginLeft: '15px',

    // backgroundImage: `url('assets/img/new-search-icon.png')`,
    backgroundRepeat: 'no-repeat',
    // padding: "4px",
    position: 'absolute',
    boxSizing: 'border-box',
    top: '50%',
    left: '2px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      marginTop: 5,
    },
  },

  searchInput: {
    height: '48px',
    boxSizing: 'border-box',
    paddingLeft: '40px',
    outline: 'none',
    width: '333px',
    border: '1px solid #DFE2E6',
    '&:focus': {
      borderColor: '#28AAE2',
    },
    [breakpoints.down('xs')]: {
      marginTop: 10,
      width: '100%',
    },
  },
  columnDirection: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  userInfoContainer: {
    width: '100%',
    paddingTop: '55px',
    borderCollapse: 'collapse',
    borderSpacing: '0 4px',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  userFirstName: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#63626C',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  userEmail: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#63626C',
    [breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  deleteText: {
    color: '#FF2121',
    borderBottom: '1px solid #FF2121',
    width: '100%',
  },
  editText: {
    color: '#28AAE2',
    borderBottom: '1px solid #28AAE2',
    width: '100%',
  },
  deleteTextBorder: {
    color: '#FF2121',
    borderBottom: '1px solid #FF2121',
    width: 48,
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      fontSize: '13px',
      width: 39,
      height: '17px',
    },
  },
  editTextBorder: {
    color: '#28AAE2',
    borderBottom: '1px solid #28AAE2',
    width: 30,
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      fontSize: '13px',
      width: 24,
      height: '17px',
    },
  },
  test: {
    '&$checked': {
      color: 'red',
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: 'red',
        '&$checked': {
          color: 'red',
        },
      },
    },
  },
  deleteAndEditButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // paddingTop: 30,
    [breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  membersName: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#020301',
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  // uploadedFilesContainer: {
  //   padding : 8,
  //   backgroundColor : "#4E4D55",
  // },
  uploadedFilesText: {
    // width : "100%",
    // height: "100%",
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#DFE2E6',
    padding: '8px',
    height: '100%',
    marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#020301',
  },
pagination: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  listStyleType: 'none',
  paddingInlineStart: 0,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  paddingTop: 32,
  '& > li': {
    // color: theme.palette.primary.generalText,
    color: '#63626C',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: '0.23px',
    // margin: 12,
    // padding: '12px',
    width: 47,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // borderStyle : "solid",
    borderTop: '1px solid rgba(74, 75, 107, 0.4)',
    borderLeft: '1px solid rgba(74, 75, 107, 0.4)',
    borderBottom: '1px solid rgba(74, 75, 107, 0.4)',
    borderRadius: '1px',
    // borderColor : "rgba(74, 75, 107, 0.4)",
  },
  '& li:first-child': {
    // color: theme.palette.primary.main,
    color: '28AAE2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& li:last-child': {
    // color: theme.palette.primary.main,
    color: '28AAE2',
    borderRight: '1px solid rgba(74, 75, 107, 0.4)',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
  },
  '& > li.disabled': {
    // color: '28AAE2',
    color: 'rgba(74, 75, 107, 0.4)',
    cursor: 'initial',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
  },
  '& > li.selected': {
    // color: theme.palette.primary.main,
    color: '#28AAE2',
    cursor: 'initial',
    borderColor: 'rgba(74, 75, 107, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [breakpoints.down('xs')]: {
    paddingBottom: '20px',
  },
},
  tableMenuContainer: {
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  mobileRegisteredContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '30px',
    [breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: '17px',
    },
  },
  notRegisteredContainer: {
    color: 'red',
    display: 'flex',
    textAlign: 'center',
    [breakpoints.down('xs')]: {
      fontSize: '13px',
    },
  },
  regenerateButtonContainer: {
    marginTop: '25px',
  },
});

const TeamDashboard = ({
  classes,
  getTeamMemberAccounts,
  teamMembers,
  deleteTeamAccountMembers,
  showModal,
  user,
  teamAccountId,
  allUsers,
  getUsers,
}) => {
  const teamAccountOwner =
    (teamAccountId ? allUsers.find((u) => u.id == teamAccountId) : user) || {};
  const inviteLink = `${REACT_APP_URL}/auth/signup/team/${teamAccountOwner.teamAccountInviteId}`;

  const [openAddTeamMemberForm, setOpenAddTeamMemberForm] = useState(false);
  const [openRegenatePopup, setOpenRegenatePopup] = useState(false);
  const [importTeamMembers, setImportTeamMembers] = useState(false);
  const [selectedMemberData, setSelectedMemberData] = useState();
  const [fileterText, setFilterText] = useState('');

  const openAddTeamMemberFormhandleClickOpen = () => {
    setOpenAddTeamMemberForm(true);
  };

  const openAddTeamMemberFormhandleClose = () => {
    setOpenAddTeamMemberForm(false);
  };

  const openRegenatePopuphandleClickOpen = () => {
    setOpenRegenatePopup(true);
  };

  const openRegenatePopuphandleClose = () => {
    setOpenRegenatePopup(false);
  };

  const openEditTeamMemberFormhandleClose = () => {
    setSelectedMemberData(undefined);
  };

  const importTeamMembershandleClickOpen = () => {
    setImportTeamMembers(true);
  };

  const importTeamMembershandleClickClose = () => {
    setImportTeamMembers(false);
  };

  const copy = async () => {
    await navigator.clipboard.writeText(inviteLink);
  };

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + 10;
  const filteredUsers = teamMembers.filter((user) => {
    if (!fileterText) {
      return true;
    }
    const filterWords = fileterText.toLowerCase().split(' ');
    return (
      filterWords.filter((term) => {
        if (user?.firstName?.toLowerCase().includes(term)) {
          return true;
        }
        if (user?.lastName?.toLowerCase().includes(term)) {
          return true;
        }
        if (user?.email?.toLowerCase().includes(term)) {
          return true;
        }
      }).length >= filterWords.length
    );
  });

  const currentPageUsers = filteredUsers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredUsers.length / 10);

  const handlePageClick = (event) => {
    setSelectedValues([]);
    const newOffset = (event.selected * 10) % filteredUsers.length;

    setItemOffset(newOffset);
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const handleClickSelectedValues = (id) => {
    const foundId = selectedValues.find((i) => i == id);
    let arr = [];
    if (foundId) {
      arr = selectedValues.filter((i) => i !== id);
    } else {
      arr = [...selectedValues, id];
    }

    setSelectedValues(arr);
  };

  useEffect(() => {
    if (teamAccountOwner.id) {
      getTeamMemberAccounts(teamAccountOwner.id);
    }
  }, [getTeamMemberAccounts, teamAccountOwner?.id]);

  useEffect(() => {
    if (teamAccountId) {
      console.log('asdasdasdasdas');
      getUsers();
    }
  }, []);

  const MembersTable = ({}) => {
    return (
      <div style={{ marginTop: '24px' }}>
        <table className={classes.userInfoContainer}>
          <thead style={{ height: '79px' }}>
            <tr className={classes.membersName}>
              <td></td>
              <td>First Name</td>
              <td>Last Name</td>
              <td>Email</td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {currentPageUsers &&
              currentPageUsers.map((user) => {
                return (
                  <>
                    {/* <div> */}
                    <tr
                      key={user.id}
                      style={{ height: '79px' }}
                      className={classes.trLine}
                    >
                      {/* <span className={classes.tableMenuContainer}>
                    <span> */}
                      <td>
                        <Checkbox
                          type='checkbox'
                          onChange={() => {
                            handleClickSelectedValues(user.id);
                          }}
                          checked={selectedValues.find((val) => val == user.id)}
                          color='primary'
                        />
                      </td>
                      <td className={classes.userFirstName}>
                        {user.firstName}
                      </td>
                      <td className={classes.userFirstName}>{user.lastName}</td>
                      <td className={classes.userEmail}>{user.email}</td>
                      {/* </div> */}
                      {/* </span>
                      <span> */}
                      <span className={classes.mobileRegisteredContainer}>
                        <td>
                          {user.user ? (
                            <span style={{ color: 'green' }}>Registered</span>
                          ) : (
                            <span className={classes.notRegisteredContainer}>
                              Not registered
                            </span>
                          )}
                        </td>
                        <td className={classes.deleteAndEditButtonContainer}>
                          <span
                            className={classes.deleteTextBorder}
                            onClick={() => {
                              showModal(
                                'deleteConfirmation',
                                () =>
                                  deleteTeamAccountMembers(
                                    [user.id],
                                    teamAccountOwner.id
                                  ),
                                'Are you sure you want to delete Member?'
                              );
                            }}
                          >
                            Delete
                          </span>
                          <span
                            className={classes.editTextBorder}
                            style={{ marginLeft: '24px' }}
                            onClick={() => setSelectedMemberData(user)}
                          >
                            Edit
                          </span>
                        </td>
                      </span>
                      {/* </span>
                      </span> */}
                    </tr>
                    {/* <div className={classes.lineBreaker} /> */}
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.teamAccountName}>
        Team Account for “{teamAccountOwner.companyName}”
      </div>
      <div className={classes.lineBreaker} />
      <div className={classes.usersAddedText}>
        {teamMembers.length} of {teamAccountOwner.teamMembersCount} Users Added
      </div>
      {selectedValues.length > 0 ? (
        <Button
          className={classes.detleButton}
          type='button'
          onClick={() => {
            showModal(
              'deleteConfirmation',
              () =>
                deleteTeamAccountMembers(
                  selectedValues.map((value) => value),
                  teamAccountOwner.id
                ),
              'Are you sure you want to delete Members?'
            );
            setSelectedValues([]);
          }}
        >
          <span> Delete Selected </span>
        </Button>
      ) : null}

      <div
        className={
          selectedValues.length > 0
            ? classes.buttonsContainerSelected
            : classes.buttonsContainer
        }
      >
        <div className={classes.columnDirection}>
          {teamMembers.length >= teamAccountOwner.teamMembersCount ? (
            <Button
              className={classes.addUserButtonDisabled}
              // addUserButtonDisabled
              type='button'
              onClick={openAddTeamMemberFormhandleClickOpen}
              disabled
            >
              <div style={{ color: 'white' }}> + Add User</div>
            </Button>
          ) : (
            <Button
              className={classes.addUserButton}
              // addUserButtonDisabled
              type='button'
              onClick={openAddTeamMemberFormhandleClickOpen}
              // disabled={teamMembers.length >= teamAccountOwner.teamMembersCount}
            >
              <div style={{ color: 'white' }}> + Add User</div>
            </Button>
          )}
          <Button
            className={classes.importCsvButton}
            type='button'
            onClick={importTeamMembershandleClickOpen}
          >
            <img src={arrowUp} />
            <span style={{ paddingLeft: '8px' }}> Import .csv</span>
          </Button>
          {teamMembers.length > 0 ? (
            <Button
              className={classes.importCsvButton}
              title='+ Export .csv'
              type='button'
              onClick={() =>
                toCSV({
                  dataJSON: teamMembers,
                  fields: ['id', 'firstName', 'lastName', 'email', ''],
                  fileName: 'team-members.csv',
                })
              }
            >
              <img src={arrowDown} />
              <span style={{ paddingLeft: '8px' }}> Export .csv</span>
            </Button>
          ) : null}
        </div>
        <Search
          value={fileterText}
          onSearchChange={(value) => {
            setFilterText(value);
            setItemOffset(0);
            setSelectedValues([]);
          }}
        />
      </div>
      {teamMembers.length > 0 ? (
        <>
          <MembersTable />
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            pageCount={pageCount}
            previousLabel='<'
            renderOnZeroPageCount={null}
            containerClassName={classes.pagination}
          />
        </>
      ) : (
        <div className={classes.youHaventAddedAnyUsersContainer}>
          <div className={classes.youHaventAddedAnyUsersText}>
            You haven't added any Users yet. There are 3 ways to add Users:
          </div>
          <div className={classes.addStepsContainer}>
            <div className={classes.addStepsText}>
              1. Use the "Add" button above to manually add Users.
            </div>
            <div className={classes.addStepsText}>
              2. Use the "Import" button above to import a formatted .csv to add
              Users in bulk.
            </div>
            <div className={classes.addStepsText}>
              3. Copy your "Sign Up URL Link" below to send out to your team.
            </div>
          </div>
        </div>
      )}
      <div className={classes.singUpLinkContainer}>
        <div className={classes.singUpLinkText}>SIGN UP URL LINK</div>
        <div className={classes.lineBreakerMobile} />
        <div className={classes.singUpLinkDescription}>
          Send this link to your team to allow them to sign up by themselves.
          When your team uses this sign up link, they will automatically be
          added to your account.
        </div>
      </div>

      <div className={classes.linkUrlInputContainer}>
        <div className={classes.urlLink}>URL Link</div>
        <div className={classes.linkBox}>
          {/* <input type='text' value={text} onChange={inputHandler} /> */}
          {inviteLink}{' '}
          <div
            className={classes.copyLinkText}
            onClick={() => {
              copy();
              toastr.success('Succes', 'Link successfully copied');
            }}
            disabled={!inviteLink}
          >
            Copy Link
          </div>
        </div>
        <div className={classes.regenerateButtonContainer}>
          <Button
            className={classes.addUserButton}
            type='button'
            onClick={openRegenatePopuphandleClickOpen}
          >
            <div style={{ color: 'white' }}> Generate new link</div>
          </Button>
        </div>
      </div>

      {/* <RegenerateTeamAccountInviteCode
        onOpen={openRegenatePopup}
        onClose={openRegenatePopuphandleClose}
        accountId={teamAccountOwner.id}
      />
      <FullTeamMembers
        onOpen={openRegenatePopup}
        onClose={openRegenatePopuphandleClose}
        accountId={teamAccountOwner.id}
      /> */}

      {teamMembers.length >= teamAccountOwner.teamMembersCount ? (
        <FullTeamMembers
          onOpen={openRegenatePopup}
          onClose={openRegenatePopuphandleClose}
          accountId={teamAccountOwner.id}
          text={
            'Your email list exceeds your plan’s total team member login amount. Please edit your list based on the number of logins included in your plan and try again. You contact us at support@movementvault.com to increase your team’s login amount.'
          }
        />
      ) : (
        <RegenerateTeamAccountInviteCode
          onOpen={openRegenatePopup}
          onClose={openRegenatePopuphandleClose}
          accountId={teamAccountOwner.id}
        />
      )}

      <AddTeamMemberForm
        onClose={openAddTeamMemberFormhandleClose}
        open={openAddTeamMemberForm}
        teamAccountId={teamAccountOwner.id}
      />

      {selectedMemberData ? (
        <EditTeamMemberForm
          onClose={openEditTeamMemberFormhandleClose}
          memberData={selectedMemberData}
          teamAccountId={teamAccountOwner.id}
        />
      ) : null}
      <FileUploaderMember
        onOpen={importTeamMembers}
        onClose={importTeamMembershandleClickClose}
        teamAccountId={teamAccountOwner.id}
        currentMembersCount={teamMembers.length}
        maxMemberCount={teamAccountOwner.teamMembersCount}
      />
    </div>
  );
};
const mapStateToProps = (
  { membership, teamMembers, auth: { user } },
  {
    match: {
      params: { teamAccountId },
    },
  }
) => {
  return {
    teamMembers: teamMembers.teamMembers,
    user,
    filter: teamMembers.filter,
    teamAccountId,
    allUsers: membership.users,
  };
};

const mapDispatchToProps = {
  getTeamMemberAccounts: Creators.getTeamMemberAccounts,
  deleteTeamAccountMembers: Creators.deleteTeamAccountMembers,
  showModal: ModalCreators.showModal,
  setFilter: Creators.setFilter,
  goTo: push,
  getUsers: UserCreator.getUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamDashboard));
