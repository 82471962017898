import { createActions } from 'reduxsauce';

// Action creators
const { Types, Creators } = createActions(
  {
    updateCheckSuccess: ['response'],
    updateCheckFail: ['error'],
    updateCheck: (data) => ({
      type: 'app/UPDATE_CHECK',
      payload: {
        request: {
          url: `site/update-check`,
          method: 'post',
          data,
        },
      },
    }),
  },
  { prefix: 'app/' }
);

export { Creators, Types };
