import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';

import { toTime } from 'core';
import { Chip, Favorite } from 'modules/dashboard';
import clockImg from 'assets/svg/clock.svg';
import playImg from 'assets/svg/play.svg';

const styles = ({ breakpoints, palette: { background, primary } }) => ({
  actionButton: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 45,
    justifyContent: 'center',
    width: 45,
    [breakpoints.down('lg')]: {
      height: 'unset',
      width: 'unset',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'actions',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    [breakpoints.down('lg')]: {
      gap: '1rem',
      justifyContent: 'flex-start',
      marginTop: '0.5rem',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chips: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '0.3rem',
    gridArea: 'tags',
    [breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
    },
  },
  container: {
    cursor: 'pointer',
    alignItems: 'center',
    borderBottom: 'solid thin #dfe2e6',
    display: 'grid',
    gridTemplateAreas: '"thumbnail title tags duration actions"',
    gridTemplateColumns: '160px auto auto 120px 65px',
    gap: '0.5rem 2rem',
    margin: '0 -1rem',
    padding: '1rem',
    borderRadius: 4,
    '&:hover': {
      background: 'rgba(0, 0, 10, 0.05)',
    },
    '& > div': {
      minHeight: 45,
    },
    [breakpoints.down('lg')]: {
      margin: 'unset',
      gridTemplateColumns: '160px auto 120px',
      gridTemplateAreas: `"thumbnail title title"
"thumbnail tags duration"
"thumbnail actions actions"`,
    },
    [breakpoints.down('md')]: {
      gap: '0.5rem 1rem',
      gridTemplateColumns: '90px auto',
      gridTemplateAreas: `"thumbnail title"
"thumbnail tags"
"thumbnail duration"
"thumbnail actions"`,
      '& > div': {
        minHeight: 'unset',
      },
    },
  },
  dialogText: {
    fontSize: 22,
    fontWeight: 900,
    letterSpacing: 0.35,
    lineHeight: 1.42,
    textTransform: 'uppercase',
  },
  duration: {
    gridArea: 'duration',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: 0.2,
    gap: '0.5rem',
    color : "black",
    [breakpoints.down('lg')]: {
      justifyContent: 'flex-end',
    },
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  thumbnail: {
    gridArea: 'thumbnail',
    height: '100%',
    '& > img': {
      borderRadius: 4,
      cursor: 'pointer',
      objectFit: 'cover',
      height: '160px',
      width: '160px',
    },
    [breakpoints.down('md')]: {
      width: '100%',
      '& > img': {
        height: '90px',
        width: '90px',
      },
    },
  },
  itemTitle: {
    minWidth: 300,
    gridArea: 'title',
    [breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  input: {
    '& > a': {
      textDecoration: 'none',
    },
    width: 'calc(50% - 12px)',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    width: 550,
    justifyContent: 'space-between',
    padding: 64,
  },
  descriptionBlock: {
    height: '100%',
    overflow: 'hidden',
  },
  text: {
    whiteSpace: 'pre-line',
    color: primary.generalText,
    fontSize: 16,
    lineHeight: 1.5,
    marginTop: '1rem',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
  title: {
    color: primary.black,
    fontSize: 20,
    fontWeight: 500,
    [breakpoints.down('xs')]: {
      fontSize: 18,
      lineHeight: 1.44,
    },
  },
});

const noop = () => {};

const ListItem = ({
  chips = [],
  classes,
  isExerciseList = false,
  isFavorite,
  isFavoriteList = false,
  item,
  onAddFavorite = noop,
  onClick = noop,
  onPlay = () => {},
  onDeleteFavorite = noop,
  processing,
  noXMargin = false,
}) => {
  const itemChips = item.chips || chips;
  const itemDuration =
    item.duration || item.video?.additionalData?.duration || 0;
  return (
    <div
      className={classes.container}
      onClick={() => onClick(item)}
      data-active-since={item.activeSince}
    >
      <div className={classes.thumbnail} >
        <img
          alt={item.title}
          src={`${item.thumbnail?.publicLocation}?width=280`}
          loading='lazy'
        />
      </div>
      <div className={classes.itemTitle}>
        <div className={classes.title}>{item.title}</div>
      </div>
      {Boolean(itemChips?.length) && (
        <div className={classes.chips}>
          {itemChips.map((chip) => (
            <Chip key={chip} text={chip} />
          ))}
        </div>
      )}
      <div className={classes.duration}>
        <img src={clockImg} alt='duration' />

        {isNaN(itemDuration) ? itemDuration : toTime(itemDuration)}
        
      </div>
      <div className={classes.actions}>
        <div className={classes.actionButton}>
          <img src={playImg} alt={'Play'} />
        </div>
        <div className={classes.actionButton}>
          
          <Favorite
            isDeleteMode={isFavoriteList}
            isFavorite={isFavorite}
            itemTitle={item.title}
            onAddFavorite={async () => {
              console.log(item.id);
              await onAddFavorite({ id: item.id });
            }}
            onDeleteFavorite={async () => {
              await onDeleteFavorite({ id: item.id });
            }}
            processing={processing}
          /> 
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(ListItem);
