import 'react-dropzone-uploader/dist/styles.css';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { withStyles } from '@material-ui/styles';

import { localStorage } from 'core';
import { BASE_URL, TOKEN } from 'core/constants';

import placeholderAvatar from 'assets/svg/avatar.svg';

const styles = ({ palette: { primary } }) => ({
  avatarImage: {
    position: 'relative',
    '& > img': {
      height: 128,
      objectFit: 'cover',
      width: 128,
    },
    '& > label': {
      alignItems: 'center',
      bottom: 0,
      cursor: 'pointer',
      height: 128,
      left: 0,
      position: 'absolute',
      width: 128,
    },
  },
  avatarLabel: {
    background: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    color: primary.main,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 8,
    position: 'absolute',
    textTransform: 'capitalize',
    width: '100%',
  },
  button: {
    backgroundColor: primary.main,
    borderRadius: 2,
    color: primary.contrastText,
    cursor: 'pointer',
    fontWeight: 'bold',
    letterSpacing: 0.3,
    padding: 15,
  },
  container: {
    height: 128,
    width: 128,
  },
  text: {
    color: primary.grayText,
    fontSize: 14,
    lineHeight: 1.5,
  },
});

const FileUploader = ({ classes, initialFileUrl, onFileUploaded, title }) => {
  const [fileUrl, setFileUrl] = useState('');
  const Input = ({ accept, onFiles, getFilesFromEvent }) => {
    const onChange = (e) => {
      getFilesFromEvent(e).then((chosenFiles) => {
        onFiles(chosenFiles);
      });
    };

    return (
      <div>
        <div className={classes.avatarImage}>
          <img
            alt='preview'
            src={fileUrl || initialFileUrl || placeholderAvatar}
          />
          <label>
            <input
              accept={accept}
              multiple
              onChange={onChange}
              style={{ display: 'none' }}
              type='file'
            />
            <div className={classes.avatarLabel}>
              <div onChange={onChange}>change image</div>
            </div>
          </label>
        </div>
      </div>
    );
  };

  const getUploadParams = () => {
    const token = localStorage.getItem(TOKEN);

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${BASE_URL}/multimedia/image`,
    };
  };

  const handleChangeStatus = ({ remove, xhr }, status) => {
    if (status === 'done') {
      const responseObj = JSON.parse(xhr.response);
      onFileUploaded({ id: responseObj.id, url: responseObj.location });
      setFileUrl(responseObj.location);
      remove();
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <div className={classes.container}>
      {title && (
        <div className={classes.text} style={{ marginBottom: 16 }}>
          {title}
        </div>
      )}
      <Dropzone
        accept='.png,.jpg,.jpeg'
        canCancel
        getFilesFromEvent={getFilesFromEvent}
        getUploadParams={getUploadParams}
        InputComponent={Input}
        maxFiles={1}
        multiple={false}
        onChangeStatus={handleChangeStatus}
        styles={{
          dropzone: {
            borderRadius: 0,
            border: 'none',
            minHeight: 128,
            maxHeight: 128,
            overflow: 'hidden',
          },
          preview: {
            flexDirection: 'column',
          },
          previewImage: {
            maxHeight: 100,
          },
        }}
      />
    </div>
  );
};

export default withStyles(styles)(FileUploader);
